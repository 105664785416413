import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';
import dayjs from 'dayjs';

const MultiLineChart = ({ title, subtitle, yAxisTitle, categories, series, inverted, chartType, type, custom, format }) => {
    CommonChartsConfig();
    const xAxisConfig = custom ? {
        type: 'datetime',
        labels: {
            formatter: function () {
                return dayjs(this.x).format(format);
            }
        },
        tickInterval: 24 * 3600 * 1000 * 30
    } : {
        categories: categories,
        type: type
    };

    const plotOptionsConfig = custom ? {
        series: {
            pointStart: Date.UTC(2023, 4, 1),
            pointInterval: 24 * 3600 * 1000,
        }
    } : {
        series: {
            pointStart: 0
        }
    };
    const options = {
        chart: {
            type: chartType || 'line',
            inverted: inverted
        },
        title: {
            text: title,
            align: 'left'
        },
        subtitle: {
            text: subtitle,
            align: 'left'
        },
        yAxis: {
            title: {
                text: yAxisTitle
            }
        },
        xAxis: xAxisConfig,
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },
        plotOptions: plotOptionsConfig,
        series: series,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        },
        credits: {
            enabled: false
        }
    };
    if (custom) {
        options.tooltip = {
            formatter: function () {
                return dayjs(this.x).format(format) + ': <b>' + this.y + '</b>';
            }
        };
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default MultiLineChart;
