import { Card, CardContent, CardHeader, Divider, Grid, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import React from 'react';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import MoreVertIcon from '@mui/icons-material/MoreVert';


export const Questions = (props) => {
    const { question, index, handleMenuOpen, moreActions, classes, t, tOpts, answerDetail, comparatorDetail, toggleStates, handleToggleChange, questionTypeArray, textTotalCount, textDistinctCount, questionWithDynamicOptions, minDate, maxDate } = props;
    return (
        <div>
            <Card >
                <CardHeader
                    title={
                        <Grid className={classes.mainHeader}>
                            <Typography className={classes.surveyDetailsItems}>{`${index + 1}. ${question?.text}`}</Typography>
                            <div className={classes.SectionIconMain}>
                                <Tooltip title="Responses">
                                    <div className={classes.dtnSectionIconSecondary}>
                                        <SpeakerNotesIcon />
                                        <Typography style={{ marginLeft: '5px' }}>{t(question?.totalCount || 0, tOpts)}</Typography>
                                    </div>
                                </Tooltip>
                                <MoreVertIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleMenuOpen(e, question)} />
                                <moreActions />
                            </div>
                        </Grid>
                    }
                />
                < Divider orientation='horizontal' sx={{ mb: 1 }
                } />
                < Grid container spacing={2} className={classes.mainHeader}>
                    <Grid item xs>
                        {question.type === 'text' || question.type === 'textarea' ? (
                            <div className={classes.questionsContent}>
                                <Typography>{t(`Total Comments: ${textTotalCount || 0}`, tOpts)}</Typography>
                                <Typography>{t(`Distinct Comments: ${textDistinctCount || 0}`, tOpts)}</Typography>
                            </div>
                        ) : null}
                        {question.type === 'date' ? (
                            <div className={classes.questionsContent}>
                                <Typography >{t(`Date Range`, tOpts)}</Typography>
                                <Typography>{t(`${minDate || null} - ${maxDate || null}`, tOpts)}</Typography>
                            </div>
                        ) : null
                        }
                    </Grid>
                    <Grid item xs>
                        {
                            (question.validatedFromAsset || questionTypeArray?.includes(question?.type) || questionWithDynamicOptions) ? (
                                <div className={classes.toggleSection}>
                                    <ToggleButtonGroup
                                        size="small"
                                        value={toggleStates[question.id] || 'top10'}
                                        exclusive
                                        onChange={(event, newValue) => handleToggleChange(event, newValue, question.id)}
                                        aria-label="toggle-button-group"
                                    >
                                        <ToggleButton value="all" aria-label="all values">
                                            {t(`All values`, tOpts)}
                                        </ToggleButton>
                                        <ToggleButton value="top10" aria-label="top 10 values">
                                            {t(`Top 10`, tOpts)}
                                        </ToggleButton>
                                        <ToggleButton value="bottom10" aria-label="bottom 10 values">
                                            {t(` Bottom 10`, tOpts)}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            ) : null
                        }
                    </Grid>
                </Grid >
                <CardContent>
                    {answerDetail || comparatorDetail}
                </CardContent>
            </Card >
        </div>
    )
}
