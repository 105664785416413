import dayjs from 'dayjs';
import date from '../utils/date'

const playbookSalesHistoryFilterDate = dayjs().subtract(30, 'day').format(date.formats.tablePreferenceEnumFormat);
const playbookAcostaReportStartDateFilter = dayjs().subtract(7, 'day').format(date.formats.tablePreferenceEnumFormat);
const playbookAcostaReportEndDateFilter = dayjs().format(date.formats.tablePreferenceEnumFormat);
const playbookOrderSuggestionHistoryFilterDate = dayjs().subtract(7, 'day').format(date.formats.tablePreferenceEnumFormat);

const tablePreferenceEnums = {
   playbookAssetList: {
      "sortModel": [
         {
            "field": "Installation",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "AssetId": false,
         "Street": false,
         "City": false,
         "State": false,
         "PostalCode": false,
         "PrimaryRepName": false,
         "PrimarySalesRep": false,
         "SecondaryRepName": false,
         "LocationRoute": false,
         "PlanogramName": false
      },
      "gridColumn": [
         {
            "headerName": "Asset Serial #",
            "field": "SerialNumber",
            "type": "string",
            "label": "Asset Serial #",
            "required": true,
            "width": 160,
            "pinned": true
         },
         {
            "headerName": "Asset Type",
            "field": "AssetType",
            "type": "string",
            "label": "Asset Type",
            "required": true,
            "width": 150,
            "pinned": true
         },
         {
            "headerName": "Is Smart",
            "field": "IsSmart",
            "type": "boolean",
            "label": "Is Smart",
            "required": true,
            "width": 100,
            "pinned": true
         },
         {
            "headerName": "Asset ID",
            "field": "AssetId",
            "type": "number",
            "hide": true,
            "width": 80,
            "label": "Asset ID"
         },
         {
            "headerName": "Camera Serial #",
            "field": "SmartDeviceSerialNumber",
            "type": "string",
            "label": "Camera Serial #",
            "required": false,
            "width": 120
         },
         {
            "headerName": "Hub Serial #",
            "field": "MDMSerialNumber",
            "type": "string",
            "label": "Hub Serial #",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Installed At",
            "field": "Installation",
            "type": "date",
            "label": "Installed At",
            "required": false,
            "width": 120,
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "Installed By",
            "field": "InstallerUser",
            "type": "string",
            "label": "Installed By",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Outlet Name",
            "field": "Location",
            "type": "string",
            "label": "Outlet Name",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Outlet Code",
            "field": "LocationCode",
            "type": "string",
            "label": "Outlet Code",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Street",
            "field": "Street",
            "type": "string",
            "hide": true,
            "label": "Street",
            "required": false,
            "width": 150
         },
         {
            "headerName": "City",
            "field": "City",
            "type": "string",
            "hide": true,
            "label": "City",
            "required": false,
            "width": 100
         },
         {
            "headerName": "State",
            "field": "State",
            "type": "string",
            "hide": true,
            "label": "State",
            "required": false,
            "width": 100
         },
         {
            "headerName": "Postal Code",
            "field": "PostalCode",
            "type": "string",
            "hide": true,
            "label": "Postal Code",
            "required": false,
            "width": 100
         },
         {
            "headerName": "Primary Sales Rep",
            "field": "PrimaryRepName",
            "type": "string",
            "hide": true,
            "label": "Primary Sales Rep",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Primary Sales Rep E-mail",
            "field": "PrimarySalesRep",
            "type": "string",
            "hide": true,
            "label": "Primary Sales Rep E-mail",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Secondary Sales Rep",
            "field": "SecondaryRepName",
            "type": "string",
            "hide": true,
            "label": "Secondary Sales Rep",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Delivery Route",
            "field": "LocationRoute",
            "type": "string",
            "hide": true,
            "label": "Delivery Route",
            "required": false,
            "width": 90
         },
         {
            "headerName": "Planogram",
            "field": "PlanogramName",
            "type": "string",
            "hide": true,
            "label": "Planogram",
            "required": false,
            "width": 150
         },
         {
            "headerName": "Outlet Active",
            "field": "OutletActive",
            "type": "boolean",
            "label": "Outlet Active",
            "required": false,
            "width": 100
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__",
            "SerialNumber",
            "AssetType",
            "IsSmart"
         ],
         "right": [

         ]
      }
   },
   playbookInFieldStatus: {
      "sortModel": [
         {
            "field": "SerialNumber",
            "sort": "asc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "AssetType": false,
         "IsChestCooler": false,
         "Street": false,
         "Street2": false,
         "Street3": false,
         "PostalCode": false,
         "City": false,
         "State": false,
         "Country": false,
         "MarketName": false,
         "Channel": false,
         "ClassificationName": false,
         "DistributorName": false,
         "LocationRoute": false,
         "PreSellerRoute": false,
         "PrimaryRepName": false,
         "HubBatteryLevel": false,
         "LatestRssi": false,
         "BatteryLevel": false,
         "SmartDeviceRssi": false
      },
      "gridColumn": [
         {
            "headerName": "Serial Number",
            "field": "SerialNumber",
            "type": "string",
            "width": 150,
            "label": "Serial Number",
            "pinned": true
         },
         {
            "headerName": "Asset Type",
            "field": "AssetType",
            "type": "string",
            "width": 100,
            "label": "Asset Type",
            "hide": true
         },
         {
            "headerName": "Chest Cooler",
            "field": "IsChestCooler",
            "type": "boolean",
            "width": 100,
            "label": "Chest Cooler",
            "hide": true
         },
         {
            "headerName": "Outlet Name",
            "field": "Location",
            "type": "string",
            "width": 200,
            "label": "Outlet Name"
         },
         {
            "headerName": "Outlet Code",
            "field": "LocationCode",
            "type": "string",
            "width": 100,
            "label": "Outlet Code"
         },
         {
            "headerName": "Street",
            "field": "Street",
            "type": "string",
            "width": 100,
            "label": "Street",
            "hide": true
         },
         {
            "headerName": "Street 2",
            "field": "Street2",
            "type": "string",
            "width": 100,
            "label": "Street 2",
            "hide": true
         },
         {
            "headerName": "Street 3",
            "field": "Street3",
            "type": "string",
            "width": 100,
            "label": "Street 3",
            "hide": true
         },
         {
            "headerName": "Postal",
            "field": "PostalCode",
            "type": "string",
            "width": 100,
            "label": "Postal",
            "hide": true
         },
         {
            "headerName": "City",
            "field": "City",
            "type": "string",
            "width": 80,
            "label": "City",
            "hide": true
         },
         {
            "headerName": "State",
            "field": "State",
            "type": "string",
            "width": 80,
            "label": "State",
            "hide": true
         },
         {
            "headerName": "Country",
            "field": "Country",
            "type": "string",
            "width": 80,
            "label": "Country",
            "hide": true
         },
         {
            "headerName": "Market",
            "field": "MarketName",
            "type": "string",
            "width": 100,
            "label": "Market",
            "hide": true
         },
         {
            "headerName": "Channel",
            "field": "Channel",
            "type": "string",
            "width": 100,
            "label": "Channel",
            "hide": true
         },
         {
            "headerName": "Classification",
            "field": "ClassificationName",
            "type": "string",
            "width": 100,
            "label": "Classification",
            "hide": true
         },
         {
            "headerName": "Distributor",
            "field": "DistributorName",
            "type": "string",
            "width": 100,
            "label": "Distributor",
            "hide": true
         },
         {
            "headerName": "Route",
            "field": "LocationRoute",
            "type": "string",
            "width": 80,
            "label": "Route",
            "hide": true
         },
         {
            "headerName": "Pre Seller Route",
            "field": "PreSellerRoute",
            "type": "string",
            "width": 150,
            "label": "Pre Seller Route",
            "hide": true
         },
         {
            "headerName": "Primary Sales Rep Name",
            "field": "PrimaryRepName",
            "type": "string",
            "width": 200,
            "label": "Primary Sales Rep Name",
            "hide": true
         },
         {
            "headerName": "MDM Hub Serial",
            "field": "MDMSerialNumber",
            "type": "string",
            "width": 150,
            "label": "MDM Hub Serial"
         },
         {
            "headerName": "MDM Last Ping",
            "field": "MdmLastPing",
            "type": "date",
            "width": 150,
            "label": "MDM Last Ping",
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "Hub Battery",
            "field": "HubBatteryLevel",
            "type": "number",
            "width": 100,
            "label": "Hub Battery",
            "hide": true
         },
         {
            "headerName": "Hub RSSI",
            "field": "LatestRssi",
            "type": "number",
            "width": 100,
            "label": "Hub RSSI",
            "hide": true
         },
         {
            "headerName": "Smart Device",
            "field": "SmartDeviceSerialNumber",
            "type": "string",
            "width": 100,
            "label": "Smart Device"
         },
         {
            "headerName": "Smart Device Ping",
            "field": "LastPing",
            "type": "date",
            "width": 150,
            "label": "Smart Device Ping",
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "Camera Battery In Millivolts",
            "field": "BatteryLevel",
            "type": "number",
            "width": 230,
            "label": "Camera Battery In Millivolts",
            "hide": true
         },
         {
            "headerName": "Smart Device RSSI",
            "field": "SmartDeviceRssi",
            "type": "number",
            "width": 150,
            "label": "Smart Device RSSI",
            "hide": true
         },
         {
            "headerName": "Door Open",
            "field": "DoorOpen",
            "type": "date",
            "width": 120,
            "label": "Door Open",
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "Planogram",
            "field": "PlanogramName",
            "type": "string",
            "width": 160,
            "label": "Planogram"
         },
         {
            "headerName": "Last Image Capture Time",
            "field": "LastImageDateTime",
            "type": "date",
            "width": 200,
            "label": "Last Image Capture Time",
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "Hardware Status",
            "field": "HardwareStatus",
            "type": "singleSelect",
            "width": 180,
            "label": "Hardware Status",
            "pinned": "right",
            "lookup": "HardwareStatus",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isAnyOf"
               }
            ]
         },
         {
            "headerName": "Responsible",
            "field": "Responsible",
            "type": "string",
            "width": 100,
            "label": "Responsible",
            "pinned": "right"
         },
         {
            "headerName": "Primary Action",
            "field": "PrimaryAction",
            "type": "string",
            "width": 150,
            "label": "Primary Action",
            "pinned": "right"
         },
         {
            "headerName": "Age",
            "field": "Age",
            "type": "number",
            "width": 100,
            "label": "Age",
            "pinned": "right"
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__",
            "SerialNumber"
         ],
         "right": [
            "HardwareStatus",
            "Responsible",
            "PrimaryAction",
            "Age"
         ]
      }
   },
   locationReorderInbox: {
      "sortModel": [

      ],
      "filterModel": {
         "items": [

         ],
         "linkOperator": "and"
      },
      "columnVisibilityModel": {
         "Code": true,
         "LocationName": true,
         "Street": true,
         "City": true,
         "State": true,
         "PostalCode": false,
         "OrderSuggestion": true,
         "LastImageDateTime": false,
         "DeliveryNotes": false,
         "ShareOfShelf": false,
         "TotalCase": true,
         "FinalTotalCase": true,
         "TotalOrder": true,
         "MarketName": false,
         "LastDeliveryDate": true,
         "LastOrderDate": true,
         "LocationTypeName": false,
         "ClassificationName": false,
         "LocationRoute": false,
         "PreSellerRouteName": false,
         "SalesRepName": false,
         "Image": false,
         "FinalMoQ": false,
         "FinalMoV": false,
         "__detail_panel_toggle__": true,
         "LastMOVAndMOQDateTime": false,
         ClientName: false
      },
      "gridColumn": [
         {
            "width": 50,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "checkboxSelection",
            "align": "center",
            "filterOperators": [
               {
                  "value": "is"
               }
            ],
            "headerAlign": "center",
            "field": "__check__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "cellClassName": "MuiDataGrid-cellCheckbox",
            "headerClassName": "MuiDataGrid-columnHeaderCheckbox",
            "headerName": "Checkbox selection",
            "computedWidth": 50
         },
         {
            "id": "Code",
            "field": "Code",
            "headerName": "Outlet Code",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "hide": true,
            "width": 100
         },
         {
            "id": "LocationName",
            "field": "LocationName",
            "headerName": "Outlet Name",
            "flex": 0,
            "minWidth": 200,
            "type": "string",
            "width": 100
         },
         {
            "id": "Street",
            "field": "Street",
            "headerName": "Street",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "City",
            "field": "City",
            "headerName": "City",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "State",
            "field": "State",
            "headerName": "State",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "PostalCode",
            "field": "PostalCode",
            "headerName": "Postal Code",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "OrderSuggestion",
            "field": "OrderSuggestion",
            "headerName": "Order Suggestion",
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "minWidth": 150,
            "width": 100
         },
         {
            "id": "DeliveryNotes",
            "field": "DeliveryNotes",
            "headerName": "Future Delivery Dates",
            "flex": 0,
            "sortable": false,
            "minWidth": 100,
            "sort": false,
            "width": 100
         },
         {
            "id": "ShareOfShelf",
            "field": "ShareOfShelf",
            "headerName": "Fill Level",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "FinalTotalCase",
            "field": "FinalTotalCase",
            "headerName": "Cases",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "FinalTotalOrder",
            "field": "FinalTotalOrder",
            "headerName": "Value",
            "type": "number",
            "flex": 0,
            "minWidth": 75,
            "width": 100
         },
         {
            "id": "MarketName",
            "field": "MarketName",
            "headerName": "Market",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationTypeName",
            "field": "LocationTypeName",
            "headerName": "Channel",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "ClassificationName",
            "field": "ClassificationName",
            "headerName": "Classification",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationRoute",
            "field": "LocationRoute",
            "headerName": "Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "PreSellerRouteName",
            "field": "PreSellerRouteName",
            "headerName": "Pre Seller Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "SalesRepName",
            "field": "SalesRepName",
            "headerName": "Sales Rep",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "Image",
            "field": "Image",
            "headerName": "Image",
            "type": "action",
            "flex": 0,
            "minWidth": 150,
            "filterable": false,
            "sortable": false,
            "width": 100
         },
         {
            "id": "LastDeliveryDate",
            "field": "LastDeliveryDate",
            "headerName": "Last Delivery Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "LastOrderDate",
            "field": "LastOrderDate",
            "headerName": "Last Order Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "FinalMoQ",
            "field": "FinalMoQ",
            "headerName": "MOQ",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "FinalMoV",
            "field": "FinalMoV",
            "headerName": "MOV",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "LastMOVAndMOQDateTime",
            "field": "LastMOVAndMOQDateTime",
            "headerName": "Date Met MOQ/MOV",
            "flex": 0,
            "minWidth": 150,
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "width": 100
         },
         {
            "width": 40,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "detailPanelToggle",
            "align": "left",
            "filterOperators": [
               {
                  "value": "contains"
               },
               {
                  "value": "equals"
               },
               {
                  "value": "startsWith"
               },
               {
                  "value": "endsWith"
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isAnyOf"
               }
            ],
            "field": "__detail_panel_toggle__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "headerName": "Detail panel toggle",
            "computedWidth": 50
         }
      ],
      "pinnedColumns": {
         "right": [
            "__detail_panel_toggle__"
         ]
      }
   },
   locationReorderScheduled: {
      "sortModel": [

      ],
      "filterModel": {
         "items": [

         ],
         "linkOperator": "and"
      },
      "columnVisibilityModel": {
         "Code": true,
         "LocationName": true,
         "Street": true,
         "City": true,
         "State": true,
         "PostalCode": false,
         "OrderSuggestion": true,
         "ReorderDate": false,
         "DeliveryNotes": false,
         "ShareOfShelf": false,
         "TotalCase": true,
         "TotalOrder": true,
         "MarketName": false,
         "LocationTypeName": false,
         "ClassificationName": false,
         "LocationRoute": false,
         "PreSellerRouteName": false,
         "SalesRepName": false,
         "Image": false,
         "__detail_panel_toggle__": true,
         "LastDeliveryDate": false,
         "LastOrderDate": false,
         "FinalMoQ": false,
         "FinalMoV": false,
         "LastMOVAndMOQDateTime": false,
         ClientName: false
      },
      "gridColumn": [
         {
            "width": 50,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "checkboxSelection",
            "align": "center",
            "filterOperators": [
               {
                  "value": "is"
               }
            ],
            "headerAlign": "center",
            "field": "__check__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "cellClassName": "MuiDataGrid-cellCheckbox",
            "headerClassName": "MuiDataGrid-columnHeaderCheckbox",
            "headerName": "Checkbox selection",
            "computedWidth": 50
         },
         {
            "id": "Code",
            "field": "Code",
            "headerName": "Outlet Code",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "hide": true,
            "width": 100
         },
         {
            "id": "LocationName",
            "field": "LocationName",
            "headerName": "Outlet Name",
            "flex": 0,
            "minWidth": 200,
            "type": "string",
            "width": 100
         },
         {
            "id": "Street",
            "field": "Street",
            "headerName": "Street",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "City",
            "field": "City",
            "headerName": "City",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "State",
            "field": "State",
            "headerName": "State",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "PostalCode",
            "field": "PostalCode",
            "headerName": "Postal Code",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "OrderSuggestion",
            "field": "OrderSuggestion",
            "headerName": "Order Suggestion",
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "minWidth": 150,
            "width": 100
         },
         {
            "id": "ReorderDate",
            "field": "ReorderDate",
            "headerName": "Order Scheduled",
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "minWidth": 150,
            "width": 100
         },
         {
            "id": "DeliveryNotes",
            "field": "DeliveryNotes",
            "headerName": "Future Delivery Dates",
            "flex": 0,
            "sortable": false,
            "minWidth": 100,
            "sort": false,
            "width": 100
         },
         {
            "id": "ShareOfShelf",
            "field": "ShareOfShelf",
            "headerName": "Fill Level",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "TotalCase",
            "field": "TotalCase",
            "headerName": "Cases",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "TotalOrder",
            "field": "TotalOrder",
            "headerName": "Value",
            "type": "number",
            "flex": 0,
            "minWidth": 75,
            "width": 100
         },
         {
            "id": "MarketName",
            "field": "MarketName",
            "headerName": "Market",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationTypeName",
            "field": "LocationTypeName",
            "headerName": "Channel",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "ClassificationName",
            "field": "ClassificationName",
            "headerName": "Classification",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationRoute",
            "field": "LocationRoute",
            "headerName": "Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "PreSellerRouteName",
            "field": "PreSellerRouteName",
            "headerName": "Pre Seller Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "SalesRepName",
            "field": "SalesRepName",
            "headerName": "Sales Rep",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "Image",
            "field": "Image",
            "headerName": "Image",
            "type": "action",
            "flex": 0,
            "minWidth": 150,
            "filterable": false,
            "sortable": false,
            "width": 100
         },
         {
            "id": "LastDeliveryDate",
            "field": "LastDeliveryDate",
            "headerName": "Last Delivery Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "LastOrderDate",
            "field": "LastOrderDate",
            "headerName": "Last Order Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "FinalMoQ",
            "field": "FinalMoQ",
            "headerName": "MOQ",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "FinalMoV",
            "field": "FinalMoV",
            "headerName": "MOV",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "LastMOVAndMOQDateTime",
            "field": "LastMOVAndMOQDateTime",
            "headerName": "Date Met MOQ/MOV",
            "flex": 0,
            "minWidth": 150,
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "width": 100
         },
         {
            "width": 40,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "detailPanelToggle",
            "align": "left",
            "filterOperators": [
               {
                  "value": "contains"
               },
               {
                  "value": "equals"
               },
               {
                  "value": "startsWith"
               },
               {
                  "value": "endsWith"
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isAnyOf"
               }
            ],
            "field": "__detail_panel_toggle__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "headerName": "Detail panel toggle",
            "computedWidth": 50
         }
      ],
      "pinnedColumns": {
         "right": [
            "__detail_panel_toggle__"
         ]
      }
   },
   locationReorderInTransit: {
      "sortModel": [

      ],
      "filterModel": {
         "items": [

         ],
         "linkOperator": "and"
      },
      "columnVisibilityModel": {
         "Code": true,
         "LocationName": true,
         "Street": true,
         "City": true,
         "State": true,
         "PostalCode": false,
         "OrderSuggestion": true,
         "ReorderDate": false,
         "DeliveryNotes": false,
         "ShareOfShelf": false,
         "TotalCase": true,
         "TotalOrder": true,
         "MarketName": false,
         "LocationTypeName": false,
         "ClassificationName": false,
         "LocationRoute": false,
         "PreSellerRouteName": false,
         "SalesRepName": false,
         "Image": false,
         "__detail_panel_toggle__": true,
         "LastDeliveryDate": false,
         "LastOrderDate": false,
         "FinalMoQ": false,
         "FinalMoV": false,
         "LastMOVAndMOQDateTime": false,
         ClientName: false
      },
      "gridColumn": [
         {
            "width": 50,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "checkboxSelection",
            "align": "center",
            "filterOperators": [
               {
                  "value": "is"
               }
            ],
            "headerAlign": "center",
            "field": "__check__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "cellClassName": "MuiDataGrid-cellCheckbox",
            "headerClassName": "MuiDataGrid-columnHeaderCheckbox",
            "headerName": "Checkbox selection",
            "computedWidth": 50
         },
         {
            "id": "Code",
            "field": "Code",
            "headerName": "Outlet Code",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "hide": true,
            "width": 100
         },
         {
            "id": "LocationName",
            "field": "LocationName",
            "headerName": "Outlet Name",
            "flex": 0,
            "minWidth": 200,
            "type": "string",
            "width": 100
         },
         {
            "id": "Street",
            "field": "Street",
            "headerName": "Street",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "City",
            "field": "City",
            "headerName": "City",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "State",
            "field": "State",
            "headerName": "State",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "PostalCode",
            "field": "PostalCode",
            "headerName": "Postal Code",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "OrderSuggestion",
            "field": "OrderSuggestion",
            "headerName": "Order Suggestion",
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "minWidth": 150,
            "width": 100
         },
         {
            "id": "ReorderDate",
            "field": "ReorderDate",
            "headerName": "Order Scheduled",
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "minWidth": 150,
            "width": 100
         },
         {
            "id": "DeliveryNotes",
            "field": "DeliveryNotes",
            "headerName": "Future Delivery Dates",
            "flex": 0,
            "sortable": false,
            "minWidth": 100,
            "sort": false,
            "width": 100
         },
         {
            "id": "ShareOfShelf",
            "field": "ShareOfShelf",
            "headerName": "Fill Level",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "TotalCase",
            "field": "TotalCase",
            "headerName": "Cases",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "TotalOrder",
            "field": "TotalOrder",
            "headerName": "Value",
            "type": "number",
            "flex": 0,
            "minWidth": 75,
            "width": 100
         },
         {
            "id": "MarketName",
            "field": "MarketName",
            "headerName": "Market",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationTypeName",
            "field": "LocationTypeName",
            "headerName": "Channel",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "ClassificationName",
            "field": "ClassificationName",
            "headerName": "Classification",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationRoute",
            "field": "LocationRoute",
            "headerName": "Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "PreSellerRouteName",
            "field": "PreSellerRouteName",
            "headerName": "Pre Seller Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "SalesRepName",
            "field": "SalesRepName",
            "headerName": "Sales Rep",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "Image",
            "field": "Image",
            "headerName": "Image",
            "type": "action",
            "flex": 0,
            "minWidth": 150,
            "filterable": false,
            "sortable": false,
            "width": 100
         },
         {
            "id": "LastDeliveryDate",
            "field": "LastDeliveryDate",
            "headerName": "Last Delivery Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "LastOrderDate",
            "field": "LastOrderDate",
            "headerName": "Last Order Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "FinalMoQ",
            "field": "FinalMoQ",
            "headerName": "MOQ",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "FinalMoV",
            "field": "FinalMoV",
            "headerName": "MOV",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "LastMOVAndMOQDateTime",
            "field": "LastMOVAndMOQDateTime",
            "headerName": "Date Met MOQ/MOV",
            "flex": 0,
            "minWidth": 150,
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "width": 100
         },
         {
            "width": 40,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "detailPanelToggle",
            "align": "left",
            "filterOperators": [
               {
                  "value": "contains"
               },
               {
                  "value": "equals"
               },
               {
                  "value": "startsWith"
               },
               {
                  "value": "endsWith"
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isAnyOf"
               }
            ],
            "field": "__detail_panel_toggle__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "headerName": "Detail panel toggle",
            "computedWidth": 50
         }
      ],
      "pinnedColumns": {
         "right": [
            "__detail_panel_toggle__"
         ]
      }
   },
   locationReorderIgnore: {
      "sortModel": [

      ],
      "filterModel": {
         "items": [

         ],
         "linkOperator": "and"
      },
      "columnVisibilityModel": {
         "Code": true,
         "LocationName": true,
         "Street": true,
         "City": true,
         "State": true,
         "PostalCode": false,
         "OrderSuggestion": true,
         "ReorderDate": false,
         "DeliveryNotes": false,
         "ShareOfShelf": false,
         "TotalCase": true,
         "TotalOrder": true,
         "MarketName": false,
         "LocationTypeName": false,
         "ClassificationName": false,
         "LocationRoute": false,
         "PreSellerRouteName": false,
         "SalesRepName": false,
         "Image": false,
         "__detail_panel_toggle__": true,
         "LastDeliveryDate": false,
         "LastOrderDate": false,
         "FinalMoQ": false,
         "FinalMoV": false,
         "LastMOVAndMOQDateTime": false,
         ClientName: false
      },
      "gridColumn": [
         {
            "width": 50,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "checkboxSelection",
            "align": "center",
            "filterOperators": [
               {
                  "value": "is"
               }
            ],
            "headerAlign": "center",
            "field": "__check__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "cellClassName": "MuiDataGrid-cellCheckbox",
            "headerClassName": "MuiDataGrid-columnHeaderCheckbox",
            "headerName": "Checkbox selection",
            "computedWidth": 50
         },
         {
            "id": "Code",
            "field": "Code",
            "headerName": "Outlet Code",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "hide": true,
            "width": 100
         },
         {
            "id": "LocationName",
            "field": "LocationName",
            "headerName": "Outlet Name",
            "flex": 0,
            "minWidth": 200,
            "type": "string",
            "width": 100
         },
         {
            "id": "Street",
            "field": "Street",
            "headerName": "Street",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "City",
            "field": "City",
            "headerName": "City",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "State",
            "field": "State",
            "headerName": "State",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "PostalCode",
            "field": "PostalCode",
            "headerName": "Postal Code",
            "flex": 0,
            "minWidth": 100,
            "type": "string",
            "width": 100
         },
         {
            "id": "OrderSuggestion",
            "field": "OrderSuggestion",
            "headerName": "Order Suggestion",
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "minWidth": 150,
            "width": 100
         },
         {
            "id": "DeliveryNotes",
            "field": "DeliveryNotes",
            "headerName": "Future Delivery Dates",
            "flex": 0,
            "sortable": false,
            "minWidth": 100,
            "sort": false,
            "width": 100
         },
         {
            "id": "ShareOfShelf",
            "field": "ShareOfShelf",
            "headerName": "Fill Level",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "TotalCase",
            "field": "TotalCase",
            "headerName": "Cases",
            "type": "number",
            "flex": 0,
            "minWidth": 100,
            "width": 100
         },
         {
            "id": "TotalOrder",
            "field": "TotalOrder",
            "headerName": "Value",
            "type": "number",
            "flex": 0,
            "minWidth": 75,
            "width": 100
         },
         {
            "id": "MarketName",
            "field": "MarketName",
            "headerName": "Market",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationTypeName",
            "field": "LocationTypeName",
            "headerName": "Channel",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "ClassificationName",
            "field": "ClassificationName",
            "headerName": "Classification",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "LocationRoute",
            "field": "LocationRoute",
            "headerName": "Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "PreSellerRouteName",
            "field": "PreSellerRouteName",
            "headerName": "Pre Seller Route",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "SalesRepName",
            "field": "SalesRepName",
            "headerName": "Sales Rep",
            "flex": 0,
            "minWidth": 150,
            "type": "string",
            "width": 100
         },
         {
            "id": "Image",
            "field": "Image",
            "headerName": "Image",
            "type": "action",
            "flex": 0,
            "minWidth": 150,
            "filterable": false,
            "sortable": false,
            "width": 100
         },
         {
            "id": "LastDeliveryDate",
            "field": "LastDeliveryDate",
            "headerName": "Last Delivery Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "LastOrderDate",
            "field": "LastOrderDate",
            "headerName": "Last Order Date",
            "type": "date",
            "minWidth": 150,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "flex": 0,
            "width": 100
         },
         {
            "id": "FinalMoQ",
            "field": "FinalMoQ",
            "headerName": "MOQ",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "FinalMoV",
            "field": "FinalMoV",
            "headerName": "MOV",
            "flex": 0,
            "minWidth": 120,
            "type": "number",
            "width": 100
         },
         {
            "id": "LastMOVAndMOQDateTime",
            "field": "LastMOVAndMOQDateTime",
            "headerName": "Date Met MOQ/MOV",
            "flex": 0,
            "minWidth": 150,
            "type": "date",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "width": 100
         },
         {
            "width": 40,
            "minWidth": 50,
            "maxWidth": null,
            "hideable": true,
            "sortable": false,
            "resizable": false,
            "filterable": false,
            "groupable": true,
            "pinnable": true,
            "aggregable": false,
            "editable": false,
            "type": "detailPanelToggle",
            "align": "left",
            "filterOperators": [
               {
                  "value": "contains"
               },
               {
                  "value": "equals"
               },
               {
                  "value": "startsWith"
               },
               {
                  "value": "endsWith"
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isAnyOf"
               }
            ],
            "field": "__detail_panel_toggle__",
            "disableColumnMenu": true,
            "disableReorder": true,
            "disableExport": true,
            "headerName": "Detail panel toggle",
            "computedWidth": 50
         }
      ],
      "pinnedColumns": {
         "right": [
            "__detail_panel_toggle__"
         ]
      }
   },
   playbookSalesHistory: {
      "sortModel": [
         {
            "field": "ScheduledOrderId",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [
            {
               "field": "ModifiedOn",
               "operator": "onOrAfter",
               "value": playbookSalesHistoryFilterDate
            }
         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "Channel": false,
         "Classification": false,
         "City": false,
         "Postal": false,
         "TotalCase": false
      },
      "gridColumn": [
         {
            "headerName": "Order Id",
            "field": "ScheduledOrderId",
            "type": "number",
            "width": 150,
            "label": "Order Id",
            "pinned": true
         },
         {
            "headerName": "Channel",
            "field": "Channel",
            "type": "string",
            "width": 150,
            "label": "Channel",
            "hide": true
         },
         {
            "headerName": "Classification",
            "field": "Classification",
            "type": "string",
            "width": 150,
            "label": "Classification",
            "hide": true
         },
         {
            "headerName": "Outlet Name",
            "field": "OutletName",
            "type": "string",
            "width": 150,
            "label": "Outlet Name"
         },
         {
            "headerName": "Outlet Code",
            "field": "OutletCode",
            "type": "string",
            "width": 150,
            "label": "Outlet Code"
         },
         {
            "headerName": "City",
            "field": "City",
            "type": "string",
            "width": 150,
            "label": "City",
            "hide": true
         },
         {
            "headerName": "Postal Code",
            "field": "Postal",
            "type": "string",
            "width": 150,
            "label": "Postal Code",
            "hide": true
         },
         {
            "headerName": "Value",
            "field": "TotalOrder",
            "type": "number",
            "width": 150,
            "label": "Value"
         },
         {
            "headerName": "Cases",
            "field": "TotalCase",
            "type": "number",
            "width": 100,
            "label": "Cases",
            "hide": true
         },
         {
            "headerName": "Order Date",
            "field": "ModifiedOn",
            "type": "date",
            "width": 150,
            "label": "Order Date",
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "User",
            "field": "Username",
            "type": "string",
            "width": 200,
            "label": "User"
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__",
            "ScheduledOrderId"
         ],
         "right": [

         ]
      }
   },
   playbookOrderSuggestionHistory: {
      "sortModel": [
         {
            "field": "TimeStamp",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [
            {
               "field": "TimeStamp",
               "operator": "onOrAfter",
               "value": playbookOrderSuggestionHistoryFilterDate
            }
         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false
      },
      "gridColumn": [
         {
            "headerName": "Status Date",
            "field": "TimeStamp",
            "type": "dateTime",
            "width": 300,
            "label": "Status Date",
            "pinned": true,
            "keepUTC": true,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         },
         {
            "headerName": "Outlet Name",
            "field": "LocationName",
            "type": "string",
            "width": 310,
            "label": "Outlet Name",
            "filterField": "LocationName.keyword",
            "isKeywordField": true
         },
         {
            "headerName": "Outlet Code",
            "field": "LocationCode",
            "type": "string",
            "width": 310,
            "label": "Outlet Code",
            "filterField": "LocationCode.keyword",
            "isKeywordField": true
         },
         {
            "headerName": "Value",
            "field": "TotalOrder",
            "type": "number",
            "width": 200,
            "label": "Value",
            "pinned": "right"
         },
         {
            "headerName": "Cases",
            "field": "TotalCase",
            "type": "number",
            "width": 200,
            "label": "Cases",
            "pinned": "right"
         },
         {
            "headerName": "Status",
            "field": "OrderStatusId",
            "type": "singleSelect",
            "lookup": "OrderStatus",
            "width": 200,
            "applyZeroFilter": true,
            "label": "Status",
            "pinned": "right",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isAnyOf"
               }
            ]
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__",
            "TimeStamp"
         ],
         "right": [
            "TotalOrder",
            "TotalCase",
            "OrderStatusId"
         ]
      }
   },
   playbookAcostaReport: {
      "sortModel": [
         {
            "field": "AlertId",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [
            {
               "field": "StatusDate",
               "operator": "after",
               "value": playbookAcostaReportStartDateFilter
            },
            {
               "field": "StatusDate",
               "operator": "before",
               "value": playbookAcostaReportEndDateFilter
            }
         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "AcostaAlertId": false,
         "SkuUpcCode": false,
         "Product": false,
         "StatusId": false,
         "Comments": false,
         "AcostaVisited": false,
         "Status": false,
         "Question": false,
         "Answer": false,
         "ValidateReason": false,
         "ValidatedByUser": false,
         "Name": false,
         "Country": false,
         "RetailerRelationshipCode": false,
         "CallId": false,
         "Store": false,
         "Client": false,
         "SubCategory": false,
         "SubBanner": false,
         "Priority": false,
         "AlertAge": false,
         "DatePlanned": false,
         "CompletedOnLocal": false,
         "StartDate": false,
         "EndDate": false,
         "LastEdited": false,
         "AlertAgeInMin": false,
         "AlertSentOn": false
      },
      "gridColumn": [
         {
            "headerName": "AlertId",
            "field": "AlertId",
            "label": "AlertId",
            "type": "string",
            "width": 150,
            "filterable": true,
            "sortable": false,
            "customCellClick": true,
            "filterOperators": [
               {
                  "value": "=",
                  "InputComponentProps": {
                     "type": "number"
                  }
               }
            ]
         },
         {
            "headerName": "Acosta AlertId",
            "field": "AcostaAlertId",
            "label": "Acosta AlertId",
            "type": "number",
            "width": 220,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Alert Text",
            "field": "AlertText",
            "label": "Alert Text",
            "type": "string",
            "width": 500,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Asset Serial #",
            "field": "SerialNumber",
            "label": "Asset Serial #",
            "type": "string",
            "width": 200,
            "filterable": false,
            "sortable": false,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ]
         },
         {
            "headerName": "Alert Type Id",
            "field": "AcostaAlertTypeId",
            "label": "Alert Type Id",
            "type": "string",
            "width": 200,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "UPC Code",
            "field": "SkuUpcCode",
            "label": "UPC Code",
            "type": "string",
            "width": 200,
            "filterable": false,
            "sortable": false,
            "hide": true
         },
         {
            "headerName": "Product",
            "field": "Product",
            "label": "Product",
            "type": "string",
            "width": 400,
            "filterable": false,
            "sortable": false,
            "hide": true
         },
         {
            "headerName": "Closed/Open",
            "field": "StatusId",
            "label": "Closed/Open",
            "type": "string",
            "width": 220,
            "filterable": false,
            "sortable": false,
            "hide": true
         },
         {
            "headerName": "Acosta Comments",
            "field": "Comments",
            "label": "Acosta Comments",
            "type": "string",
            "width": 300,
            "filterable": false,
            "sortable": false,
            "hide": true
         },
         {
            "headerName": "Acosta Visited",
            "field": "AcostaVisited",
            "label": "Acosta Visited",
            "type": "string",
            "width": 400,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Acosta Status",
            "field": "Status",
            "label": "Acosta Status",
            "type": "string",
            "width": 150,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Question",
            "field": "Question",
            "label": "Question",
            "type": "string",
            "width": 500,
            "filterable": false,
            "sortable": false,
            "hide": true
         },
         {
            "headerName": "Answer",
            "field": "Answer",
            "label": "Answer",
            "type": "string",
            "filterable": false,
            "sortable": false,
            "width": 500,
            "hide": true
         },
         {
            "headerName": "Validate Reason",
            "field": "ValidateReason",
            "label": "Validate Reason",
            "type": "string",
            "width": 200,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Validated By",
            "field": "ValidatedByUser",
            "label": "Validated By",
            "type": "string",
            "width": 200,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Market",
            "field": "MarketName",
            "label": "Market",
            "type": "string",
            "width": 200,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ],
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Location",
            "field": "Name",
            "label": "Location",
            "type": "string",
            "width": 300,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Country",
            "field": "Country",
            "label": "Country",
            "type": "string",
            "width": 200,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Retailer Relationship Code",
            "field": "RetailerRelationshipCode",
            "label": "Retailer Relationship Code",
            "type": "string",
            "width": 300,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "CallId",
            "field": "CallId",
            "label": "CallId",
            "type": "string",
            "width": 220,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Store",
            "field": "Store",
            "label": "Store",
            "type": "string",
            "width": 350,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Client",
            "field": "Client",
            "label": "Client",
            "type": "string",
            "width": 280,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Sub Category",
            "field": "SubCategory",
            "label": "Sub Category",
            "type": "string",
            "width": 150,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "SubBanner",
            "field": "SubBanner",
            "label": "SubBanner",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Priority",
            "field": "Priority",
            "label": "Priority",
            "type": "string",
            "width": 150,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Alert Age",
            "field": "AlertAge",
            "label": "Alert Age",
            "type": "string",
            "width": 300,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Status Date",
            "field": "StatusDate",
            "label": "Status Date",
            "type": "string",
            "width": 250,
            "filterOperators": [
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               }
            ],
            "sortable": false
         },
         {
            "headerName": "Date Planned",
            "field": "DatePlanned",
            "label": "Date Planned",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Completed On Local",
            "field": "CompletedOnLocal",
            "label": "Completed On Local",
            "type": "string",
            "width": 250,
            "filterable": false,
            "sortable": false,
            "hide": true
         },
         {
            "headerName": "Start Date",
            "field": "StartDate",
            "label": "Start Date",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "End Date",
            "field": "EndDate",
            "label": "End Date",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Last Edited",
            "field": "LastEdited",
            "label": "Last Edited",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Alert Age (in min)",
            "field": "AlertAgeInMin",
            "label": "Alert Age (in min)",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         },
         {
            "headerName": "Alert SentOn",
            "field": "AlertSentOn",
            "label": "Alert SentOn",
            "type": "string",
            "width": 250,
            "hide": true,
            "filterable": false,
            "sortable": false
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookUserLogin: {
      "sortModel": [
         {
            "field": "LoginTime",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false
      },
      "gridColumn": [
         {
            "headerName": "Email",
            "field": "Email",
            "type": "string",
            "width": 200,
            "label": "Email"
         },
         {
            "headerName": "First Name",
            "field": "FirstName",
            "type": "string",
            "width": 150,
            "label": "First Name"
         },
         {
            "headerName": "Last Name",
            "field": "LastName",
            "type": "string",
            "width": 150,
            "label": "Last Name"
         },
         {
            "headerName": "Role",
            "field": "Role",
            "type": "string",
            "width": 150,
            "label": "Role"
         },
         {
            "headerName": "Login Time",
            "field": "LoginTime",
            "type": "dateTimeLocal",
            "width": 250,
            "label": "Login Time",
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ]
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookOutletInstallationAudit: {
      "sortModel": [
         {
            "field": "CreatedOn",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": true,
         "CreatedByUser": true
      },
      "gridColumn": [
         {
            "headerName": "Filename",
            "field": "Filename",
            "type": "string",
            "width": 190,
            "label": "Filename"
         },
         {
            "headerName": "Type",
            "field": "AttachmentType",
            "type": "string",
            "width": 150,
            "label": "Type"
         },
         {
            "headerName": "Created By",
            "field": "CreatedByUser",
            "type": "string",
            "width": 250,
            "label": "Created By"
         },
         {
            "field": "CreatedOn",
            "type": "dateTime",
            "headerName": "Created On",
            "width": 200,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "keepUTC": false
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookMasterOutlet: {
      "sortModel": [
         {
            "field": "Name",
            "sort": "desc",
            "filterField": "Name"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "SalesRepEmail": false,
         "Street2": false,
         "Street3": false,
         "LocationRoute": false,
         "OutletGTIN": false,
         "BuyerGTIN": false,
         "DistributorGTIN": false,
         "InfieldContactPersonEmail": false,
         "SecondarySalesRepEmail": false,
         "PrimarySalesRep": false
      },
      "gridColumn": [
         {
            "headerName": "Name",
            "field": "Name",
            "label": "Name",
            "type": "string",
            "minWidth": 70,
            "width": 100
         },
         {
            "headerName": "Code",
            "field": "Code",
            "label": "Code",
            "type": "string",
            "minWidth": 100,
            "width": 100
         },
         {
            "headerName": "Country",
            "field": "Country",
            "label": "Country",
            "type": "string",
            "minWidth": 80,
            "width": 100
         },
         {
            "headerName": "State",
            "field": "State",
            "label": "State",
            "type": "string",
            "minWidth": 60,
            "width": 100
         },
         {
            "headerName": "City",
            "field": "City",
            "label": "City",
            "type": "string",
            "minWidth": 50,
            "width": 100
         },
         {
            "headerName": "Street",
            "field": "Street",
            "label": "Street",
            "type": "string",
            "minWidth": 60,
            "width": 100
         },
         {
            "headerName": "Postal Code",
            "field": "PostalCode",
            "label": "Postal Code",
            "type": "string",
            "minWidth": 100,
            "width": 100
         },
         {
            "headerName": "Market",
            "field": "MarketName",
            "label": "Market",
            "type": "string",
            "minWidth": 70,
            "width": 100
         },
         {
            "headerName": "Channel",
            "field": "LocationType",
            "label": "Channel",
            "type": "string",
            "minWidth": 80,
            "width": 100
         },
         {
            "headerName": "Classification",
            "field": "Classification",
            "label": "Classification",
            "type": "string",
            "minWidth": 100,
            "width": 100
         },
         {
            "headerName": "Latitude",
            "field": "Latitude",
            "label": "Latitude",
            "type": "number",
            "minWidth": 80,
            "isParsable": false,
            "width": 100
         },
         {
            "headerName": "Longitude",
            "field": "Longitude",
            "label": "Longitude",
            "type": "number",
            "minWidth": 90,
            "isParsable": false,
            "width": 100
         },
         {
            "headerName": "Time Zone",
            "field": "TimeZone",
            "label": "Time Zone",
            "type": "string",
            "minWidth": 90,
            "width": 100
         },
         {
            "headerName": "Sales Model",
            "field": "SalesModel",
            "label": "Sales Model",
            "type": "string",
            "minWidth": 90,
            "width": 100
         },
         {
            "headerName": "Distributor Name",
            "field": "DistributorName",
            "label": "Distributor Name",
            "type": "string",
            "minWidth": 90,
            "width": 100
         },
         {
            "headerName": "Primary Sales Rep",
            "field": "PrimarySalesRep",
            "label": "Primary Sales Rep",
            "type": "string",
            "minWidth": 90,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Active",
            "field": "IsActive",
            "type": "boolean",
            "label": "Active",
            "minWidth": 60,
            "width": 100
         },
         {
            "headerName": "Other Sales Reps",
            "field": "SalesRepEmail",
            "label": "Other Sales Reps",
            "type": "string",
            "minWidth": 90,
            "sortable": false,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ],
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Street 2",
            "field": "Street2",
            "label": "Street 2",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Street 3",
            "field": "Street3",
            "label": "Street 3",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Store Owner",
            "field": "StoreOwner",
            "label": "Store Owner",
            "type": "string",
            "minWidth": 60,
            "width": 100
         },
         {
            "headerName": "Order Email",
            "field": "LocationSalesRep",
            "label": "Order Email",
            "type": "string",
            "minWidth": 60,
            "width": 100
         },
         {
            "headerName": "Key Account",
            "field": "KeyAccount",
            "label": "Key Account",
            "type": "string",
            "minWidth": 60,
            "width": 100
         },
         {
            "headerName": "Route",
            "field": "LocationRoute",
            "label": "Route",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Outlet GTIN",
            "field": "OutletGTIN",
            "label": "Outlet GTIN",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Buyer GTIN",
            "field": "BuyerGTIN",
            "label": "Buyer GTIN",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Distributor GTIN",
            "field": "DistributorGTIN",
            "label": "Distributor GTIN",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Infield Contact Person Email",
            "field": "InfieldContactPersonEmail",
            "label": "Infield Contact Person Email",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         },
         {
            "headerName": "Secondary Sales Rep",
            "field": "SecondarySalesRepEmail",
            "label": "Secondary Sales Rep",
            "type": "string",
            "minWidth": 60,
            "hide": true,
            "width": 100
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookMasterAssetType: {
      "sortModel": [
         {
            "field": "AssetType",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false
      },
      "gridColumn": [
         {
            "headerName": "Asset Type",
            "field": "AssetType",
            "label": "Asset Type",
            "type": "string",
            "width": 270
         },
         {
            "headerName": "Model Number",
            "field": "ModelNumber",
            "label": "Model Number",
            "type": "string",
            "width": 270
         },
         {
            "headerName": "Manufacturer",
            "field": "Manufacturer",
            "label": "Manufacturer",
            "type": "string",
            "width": 300
         },
         {
            "headerName": "Orientation Type",
            "field": "ChestCoolerType",
            "label": "Orientation Type",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Number of Shelves",
            "field": "Shelves",
            "label": "Number of Shelves",
            "type": "number",
            "width": 200
         },
         {
            "headerName": "Number of Columns",
            "field": "Columns",
            "label": "Number of Columns",
            "type": "number",
            "width": 200
         },
         {
            "headerName": "Door Handle Location",
            "field": "DoorHandleLocationType",
            "label": "Door Handle Location",
            "type": "string",
            "width": 200
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookMasterAsset: {
      "sortModel": [
         {
            "field": "SerialNumber",
            "sort": "asc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false
      },
      "gridColumn": [
         {
            "headerName": "Serial Number",
            "field": "SerialNumber",
            "label": "Serial Number",
            "type": "string",
            "width": 250
         },
         {
            "headerName": "Asset Type",
            "field": "AssetType",
            "label": "Asset Type",
            "type": "string",
            "width": 250
         },
         {
            "headerName": "Outlet Code",
            "field": "LocationCode",
            "label": "Outlet Code",
            "type": "string",
            "width": 240
         },
         {
            "headerName": "Modem",
            "field": "MDMSerialNumber",
            "label": "Modem",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Camera",
            "field": "SmartDeviceSerialNumber",
            "label": "Camera",
            "type": "string",
            "width": 250
         },
         {
            "headerName": "Planogram",
            "field": "PlanogramName",
            "label": "Planogram",
            "type": "string",
            "width": 250
         },
         {
            "headerName": "Parent Asset Serial Number",
            "field": "ParentAssetSerialNumber",
            "label": "Parent Asset Serial Number",
            "type": "string",
            "width": 250
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookMasterPlanogram: {
      "sortModel": [
         {
            "field": "PlanogramName",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false
      },
      "gridColumn": [
         {
            "headerName": "Planogram Name",
            "field": "PlanogramName",
            "label": "Planogram Name",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Assigned Assets",
            "field": "AssignedAssetsCount",
            "label": "Assigned Assets",
            "type": "number",
            "width": 150
         },
         {
            "headerName": "Shelves",
            "field": "Shelves",
            "label": "Shelves",
            "type": "number",
            "width": 100
         },
         {
            "headerName": "Total SKUs",
            "field": "Facings",
            "label": "Total SKUs",
            "type": "number",
            "width": 100
         },
         {
            "headerName": "Unique SKUs",
            "field": "FacingsDistinct",
            "label": "Unique SKUs",
            "type": "number",
            "width": 150
         },
         {
            "field": "CreatedOn",
            "type": "dateTime",
            "headerName": "Created On",
            "width": 200,
            "filterOperators": [
               {
                  "value": "is",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "not",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "after",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrAfter",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "before",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "onOrBefore",
                  "InputComponentProps": {
                     "type": "date"
                  }
               },
               {
                  "value": "isEmpty",
                  "requiresFilterValue": false
               },
               {
                  "value": "isNotEmpty",
                  "requiresFilterValue": false
               }
            ],
            "keepUTC": true
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookMasterProduct: {
      "sortModel": [
         {
            "field": "Product",
            "sort": "desc"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "Country": false,
         "State": false,
         "ProductSecondCategory": false,
         "MeasurementUnit": false,
         "ShortName": false,
         "IsKeyProduct": false,
         "Manufacturer": false,
         "ProductGTIN": false
      },
      "gridColumn": [
         {
            "headerName": "Product",
            "field": "Product",
            "label": "Product",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "SKU",
            "field": "SKU",
            "label": "SKU",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "# of Images",
            "field": "ProductImageCount",
            "label": "# of Images",
            "type": "number",
            "width": 120
         },
         {
            "headerName": "Product Category",
            "field": "ProductCategory",
            "label": "Product Category",
            "type": "string",
            "width": 120
         },
         {
            "headerName": "Brand",
            "field": "BrandName",
            "label": "Brand",
            "type": "string",
            "width": 100
         },
         {
            "headerName": "UPC",
            "field": "UPC",
            "label": "UPC",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Short EAN Code",
            "field": "ShortEANCode",
            "label": "Short EAN Code",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Is Foreign Product?",
            "field": "IsForeign",
            "label": "Is Foreign Product?",
            "type": "boolean",
            "width": 102
         },
         {
            "headerName": "Country",
            "field": "Country",
            "label": "Country",
            "type": "string",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "State",
            "field": "State",
            "label": "State",
            "type": "string",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "Product Category 2",
            "field": "ProductSecondCategory",
            "label": "Product Category 2",
            "type": "string",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "Distributor",
            "field": "DistributorName",
            "label": "Distributor",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Packaging Type",
            "field": "PackagingType",
            "label": "Packaging Type",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Product Type",
            "field": "BeverageType",
            "label": "Product Type",
            "type": "string",
            "width": 200
         },
         {
            "headerName": "Measurement Unit",
            "field": "MeasurementUnit",
            "label": "Measurement Unit",
            "type": "string",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "Short Name",
            "field": "ShortName",
            "label": "Short Name",
            "type": "string",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "Is KeyProduct?",
            "field": "IsKeyProduct",
            "label": "Is KeyProduct?",
            "type": "boolean",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "Manufacturer Name",
            "field": "Manufacturer",
            "label": "Manufacturer Name",
            "type": "string",
            "width": 200,
            "hide": true
         },
         {
            "headerName": "Product GTIN",
            "field": "ProductGTIN",
            "label": "Product GTIN",
            "type": "string",
            "width": 200,
            "hide": true
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }
   },
   playbookMasterUser: {

      "sortModel": [
         {
            "field": "FirstName",
            "sort": "asc",
            "filterField": "FirstName"
         }
      ],
      "filterModel": {
         "items": [

         ],
         "logicOperator": "and",
         "quickFilterValues": [

         ],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false,
         "PreferedNotificationType": false,
         "MarketName": false,
         "KeyAccountName": false,
         "ClassificationName": false,
         "DistributorName": false
      },
      "gridColumn": [
         {
            "headerName": "First Name",
            "field": "FirstName",
            "type": "string",
            "width": 150,
            "label": "First Name"
         },
         {
            "headerName": "Last Name",
            "field": "LastName",
            "type": "string",
            "width": 150,
            "label": "Last Name"
         },
         {
            "headerName": "Email",
            "field": "PrimaryEmail",
            "type": "string",
            "width": 250,
            "label": "Email"
         },
         {
            "headerName": "Phone",
            "field": "PrimaryPhone",
            "type": "string",
            "width": 150,
            "label": "Phone"
         },
         {
            "headerName": "Employer",
            "field": "EmployerName",
            "type": "string",
            "width": 150,
            "label": "Employer"
         },
         {
            "headerName": " Role",
            "field": "Role",
            "type": "string",
            "width": 150,
            "label": " Role"
         },
         {
            "headerName": "Preferred Notification Type",
            "field": "PreferedNotificationType",
            "type": "string",
            "width": 150,
            "label": "Preferred Notification Type",
            "hide": true
         },
         {
            "headerName": "Market",
            "field": "MarketName",
            "type": "string",
            "width": 150,
            "label": "Market",
            "hide": true,
            "sortable": false,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ]
         },
         {
            "headerName": "Key Account",
            "field": "KeyAccountName",
            "type": "string",
            "width": 150,
            "label": "Key Account",
            "hide": true,
            "sortable": false,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ]
         },
         {
            "headerName": "Classification",
            "field": "ClassificationName",
            "type": "string",
            "width": 150,
            "label": "Classification",
            "hide": true,
            "sortable": false,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ]
         },
         {
            "headerName": "Distributor",
            "field": "DistributorName",
            "type": "string",
            "width": 150,
            "label": "Distributor",
            "hide": true,
            "sortable": false,
            "filterOperators": [
               {
                  "value": "contains"
               }
            ]
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": [

         ]
      }

   },
   playbookDeviceTracking: {
      "sortModel": [
         {
            "field": "DeviceType",
            "sort": "desc",
            "filterField": "DeviceType"
         }
      ],
      "filterModel": {
         "items": [],
         "logicOperator": "and",
         "quickFilterValues": [],
         "quickFilterLogicOperator": "and"
      },
      "columnVisibilityModel": {
         "CreatedOn": false,
         "CreatedByUser": false
      },
      "gridColumn": [
         {
            "headerName": "Client",
            "field": "ClientName",
            "type": "string",
            "width": 200,
            "label": "Client"
         },
         {
            "headerName": "Device Type",
            "field": "DeviceType",
            "type": "string",
            "width": 250,
            "label": "Device Type"
         },
         {
            "headerName": "Status",
            "field": "Status",
            "type": "string",
            "width": 150,
            "label": "Status"
         },
         {
            "headerName": "Total",
            "field": "Count",
            "type": "number",
            "width": 150,
            "label": "Total"
         }
      ],
      "pinnedColumns": {
         "left": [
            "__check__"
         ],
         "right": []
      }
   }

};

export default tablePreferenceEnums;