import { Card, Grid, IconButton } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";

const CustomTextChart = ({ classes, checkSentenceLength, handleExpandClick, answer, expandedText, sortedData }) => {
  return (
    <div>
      <Grid container spacing={2} className={classes.cardContainer}>
        {Array.isArray(sortedData) && sortedData.map(([name, count], index) => {
          const textString = `[${count}] ${name}`;
          const textJSX = (
            <>
              <strong>[ {count} ]</strong> {name}
            </>
          );
          const isLongSentence = checkSentenceLength(textString);
          const handleCheckSentenceLength = isLongSentence
            ? () => handleExpandClick(answer.id, index)
            : null;

          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ display: "flex" }}>
              <Card className={`${classes.stringType} ${expandedText[answer.id]?.[index] ? "expanded" : ""}`}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <div
                      className={expandedText[answer.id]?.[index] ? classes.fullText : classes.clippedText}
                      onClick={handleCheckSentenceLength}
                      onKeyDown={handleCheckSentenceLength}
                      role="button"
                      tabIndex={0}
                    >
                      {textJSX}
                    </div>
                  </Grid>
                  {isLongSentence && (
                    <Grid item xs={2} className={classes.expandIcon}>
                      <IconButton
                        size="small"
                        onClick={() => handleExpandClick(answer.id, index)}
                      >
                        {expandedText[answer.id]?.[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default CustomTextChart;
