import React from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import CustomizedLabel from '../Common/CustomizedLabel';
import { Box, Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import utils from '../../utils';
import { withTranslation } from 'react-i18next';
const t = utils.t;



const PromotionList = React.memo(function PromotionList({ index, data, t: translate, i18n }) {
    const tOpts = { t: translate, i18n }
    let records = [];
    records = data.Promotion;
    var arrayData = [];
    // get the promotions list and shown the screen with total number of count we shown the image
    for (const promotion of records) {
        for (const promotions of promotion.Promotions) {
            const dataExist = arrayData.findIndex(data => data.PromotionName === promotions.PromotionName);
            if (dataExist == -1) {
                arrayData.push({ PromotionName: promotions.PromotionName, Count: 1 });
            } else {
                arrayData[dataExist].Count += 1;
            }
        }
    }

    return (<Card className="mb-4" style={{ width: '100%' }}>
        <Box style={{ padding: '1.25rem' }} className="px-3 d-flex align-items-center justify-content-between">
            <h5 className="card-header-text mb-0">{t("Promotions", tOpts)}</h5>
        </Box>
        <div className="divider" />

        <div style={{ maxHeight: '85%', minHeight: '85%', marginTop: 0 }}>
            <AutoSizer style={utils.isMobile() ? { width: "100%", height: "100%" } : {}}>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height - 2}
                        headerHeight={38}
                        rowHeight={26}
                        rowCount={arrayData.length}

                        rowGetter={({ index }) => arrayData[index]}>
                        <Column
                            className="vista-location-product-table-data pl-1"
                            label={t('Promotion Name', tOpts)}
                            headerStyle={{ textAlign: 'left', color: '#3B3E66' }}
                            dataKey='PromotionName'
                            headerClassName="column-header-style"
                            cellRenderer={({ cellData, rowData }) => {
                                return <div title={rowData.PromotionName}>{rowData.PromotionName}</div>
                            }}
                            width={width * 0.5} />
                        <Column
                            className="vista-location-product-table-data pl-1"
                            label={t('Count', tOpts)}
                            headerStyle={{ textAlign: 'left', color: '#3B3E66' }}
                            dataKey='Count'
                            headerClassName="column-header-style"
                            cellRenderer={({ cellData, rowData }) => {
                                return <div title={rowData.Count}>{rowData.Count}</div>
                            }}
                            width={width * 0.5} />

                    </Table>

                )}
            </AutoSizer>
        </div> </Card>)
})

export default withTranslation()(PromotionList);