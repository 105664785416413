import { Grid } from "@mui/material";
import BarChart from "./BarChart";
import CombinedChart from "./CombinedChart";
import CustomTextChart from "./CustomTextChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import ComplianceChart from "./ComplianceChart";
import PosmChart from "./PosmChart";
import utils from "../../utils";
import { useEffect, useState } from "react";

function handleFileSceneMultiFile({ answer, toggleStates, utils, top10, bottom10, classes }) {
    const isProductImage = (answer.type === "multiFile" || answer.type === "scene") && [1, 2, 3].includes(answer?.beforeAfterImage);
    if (isProductImage) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const { barSeriesData, lineSeriesData } = answer.productImages.series;
        const commonProductNames = barSeriesData[0].data
            .filter(barItem => lineSeriesData[0].data.some(lineItem => lineItem.name === barItem.name))
            .map(item => item.name);
        let mergedCommonProductsData;
        if (commonProductNames.length > 0) {
            mergedCommonProductsData = barSeriesData[0].data
                .filter(item => commonProductNames.includes(item.name))
                .map(item => {
                    return {
                        name: item.hasOwnProperty('name') ? item.name : undefined,
                        y: item.hasOwnProperty('y') ? item.y : undefined
                    };
                });
        }
        const sortedAndFilteredCommonProducts = utils.getSortedData(mergedCommonProductsData, toggleState, 'y', top10, bottom10);
        const finalBarSeries = sortedAndFilteredCommonProducts.map(item => {
            const originalItem = barSeriesData[0].data.find(original => original.name === item.name);
            return originalItem ? originalItem : item;
        });

        const finalLineSeries = sortedAndFilteredCommonProducts.map(item => {
            const originalItem = lineSeriesData[0].data.find(original => original.name === item.name);
            return originalItem ? originalItem : item;
        });

        const plottingData = {
            barSeriesData: [{ name: barSeriesData[0].name, data: finalBarSeries }],
            lineSeriesData: [{ name: lineSeriesData[0].name, data: finalLineSeries }]
        };

        return (
            <>
                <CombinedChart
                    title="Product Distribution Chart"
                    subtitle="Distribution per Product"
                    categories={sortedAndFilteredCommonProducts}
                    series={plottingData}
                    xLabel="Products"
                    yLabel="Distribution Value"
                    align={"center"}
                />
                <Grid container spacing={2} >
                    <Grid item xs={4} style={{ textAlign: "center" }} >
                        <div className={classes.assortment}>
                            {"   Assortment Depth"}
                        </div>
                        <div className={classes.assortmentDetails}>
                            {"(How many different products from our portfolio can shoppers find?)"}
                        </div>
                        <div style={{ fontSize: "3em" }}>
                            {`[${answer?.AssortmentDepth?.toFixed(2) || 0}] `}
                        </div>
                    </Grid>
                    <Grid item xs={8} >
                        <PieChart
                            title={"Share of Shelf"}
                            subtitle="Distribution of SoS per Product"
                            data={answer.pieSeries}
                            align={"center"}
                            size={"40%"}
                            custom={true}
                        />
                    </Grid>
                </Grid>
                <ComplianceChart categories={answer?.complianceCategories || []} complianceValues={answer?.complianceSeries || []} />
            </>
        );
    } else {
        const toggleState = toggleStates[answer.id] || 'top10';
        return <RenderProductImage answer={answer} toggleState={toggleState} top10={top10} bottom10={bottom10} />;
    }
}

function handleTextTextArea({ answer, toggleStates, utils, top10, bottom10, classes, checkSentenceLength, handleExpandClick, expandedText }) {
    const toggleState = toggleStates[answer.id] || 'top10';
    const sortedAndSlicedData = utils.getSortedData(answer.sortedText, toggleState, 'y', top10, bottom10);
    return (
        <CustomTextChart
            classes={classes}
            answer={answer}
            sortedData={sortedAndSlicedData}
            checkSentenceLength={checkSentenceLength}
            handleExpandClick={handleExpandClick}
            chartData={answer}
            expandedText={expandedText}
        />
    );
}

const RenderProductImage = ({ answer, toggleState, top10, bottom10 }) => {
    const [maxHeight, setMaxHeight] = useState(55); // Initial max height in vh
    useEffect(() => {
        const handleResize = () => {
            const newMaxHeight = Math.min(55, window.innerHeight * 0.8);
            setMaxHeight(newMaxHeight);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const sortedImageData = utils.getSortedData(answer.imageData, toggleState, 'y', top10, bottom10);
    return (
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", maxHeight: `${maxHeight}vh`, overflowY: "auto" }}>
            {sortedImageData.map((imageData, index) => {
                const imageUrl = imageData.url;
                return (
                    <div key={index} style={{ margin: "5px", display: 'inline-block', width: 'calc(10% - 10px)', textAlign: 'center' }}>
                        <img
                            src={imageUrl}
                            alt={`Image ${index}`}
                            loading="lazy"
                            style={{ width: "100%", height: "auto", margin: '0 auto' }}
                            className="survey-view-image"
                            onClick={(e) => utils.openPopUp(e, imageData.url, index, sortedImageData)}
                            onKeyDown={(e) => utils.openPopUp(e, imageData.url, index, sortedImageData)}
                        />
                    </div>
                );
            })}
        </div>
    );
}


export const answerType = {
    radio: function ({ answer, toggleStates, utils, top10, bottom10, isCustomOption, classes, checkSentenceLength, handleExpandClick, expandedText }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series[0]?.data, toggleState, 'y', top10, bottom10);
        const plottingData = {
            ...answer.series[0],
            data: sortedAndSlicedData
        };
        const dataWithPercentages = sortedAndSlicedData.map(dataPoint => ({
            ...dataPoint,
            percentage: ((dataPoint.y / answer.totalCount) * 100).toFixed(2)
        }));
        const categories = dataWithPercentages.map(dataPoint => {
            const nameParts = dataPoint.name.split('-');
            return nameParts[0];
        });

        if (sortedAndSlicedData.length > 5) {
            return (
                <BarChart
                    subtitle={answer.subtitle}
                    categories={categories}
                    series={plottingData}
                    totalCount={answer.totalCount}
                    orderBy="descending"
                    xLabel={`No of responses`}
                />
            );
        } else if (isCustomOption) {
            return (
                <CustomTextChart
                    classes={classes}
                    answer={answer}
                    sortedData={answer.sortedText}
                    checkSentenceLength={checkSentenceLength}
                    handleExpandClick={handleExpandClick}
                    chartData={answer}
                    expandedText={expandedText}
                />
            );
        } else {
            return (
                <PieChart
                    subtitle={answer.subtitle}
                    data={dataWithPercentages}
                />
            );
        }
    },
    checkbox: function ({ answer, toggleStates, utils, top10, bottom10, isCustomOption, classes, checkSentenceLength, handleExpandClick, expandedText }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series[0]?.data, toggleState, 'y', top10, bottom10);
        const plottingData = {
            ...answer.series[0],
            data: sortedAndSlicedData
        };
        const dataWithPercentages = sortedAndSlicedData.map(dataPoint => ({
            ...dataPoint,
            percentage: ((dataPoint.y / answer.totalCount) * 100).toFixed(2)
        }));
        const categories = dataWithPercentages.map(dataPoint => {
            const nameParts = dataPoint.name.split('-');
            return nameParts[0];
        });
        if (sortedAndSlicedData.length > 5) {
            return (
                <BarChart
                    subtitle={answer.subtitle}
                    categories={categories}
                    series={plottingData}
                    totalCount={answer.totalCount}
                    orderBy="descending"
                    xLabel={`No of responses`}
                />
            );
        } else if (isCustomOption) {
            return (
                <CustomTextChart
                    classes={classes}
                    answer={answer}
                    sortedData={answer.sortedText}
                    checkSentenceLength={checkSentenceLength}
                    handleExpandClick={handleExpandClick}
                    chartData={answer}
                    expandedText={expandedText}
                />
            );
        } else {
            return (
                <PieChart
                    subtitle={answer.subtitle}
                    data={dataWithPercentages}
                />
            );
        }
    },
    date: function ({ answer, format }) {
        return (
            <LineChart
                subtitle={answer.subtitle}
                categories={answer.sortedDateData.categories}
                series={answer.sortedDateData.series}
                orientation="horizontal"
                xLabel={`Date Range`}
                yLabel={`No of responses`}
                dateFormat={format}
            />
        )
    },

    number: function ({ answer, toggleStates, utils, top10, bottom10 }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series.data, toggleState, 'y', top10, bottom10);
        const plottingData = {
            ...answer.series,
            data: sortedAndSlicedData
        };

        return (
            <BarChart
                subtitle={answer.subtitle}
                categories={answer.categories}
                series={plottingData}
                totalCount={answer.totalCount}
                orderBy="descending"
                xLabel={`No of responses`}
                type={'column'}
                orientation="horizontal"
            />
        );
    },
    barcode: function ({ answer, toggleStates, utils, top10, bottom10, classes, checkSentenceLength, handleExpandClick, expandedText }) {
        const isValidatedAssetBarcode = answer.validateFromAsset || Array.isArray(answer?.items) || (answer?.title === "General Section");
        if (isValidatedAssetBarcode) {
            return (
                <BarChart
                    subtitle={answer.subtitle}
                    categories={answer.categories}
                    series={answer.series}
                    orderBy="descending"
                    xLabel={`No of responses`}
                />
            );
        } else {
            const toggleState = toggleStates[answer.id] || 'top10';
            const dataForSorting = answer.sortedText.map(([name, count]) => ({ y: count, name }));
            const sortedAndSlicedData = utils.getSortedData(dataForSorting, toggleState, 'y', top10, bottom10);
            const finalSortedAndSlicedData = sortedAndSlicedData.map(item => [item.name, item.y]);
            return (
                <CustomTextChart
                    classes={classes}
                    answer={answer}
                    sortedData={finalSortedAndSlicedData}
                    checkSentenceLength={checkSentenceLength}
                    handleExpandClick={handleExpandClick}
                    chartData={answer}
                    expandedText={expandedText}
                />
            );
        }
    },
    multiFileStitch: function ({ answer, toggleStates, utils, top10, bottom10 }) {
        if (answer.validateFromPosMaterial) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <PosmChart
                            title="Promotion By Type Distribution Chart"
                            subtitle=""
                            categories={answer.posImages.categories}
                            series={answer.posImages.barSeries}
                            xLabel="Promotions"
                            yLabel="POSM Distribution"
                            align="center"
                            useCount={true}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <PosmChart
                            title="Promotion By Store Distribution Chart"
                            subtitle=""
                            categories={answer.posByStoreImages.categories}
                            series={answer.posByStoreImages.barSeries}
                            xLabel="Promotion Count"
                            yLabel="Outlets"
                            align="center"
                            useCount={true}
                        />
                    </Grid>
                </Grid>
            );
        } else {
            const toggleState = toggleStates[answer.id] || 'top10';
            return <RenderProductImage answer={answer} toggleState={toggleState} top10={top10} bottom10={bottom10} />;
        }
    },
    select: function ({ answer, toggleStates, utils, top10, bottom10 }) {
        const toggleState = toggleStates[answer.id] || 'top10';
        const sortedAndSlicedData = utils.getSortedData(answer.series[0]?.data, toggleState, 'y', top10, bottom10);
        const plottingData = {
            ...answer.series[0],
            data: sortedAndSlicedData
        };
        if (sortedAndSlicedData.length > 5) {
            return (
                <BarChart
                    subtitle={answer.subtitle}
                    categories={answer.categories}
                    series={plottingData}
                    totalCount={answer.totalCount}
                    orderBy="descending"
                    xLabel={`No of responses`}
                />
            );
        } else {
            const dataWithPercentages = sortedAndSlicedData.map(dataPoint => ({
                ...dataPoint,
                percentage: ((dataPoint.y / answer.totalCount) * 100).toFixed(2)
            }));
            return (
                <PieChart
                    subtitle={answer.subtitle}
                    data={dataWithPercentages}
                />
            );
        }
    },
    multiFile: handleFileSceneMultiFile,
    file: handleFileSceneMultiFile,
    scene: handleFileSceneMultiFile,
    text: handleTextTextArea,
    textarea: handleTextTextArea
};