import UiModel from './ui-model'
import Location from './Location';
import User from './ClientUser';
import LocationGroup from './outlet-group';
import UserGroup from './user-group';
import { RenderDayCell, brandBackgroundColor, brandColor } from '../components/Common/CustomRenderCell';
import { Avatar, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import dayjs from 'dayjs';

const iconValueRenderer = (params) => {
    const { id, field, value, api } = params;
    if (value === undefined || value === null) {
        return null;
    }
    const colDef = params.api.getColumn(field);
    let renderValue = value;
    if (colDef.valueFormatter) {
        renderValue = colDef.valueFormatter(params);
    }

    let prefix, suffix, avatarText;
    if (colDef.iconOptions === 'valueAvatar') {
        avatarText = value ? value.substring(0, 1) : '?';
    } else if (colDef.iconOptions === 'labelAvatar') {
        avatarText = renderValue ? renderValue.substring(0, 1) : '?';
    } else if (typeof colDef.iconOptions === 'function') {
        ({ prefix, suffix } = colDef.iconOptions({ ...params, renderValue, colDef }));
    } else if (typeof colDef.iconOptions === 'object') {
        ({ prefix, suffix } = colDef.iconOptions[value]);
    }
    if (avatarText) {
        prefix = <Avatar
            sx={{
                backgroundColor: brandBackgroundColor,
                color: brandColor,
                marginRight: '5px',
                width: '25px',
                height: '25px',
                fontSize: '0.65rem',
                lineHeight: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: "1px solid grey"
            }}>{avatarText}</Avatar>;
    }
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {prefix}
            <Typography variant="body2">{renderValue}</Typography>
            {suffix}
        </div>
    );

}
const priorityIconOptions = {
    1: {
        prefix: <ArrowUpwardIcon color="error" />
    },
    2: {
        prefix: <div style={{ width: "24px", display: "block" }}></div>
    },
    3: {
        prefix: <ArrowDownwardIcon color="primary" />
    }
}

const surveyModel = new UiModel({
    title: "Surveys",
    defaultSort: 'SurveyName ASC',
    linkColumn: 'SurveyName',
    comboType: 'SurveyType',
    titleDescription: 'Create and configure surveys for use in StoreBuddy',
    overrideLoaderOnInitialRender: true,
    api: 'SurveyMaster',
    module: "Survey",
    helperText: "Survey already started",
    customAddTextTitle: "Create Survey",
    pageTitle: "Surveys Overview",
    overrideFileName: 'Surveys',
    tabs: {
        "schedule": "Schedule Survey",
        "outlets": "Assign Outlets",
        "users": "Assign Users",
    },
    formConfig: {
        showTabbed: true
    },
    showAddIcon: false,
    breadCrumbs: "Survey",
    calculatedColumns: ['Color'],
    columns: [
        { field: "SurveyMasterId", type: 'number', hide: true, width: 80, label: "Survey Id", fieldLabel: null },
        { field: "SurveyName", type: "string", label: "Name", width: 240, pinned: true, tab: "schedule", required: true, min: 5, max: 50, requiredIfNew: true, link: true },
        { field: "SurveyDescription", type: "string", label: "Description", width: 240, tab: "schedule", max: 200 },
        { field: "FrequencyType", type: "radio", label: "Recurrence Period", width: 150, tab: "schedule", lookup: "SurveyFrequency", renderCell: iconValueRenderer, iconOptions: "valueAvatar" },
        { field: "Priority", type: "radio", label: "Priority", width: 100, tab: "schedule", lookup: "Priority", renderCell: iconValueRenderer, iconOptions: priorityIconOptions },
        {
            field: "StartDate", type: "date", label: "Start Date", width: 100, tab: "schedule", disablePast: true, showErrorText: true,
            shouldDisableDate: (date, formik) => {
                const endDate = formik.values['EndDate'] ? dayjs(formik.values['EndDate']) : null;
                return dayjs(date).isBefore(dayjs(), "day") || (endDate && dayjs(date).isAfter(endDate, "day"));
            }
        },
        {
            field: "EndDate", type: "date", label: "End Date", width: 100, tab: "schedule", disablePast: true,
            shouldDisableDate: (date, formik) => {
                const startDate = formik.values['StartDate'] ? dayjs(formik.values['StartDate']) : null;
                return dayjs(date).isBefore(dayjs(), "day") || (startDate && dayjs(date).isBefore(startDate, "day"));
            }
        },
        { field: "DefinedDays", type: "dayRadio", label: "Defined Days", tab: "schedule", lookup: 'DaysOfWeek', width: 220, renderCell: params => { return <RenderDayCell {...params} /> }, filterable: false },
        { field: "SurveyTypeId", type: "autocomplete", headerName: null, tab: "schedule", label: "Questionnaire", lookup: 'SurveyType', width: 240, selectField: "SurveyTypeId" },
        { field: "Notes", type: "string", headerName: null, tab: "schedule", label: "Notes For Field Team", width: 240 },
        { field: "TeamTarget", type: "number", headerName: null, tab: "schedule", label: "Min Surveys For Team", width: 240, minValue: 1 },
        { field: "SurveyStatus", type: "boolean", label: "Active", width: 80, tab: "schedule", defaultValue: true },
        { field: "IsAllLocations", type: "boolean", headerName: null, label: "Assign To All Outlets", tab: "schedule", width: 240, skip: { field: "IsAllLocations", step: 1 } },
        { field: "IsAllUsers", type: "boolean", headerName: null, label: "Assign To All Users", tab: "schedule", width: 240, skip: { field: "IsAllUsers", step: 2 } },
        { field: "LocationGroupMembers", type: "oneToMany", label: "Outlet Groups", width: 120, relation: LocationGroup.ChildGrid, countInList: true, tab: "outlets", filterable: false, sortable: false, showLabel: false, disableCellRedirect: true, useLinkColumn: false, readOnly: true },
        { field: "LocationMembers", type: "oneToMany", label: "Outlets", width: 80, relation: Location.ChildGrid, countInList: true, tab: "outlets", filterable: false, sortable: false, showLabel: false },
        { field: "UserGroupMembers", type: "oneToMany", label: "User Groups", width: 120, relation: UserGroup.ChildGrid, countInList: true, tab: "users", filterable: false, sortable: false, showLabel: false, disableCellRedirect: true, useLinkColumn: false, readOnly: true },
        { field: "UserMembers", type: "oneToMany", label: "Users", width: 80, relation: User.ChildGrid, countInList: true, tab: "users", filterable: false, sortable: false, showLabel: false },
    ],
    applyFieldConfig: function ({ data }) {
        const today = new Date();
        const startDate = new Date(data?.StartDate);
        const disabled = startDate <= today;
        return {
            FrequencyType: { disabled: disabled },
            StartDate: { disabled: disabled },
            SurveyTypeId: { disabled: disabled }
        }
    }
});

export default surveyModel;