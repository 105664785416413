import UiModel from "./ui-model";

const surveyInboxModel = new UiModel({
  title: "Survey Inbox",
  defaultSort: "SurveyTypeName ASC",
  linkColumn: "SurveyTypeName",
  titleDescription: "Review submitted surveys individually",
  api: "Survey",
  module: "SurveyInbox",
  detailsAPI: "Survey.ashx",
  
  pageTitle: "Survey Inbox Overview",
  readOnly: true,
  overrideFileName: "Survey Inbox", 
  addCreatedOnColumn: false,
  addCreatedByColumn: false,
  addModifiedOnColumn: false,
  addModifiedByColumn: false,
  rowRedirectLink: "/SurveyInbox/${LocationId}",
  addRecordToState: true,
  showExportWithDetails: true,
  columns: [
    {
      field: "SurveyTypeId",
      type: "number",
      hide: true,
      width: 80,
      label: "ID",
      fieldLabel: null,
      pinned: false,
    },
    {
      field: "SurveyName",
      type: "string",
      label: "Survey Name",
      width: 240,
      pinned: true,
      link: true,
        /* TO DO
  : ONCE EXPORT FUNCTIONALITY DISCUSSED REDIRECT LINK SHOULD BE /SurveyReport/${LocationId} 
  */
      linkTo: "/SurveyInbox/${LocationId}",
    },
    {
      field: "SurveyType",
      type: "string",
      label: "Questionairre",
      width: 240,
      pinned: false,
      link: false,
      hide: false,
    },
    {
      field: "OutletName",
      type: "string",
      label: "Outlet Name",
      width: 240,
      pinned: false,
      link: false,
    },
    {
      field: "OutletCode",
      type: "string",
      label: "Outlet Code",
      width: 240,
      pinned: false,
      link: false,
      hide: false,
    },
    {
      field: "MarketName",
      type: "string",
      label: "Markets",
      width: 240,
      pinned: false,
      link: false,
      hide: true,
    },
    {
      field: "Channel",
      type: "string",
      label: "Channels",
      width: 240,
      pinned: false,
      link: false,
      hide: true,
    },
    {
      field: "Classification",
      type: "string",
      label: "Classifications",
      width: 240,
      pinned: false,
      link: false,
      hide: true,
    },
    {
      field: "Latitude",
      type: "string",
      label: "Latitude",
      width: 240,
      pinned: false,
      link: false,
      hide: true,
    },
    {
      field: "Longitude",
      type: "string",
      label: "Longitude",
      width: 240,
      pinned: false,
      link: false,
      hide: true,
    },
    {
      field: "SurveyCategory",
      type: "string",
      label: "Survey Category",
      width: 240,
      pinned: false,
      link: false,
      hide: true,
    },
    {
      field: "EndDate",
      type: "dateTime",
      label: "Survey Date Time",
      width: 240,
      pinned: false,
      link: false,
    },
    {
      field: "ModifiedByUser",
      type: "string",
      label: "User",
      width: 240,
      pinned: false,
      link: false,
    },
  ],
});

export default surveyInboxModel;
