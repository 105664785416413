import { useEffect } from "react";
import { apis, request } from "../httpUtil";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import constants from "./constants";

const freshChatScript = constants.freshChatScript;

const useFreshChat = ({ sidebarToggleMobile, mobile, loginData }) => {
  const host = process.env.REACT_APP_HOST, history = useHistory(), dispatch = useDispatch();

  useEffect(() => {
    const fcFrame = document.getElementById("fc_frame");
    if (fcFrame && mobile) {
      sidebarToggleMobile ? window.fcWidget.show() : window.fcWidget.hide();
    }

    const onlyForProd = host && (host.includes('portal') || host.includes('dev')),
      scriptId = 'freshChat-script',
      existingScript = document.getElementById(scriptId);

    if (!existingScript && Object.keys(loginData).length && onlyForProd) {
      const { Username, FirstName, LastName, FreshChatRestoreId, ClientUserId } = loginData.tags;

      const WIDGET_CONFIG = {
        siteId: 'CoolR-Playbook-V3',
        externalId: Username,
        restoreId: FreshChatRestoreId ? FreshChatRestoreId : null,
        config: {
          cssNames: {
            widget: "custom_fc_frame"
          }
        }
      }


      window.fcSettings = {

        onInit: function () {
          if (mobile) {
            window.fcWidget.on("widget:loaded", function (resp) {
              document.getElementById("fc_frame").classList.add("custom_fc_frame");
              window.fcWidget.hide();
            });
          }


          window.fcWidget.setExternalId(Username);
          window.fcWidget.user.setFirstName(FirstName);
          window.fcWidget.user.setLastName(LastName);
          window.fcWidget.user.setEmail(Username);

          window.fcWidget.user.get(function (resp) {
            const status = resp && resp.status;
            if (status !== 200) {
              window.fcWidget.user.setProperties({
                firstName: FirstName,
                lastName: LastName,
                email: Username
              });
              window.fcWidget.on('user:created', function (resp) {
                const status = resp && resp.status, data = resp && resp.data;
                if (status === 200) {
                  if (data.restoreId && data.externalId === Username) {
                    const url = `${apis.ClientUser}?action=updateRestoreId&clientUserId=${ClientUserId}&freshChatRestoreId=${data.restoreId}`;
                    request({ url: url, additionalParams: { method: "GET" }, history, dispatch })
                      .then((result) => {
                        console.log("freshChatRestoreId id updated")
                      })
                      .catch((err) => {
                        console.log("err in freshChatRestoreId id update=>", err)
                      })
                  }
                }
              });
            }
          });
        }
      }

      if (mobile) {
        window.fcWidgetMessengerConfig = WIDGET_CONFIG;
      } else {
        delete WIDGET_CONFIG["config"];
        window.fcWidgetMessengerConfig = WIDGET_CONFIG
      }
      const script = document.createElement('script');
      script.src = freshChatScript + `?version=${Date.now()}`;
      script.id = scriptId;
      script.async = true;
      document.body.appendChild(script);

    }
  }, [loginData, mobile, sidebarToggleMobile]);
};

export default useFreshChat;