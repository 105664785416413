import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import actions from "../../../redux/actions";
import PageTitle from "../../PageTitle";
import { Box, Button, Card, CardContent, Checkbox, CircularProgress, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, MenuList, OutlinedInput, Pagination, PaginationItem, Paper, Select, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import utils from "../../../utils";
import FilterPanel from "../../filterPanel";
import constants from "../../../utils/constants";
import { apis, request } from "../../../httpUtil";
import { makeStyles } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useDebounce } from "use-debounce";
import operationalModel from "../../../pages/OperationalDashboardConfig";
import BarChartComponent from "./BarChartComponent";
import BarChart from "../../SurveyAnalysis/BarChart";
import MultiLineChart from "../../SurveyAnalysis/MultiLineChart";
import dayjs from "dayjs";
import { useSnackbar } from "../../SnackBar";
import SurveyMap from "./SurveyMap";

const { t, numberWithCommas } = utils;
const dateFilterMaxDate = new Date();
const { defaultCoverageOption, defaultSubmissionsOption, defaultSubmissionsOrder } = constants;

const useStyles = makeStyles((theme) => ({
    kpiContainer: {
        marginTop: '10px !important',
        marginBottom: '10px !important'
    },
    surveyFilter: {
        width: '130px !important'
    },
    periodFilter: {
        width: '170px !important'
    },
    submissionsPerContainer: {
        overflowX: 'scroll !important'
    },
    coverageContainer: {
        width: '100% !important'
    },
    kpiCardContainer: {
        width: '250px !important'
    },
    kpiCard: {
        width: '100% !important'
    },
    surveySelectContainer: {
        position: 'relative',
    },
    surveyApplyBtn: {
        width: '100% !important',
        marginTop: 'auto',
        position: 'sticky',
        bottom: 0
    },
    mapContainer: {
        marginTop: '10px !important',
        marginBottom: '10px !important',
        width: '100% !important'
    },
    stickyButtonContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        bottom: 0,
        padding: theme.spacing(1)
    }
}));

function SurveyOperationalDashboard() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const comboData = useSelector(state => state.appReducer.comboData) || {};
    const reportingCombos = useSelector(state => state.appReducer.resultAnalysisComboData) || {};
    const { Market, City, Users, Distributor } = reportingCombos;
    const { LocationType: Channels, LocationClassification: Classification, State } = comboData;
    const [allSurveys, setAllSurveys] = useState([]);
    const [selectedAllSurveys, setSelectedAllSurveys] = useState([]);
    const [surveyOutlets, setSurveyOutlets] = useState([]);
    const [tempFilterModel, setTempFilterModel] = useState({
        MarketId: null,
        LocationTypeId: null,
        ClassificationId: null,
        DistributorId: null,
        SurveyMasterId: []
    });
    const [isFilter, setIsFilter] = useState(false);
    const [filter, setFilters] = useState(utils.defaultFilter);
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const [filterModel, setFilterModel] = useState({ items: [] });
    const [submissionsPerOption, setSubmissionsPerOption] = useState(defaultSubmissionsOption);
    const [orderSubmissionsPer, setOrderSubmissionsPer] = useState(defaultSubmissionsOrder)
    const [coveragePerOption, setCoveragePerOption] = useState(defaultCoverageOption)
    const [submissionsPer, setSubmissionsPerData] = useState([]);
    const [surveyCoverageData, setSurveyCoverageData] = useState([]);
    const [surveySubmissionsInTimeData, setSurveySubmissionsInTimeData] = useState([]);
    const format = utils.systemDateTimeFormat(true);
    const filterValues = useSelector(state => state.appReducer.filterValues);
    const [allSurveyFilter, setAllSurveyFilter] = useState(true);
    const [usersCount, setUserCount] = useState({});
    const snackbar = useSnackbar();
    const [loadingSubmissions, setLoadingSubmissions] = useState(false);
    const [loadingCoverage, setLoadingCoverage] = useState(false);
    const [loadingSubmissionsInTime, setLoadingSubmissionsInTime] = useState(false);
    const [locationsMapData, setLocationsMapData] = useState([]);
    const [submissionsCount, setSubmissionsCount] = useState(0);

    const operationalComboFilters = [

        {
            label: 'Market',
            idKey: 'MarketId',
            options: Market
        },
        {
            label: 'Channel',
            idKey: 'LocationTypeId',
            options: Channels
        },
        {
            label: 'Distributor',
            idKey: 'DistributorId',
            options: Distributor
        },
        {
            label: 'Classification',
            idKey: 'ClassificationId',
            options: Classification
        }
    ];

    const kpiCards = [
        {
            name: 'Submissions',
            count: submissionsCount
        },
        {
            name: 'Outlets with submission',
            count: surveyOutlets.OutletCount
        },
        {
            name: 'Users with submission',
            count: surveyOutlets?.UserCount
        }
    ]

    function setPage(page) {
        setFilters((prev) => ({
            ...prev,
            isGridFilter: true,
            pagination: { ...prev.pagination, page },
        }));
    }
    function setPageSize(pageSize) {
        setFilters((prev) => ({
            ...prev,
            isGridFilter: true,
            pagination: { ...prev.pagination, pageSize },
        }));
    }

    useEffect(() => {
        let backRoute = location.pathname;
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute }
        });
        utils.getAnalysisComboData();
    }, []);

    React.useEffect(() => {
        const breadcrumbs = [{ text: constants.operationalDashboardTitle }];
        dispatch({
            type: actions.SET_PAGE_TITLE_DETAILS,
            pageTitleDetails: (
                <PageTitle showBreadcrumbs={true} breadcrumbs={breadcrumbs} />
            )
        });
    }, []);

    const fetchSurveyData = async () => {
        try {
            const reqParams = {
                checkedSurveys: selectedAllSurveys || null,
                startDate: filterValues?.fromDate,
                endDate: filterValues?.toDate,
                isFilter: isFilter,
                MarketId: tempFilterModel.MarketId && tempFilterModel.MarketId.length > 0 ? tempFilterModel.MarketId[0] : null,
                LocationTypeId: tempFilterModel.LocationTypeId && tempFilterModel.LocationTypeId.length > 0 ? tempFilterModel.LocationTypeId[0] : null,
                ClassificationId: tempFilterModel.ClassificationId && tempFilterModel.ClassificationId.length > 0 ? tempFilterModel.ClassificationId[0] : null,
                DistributorId: tempFilterModel.DistributorId && tempFilterModel.DistributorId.length > 0 ? tempFilterModel.DistributorId[0] : null

            }
            const result = await request({ url: apis.SurveyOperationalDashboard, params: reqParams, history, dispatch, jsonPayload: true });
            setAllSurveys(result?.data.clientSurveys);
            setSurveyOutlets(result?.data.outlets);
            setUserCount(result?.data.users);
            setLocationsMapData(result?.data.locationsMapData);
            setSubmissionsCount(result?.data?.submissions?.SubmissionsCount || 0)

        } catch (error) {
            snackbar.showError(`${t('Error fetching constant survey data', tOpts)}`);
            console.error(t('Error fetching constant survey data:', tOpts), error);
        }
    }

    const fetchSubmissionsData = async () => {
        try {
            setLoadingSubmissions(true);
            const reqParams = {
                checkedSurveys: selectedAllSurveys || null,
                optionSelected: submissionsPerOption,
                orderSelected: orderSubmissionsPer
            }
            const result = await request({ url: apis.SurveySubmissions, params: reqParams, history, dispatch, jsonPayload: true, disableLoader: true });
            setSubmissionsPerData(result?.data);
            setLoadingSubmissions(false);

        } catch (error) {
            snackbar.showError(`${t('Error fetching constant submissions data', tOpts)}`);
            console.error(t('Error fetching constant submissions data:', tOpts), error);
        }
    }

    const fetchCoverageData = async () => {
        try {
            setLoadingCoverage(true)
            const reqParams = {
                checkedSurveys: selectedAllSurveys || null,
                coverageFilter: coveragePerOption
            }
            const result = await request({ url: apis.SurveyCoverage, params: reqParams, history, dispatch, jsonPayload: true, disableLoader: true });
            setSurveyCoverageData(result?.data);
            setLoadingCoverage(false)
        } catch (error) {
            snackbar.showError(`${t('Error fetching constant survey coverage data', tOpts)}`);
            console.error(t('Error fetching constant survey coverage data:', tOpts), error);
        }
    }

    const fetchSubmissionInTimeData = async () => {
        try {
            setLoadingSubmissionsInTime(true);
            const reqParams = {
                submissionFilter: 'survey',
            }
            const result = await request({ url: apis.SurveySubmissionsInTime, params: reqParams, history, dispatch, jsonPayload: true, disableLoader: true });
            setSurveySubmissionsInTimeData(result?.data);
            setLoadingSubmissionsInTime(false)
        } catch (error) {
            snackbar.showError(`${t('Error fetching constant survey submission data', tOpts)}`);
            console.error(t('Error fetching constant survey submission data:', tOpts), error);
        }
    }

    useEffect(() => {
        fetchSurveyData();
    }, [tempFilterModel, filterValues]);

    const handleApplyClick = () => {
        const updatedTempFilterModel = { ...tempFilterModel, SurveyMasterId: selectedAllSurveys };
        setTempFilterModel(updatedTempFilterModel);
    }

    useEffect(() => {
        fetchSubmissionsData();
    }, [orderSubmissionsPer, submissionsPerOption, tempFilterModel]);

    useEffect(() => {
        fetchCoverageData();
    }, [coveragePerOption, tempFilterModel]);

    {/* Commenting for now as is WIP */ }
    // useEffect(() => {
    //     fetchSubmissionInTimeData();
    // }, [surveySubmissionsInTimeData]);

    const filtersConfig = [
        {
            name: "Time Period",
            config: [
                {
                    name: "period",
                    type: "date",
                    size: 'small',
                    maxDate: dateFilterMaxDate,
                    dateFormat: format,
                    hidden: false,
                    defaultDay: constants.noneDateFilter,
                    label: "Period",
                    hideMenuLabel: true
                }
            ],

            openDefault: false
        }
    ];

    useEffect(() => {
        dispatch({
            type: actions.SET_FILTER_VALUES, filterValues: {
                fromDate: dayjs().subtract(30, 'day'),
                toDate: dateFilterMaxDate,
                daysFilter: "30:day",
            }
        });
    }, [comboData, reportingCombos]);


    const onComboFilterChange = (event, type) => {
        const value = event.target.value;
        const validTypes = Object.keys(tempFilterModel);

        if (!validTypes.includes(type)) {
            console.error(`Invalid type: ${type}. Cannot update filterModel.`);
            return;
        }

        setTempFilterModel(prevModel => {
            const newModel = {
                ...prevModel,
                [type]: value !== "all" ? [value] : null,
            };
            return newModel;
        });
        setIsFilter(true);
    };

    const CombinedFilterSelect = ({ label, options, value, onChange }) => (
        <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth size="small">
                <InputLabel>{label}</InputLabel>
                <Select label={label} value={value} onChange={onChange}>
                    <MenuItem value="all">All</MenuItem>
                    {options?.map((option, key) => (
                        <MenuItem key={option?.LookupId} value={option.LookupId}>
                            {t(`${option?.DisplayValue} `, tOpts)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );

    const CustomKpiCard = ({ count, label, classes }) => (
        <Grid container justifyContent="center" alignItems="center" className={classes.kpiCardContainer}>
            <Card className={classes.kpiCard}>
                <CardContent>
                    <Typography variant="h5" component="div" align="center">
                        {count}
                    </Typography>
                    <Typography align="center" mt={1}>
                        {label}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    const handleCheckChange = (event) => {
        let { target: { value } } = event;
        if (value.includes('All') && value.length === allSurveys.length + 1 && allSurveyFilter) {
            setAllSurveyFilter(false);
            value = [];
        } else if (value.includes('All')) {
            setAllSurveyFilter(!allSurveyFilter);
            if (!allSurveyFilter) {
                value = []
            }
        } else {
            setAllSurveyFilter(false);
        }
        const selectedValues = value.filter((item) => item !== 'All');
        setSelectedAllSurveys(selectedValues);
    };

    //clear filter
    function clearFilter() {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: [],
        });
        dispatch({ type: actions.GRID_HEADER_FILTERS, headerFilters: {} })
    }

    const RenderSurveyGrid = () => {
        return (
            <operationalModel.Grid customFilters={tempFilterModel}
            />
        );
    };
    const RenderSurveyGridMemoized = useMemo(() => <RenderSurveyGrid />, [tempFilterModel]);

    const CustomLoaderComponent = () => {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} p={3}>
                <CircularProgress />
            </Box>
        );
    }

    const RenderSubmissionsPerChart = () => {
        const { categories, seriesData } = submissionsPer;
        if (loadingSubmissions) {
            return <CustomLoaderComponent />
        }
        return (
            <Paper className={classes.submissionsPerContainer}>
                <BarChartComponent
                    title={'Survey'}
                    subtitle={`Submissions per ${submissionsPerOption}`}
                    categories={categories || []}
                    series={seriesData || []}
                    orientation="horizontal"
                    loading={true}
                />
            </Paper>
        )
    }
    const RenderSubmissionsPerChartMemoized = useMemo(() => <RenderSubmissionsPerChart />, [submissionsPer, loadingSubmissions]);

    const RenderSubmissionsInTimeChart = () => {
        const { categories, seriesData } = surveySubmissionsInTimeData;
        if (loadingSubmissionsInTime) {
            return <CustomLoaderComponent />
        }
        return (
            <Paper className={classes.coverageContainer}>
                <MultiLineChart
                    subtitle={''}
                    categories={categories}
                    series={seriesData}
                    type={'datetime'}
                    custom={true}
                    chartType={'line'}
                    format={format}
                />
            </Paper>
        )
    }
    const RenderSubmissionsInTimeChartMemoized = useMemo(() => <RenderSubmissionsInTimeChart />, [surveySubmissionsInTimeData, loadingSubmissionsInTime]);

    const RenderCoveragePerChart = () => {
        const { categories, seriesData } = surveyCoverageData;
        if (loadingCoverage) {
            return <CustomLoaderComponent />
        }
        return (
            <Paper className={classes.coverageContainer}>
                <BarChart
                    subtitle={''}
                    categories={categories || []}
                    series={seriesData || []}
                    orderBy="descending"
                    xLabel={''}
                />
            </Paper>
        );
    }
    const RenderCoveragePerChartMemoized = useMemo(() => <RenderCoveragePerChart />, [surveyCoverageData, loadingCoverage]);
    const RenderMemoizedMap = useMemo(() => <SurveyMap locations={locationsMapData} t={t} tOpts={tOpts} classes={classes} />, [locationsMapData]);
    return (
        <Grid spacing={3} xs={12}>
            <Paper>
                <Grid container spacing={1} p={3}>
                    <Grid item>
                        <FormControl variant="outlined" fullWidth size="small" className={classes.surveyFilter}>
                            <InputLabel>{t("Survey", tOpts)}</InputLabel>
                            <Select
                                label={t("Survey", tOpts)}
                                className={classes.surveySelectContainer}
                                multiple
                                value={allSurveyFilter ? allSurveys.map(survey => survey.SurveyMasterId) : selectedAllSurveys}
                                MenuProps={{ PaperProps: { style: { width: 300 } } }}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selectedKeys) =>
                                    allSurveyFilter ? t('All', tOpts) :
                                        selectedKeys
                                            .map((key) =>
                                                allSurveys.find((survey) => survey.SurveyMasterId === key)?.SurveyName || key
                                            )
                                            .join(", ")
                                }
                                onChange={(event) => handleCheckChange(event, event.target.value)}
                            >
                                <MenuItem value={'All'}>
                                    <Checkbox checked={allSurveyFilter} />
                                    <IconButton>
                                        <FiberManualRecord />
                                    </IconButton>
                                    <ListItemText primary={t(`${'All'} `, tOpts)} />
                                </MenuItem>
                                {allSurveys?.map((survey, key) => (
                                    <MenuItem key={survey?.LookupId} value={survey?.SurveyMasterId}>
                                        <Checkbox checked={allSurveyFilter || selectedAllSurveys.includes(survey?.SurveyMasterId)} />
                                        <IconButton color={survey.SurveyStatus ? 'success' : 'error'}>
                                            <FiberManualRecord />
                                        </IconButton>
                                        <ListItemText primary={t(`${survey?.SurveyName} `, tOpts)} />
                                    </MenuItem>
                                ))}
                                <Paper className={classes.stickyButtonContainer}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleApplyClick}
                                        className={classes.surveyApplyBtn}
                                    >
                                        {t("Apply", tOpts)}
                                    </Button>
                                </Paper>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item className={classes.periodFilter}>
                        <FilterPanel filtersConfig={filtersConfig} getData={null} dateFilterMaxDate={dateFilterMaxDate} />
                    </Grid>
                    {operationalComboFilters.map((filter, f) => {
                        const { label, idKey, options } = filter;
                        return (
                            <CombinedFilterSelect key={f} label={t(label, tOpts)} options={options} value={tempFilterModel[idKey] || 'all'} onChange={(e) => onComboFilterChange(e, idKey)} />
                        );
                    })}
                </Grid>
            </Paper>
            <Grid container spacing={3} className={classes.kpiContainer}>
                {kpiCards.map((card, c) => {
                    const { name, count } = card;
                    return (
                        <Grid item key={c}>
                            <CustomKpiCard count={count || 0} label={t(name, tOpts)} classes={classes} />
                        </Grid>
                    );
                })}
            </Grid>

            <Paper>
                <Grid container mt={2} spacing={1}>
                    <Grid item xs={8}>
                        <Paper p={1}>
                            {RenderSurveyGridMemoized}
                        </Paper>

                        {/* Commenting for now as is WIP */}
                        {/* <Paper p={1} m={1} mt={2}>
                            <Grid m={1}>
                                <Typography variant="h6">{t('Submissions in time', tOpts)}</Typography>
                            </Grid>
                            <Grid container p={2}>
                                <Grid item>  
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={[
                                            { value: 'Survey', label: t('Per Survey', tOpts) },
                                            { value: 'User', label: t('Per User', tOpts) },
                                            { value: 'Total', label: t('Total', tOpts) }
                                        ]}
                                        value={'Survey'}
                                        onChange={(event, newValue) => {}}
                                        />
                                </Grid>
                                <Grid item ml={2}> 
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={[
                                            { value: 'day', label: t('Per Day', tOpts) },
                                            { value: 'week', label: t('Per Week', tOpts) }
                                        ]}
                                        value={'day'}
                                        onChange={(event, newValue) => {}}
                                    />
                                </Grid>
                                <Grid item ml={2}> 
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={[
                                            { value: 'cumulative', label: t('Cumulative', tOpts) },
                                            { value: 'period', label: t('Per Period', tOpts) }
                                        ]}
                                        value={'day'}
                                        onChange={(event, newValue) => {}}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                {RenderSubmissionsInTimeChartMemoized}
                            </Grid>
                        </Paper> */}
                        <Paper mt={2}>
                            <Grid container p={2}>
                                <Grid item>
                                    <CustomToggleButtonGroup
                                        label={t('Coverage % per', tOpts)}
                                        values={utils.coverageFilters}
                                        value={coveragePerOption}
                                        onChange={(event, newValue) => { setCoveragePerOption(newValue) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                {RenderCoveragePerChartMemoized}
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper p={1} m={1}>
                            <Typography variant="h6" m={1}>{t('Submissions Per', tOpts)}</Typography>
                            <Grid container p={2}>
                                <Grid item ml={2}>
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={utils.submissionsPerFilters}
                                        value={submissionsPerOption}
                                        onChange={(event, newValue) => { setSubmissionsPerOption(newValue) }}
                                    />
                                </Grid>
                                <Grid item ml={2}>
                                    <CustomToggleButtonGroup
                                        label={null}
                                        values={utils.orderSubmissionsPer}
                                        value={orderSubmissionsPer}
                                        onChange={(event, newValue) => { setOrderSubmissionsPer(newValue) }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                {RenderSubmissionsPerChartMemoized}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container className={classes.mapContainer}>
                <Paper className={classes.mapContainer}>
                    {RenderMemoizedMap}
                </Paper>
            </Grid>
        </Grid>
    )
}

const CustomToggleButtonGroup = ({ label, values, value, onChange }) => (
    <Grid container>
        {label ? <Grid item>
            <Typography variant="h6">{label}</Typography>
        </Grid> : <></>}
        <Grid item ml={2}>
            <ToggleButtonGroup
                size="small"
                value={value}
                exclusive
                onChange={onChange}
                aria-label="toggle-button-group"
            >
                {values.map((val) => (
                    <ToggleButton key={val.value} value={val.value} aria-label={val.label}>
                        {val.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Grid>
    </Grid>
);

export default SurveyOperationalDashboard;