import theme from "./theme";

export const defaultSettings = {
    ToDo: {
        title: "ToDo",
        options: [],
        priorityIcon: true,
        stripBackgroundColor: theme.palette.card_orange.main,
        cardBackgroundColor: theme.palette.card_orange.light,
    },
    InProgress: {
        title: "In Progress",
        options: [],
        priorityIcon: true,
        stripBackgroundColor: theme.palette.card_blue.main,
        cardBackgroundColor: theme.palette.card_blue.light,
    },
    ExpiringSoon: {
        title: "Expiring Soon",
        options: [],
        priorityIcon: true,
        stripBackgroundColor: theme.palette.card_pink.main,
        cardBackgroundColor: theme.palette.card_pink.light,

    },
    Completed: {
        title: "Completed",
        options: [],
        priorityIcon: true,
        stripBackgroundColor: theme.palette.card_green.main,
        cardBackgroundColor: theme.palette.card_green.light,

    }
}