import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    IconButton,
    Grid,
    Typography,
    FormControl,
    Box,
    TextField,
    MenuItem,
    Select,
    useTheme,
    Paper,
    Stack,
    Button,
    Tooltip,
    InputLabel,
    OutlinedInput,
    ListItemText
} from "@mui/material";
import BarcodeImg from "../../../../src/assets/images/barcode.svg";
import { Add, ArrowDownwardSharp, ArrowUpward, BorderColor, ControlPointSharp, Delete, DeleteOutline, Info, PhotoCamera, TagFacesTwoTone } from "@material-ui/icons";
import { DatePicker } from '@mui/x-date-pickers-pro';
import QuestionsModal from "./QuestionsModal";
import { nanoid } from "nanoid";
import useMobile from "../../../utils/useMobile";
import dbUtils from "../../../pages/RenderSurveyAnswer/dbUtil";
import { ArrowDropDown, Clear, Lan } from "@mui/icons-material";
import DefineConditionModal from "./DefineConditionModal";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import utils from "../../../utils";
import swal from "sweetalert";
import { useSnackbar } from "../../SnackBar";
import constants from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { apis, request } from "../../../httpUtil";
import { useHistory } from "react-router";
import actions from "../../../redux/actions";
import { CustomBarcode, PhotoCameraDisplayType, renderFormControl, renderQuestionOptions } from "./RenderQuestionDetails";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    newSectionContainer: {
        marginTop: 3,
        padding: 1
    },
    fullWidth: {
        width: '100%'
    },
    addOptionMargin: {
        marginTop: '20px !important'
    },
    addOptionSpacing: {
        marginTop: '6px !important'
    },
    addOptionInput: {
        width: '187px !important'
    },
    addOptionContainer: {
        width: '100% !important'
    },
    infoTooltip: {
        fontSize: '20px !important',
        marginTop: '-5px !important'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const {QUESTION_TYPES, CREATOR_QUESTION_DISPLAYNAME} = constants;
function SelectDynamicOptions({ qid, question, questionnaireInit, getLayoutByType, selectedTab, t, tOpts, setDynamicQuestionsToUpdate }) {
    const [selectedType, setSelectedType] = useState('None');
    const locationDetails = useSelector(state => state.appReducer.questionnaireLocationDetails);
    const locationId = useSelector(state => state.appReducer.questionnaireLocationId);
    const assetId = useSelector(state => state.appReducer.questionnaireAssetFilter);
    let { assets } = locationDetails;
    const [showAssetFilter, setShowAssetFilter] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    if (locationId) {
        assets = { [locationId]: assets[locationId] };
    } 

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    React.useEffect(()=>{
        const dynamicOptions = question?.dynamicOptions;
        if (dynamicOptions) {
            setSelectedType(getKeyByValue(constants.dynamicOptionType, dynamicOptions.items));
        }
    },[question]);

    const addDynamicOption = async (qid, dynamicType) => {
        const generatedLayout = getLayoutByType(dynamicType);
        if (dynamicType) {
            const initialQuestionnaire = [...questionnaireInit];
            const updatedDynamicType = constants.dynamicOptionType[dynamicType];
            if (selectedTab >= 0 && selectedTab < initialQuestionnaire.length) {
                const selectedTabQuestions = initialQuestionnaire[selectedTab].questions;
                if (qid >= 0 && qid < selectedTabQuestions.length) {
                    const question = selectedTabQuestions[qid];
                    if (!question.dynamicOptions) {
                        question.dynamicOptions = {};
                    }
                    question.dynamicOptions.items = updatedDynamicType;
                    question.dynamicOptions.layout = generatedLayout;
                    question.dynamicOptionLookup = constants.previewRepeatType[updatedDynamicType] || 0;
                }
            }
            setDynamicQuestionsToUpdate(initialQuestionnaire[selectedTab].questions);
            await dbUtils.set('questionnaire', initialQuestionnaire);
        }
    }
    
    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
        addDynamicOption(qid, e.target.value);
        if (e.target.value === utils.ProductContentSensitive) {
            setShowAssetFilter(true);
        } else {
            setShowAssetFilter(false);
        }
    }
    const handleAssetFilter = async (e) => {
        const assetPlanogramId = e.target.value;
        dispatch({
            type: actions.SAVE_QUESTIONNAIRE_ASSET_FILTER,
            questionnaireAssetFilter: assetPlanogramId
        });
    }
    return (
        <>
            <Grid container alignItems="center" spacing={2} >
                <Grid item xs={5}>
                    <Typography>{t("Dynamic Answers", tOpts)}</Typography>
                </Grid>
                <Grid item xs={7}>
                    <FormControl fullWidth>
                        <Select
                            value={selectedType}
                            onChange={handleTypeChange}
                        >
                            {Object.entries(constants.dynamicOptionType).map(([optionText, optionValue]) => (
                                <MenuItem key={optionValue} value={optionText}>
                                    {optionText}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {
                showAssetFilter? 
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={5}>
                        <Typography>{t("Select asset", tOpts)} :</Typography>
                    </Grid>
                    <Grid item xs={7}> 
                        <FormControl fullWidth>
                            <Select
                                value={assetId}
                                onChange={handleAssetFilter}
                            >    
                                {
                                    Object.keys(assets).map((key) => {
                                        const assetArray = assets[key];
                                        return (
                                            assetArray?.map((asset, a) => (
                                                <MenuItem key={a} value={asset.PlanogramId}>
                                                    {asset.SerialNumber}
                                                </MenuItem>
                                            ))
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                : null
            }
        </>
    );
}
const ListDynamicOptions = ({
  question,
  isDisabled,
  editMode,
  handleChange,
  handleCheckboxOptionChange,
  section,
  getAnswerValue,
  answerFields,
  setAnswerFields,
  item,
  handleMultipleSelectChange
}) => {
  const t = utils.t;
  const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n };
  const { type, displayName, dynamicOptionLookup, questionUniqueId, subType } = question;
  const locationDetails = useSelector(
    (state) => state.appReducer.questionnaireLocationDetails
  );
  const locationId = useSelector(
    (state) => state.appReducer.questionnaireLocationId
  );
  const assetId = useSelector(
    (state) => state.appReducer.questionnaireAssetFilter
  );
  let { assets, products, productsContextSensitive, productsInStorePlanogram } =
    locationDetails;
  const [selectedOption, setSelectedOption] = useState("");
  const classes = useStyles();
  if (locationId) {
    assets = { [locationId]: assets[locationId] };
    productsInStorePlanogram = {
      [locationId]: productsInStorePlanogram[locationId],
    };
  }
  if (assetId) {
    productsContextSensitive = { [assetId]: productsContextSensitive[assetId] };
  }

  const ControlIcon = ({ editMode }) => {
    switch (displayName || type) {
      case CREATOR_QUESTION_DISPLAYNAME.RADIO: //"radio":
        return <Radio disabled={editMode} />;
      case CREATOR_QUESTION_DISPLAYNAME.CHECKBOX: //"checkbox":
        return <Checkbox disabled={editMode} />;
      case CREATOR_QUESTION_DISPLAYNAME.DROPDOWN: //"Dropdown":
        return (
          <IconButton disabled={editMode}>
            <ArrowDropDown />
          </IconButton>
        );
      default:
        return null;
    }
  };

  const handleSelectChange = (
    event,
    sectionId,
    questionId
  ) => {
    const selectedValues = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setAnswerFields((prevAnswerFields) => {
      const updatedFields = prevAnswerFields.map((field) => {
        if (field.sectionId === sectionId && field.questionId === questionId) {
          field.answer = selectedValues;
        }
        return field;
      });
      return updatedFields;
    });
  };

  const getDynamicAnswer = (
    sectionId,
    questionId,
    repeatItem
  ) => {
    let answerObject = null;
    if (repeatItem) {
        answerObject = answerFields.find(
          (item) =>
            item.sectionId === sectionId &&
            item.questionId === questionId &&
            item.repeatItem === repeatItem
        );
    } else {
        answerObject = answerFields.find(
        (item) =>
          item.sectionId === sectionId &&
          item.questionId === questionId
        );
    }
    return answerObject ? answerObject.answer : null;
  };

  const handleMultipleChange = (event) => {
    const selectedValues = event.target.value || []; 
    handleMultipleSelectChange(
      selectedValues,
      section,
      item?.SerialNumber,
      item?.Product,
      questionUniqueId,
      item
    );
  };

  const mapItems = (items, ControlIcon, handleSelectChange, labelCallback) => {
    if (type === "Dropdown" && !editMode) {
      const repeatItemSection = item;
      return (
        <FormControl>
          {subType === "Multiple Choice" ? (
            <FormControl sx={{ width: 300 }}>
              <Select
                id="demo-multiple-name"
                multiple
                value={
                  getDynamicAnswer(
                    section,
                    questionUniqueId,
                    repeatItemSection
                  ) || []
                }
                input={<OutlinedInput />}
                onChange={editMode ? null : handleMultipleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {Object.keys(items).map((key) => {
                  const itemArray = items[key];
                  return itemArray?.map((item, i) => (
                    <MenuItem
                      key={i}
                      value={item?.SerialNumber || item?.Product}
                      data-serialNumber={item?.SerialNumber}
                      data-product={item?.Product}
                    >
                      <Checkbox
                        checked={
                          editMode
                            ? false
                            : getDynamicAnswer(
                                section,
                                questionUniqueId,
                                repeatItemSection
                              )?.includes(item?.SerialNumber || item?.Product) ||
                              false
                        }
                      />
                      {labelCallback(item, i)}
                    </MenuItem>
                  ));
                })}
              </Select>
            </FormControl>
          ) : (
            <FormControl sx={{ width: 300 }}>
              <Select
                id="demo-simple-select"
                value={
                  editMode
                    ? null
                    : getDynamicAnswer(section, questionUniqueId, item)
                }
                onChange={
                  editMode
                    ? null
                    : (e) =>
                        handleChange(
                          e,
                          section,
                          e.target.value.SerialNumber,
                          e.target.value.Product,
                          questionUniqueId,
                          item
                        )
                }
                gutterBottom
              >
                {Object.keys(items).map((key) => {
                  const itemArray = items[key];
                  return itemArray?.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {labelCallback(item, i)}
                    </MenuItem>
                  ));
                })}
              </Select>
            </FormControl>
          )}
        </FormControl>
      );
    } else if (type === "radio" && !editMode) {
      return (
        <FormControl sx={{ width: 300 }}>
          <RadioGroup
            id="demo-simple-select"
            value={
              editMode
                ? null
                : getDynamicAnswer(section, questionUniqueId, item)
            }
            onChange={
              editMode
                ? null
                : (e) =>
                    handleChange(
                      e,
                      section,
                      e.target.value,
                      e.target.getAttribute("data-product"),
                      questionUniqueId,
                      item
                    )
            }
            gutterBottom
          >
            {Object.keys(items).map((key) => {
              const itemArray = items[key];
              return itemArray?.map((item, i) => {
                const serialNumber = item?.SerialNumber;
                const product = item?.Product;
                return (
                  <FormControlLabel
                    key={i}
                    value={serialNumber || product}
                    data-serialNumber={serialNumber}
                    data-product={product}
                    control={<Radio />}
                    disabled={editMode}
                    label={<span>{labelCallback(item, i)}</span>}
                  />
                );
              });
            })}
          </RadioGroup>
        </FormControl>
      );
    } else if (type === "checkbox" && !editMode) {
      const repeatItemSection = item;
      return (
        <FormControl>
          {Object.keys(items).map((key) => {
            const itemArray = items[key];
            return itemArray?.map((item, i) => {
              const serialNumber = item?.SerialNumber;
              const product = item?.Product;
              return (
                <FormControlLabel
                  key={i}
                  disabled={false}
                  data-serialNumber={serialNumber}
                  data-product={product}
                  onChange={(e) =>
                    handleCheckboxOptionChange(
                      e,
                      section,
                      serialNumber,
                      product,
                      questionUniqueId,
                      repeatItemSection
                    )
                  }
                  value={
                    getDynamicAnswer(
                      section,
                      questionUniqueId,
                      repeatItemSection
                    ) || []
                  }
                  control={
                    <Checkbox
                      name={product || serialNumber}
                      disabled={editMode}
                      checked={
                        getDynamicAnswer(
                          section,
                          questionUniqueId,
                          repeatItemSection
                        )?.includes(serialNumber || product) || false
                      }
                      value={product || serialNumber}
                    />
                  }
                  label={<span>{labelCallback(item, i)}</span>}
                />
              );
            });
          })}
        </FormControl>
      );
    } else {
      return (
        <>
          {Object.keys(items).map((key) => {
            const itemArray = items[key];
            return itemArray?.map((item, i) => (
              <FormControlLabel
                name={item.Product}
                key={i}
                value={
                  typeof getAnswerValue === "function"
                    ? getAnswerValue(
                        section,
                        item?.SerialNumber,
                        item.Product,
                        questionUniqueId
                      )
                    : ""
                }
                onChange={
                  editMode
                    ? (e) => handleSelectChange(e, section, questionUniqueId)
                    : null
                }
                control={<ControlIcon editMode={editMode} />}
                disabled={editMode}
                className={classes.fullWidth}
                label={labelCallback(item, i)}
              />
            ));
          })}
        </>
      );
    }
  };

  const optionLookupMap = {
    1: { items: assets, labelCallback: (asset, i) => asset.SerialNumber },
    2: { items: products, labelCallback: (p, i) => p.Product },
    3: { items: productsContextSensitive, labelCallback: (p, i) => p.Product },
    4: { items: productsInStorePlanogram, labelCallback: (p, i) => p.Product }
  };

  if (dynamicOptionLookup && optionLookupMap[dynamicOptionLookup]) {
    const { items, labelCallback } = optionLookupMap[dynamicOptionLookup];
    return mapItems(
      items,
      ControlIcon,
      handleSelectChange,
      (item, i) => (
        <span option-id={i} className={classes.fullWidth}>
          {labelCallback(item, i)}
        </span>
      )
    );
  } else {
    return <></>;
  }
};

const QuestionInformation = ({ question, formatQuestionTypeText }) => {
    const { displayName, subType, type } = question;
    const t = utils.t;
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    return (
        <Typography>
          {t('Question type', tOpts)}: {t(formatQuestionTypeText(displayName || type), tOpts)} 
          {subType ? ` ${t(formatQuestionTypeText(subType), tOpts)}` : null}
        </Typography>
      );
}

const QuestionList = React.memo(({ question, index, editMode, handleOpenModal, modalIndex, handleCloseModal, openModal, selectedTab, selectedTabId, showView, formatQuestionTypeText, setDroppedItems, droppedItems }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedCheckboxOption, setSelectedCheckboxOption] = useState([]);
    const [editOption, setEditOption] = useState(null);
    const [questionnaireInit, setQuestionnaireInit] = useState(droppedItems || []);
    const [questionsToMap, setQuestionsToMap] = useState(question);
    const [optionToAdd, setOptionToAdd] = useState('');
    const [answerFields, setAnswerFields] = useState([]);
    const [edittedOption, setEdittedOption] = useState('');
    const [edittedQuestionText, setEdittedQuestionText] = useState('');
    const [dynamicQuestionsToUpdate, setDynamicQuestionsToUpdate] = useState([]);
    const locationDetails = useSelector(state => state.appReducer.questionnaireLocationDetails);
    const isMobile = useMobile();
    const questionsToMapRef = React.useRef(questionsToMap);
    const classes = useStyles();
    const snackbar = useSnackbar();
    const isDisabled = showView === constants.questionnaireDesignView;

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    React.useEffect(() => {
        const setQuestionnaire = async () => {
            const questionnaireInit = await dbUtils.get('questionnaire');
            const assets = await dbUtils.set('locationAssets');
            const selectedSection = questionnaireInit.find((item => item.id = selectedTabId))
            setQuestionnaireInit(questionnaireInit);
            setQuestionsToMap(questionnaireInit[selectedTab].questions)
        }
        setQuestionnaire();
    }, [selectedTab, selectedTabId, question, dynamicQuestionsToUpdate]);


    const handleChange = (event) => {
        event.preventDefault();
        setAnswerFields((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.value,
            };
        });
        setSelectedOption(event.target.value);
    };

    const onMoveQuestion = async (e, fromIndex, direction) => {
        e.preventDefault();
        const clonedItems = [...questionsToMap];
        const [removed] = clonedItems.splice(fromIndex, 1);
        let toIndex;
        if (direction === "up") {
            toIndex = fromIndex - 1;
        } else if (direction === "down") {
            toIndex = fromIndex + 1;
        }
        clonedItems.splice(toIndex, 0, removed);
        setQuestionsToMap(clonedItems);
        const initialQuestionnaire = [...questionnaireInit];
        initialQuestionnaire[selectedTab].questions = clonedItems;
        await dbUtils.set('questionnaire', initialQuestionnaire);
    };

    const onDeleteQuestion = async (e, index) => {
        e.preventDefault();
        const questionToDelete = questionsToMap[index].text;
        try {
            const userConfirmed = await swal({
                title: `${t("Are you sure?", tOpts)}`,
                text: `${t("You are about to delete question :", tOpts)} ${questionToDelete}`,
                icon: "warning",
                buttons: true,
                dangerMode: true
            });

            if (userConfirmed) {
                const clonedItems = [...questionsToMap];
                const updatedList = [...clonedItems.slice(0, index), ...clonedItems.slice(index + 1)];
                // clonedItems[index].IsDeleted = 1;
                setQuestionsToMap(updatedList);
                const updatedQuestionnaire = [...questionnaireInit];
                updatedQuestionnaire[selectedTab].questions = updatedList;
                await dbUtils.set('questionnaire', updatedQuestionnaire);
            }
        } catch (error) {
            console.error("Error deleting question:", error);
            snackbar.showError(`${t("Error deleting question, please try after some time.", tOpts)}`);
        }
    };

    const addQuestion = (question, prevQuestionIndex, handleClose) => {
        const uid = nanoid();
        const assetId = '${item.AssetId}';
        const uidWithAsset = `${uid}~${assetId}`;
        setQuestionsToMap((prevItems) => {
            const updatedItems = [
                ...prevItems.slice(0, prevQuestionIndex),
                { ...question, questionUniqueId: uidWithAsset, id: uidWithAsset },
                ...prevItems.slice(prevQuestionIndex)
            ];
            return updatedItems;
        });
        handleClose();
    };

    const handleCheckboxOptionChange = (event) => {
        event.preventDefault();
        setSelectedCheckboxOption((prevValues) => {
            if (prevValues.includes(event.target.value)) {
                prevValues = prevValues.filter((val) => val !== event.target.value);
                return prevValues;
            } else {
                return [...prevValues, event.target.value];
            }
        });
    };

    const onDeleteOption = async (i, qid) => {
        const clonedItems = [...questionsToMap];
        if (clonedItems.length > qid && clonedItems[qid].options.length > 0) {
            clonedItems[qid] = {
                ...clonedItems[qid],
                options: [...clonedItems[qid].options.slice(0, i), ...clonedItems[qid].options.slice(i + 1)]
            };
        }
        setQuestionsToMap(clonedItems);
        const initialQuestionnaire = [...questionnaireInit];
        initialQuestionnaire[selectedTab].questions = clonedItems;
        await dbUtils.set('questionnaire', initialQuestionnaire);
    }

    const addQuestionOption = async (qid) => {
        const clonedItems = [...questionsToMap];
        if (clonedItems.length > qid && optionToAdd !== '') {
            const updatedQuestion = {
                ...clonedItems[qid],
                options: [
                    ...clonedItems[qid].options,
                    { key: clonedItems[qid].options.length + 1, value: optionToAdd, isDeleted: false, dependency: true }
                ]
            };
            clonedItems[qid] = updatedQuestion;
        }
        setQuestionsToMap(clonedItems);
        let initialQuestionnaire = null;
        initialQuestionnaire = [...questionnaireInit];
        if (initialQuestionnaire[selectedTab] === undefined) {
            initialQuestionnaire[selectedTab] = {};
          }
        initialQuestionnaire[selectedTab].questions = clonedItems;
        await dbUtils.set('questionnaire', initialQuestionnaire);
        setOptionToAdd('');
    }

    function getLayoutByType(type) {
        if (type === utils.questionnaireAssetText) {
            return {
                "key": "${AssetId}",
                "value": "${Asset}"
            };
        } else if (type === 'None') {
            return {}
        } else {
            return {
                "key": "${ProductId}",
                "value": "${Product}"
            };
        }
    }

    const editQuestionOptionText = async (optionIndex, questionIndex) => {
        updateQuestionsAndSave(
            questionIndex,
            (item) => {
                const updatedOptions = item.options.map((option, optionIdx) => {
                    if (optionIdx === optionIndex) {
                        return { ...option, value: edittedOption };
                    }
                    return option;
                });
                return { ...item, options: updatedOptions };
            }
        );
        setEditOptForQuestion(null);
        setEditOption(null);
        setEdittedOption(null);
    };
    
    const editOptionText = (e) => {
        setEdittedOption(e.target.value);
    }

    const setQuestionText = (e) => {
        setEdittedQuestionText(e.target.value);
    }

    const handleOptionOnEnter = (e, oid, qid) => {
        if (e.key === 'Enter') {
            editQuestionOptionText(oid, qid)
        }
    };

    function renderQuestionOptions(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick) {
        return (q.options || []).map((option, i) => (
            <MenuItem key={option.id} value={option.value}>
                {editOptForQuestion === qid && editOption === i ? (
                    <TextField
                        type="text"
                        className={classes.fullWidth}
                        value={edittedOption}
                        onKeyDown={(e) => handleOptionOnEnter(e, i, qid)}
                        onChange={(e) => editOptionText(e)}
                        placeholder={constants.defaultOptionText}
                    />
                ) : (
                    <span onClick={divClick} data-qid={qid} option-id={i}>
                        {option?.value?.length ? option.value : constants.defaultOptionText}
                        {editMode ? <Clear className="delete-option-icon" color="error" onClick={() => onDeleteOption(i, qid)} /> : null}
                    </span>
                )}
            </MenuItem>
        ));
    }
    
    function renderFormControl(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick) {
        return (
            <FormControl>
                {q.subType === 'Multiple select' ? (
                    <FormControl sx={{ m: 1, width: 300 }}>
                        {!editMode && (
                                <>
                            <InputLabel id="demo-multiple-name-label">Multiple Select</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                disabled={isDisabled}
                                onChange={handleChange}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                                value={[]}
                            >
                                {renderQuestionOptions(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick)}
                            </Select>
                        </>
                        )}
                        {editMode && (
                            <>
                                {renderQuestionOptions(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick)}
                                <Grid className={classes.addOptionMargin}>
                                    <TextField
                                        label="Add option"
                                        value={addOptQuestion === qid ? optionToAdd : ""}
                                        onKeyDown={(e) => handleKeyPress(e, qid)}
                                        onChange={(e) => onAddOptionChange(e, qid)}
                                        onBlur={(e) => addQuestionOption(qid)}
                                        className={classes.addOptionInput}
                                        autoFocus
                                    />
                                    <IconButton onClick={(e) => addQuestionOption(qid)} className={classes.addOptionSpacing} color="info">
                                        <ControlPointSharp />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </FormControl>
                ) : (
                    <FormControl>
                        {!editMode && (
                            <>
                                <InputLabel id="demo-simple-select-label">Options</InputLabel>
                                <Select
                                    value={isDisabled ? null : ''}
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    gutterBottom
                                >
                                    {renderQuestionOptions(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick)}
                                </Select>
                            </>
                        )}
                        {editMode && (
                            <>
                                {renderQuestionOptions(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick)}
                                <Grid className={classes.addOptionMargin}>
                                    <TextField
                                        label="Add option"
                                        value={addOptQuestion === qid ? optionToAdd : ""}
                                        onKeyDown={(e) => handleKeyPress(e, qid)}
                                        onChange={(e) => onAddOptionChange(e, qid)}
                                        onBlur={(e) => addQuestionOption(qid)}
                                        className={classes.addOptionInput}
                                    />
                                    <IconButton onClick={(e) => addQuestionOption(qid)} className={classes.addOptionSpacing} color="info">
                                        <ControlPointSharp />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </FormControl>
                )}
            </FormControl>
        );
    }

    const renderOptions = (q, qid) => {
        switch (q.displayName || q.type) {
            case CREATOR_QUESTION_DISPLAYNAME.BOOLEAN: //"boolean":
                return (
                    <FormControl>
                        <RadioGroup
                            defaultValue=""
                            name={q.displayName}
                            value={isDisabled ? null : ''}
                            onChange={handleChange}
                            sx={{
                                marginLeft: 2,
                            }}
                        >
                            {q &&
                                q?.options &&
                                q.options.map((option, i) => (
                                    <FormControlLabel
                                        name={question.name}
                                        key={option.key}
                                        value={option.value}
                                        control={<Radio />}
                                        disabled={isDisabled}
                                        label={editOptForQuestion === qid && editOption === i ? <TextField
                                            type="text"
                                            key={i}
                                            value={edittedOption}
                                            onKeyDown={(e) => handleOptionOnEnter(e, i, qid)}
                                            onChange={(e) => editOptionText(e)}
                                            onBlur={(e) => editQuestionOptionText(i, qid)}
                                            placeholder={constants.defaultOptionText}
                                            autoFocus
                                        /> :
                                            <span onClick={divClick} data-qid={qid} option-id={i} >{option?.value?.length ? option.value : constants.defaultOptionText}</span> 
                                        }
                                    />
                                ))}
                        </RadioGroup>

                    </FormControl>
                );
            case CREATOR_QUESTION_DISPLAYNAME.CHECKBOX: //"checkbox":
                return (
                    <FormControl>
                        {(q.options || []).map((option, i) => (
                            <FormControlLabel
                                key={option.id}
                                disabled={isDisabled }
                                control={
                                    <Checkbox
                                        name={option.name}
                                        disabled={option.disabled}
                                        checked={selectedCheckboxOption.includes(option.value)}
                                        onChange={handleCheckboxOptionChange}
                                        value={option.value}
                                    />
                                }
                                label={editOptForQuestion === qid && editOption === i ? <TextField
                                    type="text"
                                    className={classes.fullWidth}
                                    value={edittedOption}
                                    onKeyDown={(e) => handleOptionOnEnter(e, i, qid)}
                                    onChange={(e) => editOptionText(e)}
                                    onBlur={(e) => editQuestionOptionText(i, qid)}
                                    placeholder={constants.defaultOptionText}
                                    autoFocus
                                /> :
                                    <span onClick={divClick} data-qid={qid} option-id={i} >
                                        {option?.value?.length ? option.value : constants.defaultOptionText} 
                                        {editMode ? <Clear className="delete-option-icon" color="error" onClick={() => onDeleteOption(i, qid)} /> : null}</span>
                                }
                            />
                        ))}
                        {editMode ? <FormControlLabel
                            value={isDisabled ? null : ''}
                            control={<Checkbox />}
                            label={
                                <Grid className={classes.addOptionContainer}>
                                    <TextField
                                        label="Add option"
                                        value={addOptQuestion === qid ? optionToAdd : ""}
                                        onKeyDown={(e) => handleKeyPress(e, qid)}
                                        onChange={(e) => onAddOptionChange(e, qid)}
                                        onBlur={(e) => addQuestionOption(qid)}
                                        className={classes.addOptionInput}
                                    /> 
                                    <IconButton onClick={(e) => addQuestionOption(qid)} className={classes.addOptionSpacing} color="info">
                                        <ControlPointSharp />
                                    </IconButton>
                                </Grid>
                            }
                        /> : null}
                    </FormControl>
                );
            case CREATOR_QUESTION_DISPLAYNAME.RADIO: //"radio":
                return (
                    <FormControl component="fieldset">
                    <RadioGroup
                        name={`question-${qid}`}
                        value={isDisabled ? null : ''}
                        onChange={handleChange}
                    >
                        {(q.options || []).map((option, i) => (
                            <FormControlLabel
                                key={option.id}
                                value={option.value}
                                disabled={isDisabled }
                                control={<Radio />}
                                label={editOptForQuestion === qid && editOption === i ? <TextField
                                    type="text"
                                    className={classes.fullWidth}
                                    value={edittedOption}
                                    onKeyDown={(e) => handleOptionOnEnter(e, i, qid)}
                                    onChange={(e) => editOptionText(e)}
                                    onBlur={(e) => editQuestionOptionText(i, qid)}
                                    placeholder={constants.defaultOptionText}
                                    autoFocus
                                /> :
                                    <span onClick={divClick} data-qid={qid} option-id={i} >
                                        {option?.value?.length ? option.value : constants.defaultOptionText} 
                                        {editMode ? <Clear className="delete-option-icon" color="error" onClick={() => onDeleteOption(i, qid)} /> : null}</span>
                                }
                            />
                        ))}
                        {editMode ? <FormControlLabel
                            value={isDisabled ? null : ''}
                            control={<Radio />}
                            label={
                                <Grid className={classes.addOptionContainer}>
                                    <TextField
                                        label="Add option"
                                        value={addOptQuestion === qid ? optionToAdd : ""}
                                        onKeyDown={(e) => handleKeyPress(e, qid)}
                                        onChange={(e) => onAddOptionChange(e, qid)}
                                        onBlur={(e) => addQuestionOption(qid)}
                                        className={classes.addOptionInput}
                                    /> 
                                    <IconButton onClick={(e) => addQuestionOption(qid)} className={classes.addOptionSpacing} color="info">
                                        <ControlPointSharp />
                                    </IconButton>
                                </Grid>
                            }
                        /> : null}
                    </RadioGroup>
                </FormControl>
                );
            case QUESTION_TYPES.TEXTAREA: //"textarea":
                return (
                    <CardContent
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                        <TextField
                            sx={{
                                width: "100%",
                            }}
                            disabled={isDisabled }
                            multiline
                            rows={1}
                            maxRows={2}
                        />
                    </CardContent>
                );
            case CREATOR_QUESTION_DISPLAYNAME.SINGLE_LINE_INPUT: //"Text Input (Single line)":
                return (
                    <TextField id="standard-basic" label="Text Input (Single line)" variant="standard" disabled={isDisabled} />
                );
            case CREATOR_QUESTION_DISPLAYNAME.MULTI_LINE_INPUT: //"Text Input (Multiple lines)":
                return (
                    <TextField
                        id="standard-multiline-flexible"
                        label="Multiline"
                        multiline
                        maxRows={4}
                        variant="standard"
                        disabled={isDisabled}
                    />
                );
            case QUESTION_TYPES.MULTI_FILE: //"multiFile":
                return (
                    <div style={{ display: "flex" }}>
                        <PhotoCameraDisplayType />
                        {/* <PhotoCameraDisplayType />
            <PhotoCameraDisplayType /> */}
                    </div>
                );
            case QUESTION_TYPES.NUMBER: //'number':
                return <TextField
                    type="number"
                    label="Number Input"
                    sx={{ width: '100%' }}
                    inputProps={{ min: q.min, max: q.max }}
                    disabled={isDisabled}
                    value={isDisabled ? null : ''}
                    onChange={handleChange}
                />;
            case QUESTION_TYPES.DATE: //'date':
            case CREATOR_QUESTION_DISPLAYNAME.DATE:
                return <DatePicker
                    type="date"
                    label="Date Input"
                    sx={{ width: '100%' }}
                    format={utils.systemDateTimeFormat(true)}
                    disabled={isDisabled}
                    value={isDisabled ? null : ''}
                    onChange={handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />;
            case QUESTION_TYPES.SELECT: //"select":
                return (
                    <Select className={classes.fullWidth} disabled={isDisabled} displayEmpty>
                        {q.options.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={isDisabled ? null : []}
                                sx={{ lineHeight: 1 }}
                            >
                                {option.value}
                            </MenuItem>
                        ))}
                    </Select>
                );
            case CREATOR_QUESTION_DISPLAYNAME.DROPDOWN: //'Dropdown':
                return renderFormControl(q, handleChange, editMode, qid, optionToAdd, addOptQuestion, edittedOption, addQuestionOption, editOptionText, onDeleteOption, divClick);
            case "scene":
            case "multiFileStitch":
            case CREATOR_QUESTION_DISPLAYNAME.GENERAL_CAPTURE: //"General Image Capture":
            case CREATOR_QUESTION_DISPLAYNAME.PRODUCT_CAPTURE: //"Product Image Capture":
            case CREATOR_QUESTION_DISPLAYNAME.POS_CAPTURE: //"POS materials image capture":
                return (
                    <div style={{ display: "flex" }}>
                        <PhotoCameraDisplayType question={q} editMode={editMode} />
                    </div>
                );
            case "Asset Barcode Capture":
                return (
                    <div style={{ display: "flex" }}>
                        <CustomBarcode editMode={editMode} />
                    </div>
                );
            // case "Door by Door Capture":
            //     return (
            //         <div style={{ display: "flex" }}>
            //             <PhotoCameraDisplayType />
            //         </div>
            //     );

            default:
                return null;
        }
    };

    const [editQuestion, setEditQuestion] = useState(null);
    const [newQuestion, setNewQuestion] = useState('');
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [addOptQuestion, setAddOptQuestion] = useState(null);
    const [editOptForQuestion, setEditOptForQuestion] = useState(null);
    const [showInfoFor, setShowInfoFor] = useState(null);

    const divClick = function (e, t) {
        const target = e.target;
        const allowedTagNames = ["H6", "SPAN"];
        if (!allowedTagNames.includes(target.tagName)) return;
        let qid = null; let oid = null;
        if (target.tagName === "H6") {
            qid = target.closest("div.MuiPaper-root").dataset.qid;
            if (qid) {
                setEditQuestion(Number(qid));
                setEdittedQuestionText(questionsToMap[qid].text);
            }
        } else if (target.tagName === "SPAN") {
            qid = target.getAttribute('data-qid');
            oid = target.getAttribute('option-id')
            if (oid && qid) {
                setEditOptForQuestion(Number(qid));
                setEditOption(Number(oid));
                setEdittedOption(questionsToMap[qid].options[oid].value);
            }
        }
    }


    const onAddOptionChange = (event, qid) => {
        setAddOptQuestion(qid);
        setOptionToAdd(event.target.value);
    };

    const onEditQuestionText = (e) => {
        setNewQuestion(e.target.value);
    }

    const editMinValue = async (e, qid) => {
        const {value, type} = e.target;
        const minValue = value ? Number(value) : null;
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            min: type === 'date' ? value : minValue
        }));
    }

    const editDateValue = async (value, qid, key) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            [key]: value
        }));
    }

    const editFileValidations = async (e, qid, typeToValidate, sequence) => {
        const updatedItems = questionsToMap.map((item, index) => {
            if (index === qid) {
                if (typeToValidate === 'minFileSize') {
                    return { ...item, minFileSize: Number(e.target.value) };
                } else if (typeToValidate === 'maxFileSize') {
                    return { ...item, maxFileSize: Number(e.target.value) };
                } else if (typeToValidate === 'minResolution') {
                    return { ...item, minResolution: Number(e.target.value) };
                } else if (typeToValidate === 'maxResolution') {
                    return { ...item, maxResolution: Number(e.target.value) };
                } else if (typeToValidate === 'minImageCount') {
                    return { ...item, minImageCount: Number(e.target.value) };
                } else if (typeToValidate === 'maxImageCount') {
                    return { ...item, maxImageCount: Number(e.target.value) };
                } else if (typeToValidate === 'imageCount') {
                    return { ...item, imageCount: Number(e.target.value) };
                } else if (typeToValidate === 'moreInfo') {
                    return { ...item, info: e.target.value };
                } else if (typeToValidate === 'imageLabels') {
                    item.imageLabels = Array.isArray(item.imageLabels) ? item.imageLabels : [];
                    const existingSequenceIndex = item.imageLabels.findIndex(labelObj => labelObj.sequence === constants.initialImageLabelSequence + sequence);
                    if (existingSequenceIndex !== -1) {
                      item.imageLabels[existingSequenceIndex] = { label: e.target.value, sequence: constants.initialImageLabelSequence + sequence };
                    } else {
                      item.imageLabels.push({ label: e.target.value, sequence: constants.initialImageLabelSequence + sequence });
                    }
                    return item;
                }
            }
            return item;
        });
        setQuestionsToMap(updatedItems);
        const initialQuestionnaire = [...questionnaireInit];
        initialQuestionnaire[selectedTab].questions = updatedItems;
        await dbUtils.set('questionnaire', initialQuestionnaire);
    }
    
    const editMaxValue = async (e, qid) => {
        const {value, type} = e.target;
        const maxValue = value ? Number(value) : null;
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            max: type === 'date' ? value : maxValue
        }));
    };
    
    const handleCheckComplianceChange = async (e, qid) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            validateFromAsset: e.target.checked,
        }));
    };

    const handleBeforeAfterChange = async (e, qid) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            beforeAfterImage: Number(e.target.value)
        }));
    }

    const handleIsMultiFileChange = async (e, qid) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            type: e.target.value
        }));
    }

    const handleMaterialChange = async (e, qid) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            validateFromPosMaterial: e.target.checked
        }));
    }

    const handleTargetPortfolioChange = async (e, qid) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            checkTargetPortfolio: e.target.checked,
        }));
    };

    const handleKeyPress = (e, qid, forQuestionText) => {
        if (e.key === 'Enter') {
            if (forQuestionText) {
                updateQuestionText(qid)
            } else {
                addQuestionOption(qid);
            }
        }
    };

    const updateQuestionText = (qid) => {
        editQuestionText(qid);
        setEditQuestion(null);
        setEdittedQuestionText(null);
    }

    const updateQuestionsAndSave = async (qid, updateFn) => {
        const updatedItems = questionsToMap.map((item, index) => {
            if (index === qid) {
                return updateFn(item);
            }
            return item;
        });
        setQuestionsToMap(updatedItems);
        const initialQuestionnaire = [...questionnaireInit];
        initialQuestionnaire[selectedTab].questions = updatedItems;
        setDroppedItems(initialQuestionnaire);
        await dbUtils.set('questionnaire', initialQuestionnaire);
    };
    
    const handleIsRequiredChange = async (qid) => {
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            required: !item?.required || false,
        }));
    };
    
    const handleVisibilityChange = async (event, qid) => {
        const isChecked = event.target.checked;
        updateQuestionsAndSave(qid, (item) => ({
            ...item,
            defaultDisplay: isChecked ? 'show' : 'hidden'
        }));
    };

    const handleAddNotFoundAlertChange = async (event, qid) => {
        /**
         * Description: Adds property coolerFound or not
         * @param: event: type=object , qid: type=object
         * @return: question object with property 'coolerFound' or not based on value of 'isChecked'
         */
        const isChecked = event.target.checked;
        updateQuestionsAndSave(qid, (item) => {
            if (isChecked) {
                item.coolerFound = false;
            } else {
                delete item.coolerFound;
            }
            return { ...item }
        });
    };

    const editQuestionText = async (itemIndex) => {
        updateQuestionsAndSave(itemIndex, (item) => ({
            ...item,
            text: edittedQuestionText,
        }));
    };    

    const toggleQuestionInformation = (qUid) => {
        setShowInfoFor(showInfoFor === qUid ? null : qUid);
    }

    const theme = useTheme();

    const typesWithMinMax = [CREATOR_QUESTION_DISPLAYNAME.NUMBER];
    const typesWithDynamicOptions = [CREATOR_QUESTION_DISPLAYNAME.DROPDOWN, CREATOR_QUESTION_DISPLAYNAME.CHECKBOX, CREATOR_QUESTION_DISPLAYNAME.RADIO];
    const typesWithFileValidation = [];
    const typesWithComplianceCheck = [CREATOR_QUESTION_DISPLAYNAME.BARCODE];
    const typesWithTargetPortfolio = [CREATOR_QUESTION_DISPLAYNAME.POS_CAPTURE];
    const typesWithImageCount = [CREATOR_QUESTION_DISPLAYNAME.PRODUCT_CAPTURE];
    const typesWithSingleMultiFiles = [CREATOR_QUESTION_DISPLAYNAME.GENERAL_CAPTURE];
    const typesWithMaterialCheck = [CREATOR_QUESTION_DISPLAYNAME.POS_CAPTURE];
    const typesWithQuestionInfo = [CREATOR_QUESTION_DISPLAYNAME.PRODUCT_CAPTURE];
    const typesWithNotFoundOutlet = [];// ["radio"];

    function getDependency(val) {
        let dependencyArray = this.props.dependencyQuestions,
            { dependentQuestion } = val,
            canDisable = false;

        if (dependencyArray.length && dependentQuestion) {
            dependentQuestion.forEach(function (item, key) {
                if (!canDisable) {
                    let index = dependencyArray.findIndex(x => x.question == item.question && item.answerkey.includes(Number(x.answerkey)));
                    canDisable = index > -1 ? dependentQuestion[key].behaviour : false;
                }
            })
        }
        return canDisable;
    }

    const beforeAfterOptions = [
        {
            key: 'Default',
            value: 0
        },
        {
            key: 'Before Action',
            value: 1
        },
        {
            key: 'After Action',
            value: 2
        }
    ];

    const fileCountOptions = [
        {
            key: 'Single Image',
            value: 'file'
        },
        {
            key: 'Multiple Images',
            value: 'multiFile'
        }
    ]

    return (
        <Paper>
            {questionsToMap.length > 0 ? questionsToMap.map((q, i) => {
                return (
                    q.IsDeleted === 0 || !q.IsDeleted ?
                        <>
                            <Paper
                                data-qid={i}
                                onClick={divClick}
                                variant="outlined"
                                sx={{ margin: 1 }}
                            >
                                {showView === constants.questionnaireDesignView ? 
                                <>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end" mb={1} sx={{ position: 'absolute', zIndex: 1, right: 0, top: 3 }}>
                                        <Button onClick={(e) => onDeleteQuestion(e, i)}>
                                            <Delete color="error" />
                                        </Button>
                                    </Stack>
                                <Grid container xs={12}>
                                    <Grid item xs={isMobile.mobile ? 12 : 6}>
                                        <CardContent>
                                            <Grid
                                                container
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: 0,
                                                    fontSize: 16
                                                }}
                                            >
                                                <Typography variant="subtitle1"  gutterBottom>
                                                    {i + 1}. {editMode ? editQuestion === i ? <TextField type="text" sx={{ width: "90%" }} data-qid={i}
                                                        value={edittedQuestionText}
                                                        onKeyDown={(e) => handleKeyPress(e, i, true)}
                                                        placeholder={constants.defaultQuestionText}
                                                        onChange={(e) => setQuestionText(e)}
                                                        autoFocus
                                                        onBlur={(e) => updateQuestionText(i)}
                                                         /> :
                                                        q.text !== '' ? q.text : constants.defaultQuestionText : q.text !== '' ? q.text : constants.defaultQuestionText}
                                                </Typography>
                                                <Button
                                                    variant="text"
                                                    className="float-right"
                                                    sx={{
                                                        marginTop: '-5px !important'
                                                    }}
                                                    startIcon={
                                                        <Tooltip
                                                            title={<QuestionInformation question={q} formatQuestionTypeText={formatQuestionTypeText} />}
                                                            placement="right"
                                                        >
                                                            <Info />
                                                        </Tooltip>
                                                    }
                                                    onClick={()=>toggleQuestionInformation(q.questionUniqueId)}
                                                    ></Button>
                                                    {q.info ? 
                                                        <Tooltip
                                                        title={q.info}
                                                        placement="right"
                                                    >
                                                        <Info className={classes.infoTooltip} />
                                                    </Tooltip>
                                                    : null }
                                            </Grid>
                                            {typesWithDynamicOptions.includes(q.displayName || q.type) ? <ListDynamicOptions question={q} showView={showView} editMode={editMode} handleChange={null} handleCheckboxOptionChange={null} section={null} getAnswerValue={null} answerFields={null} setAnswerFields={null} item={null} handleMultipleSelectChange={null} /> : null}
                                            {!q.dynamicOptionLookup || q.dynamicOptionLookup === 0 ? renderOptions(q, i) : null}
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={isMobile.mobile ? 12 : 6}>
                                        {isDisabled ?
                                            <Paper variant="outlined" className="edit-question-settings">
                                                <Grid container xs={12}>
                                                    <Grid
                                                    item
                                                    xs={typesWithImageCount.includes(q.displayName) ? 4 : 6}
                                                    >
                                                    <FormControlLabel
                                                        xs={typesWithImageCount.includes(q.displayName) ? 3 : 6}
                                                        control={
                                                            <Checkbox
                                                                checked={q.required}
                                                                onChange={() => handleIsRequiredChange(i)}
                                                                name="isRequired"
                                                            />
                                                        }
                                                        label={t("Required", tOpts)}
                                                    />
                                                    </Grid>
                                                    <Grid
                                                    item
                                                    xs={typesWithImageCount.includes(q.displayName) ? 4 : 6}
                                                    >
                                                        <FormControlLabel
                                                            xs={12}
                                                            control={
                                                                <Checkbox
                                                                    checked={q.defaultDisplay !== 'hidden'}
                                                                    onChange={(e) => handleVisibilityChange(e, i)}
                                                                    disabled={q.dependentQuestion?.length}
                                                                    name="isVisible"
                                                                />
                                                            }
                                                            label={t("Visible", tOpts)}
                                                        />
                                                    </Grid>
                                                    {typesWithImageCount.includes(q.displayName) ? 
                                                        <Grid
                                                        item
                                                         xs={4}>
                                                            <TextField
                                                                label="Image Count"
                                                                type="number"
                                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                value={q.imageCount}
                                                                onChange={(e) => editFileValidations(e, i, 'imageCount')}
                                                            />
                                                        </Grid>
                                                     : null}
                                                </Grid>
                                                {/* <Grid item xs={6} md={6}>
                                                </Grid> */}
                                                        {typesWithNotFoundOutlet.includes(q.type) ? <FormControlLabel
                                                            xs={12}
                                                            control={
                                                                <Checkbox
                                                                    checked={'coolerFound' in q}
                                                                    onChange={(e) => handleAddNotFoundAlertChange(e, i)}
                                                                    name="notFoundAlert"
                                                                />
                                                            }
                                                            label={t("Outlet Not Found Alert", tOpts)}
                                                        /> : null}
                                                {typesWithDynamicOptions.includes(q.displayName || q.type) ?
                                                    <Grid item xs={12} md={12}>
                                                        <SelectDynamicOptions qid={i} question={q}  questionnaireInit={questionnaireInit} getLayoutByType={getLayoutByType} selectedTab={selectedTab} t={t} tOpts={tOpts} setDynamicQuestionsToUpdate={setDynamicQuestionsToUpdate} />
                                                     </Grid>
                                                : null}

                                                {typesWithMinMax.includes(q.type) ?
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label={t("Minimum Value", tOpts)}
                                                                type="number"
                                                                value={q.min}
                                                                onChange={(e) => editMinValue(e, i)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label={t("Maximum Value", tOpts)}
                                                                type="number"
                                                                value={q.max}
                                                                onChange={(e) => editMaxValue(e, i)}
                                                            />
                                                        </Grid>
                                                    </Grid> : null}
                                                    {q.displayName === CREATOR_QUESTION_DISPLAYNAME.DATE ?
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                label={t("From Date", tOpts)}
                                                                InputLabelProps={{ shrink: true }}
                                                                format={utils.systemDateTimeFormat(true)}
                                                                variant="standard"
                                                                value={q.fromDate ? dayjs(q.fromDate) : null}
                                                                onChange={(e) => {
                                                                    const adjustedDate = dayjs(e).hour(12);
                                                                    const isoString = adjustedDate.toISOString();
                                                                    editDateValue(isoString, i, 'fromDate')
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker
                                                                label={t("To Date", tOpts)}
                                                                InputLabelProps={{ shrink: true }}
                                                                format={utils.systemDateTimeFormat(true)}
                                                                value={q.toDate ? dayjs(q.toDate) : null}
                                                                onChange={(e) => {
                                                                    const adjustedDate = dayjs(e).hour(12);
                                                                    const isoString = adjustedDate.toISOString();
                                                                    editDateValue(isoString, i, 'toDate')
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid> : null}
                                                {typesWithFileValidation.includes(q.displayName) ?
                                                    <Grid>
                                                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="File size minimum limit"
                                                                    type="number"
                                                                    value={q.minFileSize}
                                                                    onChange={(e) => editFileValidations(e, i, 'minFileSize')}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="File size maximum value"
                                                                    type="number"
                                                                    value={q.maxFileSize}
                                                                    onChange={(e) => editFileValidations(e, i, 'maxFileSize')}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="Minimum resolution"
                                                                    type="number"
                                                                    value={q.minResolution}
                                                                    onChange={(e) => editFileValidations(e, i, 'minResolution')}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="Maximum resolution"
                                                                    type="number"
                                                                    value={q.maxResolution}
                                                                    onChange={(e) => editFileValidations(e, i, 'maxResolution')}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="Minimum number of images"
                                                                    type="number"
                                                                    value={q.minImageCount}
                                                                    onChange={(e) => editFileValidations(e, i, 'minImageCount')}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="Maximum number of images"
                                                                    type="number"
                                                                    value={q.maxImageCount}
                                                                    onChange={(e) => editFileValidations(e, i, 'maxImageCount')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    : null}
                                                     {q.imageCount > 0 ? (
                                                    <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                                        {[...Array(q.imageCount)].map((_, index) => (
                                                        <Grid item xs={6} key={index}>
                                                            <TextField
                                                            label={`Image Label ${index + 1}`}
                                                            type="text"
                                                            value={q.imageLabels?.[index]?.label}
                                                            onChange={(e) => editFileValidations(e, i, 'imageLabels', index + 1)}
                                                            />
                                                        </Grid>
                                                        ))}
                                                    </Grid>
                                                    ) : null}
                                                    {typesWithSingleMultiFiles.includes(q.displayName) && (
                                                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                        <RadioGroup
                                                        value={q.type || 'file'}
                                                        onChange={(e)=>handleIsMultiFileChange(e,i)}
                                                        sx={{ marginLeft: 2, flexDirection: 'row' }}
                                                        >
                                                        {fileCountOptions.map((option, i) => (
                                                            <FormControlLabel
                                                            key={option.key}
                                                            value={option.value}
                                                            control={<Radio />}
                                                            label={
                                                                <span option-id={i}>
                                                                {option.key}
                                                                </span>
                                                            }
                                                            />
                                                        ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                    )}
                                                    {typesWithImageCount.includes(q.displayName) && (
                                                        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                            <RadioGroup
                                                            defaultValue=""
                                                            name={q.displayName}
                                                            value={q.beforeAfterImage || 0}
                                                            onChange={(e)=>handleBeforeAfterChange(e,i)}
                                                            sx={{ flexDirection: 'row' }}
                                                            >
                                                            {beforeAfterOptions.map((option, i) => (
                                                                <FormControlLabel
                                                                key={option.key}
                                                                value={option.value}
                                                                control={<Radio />}
                                                                label={
                                                                    <span option-id={i}>
                                                                    {option.key}
                                                                    </span>
                                                                }
                                                                />
                                                            ))}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                    {typesWithQuestionInfo.includes(q.displayName) && (
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                label={`More info`}
                                                                type="text"
                                                                value={q.info || ''}
                                                                onChange={(e) => editFileValidations(e, i, 'moreInfo', index + 1)}
                                                                gutterBottom
                                                            />
                                                        </Grid>
                                                    )}
                                                    {typesWithComplianceCheck.includes(q.displayName) ? 
                                                    <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={q.validateFromAsset}
                                                            onChange={(e)=>handleCheckComplianceChange(e,i)}
                                                            name="compliance"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t("Run image analytics", tOpts)}
                                                />
                                                    :null}
                                                    {typesWithTargetPortfolio.includes(q.displayName) ?
                                                    <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={q.checkTargetPortfolio || false}
                                                            onChange={(e)=>handleTargetPortfolioChange(e,i)}
                                                            name="target portfolio"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t("Run image analytics", tOpts)}
                                                />
                                                    :null}
                                                    {typesWithMaterialCheck.includes(q.displayName) ?
                                                    <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={q.validateFromPosMaterial || false}
                                                            onChange={(e)=>handleMaterialChange(e,i)}
                                                            name="POS material"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t("Run image analytics", tOpts)}
                                                />
                                                    :null}
                                                <Grid item xs={12} md={12}>
                                                    <DependentLogic qid={i} questionsToMap={questionsToMap} questionnaireInit={questionnaireInit} selectedTab={selectedTab} t={t} tOpts={tOpts} />
                                                </Grid>
                                            </Paper>
                                            : null}
                                    </Grid>
                                </Grid>
                                    <Grid container justifyContent="center" alignItems="center" className={classes.fullWidth}>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
                                            {i < questionsToMap.length - 1 ? (
                                                <IconButton onClick={(e) => onMoveQuestion(e, i, "down")} color="info">
                                                    <ArrowDownwardSharp />
                                                </IconButton>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                                            {questionsToMap.length > 0 && questionsToMap[0] !== q ? (
                                                <IconButton className="float-right" color="info" onClick={(e) => onMoveQuestion(e, i, "up")}>
                                                    <ArrowUpward />
                                                </IconButton>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    </>
                                     : <></>}
                            </Paper>
                            {editMode ?
                                <Grid container justifyContent="center" alignItems="center" className={classes.fullWidth}>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <IconButton onClick={() => handleOpenModal(i)} color="info">
                                            <ControlPointSharp />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                : null}
                        </> : null
                );
            }) : <Paper justifyContent='center' alignItems='center' className={classes.newSectionContainer}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h6">{t("Drag and Drop question type to add question", tOpts)}</Typography>
                </Grid>
            </Paper>}
            <QuestionsModal open={openModal} handleClose={handleCloseModal} addQuestion={addQuestion} formatQuestionTypeText={formatQuestionTypeText} prevQuestionIndex={modalIndex} list={utils.questionTypes} />
        </Paper>

    );
});

function DependentLogic({ qid, questionsToMap, questionnaireInit, selectedTab, t, tOpts }) {
    const [openModal, setOpenModal] = useState(false);
    const [cleanedQuestions, setCleanedQuestions] = useState(false);
    const [existingConditions, setExistingConditions] = useState([]);
    const childQuestionId = questionsToMap[qid].questionUniqueId;

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const removeItemAtIndex = (arr, index) => {
        const clonedArray = [...arr];
        if (index >= 0 && index < clonedArray.length) {
            clonedArray.splice(index, 1);
        }
        return clonedArray;
    };

    const handleOpenModal = (index) => {
        const questionsWithoutSelected = removeItemAtIndex(questionsToMap, qid);
        const typesToAllowDependency = [CREATOR_QUESTION_DISPLAYNAME.BOOLEAN, CREATOR_QUESTION_DISPLAYNAME.RADIO, CREATOR_QUESTION_DISPLAYNAME.CHECKBOX, CREATOR_QUESTION_DISPLAYNAME.DROPDOWN]
        let allowedQuestions = []
        questionsWithoutSelected.forEach(
            function (question, key) {
                if (typesToAllowDependency.includes(question.displayName || question.type)) {
                    allowedQuestions.push(question);
                }
            }
        )
        const predefinedConditions = questionsToMap[qid].dependentQuestion;
        let questionsInCondition = [];
        if (predefinedConditions) {
            predefinedConditions.forEach(
                function (condition, key) {
                    const questionWithCondition = questionsToMap.find((question, q) => {
                        return question.questionUniqueId === condition.parentQuestionUniqueId;
                    });
                    if (questionWithCondition) {
                        questionWithCondition.answerkey = condition.answerkey;
                        questionsInCondition.push(questionWithCondition);
                    }
                }
            )
        }
        setExistingConditions(questionsInCondition);
        setCleanedQuestions(allowedQuestions);
        setOpenModal(true);
    };

    const onApplyConditions = async (updatedQuestions) => {
        const createdConditionSet = updatedQuestions.map((item) => {
            return {
                parentQuestionUniqueId: item.questionUniqueId,
                childQuestionUniqueId: childQuestionId,
                operator: item.operator || '=',
                answerkey: item.answerkey,
                // visibility: item.visibility === 'Show' ? 1 : 0
                visibility: 1,
                question: item.questionUniqueId,
                behaviour: 'show'
            };
        });

        const initialQuestionnaire = [...questionnaireInit];
        initialQuestionnaire[selectedTab].questions[qid].visibility = 0;
        initialQuestionnaire[selectedTab].questions[qid].defaultDisplay = 'hidden';
        initialQuestionnaire[selectedTab].questions[qid].dependentQuestion = createdConditionSet;
        await dbUtils.set('questionnaire', initialQuestionnaire);
        handleCloseModal();
    };

    const onRemoveDependency = async (childQuestionUniqueId, parentQuestionUniqueId ) => {
        const initialQuestionnaire = [...questionnaireInit];
        const questionsInSection = initialQuestionnaire[selectedTab].questions || [];
        const questionWithDependency = questionsInSection.find((question) => question.questionUniqueId === childQuestionUniqueId);
        let newDependentQuestion = [];
        if (questionWithDependency.dependentQuestion) {
            newDependentQuestion = questionWithDependency.dependentQuestion.filter((dependency) => dependency.parentQuestionUniqueId !== parentQuestionUniqueId)
        }
        initialQuestionnaire[selectedTab].questions[qid].visibility = 1;
        initialQuestionnaire[selectedTab].questions[qid].dependentQuestion = newDependentQuestion;
        initialQuestionnaire[selectedTab].questions[qid].defaultDisplay = 'show';
        await dbUtils.set('questionnaire', initialQuestionnaire);
    }

    return (
        <Paper elevation={0}>
            <Button color="info" sx={{ marginTop: 1 }} onClick={handleOpenModal}>
                <Lan />
                {t("Add dependency", tOpts)}</Button>
            <DefineConditionModal
                open={openModal}
                qid={qid}
                handleClose={handleCloseModal}
                questions={questionsToMap}
                questionsToSelect={cleanedQuestions || []}
                onApplyConditions={onApplyConditions}
                existingConditions={existingConditions}
                onRemoveDependency={onRemoveDependency} />
        </Paper>
    );
}

export {QuestionList, SelectDynamicOptions, ListDynamicOptions, QuestionInformation};
