import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import logo from '../../assets/images/logo.png';
import ClientLogo from '../Common/ClientLogo';

const HeaderLogo = ({ userRoutes }) => {
    return (
        <div className={clsx('app-header-logo')}>
            <Box className="header-logo-wrapper" title="CoolR Theme">
                <Link to={userRoutes} className="header-logo-wrapper-link">
                    <img src={logo} alt="logo" className="mr-2" width={97} height={29} />
                    <ClientLogo />
                </Link>
            </Box>
        </div>
    );
};

export default HeaderLogo;
