import UiModel from './ui-model'

const modelConfig = new UiModel({
    title: "Location",
    defaultSort: 'Name ASC',
    doubleClicked: false,
    columns: [
        { field: "LocationId", type: 'number', hide: true, width: 80, label: "Location ID" },
        { field: "Name", type: "string", label: "Name", width: 240, showOnForm: true, pinned: true },
        { field: "ExternalLocationCode", type: "string", label: "External Code", width: 240, hide: true },
        { field: "Code", type: "string", label: "Outlet Code", width: 240, showOnForm: true },
        { field: "Street", type: "string", label: "Street", width: 240 },
        { field: "City", type: "string", label: "City", width: 240 },
        { field: "State", type: "string", label: "State", width: 240 },
        { field: "PostalCode", type: "text", label: "Postal Code", width: 240 },
        { field: "SalesRepId", type: "text", label: "Sales Rep", width: 240 },
        { field: "Classification", type: "text", label: "Classification", width: 240 },
        { field: "Channel", type: "text", label: "Channel", width: 240 },
        { field: "MarketName", type: "text", label: "Market", width: 240 }
    ]
});

export default modelConfig;