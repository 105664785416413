import React from 'react';
import { Button, IconButton, Menu, MenuItem, Typography, ListItemIcon } from '@material-ui/core';
import utils from '../../utils'
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMobile from '../../utils/useMobile';

let smView = "remove-mobile-view", showIcon = false, showLabel = false;

const ButtonWithIcon = React.memo(({
    t: trans,
    i18n,
    disabled,
    label,
    icon,
    iconButtonClass,
    handleClick: onClick,
    showIconOnMobile = false,
    showIconOnWeb = false,
    showLabelOnMobile = false,
    showLabelOnWeb = false,
    mobileOnly,
    menuItems = [],
    btnColor = 'default',
    useHeader = true,
    btnClass = '',
    variant,
    size = "medium",
    useOnlyButton }) => {
    const tOpts = { t: trans, i18n };
    const t = utils.t;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMobile(true);
    smView = mobileOnly ? "remove-desktop-view" : "";

    if (isMobile) {
        showIcon = showIconOnMobile;
        showLabel = showLabelOnMobile;
    } else {
        showIcon = showIconOnWeb;
        showLabel = showLabelOnWeb;
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    menuItems = menuItems?.filter(v => v?.show);

    if (useOnlyButton) {
        return <IconButton size={size} disabled={disabled} onClick={onClick || handleClick} variant={variant || "contained"} color={btnColor} className={` m-2  ${btnClass}   ${useHeader && showIconOnMobile && isMobile ? "button-with-icon" : ""} ${smView}`}>
            {icon}
        </IconButton>
    }

    return <>
        <Button size={size} disabled={disabled} onClick={onClick || handleClick} variant={variant || "contained"} color={btnColor} className={` m-1  ${btnClass}    ${smView}`}>
            {showIcon &&
                <IconButton aria-label="show more" color="inherit" className={iconButtonClass} >
                    {icon}
                </IconButton>}
            {showLabel && t(label, tOpts)}
        </Button>
        {menuItems.length ?
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((v, i) => {
                    return <MenuItem className='w-100 ' component={"button"} key={i} onClick={v.onClick} >
                        {v.Icon ? <ListItemIcon className='width-0 m-1'>
                            <FontAwesomeIcon icon={v.Icon} />
                        </ListItemIcon> : null}
                        <Typography className={`w-100 p-1 ${v.color}`} >{t(v.label, tOpts)}</Typography>
                    </MenuItem>
                })}
            </Menu> : null
        }
    </>
})

export default withTranslation()(ButtonWithIcon)