import React, { useState, useEffect } from "react";
import { Serializer, Action } from "survey-core";
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactElementFactory } from "survey-react-ui";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { localization } from "survey-creator-core";
import { request, apis } from "../../httpUtil";
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import QuestionnairePreview from "../../pages/RenderSurveyAnswer/questionnairePreview";
import utils from "../../utils";

function mapOptions({ list, valueKey = "LookupId", textKey = "DisplayValue" }) {
  const choices = [];

  for (const cb in list) {
    let concatText = "";
    if (Array.isArray(textKey)) {
      textKey.map((text, count) => {
        if (count === 0) {
          concatText += `${list[cb][text]} `;
        } else {
          concatText += `(${list[cb][text]})`;
        }

      })
    } else {
      concatText = list[cb][textKey];
    }
    choices.push({ value: list[cb][valueKey], text: concatText });
  }
  return choices;
}


const allQuestionTypes = ["boolean", "checkbox", "comment", "dropdown", "tagbox", "expression", "file", "html", "image", "image",
  "imagepicker", "matrix", "matrixdropdown", "matrixdynamic", "multipletext", "panel", "paneldynamic",
  "radiogroup", "rating", "ranking", "signaturepad", "text"];

const repeatOptionScope = ["panel", "page"];
const dynamicTypes = ["checkbox", "radiogroup", "dropdown"];
const dynamicOptions = [
  { LookupId: "", DisplayValue: "Select..." },
  { LookupId: "assets", DisplayValue: "Assets" },
  { LookupId: "Product", DisplayValue: "All Products" },
  { LookupId: "ProductPlanogram", DisplayValue: "Products in store planograms" },
  { LookupId: "ProductContext", DisplayValue: "Products - context-sensitive" },
  { LookupId: "UserAction", DisplayValue: "By user action" }
]
const additionalProperty = [
  //TODO: reindex object
  {
    id: 1,
    name: "repeatOptions",
    displayName: "Repeat Options",
    type: "dropdown",
    category: "Repeat",
    scopes: [...repeatOptionScope],
    required: true,
    choices: mapOptions({ list: dynamicOptions }),
    visibleIndex: 1
  },
  {
    id: 2,
    name: "hideQuestion",
    displayName: "Hide question",
    type: "boolean",
    category: "logic",
    scopes: [...allQuestionTypes],
    visibleIndex: 0,
  },
  {
    id: 3,
    name: "hideLogic",
    displayName: "Define Hide Condition",
    type: "condition",
    category: "logic",
    scopes: [...allQuestionTypes],
    visibleIndex: 1,
    dependsOn: ["hideQuestion"],
    required: true,
    visibleIf: function (obj) {
      return (
        obj["hideQuestion"] === true
      );
    },
  },

  {
    id: 4,
    name: "showQuestion",
    displayName: "Show question",
    type: "boolean",
    category: "logic",
    scopes: [...allQuestionTypes],
    visibleIndex: 2,

  },
  {
    id: 5,
    name: "showLogic",
    displayName: "Define show condition",
    type: "condition",
    category: "logic",
    scopes: [...allQuestionTypes],
    visibleIndex: 3,
    required: true,
    dependsOn: ["showQuestion"],
    visibleIf: function (obj) {
      return (
        obj["showQuestion"] === true
      );
    },
  },
  {
    id: 12,
    name: "multiFile",
    displayName: "Allow Multiple Capture",
    type: "boolean",
    category: "general",
    scopes: "file",
    visibleIndex: 11,
    dependsOn: ["posCapture", "scene"],
    visibleIf: function (obj) {
      return (
        (obj["posCapture"] === false) && (obj["scene"] === false)
      );
    },
  },
  {
    id: 6,
    name: "scene",
    displayName: "Allow Scene capture (for SKU recognition)",
    type: "boolean",
    category: "general",
    scopes: "file",
    visibleIndex: 11,
    dependsOn: ["posCapture", "multiFile"],
    visibleIf: function (obj) {
      return (
        (obj["posCapture"] === false) && (obj["multiFile"] === false)
      );
    },
  },
  {
    id: 7,
    name: "posCapture",
    displayName: "Allow Scene capture (for POS recognition)",
    type: "boolean",
    category: "general",
    scopes: "file",
    visibleIndex: 12,
    required: true,
    dependsOn: ["scene", "multiFile"],
    visibleIf: function (obj) {
      return (
        (obj["scene"] === false) && (obj["multiFile"] === false)
      );
    }
  },
  {
    id: 8,
    name: "dynamic",
    type: "dropdown",
    category: "Dynamic option",
    scopes: [...dynamicTypes],
    visibleIndex: 1,
    allowClear: true,
    choices: mapOptions({ list: dynamicOptions }),
    default: ""
  },
  {
    id: 9,
    name: "hasDependency",
    displayName: "Has one or more child question",
    type: "boolean",
    category: "logic",
    scopes: [...allQuestionTypes],
    visible: false,
  },
  {
    id: 10,
    name: "customId",
    displayName: "customId",
    type: "text",
    category: "general",
    scopes: [...allQuestionTypes],
    visible: false,
  },
  {
    id: 11,
    name: "dependentQuestion",
    displayName: "dependentQuestion",
    type: "text",
    category: "general",
    scopes: [...allQuestionTypes],
    visible: false,
  },
]

const creatorOptions = {
  pageEditMode: "single",
  showJSONEditorTab: false,
  showPreviewTab: false,
  questionTypes: ["text", "comment", "checkbox", "radiogroup", "dropdown", "file", "panel"],
  showSidebar: false,
}

function SurveyCreatorRenderComponent() {
  //TODO: move all constant out of survey component
  const creator = new SurveyCreator(creatorOptions);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: LocationId } = useParams();
  const [loadedFromGrid, setLoadedFromGrid] = useState(null);
  const [titleFromGridData, setTitleFromGrid] = useState("");
  const localStorageName = "questionnaire";
  const storageRefCoolRJSON = "coolrjson";
  const questionTitle = "Enter your question or instruction here";
  const questionnaireTitle = "Questionnaire Title"
  const uniqueQuestionTag = "";
  const maxRepeatConditionInOneExpression = 4;
  const propertiesToShowInSurvey = [];
  const logicTabRename = "Dependent Logic";
  const fileUploadRename = "Image/File Upload";
  localization.getLocale('en').pe.tabs.logic = logicTabRename;
  const propertiesToShow = ["defaultValueExpression", "expression", "hideIfChoicesEmpty", "validators", "errors", "containErrors", "maxErrorText", "minErrortext", "requiredErrorText", "otherErrorText", "maxLength", "defaultValueExpression", "minValueExpression", "maxValueExpression"];
  const propertiesToHide = ["choicesVisibleIf", "choicesEnableIf", "visibleIf", "showCommentArea", "enableIf", "requiredIf", "clearIfInvisible", "comment", "commentPlaceholder", "commentText", "containsErrors", "correctAnswer", "cssClasses", "defaultValue",
    "defaultValueExpression", "description", "descriptionLocation", "errors", "hasDescription", "hasInput", "hasSingleInput", "hasTitle", "hideNumber", "id", "indent",
    "inSurvey", "isAllowTitleLeft", "isCollapsed", "isDesignMode", "isExpanded", "isLoadingFromJson", "isPage", "isPanel", "isParentVisible", "isQuestion", "isReadOnly",
    "isRequired", "isVisible", "maxWidth", "minWidth", "name", "no", "page", "parent", "parentQuestion", "quizQuestionCount", "readOnly", "renderWidth", "requiredErrorText",
    "requiredText", "rightIndent", "showCommentArea", "showOtherItem", "startWithNewLine", "state", "survey", "title", "titleLocation", "useDisplayValuesInDynamicTexts", "value",
    "valueName", "visible", "visibleIndex", "width", "autocomplete", "dataList", "inputType", "isMinMaxType", "maxErrorText", "maxValueExpression",
    "minErrorText", "minValueExpression", "placeholder", "textUpdateMode", "size", "autoGrow", "rows", "cols", "choices", "choicesFromQuestion", "choicesOrder", "showNoneItem",
    "showClearButton", "separateSpecialChoices", "choicesByUrl", "isLoadingFromJson", "hideIfChoicesEmpty", "colCount", "selectAllItem", "showSelectAllItem", "maxSelectedChoices",
    "valuePropertyName", "choicesMin", "choicesMax", "choicesStep", "allowClear", "dependentQuestion", "dynamicOptions", "secItems", "SurveyName", "SurveyCategory", "SurveyType",
    "info", "multipleCaptureType", "sceneType", "barcodeType", "singleCaptureType", "options", "min", "max", "step", "allowMultiple", "allowImagesPreview", "showPreview", "storeDataAsText",
    "maxSize", "maxWidth", "imageHeight", "imageWidth", "waitForUpload", "needConfirmRemoveFile", "acceptedTypes"]
  const propertiesToShowInPage = [];
  const propertiesToShowInPanel = ["repeatOptions", "userRepeat", "autoRepeat"];
  const propertiesToShowInFile = ["scene", "posCapture", "multiFile"];
  const settingsScope = {
    SURVEY: "survey",
    PANEL: "panel",
    PAGE: "page",
    FILE: "file"
  };
  const backRoute = "/Survey/Questionnaire";

  const addCustomProperty = ({ Serializer, properties, data }) => {
    if (!properties?.length) {
      return
    }

    for (let prop of properties) {
      const { id, name, type, category, scopes, isRequired, choices, visibleIndex, ...rest } = prop;
      const serializerOptions = {
        name: name,
        type: type,
        category: category,
        visibleIndex: visibleIndex,
        value: 0,
        isRequired: isRequired,
        choices: choices,
        ...rest
      }

      if (Array.isArray(scopes)) {
        scopes.map((scope) => {
          serializerOptions.scope = scope;
          Serializer.addProperty(scope, serializerOptions);
        })
      } else {
        Serializer.addProperty(scopes, serializerOptions);
      }


    }
  }

  addCustomProperty({ Serializer, properties: additionalProperty });


  const separateAnswerFromQuestion = (element) => {
    const canSplit = element.split("=");
    if (canSplit.length < 2) {
      console.error(`OnConditionChanged: Invalid operator. Please use 'equals'`)
      return false;
    }
    const complexValue = canSplit[1];
    const optionKey = complexValue.match(/(\d+)/)[0];
    let questionNumber = canSplit[0].replace(/{|}/g, "");
    return [questionNumber.trim(), optionKey];
  }
  const getDependentElement = ({ text, sender, dependentObject = [], foundConditions = [], max = 0 }) => {
    if (max === maxRepeatConditionInOneExpression) {
      console.error("Error getDependentElement: exceeded maximum number of realistic condition.");
      return;
    } else {
      max++;
    }
    const oper1 = " or ";
    const oper2 = " and ";
    const foundElements = [];

    let leftCharacters;
    const orMatch = text.indexOf(oper1);
    const andMatch = text.indexOf(oper2);

    //findMatch
    if ((orMatch > -1) || (andMatch > -1)) {
      let finalMatch;
      if (orMatch > -1 && andMatch > -1) {
        finalMatch = orMatch < andMatch ? orMatch : andMatch;
      } else {
        finalMatch = orMatch < andMatch ? andMatch : orMatch;
      }
      const firstElement = text.substring(0, finalMatch + 1);
      const secondElement = text.substring(finalMatch + 4, text.length);
      foundElements.push(firstElement);
      leftCharacters = secondElement;
      const finalCond = orMatch < andMatch ? "or" : "and";
      foundConditions.push(finalCond);
      const separated = separateAnswerFromQuestion(firstElement);
      if (!separated) {
        return false;
      }
      const parentQuestionIndex = sender.survey.getAllQuestions().findIndex((quest) => quest.name == separated[0]);
      let parentQuestion;
      if (parentQuestionIndex !== -1) {
        parentQuestion = sender.survey.getAllQuestions()[parentQuestionIndex];
        parentQuestion.hasDependency = true;
      }
      dependentObject.push({ question: parentQuestion.customId, value: separated[1] });
    } else {
      foundElements.push(text);
      const separated = separateAnswerFromQuestion(text);
      if (!separated) {
        return false;
      }
      const parentQuestionIndex = sender.survey.getAllQuestions().findIndex((quest) => quest.name == separated[0]);
      let parentQuestion;
      if (parentQuestionIndex !== -1) {
        parentQuestion = sender.survey.getAllQuestions()[parentQuestionIndex];
        parentQuestion.hasDependency = true;
      }
      dependentObject.push({ question: parentQuestion.customId, value: separated[1] });
      return { dependentObject, foundConditions };
    }
    return getDependentElement({ text: leftCharacters, sender, dependentObject, foundConditions, max });
  }

  const createDependentQuestion = (options, sender) => {
    const logic = options.newValue;
    const result = getDependentElement({ text: logic, sender });
    if (!result) {
      options.obj[options.propertyName] = options.oldValue;
      return false;
    }
    const dependentQuestion = [];
    const type = options.propertyName === "showLogic" ? "show" : "hide";
    // eslint-disable-next-line array-callback-return
    result?.dependentObject.map((logic) => {
      dependentQuestion.push({
        "question": logic.question,
        "behaviour": type,
        "answerkey": [Number(logic.value)]
      })
    })
    options.obj.dependentQuestion = dependentQuestion

  }

  const saveToDB = async (convertedConfig) => {
    try {
      const details = convertedConfig["survey"];
      let params = {
        "action": "save",
        "id": details?.SurveyId || 0,
        "SurveyTypeName": details.SurveyName,
        "TypeId": details?.TypeId || 0,
        "ForEmployee": details?.ForEmployee || false,
        "Markets": details?.Markets || '',
        "Classifications": details?.Classifications || '',
        "CategoryId": details?.CategoryId || 0,
        "Channels": details?.Channels || '',
        "IsDefault": details?.IsDefault || false,
        "SurveyQuestions": convertedConfig?.sections
      };

      if (!params.SurveyTypeName || !params.SurveyQuestions) {
        return;
      }
      const response = await request({ url: apis.SurveyType, params, history, dispatch });

      if (response?.success) {
        window.localStorage.removeItem(localStorageName);
        window.localStorage.removeItem(storageRefCoolRJSON);
        history.push(backRoute);
      }

    } catch (err) {
      console.error(err)
    }
  }

  const saveAuto = ({ localOnly = false }) => {
    const surveyJsJSON = creator.JSON;
    const convertedConfig = utils.convertToCoolRJSON(surveyJsJSON) || {};
    if (!convertedConfig?.sections) {
      return;
    }
    window.localStorage.setItem(localStorageName, JSON.stringify(surveyJsJSON));
    window.localStorage.setItem(storageRefCoolRJSON, JSON.stringify(convertedConfig.sections));
    if (localOnly) {
      return;
    }
    saveToDB(convertedConfig);
  }

  const changeSurveyText = () => {

    const surveyText = document.querySelector(".spg-body.spg-body--empty");
    const replacedText = surveyText.innerText.replace(
      "survey",
      "questionnaire"
    );

    surveyText.innerText = replacedText;
  };

  const loadQuestionnaire = async () => {
    let surveyQuestions;
    let params = {
      action: 'load',
      asArray: 0,
      id: LocationId || 0,
    }
    //const pathName = isCopied ? "create" : "edit"
    const response = await request({ url: apis.SurveyType, params, history, dispatch });
    if (response?.success) {
      if (response?.data?.SurveyQuestions?.length) {
        const coolrJSON = JSON.parse(response.data?.SurveyQuestions);
        surveyQuestions = utils.convertToSurveyCreatorJSON(coolrJSON);
        setLoadedFromGrid(surveyQuestions);
        setTitleFromGrid(response.data.SurveyTypeName);

        window.localStorage.setItem(storageRefCoolRJSON, response.data?.SurveyQuestions);
        window.localStorage.setItem(localStorageName, JSON.stringify(surveyQuestions));
      }
    }

  }

  useEffect(() => {
    if (LocationId && (LocationId !== 0)) {
      loadQuestionnaire();
      const saveLater = setTimeout(() => {
        saveAuto({ localOnly: true });
        clearTimeout(saveLater);
      }, 500)
    }
    return () => {
      if (window.localStorage.getItem(localStorageName)) {
        window.localStorage.removeItem(localStorageName);
        window.localStorage.removeItem(storageRefCoolRJSON);
        window.localStorage.removeItem(utils.activeLocationStorage);
        window.localStorage.removeItem(utils.allLocationStorage);
      }
    }
  }, [])


  // Add a button to the toolbar
  creator.toolbarItems.push(
    new Action({
      id: "closeSurveyScreen",
      visible: true,

      enabled: true,
      iconName: "icon-clear_16x16",
      action: function () {
        if (window.localStorage.getItem(localStorageName)) {
          window.localStorage.removeItem(localStorageName);
          window.localStorage.removeItem(storageRefCoolRJSON);
          window.localStorage.removeItem(utils.activeLocationStorage);
          window.localStorage.removeItem(utils.allLocationStorage);
          creator.JSON = "";
        }

      },
    })
  );

  creator.onSurveyInstanceCreated.add(function (sender, options) {
    options.survey.onAfterRenderQuestion.add(() => {
      //changeSurveyText();
    });

  })


  creator.onShowingProperty.add((sender, options) => {
    options.canShow = propertiesToHide.indexOf(options.property.name) < 0;
    const questionType = options.obj.getType();
    if (questionType === settingsScope.SURVEY) {
      options.canShow = propertiesToShowInSurvey.indexOf(options.property.name) > -1;
    } else if (questionType === settingsScope.PANEL) {
      options.canShow = propertiesToShowInPanel.indexOf(options.property.name) > -1;
    } else if (questionType === settingsScope.PAGE) {
      options.canShow = propertiesToShowInPage.indexOf(options.property.name) > -1;
    } else if (questionType === settingsScope.FILE) {
      options.canShow = propertiesToShowInFile.indexOf(options.property.name) > -1;
    }
  });

  creator.onQuestionAdded.add(function (sender, options) {

    const currentQuestionType = options.question.getType();
    let panelName = '';
    if (options?.question?.parent?.getType() !== "panel") {

      const survey = creator.survey;
      options.question.delete();
      survey.pages[0].addNewPanel();
      if (!survey.title) {
        survey.title = questionnaireTitle;
        survey.name = "questionnaire";
      }
      const indexOfLastPanel = survey.pages[0].elements.length - 1;
      const panelObject = survey.pages[0].elements[indexOfLastPanel];
      panelName = panelObject.name;
      survey.pages[0].elements[indexOfLastPanel].title = panelName;
      survey.pages[0].elements[indexOfLastPanel].addNewQuestion(currentQuestionType)
      const indexOfLastQuestion = survey.pages[0].elements[indexOfLastPanel].elements.length - 1;
      const lastQuestion = survey.pages[0].elements[indexOfLastPanel].elements[indexOfLastQuestion];

      lastQuestion.title = questionTitle

    } else {
      const oldTitle = options?.question?.title.replace(/(\d+)/, "").trim()
      if (oldTitle === "question" && options?.question?.title) {
        options.question.title = questionTitle;
      }

    }

  })

  creator.onSurveyPropertyValueChanged.add((sender, options) => {
    sender.survey.getAllQuestions().map((quest, index) => {
      quest.customId = `${index + 1}${uniqueQuestionTag}`.trim();
    })
    const questNumber = sender.survey.getAllQuestions().findIndex((quest) => quest.name === options.obj.name) + 1;
    if (((options.propertyName === "showLogic") || (options.propertyName === "hideLogic"))) {
      if (options?.newValue) {
        createDependentQuestion(options, sender);
      }
    }
    if (options.propertyName === "title") {
      if (!options.obj.name) {
        return;
      } else {
        options.obj.name = `${questNumber}. ${options.obj.title}`;

      }
      if (questNumber) {
        options.obj.customId = `${questNumber}${uniqueQuestionTag}`.trim();
      }

    }
    const saveLater = setTimeout(() => {
      saveAuto({ localOnly: true });
      clearTimeout(saveLater);
    }, 500)

  })


  const templatesPlugin = {
    activate: () => { },
    deactivate: () => { return true; }
  };
  ReactElementFactory.Instance.registerElement(
    "svc-tab-template",
    (props) => {
      return React.createElement(QuestionnairePreview, { fromDashboard: true, forPreview: true, ...props });
    }
  );

  creator.addPluginTab("templates", templatesPlugin, "Preview", "svc-tab-template", 1);

  creator.isAutoSave = false;
  creator.showState = true;
  creator.saveSurveyFunc = function (saveNo, callback) { // save the survey JSON
    saveAuto();
    callback(saveNo, true);
  }


  Serializer.findProperty("survey", "logo").visible = false;
  Serializer.findProperty("survey", "description").visible = false;

  if (localStorage.getItem(localStorageName) && localStorage.getItem(localStorageName) !== undefined) {
    creator.JSON = JSON.parse(localStorage.getItem(localStorageName));
  }
  if (loadedFromGrid) {
    creator.JSON = loadedFromGrid;
  }
  creator.survey.title = titleFromGridData ? titleFromGridData : questionnaireTitle;

  return (
    <div style={{ height: "78vh" }}>
      <SurveyCreatorComponent creator={creator} />
    </div>);
}

export default SurveyCreatorRenderComponent;