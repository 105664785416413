import React, { useMemo } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import utils from "../../utils";
import { useTranslation } from 'react-i18next';
// Load Map Icon
import locationPin from '../../assets/images/vista-images/locationpin.png';
const { t } = utils;

const VistaLocation = React.memo(function VistaLocation({
    data: { RowNumber = '', LocationName = '', FullAddress = '', Code = '', Street = '', City = '', PostalCode = '' },
    onClose , showAddressFilter = true }) {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const dispatch = useDispatch();
    const memorizedShowAll = useMemo(() => <span className="red" style={{ cursor: 'pointer' }} onClick={() => { dispatch({ type: actions.SET_VISTA_SEARCH, vistaSearch: LocationName }); onClose(); }}>{t(`Show all at this address`, tOpts)}</span>, [LocationName, dispatch]);
    const text = (`${City && City + ',' || ''} ${PostalCode || ''}`).trim();

    return <Box className='vista-box mt-2 mb-2'><Grid container wrap="nowrap" spacing={1}>
        <Grid item>
            <div className="icon-size">
                <img src={locationPin} alt={`pin${RowNumber}`} />
            </div>
        </Grid>
        <Grid item xs>
            {LocationName && <Typography variant="body2" gutterBottom className="vista-address">
                {LocationName} <br /> {Street} <br />{text}<br />{Code}
            </Typography>}
            {showAddressFilter && memorizedShowAll}
        </Grid>
    </Grid>
    </Box>
});

export default VistaLocation;