import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faSearch, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { request, apis } from "../../httpUtil";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import Magnifier from '../../PinchZoomPen/Magnifier';
import toast from 'react-hot-toast';
import Canvas from '../Canvas';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';
import useMobile from "../../utils/useMobile";
const t = utils.t;
let ZOOM_LEVEL = { MIN: 0, MAX: 4 };
let OFFSET_DEFAULT = { x: 0, y: 0 };
// let CursorType = { NONE: 'none', NORMAL: 'normal' };
// let zoomFactor = 3

/**
 * orange 0% 
 * Blue - 1-25% (Model output label - 25)
 * Red- 25-50% (Model output label - 50)
 * Yellow- 50-75% (Model output label - 75)
 * Green- 75-100% (Model output label - 100)
 */
const colors = {
    0: "orange",
    25: "blue",
    50: "red",
    75: "yellow",
    100: "green"
}
const coords = {
    "left": 0,
    "top": 1,
    "right": 2,
    "bottom": 3
};

let history, dispatch;

// Magnify attributes
let magnifyOptions = {
    round: true, width: 200, height: 200, background: "#FFF",
    shadow: "0 8px 17px 0 rgba(0, 0, 0, 0.2)", border: "6px solid #FFF", cursor: true, zIndex: 999999, scale: .5, customClasses: ""
};

function getDistance(firstPoint, secondPoint) {
    return Math.sqrt(
        Math.pow(firstPoint.pageX - secondPoint.pageX, 2) +
        Math.pow(firstPoint.pageY - secondPoint.pageY, 2),
    );
};

function getCurrentDistance(event) {
    return getDistance(event.touches[0], event.touches[1]);
}


function getRenderedSize(contains, cWidth, cHeight, width, height, pos) {
    let oRatio = width / height,
        cRatio = cWidth / cHeight;
    return function () {
        if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
            this.width = cWidth;
            this.height = cWidth / oRatio;
        } else {
            this.width = cHeight * oRatio;
            this.height = cHeight;
        }
        this.left = (cWidth - this.width) * (pos / 100);
        this.right = this.width + this.left;
        return this;
    }.call({});
}

// function getImgSizeInfo(img) {
//     let pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
//     return getRenderedSize(true,
//         img.width,
//         img.height,
//         img.naturalWidth,
//         img.naturalHeight,
//         parseInt(pos[0]));
// }

const VistaImage = React.memo(function VistaImage({ data: { RowNumber = '', StitchedImageURL = ImageNotFound, AssetPurityId = '', IsStitched = -1, AssetId = '', PurityDateTime = '', Children = [], Image1Size, Image2Size, ImageCount }, isFromVistaDetail = false, measure = '', rightImageDateTime = '', leftImageDateTime ,isToggleChecked = false, aiData = {}, t: translate, i18n }) {
    dispatch = useDispatch();
    history = useHistory();
    const ismobile = useMobile();
    const imageDivWraper = useRef(null);
    const image = useRef(null);
    const topDiv = useRef(null)
    const [zoom, setZoom] = useState(0);
    const [offset, setOffset] = useState(OFFSET_DEFAULT);
    const [offsetRange, setOffsetRange] = useState(OFFSET_DEFAULT);
    const [clientOffset, setClientOffset] = useState({ x: undefined, y: undefined });
    const [draggable, setDraggable] = useState(false);
    const [pinchDistance, setPinchDistance] = useState(0)
    const [isMagnify, setMagnify] = useState(false);
    const [loading, setLoading] = useState(true);
    const [imgPositionFromParent, setImgPositionFromParent] = useState(null);
    const tOpts = { t: translate, i18n }

    const userData = useSelector(state => state.appReducer.userData);
    let { ClientId } = userData && userData.tags ? userData.tags : "";
    let isLeftImageStitched = IsStitched === 1;
    let cam1Image = '';
    let cam2Image = '';
    if (!isLeftImageStitched) {
        cam1Image = `${StitchedImageURL.split("ImageType")[0]}ImageType=cam1`;
        cam2Image = `${StitchedImageURL.split("ImageType")[0]}ImageType=cam2`;
    }
    const ZoomOutButton = ({ disabled, onClick }) => (
        <button className='iconButton zoomButton' onClick={onClick} disabled={disabled}>
            <FontAwesomeIcon icon={faMinus} />
        </button>
    );

    const ZoomInButton = ({ disabled, onClick }) => (
        <button className='iconButton zoomButton' onClick={onClick} disabled={disabled}>
            <FontAwesomeIcon icon={faPlus} />
        </button>
    );

    const ZoomInMagnifiy = ({ disabled, onClick }) => ( // TODO
        <button className='iconButton zoomButton' onClick={onClick} disabled={disabled}>
            <FontAwesomeIcon icon={faSearch} />
        </button>
    );

    const resetOffset = () => setOffset(OFFSET_DEFAULT);

    const setOffsetRangeNew = () => { // todo  
        let { current: imgDOM } = image;
        let { current: divDOM } = imageDivWraper;
        if (imgDOM && !isMagnify) {
            const dx = imgDOM.scrollWidth * (1 + zoom) - divDOM.clientWidth;
            const dy = imgDOM.scrollHeight * (1 + zoom) - divDOM.clientHeight;
            setOffsetRange({ x: Math.max(0, dx / 2), y: Math.max(0, dy / 2) })
        }
    }

    const onZoomInClick = () => setZoom(Math.min(zoom + 1, ZOOM_LEVEL.MAX));

    const onZoomOutClick = () => setZoom(Math.max(0, zoom - 1));

    const onLoadImg = () => {//TODO
        // let { current: imgDOM } = image;
        // let { current: imageDivWraperDOM } = imageDivWraper;
        //let imageProperty = getImgSizeInfo(imgDOM);
        setLoading(false);
        // TODO
        // setImgPositionFromParent({ 
        //     imgPosX: (imageDivWraperDOM.clientHeight - imageProperty.height),
        //     imgPosY: (imageDivWraperDOM.clientWidth - imageProperty.width),
        //     imgPosH: (imageDivWraperDOM.clientHeight - imageProperty.height),
        //     imgPosW: (imageDivWraperDOM.clientWidth - imageProperty.width)
        // })

    }

    const magnifyMoveEvent = (e) => {
        let { current: imgDOM } = image;
        let blowupLens = document.getElementById(`BlowupLens-${RowNumber}`);
        if (blowupLens) {
            let IMAGE_URL = imgDOM.src;
            let NATIVE_IMG = new Image();
            NATIVE_IMG.src = imgDOM.src;

            if (imgPositionFromParent) {


                let lensX = (e.offsetX - magnifyOptions.width / 2) + imgPositionFromParent.imgPosY / 2;
                let lensY = (e.offsetY - magnifyOptions.height / 2)



                // Lens position coordinates
                let relX = imgPositionFromParent.imgPosX + e.offsetX;
                let relY = imgPositionFromParent.imgPosY + e.offsetY;



                // Relative coordinates of image    
                // Zoomed image coordinates
                let zoomX = -Math.floor(
                    (relX / imgDOM.width) * (NATIVE_IMG.width * magnifyOptions.scale) -
                    magnifyOptions.width / 2
                );
                let zoomY = -Math.floor(
                    (relY / imgDOM.height) * (NATIVE_IMG.height * magnifyOptions.scale) -
                    magnifyOptions.height / 2
                );

                let backPos = zoomX + "px " + zoomY + "px";
                let backgroundSize = NATIVE_IMG.width * magnifyOptions.scale + "px " +
                    NATIVE_IMG.height * magnifyOptions.scale + "px";

                // Apply styles to lens
                blowupLens.style.left = lensX + "px";
                blowupLens.style.top = lensY + "px";
                blowupLens.style["background-image"] =
                    "url(" + encodeURI(IMAGE_URL) + ")";
                // blowupLens.style["background-size"] = backgroundSize;
                blowupLens.style["background-position"] = backPos;
            }
        }
    }

    const mouseEnterLens = (e) => {
        let blowupLens = document.getElementById(`BlowupLens-${RowNumber}`);
        if (blowupLens) {
            blowupLens.style.display = "block";
        }
    }

    const mouseEnterDrag = (e) => e.preventDefault();

    const onSwipe = useCallback(async (isNext) => {
        setLoading(true)
        let childData = Children && Children[0];
        let params = {
            action: "GetPrevNextImage", asArray: 0, showAllImages: false, isFetchCoolerData: true,
            AssetId: AssetId,
            LeftVisibleImageDateTime: leftImageDateTime,
            RightVisibleImageDateTime: rightImageDateTime,
            childAssetId: childData && childData.AssetId
        };
        if (Number(ClientId) > 0) {
            Object.assign(params, { clientId: Number(ClientId) });
        }
        Object.assign(params, { PrevNext: isNext ? 1 : -1 });
        const response = await request({ url: apis.assetLatestImages2, params, history, dispatch });
        if (response && response.records && response.records.length > 0) {
            dispatch({ type: 'SET_PREV_NEXT_VISTA_DATA_RECORD', vistaData: { UpdatedRecord: response.records[0], rowId: RowNumber } });
            setLoading(false);
        } else {
            setLoading(false);
            toast(isNext ? t("This is the latest image.", tOpts) : t("There is no previous image available.", tOpts));
        }
    }, [AssetPurityId, AssetId, RowNumber, setLoading, translate])

    const memorizedPrev = useMemo(() => <div className="prev-button" onClick={() => onSwipe(false)}>
        <FontAwesomeIcon icon={faChevronLeft} />
    </div>, [onSwipe]);
    const memorizedNext = useMemo(() => <div className="next-button" onClick={() => onSwipe(true)}>
        <FontAwesomeIcon icon={faChevronRight} />
    </div>, [onSwipe]);

    const hideMagnifyGlass = () => setMagnify(false);

    useEffect(() => {
        document.addEventListener('keydown', hideMagnifyGlass);
        return () => document.removeEventListener('keydown', hideMagnifyGlass);
    }, [])

    useEffect(() => {
        setOffsetRangeNew();
        if (!zoom) {
            resetOffset();
        }
    }, [zoom])

    const onMoveStart = (e) => {
        if (!draggable) { // Check if image is not popped up and zoomed in
            setDraggable(true); // Enable dragging
            setClientOffset({ x: e.clientX, y: e.clientY });
        }
        if (e.touches && e.touches.length === 2) {
            const currentDistance = getCurrentDistance(e);
            setPinchDistance(currentDistance)
            return;
        }

        if (!offsetRange.x && !offsetRange.y) return;

        let touch = e.touches && e.touches[0];
        let x = e.clientX || touch.pageX;
        let y = e.clientY || touch.pageY;
        setClientOffset({ x, y })
        setDraggable(true);
    }


    const handleZoomPinch = (e) => {
        const currentDistance = getCurrentDistance(e);
        setPinchDistance(currentDistance)
        if (currentDistance > pinchDistance && zoom < ZOOM_LEVEL.MAX) {
            onZoomInClick();
        }
        else if (currentDistance < pinchDistance && zoom > ZOOM_LEVEL.MIN) {
            onZoomOutClick();
        }
    }

    const onMove = (event) => {
        event.preventDefault();

        if (event.touches && event.touches.length === 2) {
            handleZoomPinch(event);
            return;
        }

        const touch = event.touches && event.touches[0];
        const currentX = event.clientX || touch.pageX;
        const currentY = event.clientY || touch.pageY;

        if ((!currentX && !currentY) || !draggable) {
            return;
        }

        const imageElement = image.current;
        const containerElement = imageDivWraper.current;
        const containerWidth = containerElement.offsetWidth;
        const containerHeight = containerElement.offsetHeight;
        const imageWidth = imageElement?.naturalWidth;
        const imageHeight = imageElement?.naturalHeight;

        let visibleWidth;
        let visibleHeight;

        const aspectRatio = imageWidth / imageHeight;
        if (aspectRatio > 1) {
            visibleWidth = containerWidth / zoom;
            visibleHeight = visibleWidth / aspectRatio;
        } else {
            visibleHeight = containerHeight / zoom;
            visibleWidth = visibleHeight * aspectRatio;
        }

        const minOffset = {
            x: Math.max((containerWidth - visibleWidth) / 2, containerWidth - imageWidth),
            y: Math.max((containerHeight - visibleHeight) / 2, containerHeight - imageHeight),
        };

        const maxOffset = {
            x: Math.min((containerWidth - visibleWidth) / 2),
            y: Math.min((containerHeight - visibleHeight) / 2, Math.max(0, containerHeight - visibleHeight)),
        };

        const localOffset = { x: currentX - clientOffset.x, y: currentY - clientOffset.y };
        const updatedOffset = {
            x: Math.min(Math.max(offset.x + localOffset.x, minOffset.x), maxOffset.x),
            y: Math.min(Math.max(offset.y + localOffset.y, minOffset.y), maxOffset.y),
        };

        setClientOffset({ x: currentX, y: currentY });
        setOffset(updatedOffset);

        // check if the image is fully visible within the container
        const isImageFullyVisible = visibleWidth >= imageWidth - Math.abs(updatedOffset.x - offset.x) && visibleHeight >= imageHeight - Math.abs(updatedOffset.y - offset.y);

        // if the image is fully visible, disable scrolling
        if (isImageFullyVisible) {
            event.preventDefault();
        }
    };



    const onMoveEnd = (e) => {
        setDraggable(false);
        if (Math.abs(offset.x) >= offsetRange.x) {
            offset.x =
                offset.x < 0
                    ? Math.min(0, -offsetRange.x)
                    : Math.max(0, offsetRange.x);
            setOffset(offset)
        }

        if (Math.abs(offset.y) >= offsetRange.y) {
            offset.y =
                offset.y < 0
                    ? Math.min(0, -offsetRange.y)
                    : Math.max(0, offsetRange.y);
            setOffset(offset)
        }
    }

    const onZoomInMagnifiyClick = () => {
        setZoom(Math.max(0, 0))
        // setOffsetRangeNew();
        // if (zoom) {
        //     resetOffset();
        // }
        // let { current: imgDOM } = image;
        // let { current: imageDivWraperDOM } = imageDivWraper;


        //TODO
        // if (!isMagnify) {
        //     // Create magnification lens element
        //     let lens = document.createElement("div");
        //     lens.id = `BlowupLens-${RowNumber}`;

        //     // Attack the element to the body
        //     imageDivWraperDOM.append(lens);

        //     // Updates styles
        //     let blowupLens = document.getElementById(`BlowupLens-${RowNumber}`);

        //     blowupLens.style.position = "absolute";
        //     blowupLens.style.display = "none";
        //     blowupLens.style["pointer-events"] = "none";
        //     blowupLens.style.zIndex = magnifyOptions.zIndex;
        //     blowupLens.style.width = magnifyOptions.width + "px";
        //     blowupLens.style.height = magnifyOptions.height + "px";
        //     blowupLens.style.border = magnifyOptions.border;
        //     blowupLens.style.background = magnifyOptions.background;
        //     blowupLens.style["border-radius"] = magnifyOptions.round ? "50%" : "none";
        //     blowupLens.style["box-shadow"] = magnifyOptions.shadow;
        //     blowupLens.style["background-repeat"] = "no-repeat";

        //     imgDOM.style.cursor = magnifyOptions.cursor ? "crosshair" : "none";
        //     imgDOM.addEventListener("dragstart", mouseEnterDrag, { passive: true });
        //     console.log('event add onZoomInMagnifiyClick')
        //     imgDOM.addEventListener("mouseenter", mouseEnterLens, { passive: true });
        //     imgDOM.addEventListener("mousemove", magnifyMoveEvent, { passive: true });
        // } else {
        //     let lens = document.getElementById(`BlowupLens-${RowNumber}`);


        //     console.log('event remove onZoomInMagnifiyClick')
        //     imgDOM.removeEventListener("mouseenter", mouseEnterLens, { passive: true });
        //     imgDOM.removeEventListener("mousemove", magnifyMoveEvent, { passive: true });
        //     imgDOM.removeEventListener("dragstart", mouseEnterDrag, { passive: true });
        //     lens.remove();
        // }

        setMagnify(!isMagnify)
    }
    function draw(ctx) {
        ctx.scale(1, 1);
        ctx.canvas.style.width = "100%";
        ctx.canvas.style.height = "100%";
        ctx.canvas.width = image.current.width;
        ctx.canvas.height = image.current.height;
        ctx.lineWidth = 10;
        ctx.drawImage(image.current, 0, 0, image.current.width, image.current.height)
        let boxes = {}, scores = [], labels = [], labelLen = 0, rectCount = 0, dimentions = [];
        if (aiData && Object.keys(aiData).length > 0) {
            boxes = aiData.boxes;
            scores = aiData.scores;
            labels = aiData.labels;
            labelLen = labels && labels.length;
            rectCount = boxes && boxes.length;
        }
        ctx.beginPath()
        for (let i = 0; i < rectCount; i++) {
            dimentions = boxes[i];
            if (dimentions && dimentions.length > 0) {
                let width = dimentions[coords.right] - dimentions[coords.left];
                let height = dimentions[coords.bottom] - dimentions[coords.top];
                let label = labelLen > i ? Number(labels[i]) : 0;
                let color = "green";
                ctx.font = "40px Arial";
                for (const colorValue in colors) {
                    if (label <= colorValue) {
                        color = colors[colorValue];
                        break;
                    }
                }

                ctx.strokeStyle = color;

                ctx.strokeRect(dimentions[coords.left], dimentions[coords.top], width, height);
                var textWidth = ctx.measureText(label.toString()).width;
                ctx.fillStyle = '#000';

                /// draw background rect assuming height of font
                ctx.fillRect(dimentions[0], dimentions[1], textWidth + 20, parseInt(ctx.font, 10));
                ctx.fillStyle = 'white';
                ctx.font = "bold 40px Arial";
                ctx.fillText(label.toString(), dimentions[0] + 15, dimentions[1] + 30);
            }
        }
    }
    const value = `translate3d(${offset.x}px, ${offset.y}px, 0px)`;
    let stichedImageZoom ={transform: `scale(${zoom + 1})`,transition: 'transform 0.4s ease-in-out',}
    let stichedImageTransformNone = { transform:"none"}
    const imageWrapperClass = `zoom-${zoom} image-outer ${draggable ? "dragging" : ""}`;


    return <div className="hero-wrapper bg-composed-wrapper h-100 rounded-right" ref={topDiv}>
        <div className="flex-grow-1 w-100 d-flex align-items-end">
            <div className="bg-composed-wrapper--image rounded-right opacity-9 bg-composed-filter-rm">
                <div className="card-image-container" style={{ background: isFromVistaDetail ? '#fff' : '#000' }}>
                    {!isToggleChecked && <div className="image-zoom-pinch-magnify">
                        <ZoomInButton onClick={onZoomInClick} disabled={zoom === ZOOM_LEVEL.MAX || isMagnify} />
                        <ZoomOutButton onClick={onZoomOutClick} disabled={zoom === ZOOM_LEVEL.MIN || isMagnify} />
                        <ZoomInMagnifiy onClick={onZoomInMagnifiyClick} />
                    </div>}
                    {!isMagnify && <div className="image-next-previous">
                        {memorizedPrev}
                        {memorizedNext}
                    </div>}
                    {loading && <div className="image-loader-wrapper"><FadeLoader size={8} color={'#fff'} loading={true} /> {t("Loading image",tOpts)+' ...'} </div>}
                    <div ref={imageDivWraper} className={imageWrapperClass} style={{ transform: value, height: '100%', width: '100%', display: isMagnify ? 'initial' : 'flex', flex: 1, justifyContent: 'center' }}>

                        {isToggleChecked ? <Canvas draw={draw} className={`image-container`} imageref={"image-" + RowNumber} ><img
                            ref={image}
                            style={{ display: 'none' }}
                            alt=''
                            id={`image-view-${RowNumber}`}
                            src={`${StitchedImageURL}&`}
                            onLoad={onLoadImg}
                        /></Canvas> : !isMagnify && (isLeftImageStitched ? <img
                            className={`image-container`}
                            alt=''
                            id={`image-view-${RowNumber}`}
                            src={`${StitchedImageURL}&`}
                            draggable={true}
                            onDragStart={(e) => e.preventDefault()}
                            onMouseMove={(e) => !isMagnify && onMove(e)}
                            onMouseDown={(e) => !isMagnify && onMoveStart(e)}
                            onLoad={onLoadImg}
                            onMouseUp={(e) => onMoveEnd(e)}
                            onClick={() => zoom === ZOOM_LEVEL.MIN && dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${StitchedImageURL}` } })}
                        /> :

                            <div class='d-flex' style={{...stichedImageZoom, height: '100%'}}>
                                {
                                    [Image1Size, Image2Size].map((val, i) => {
                                        return (ImageCount === 1 || val) && <img
                                            className={`image-container manual-stitch`}
                                            alt=''
                                            id={`image- view - ${RowNumber}`}
                                            src={`${eval(`cam${i + 1}Image`)}&maxWidth=300`}
                                            style={stichedImageTransformNone}
                                            draggable={true}
                                            onDragStart={(e) => e.preventDefault()}
                                            onMouseMove={(e) => !isMagnify && onMove(e)}
                                            onMouseDown={(e) => !isMagnify && onMoveStart(e)}
                                            onLoad={onLoadImg}
                                            onMouseUp={(e) => onMoveEnd(e)}
                                            onClick={() => zoom === ZOOM_LEVEL.MIN && dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${eval(`cam${i + 1}Image`)}` } })} />
                                    })
                                }
                            </div>)


                        }
                        {isMagnify &&
                            <Magnifier src={`${StitchedImageURL}`} isStitched={IsStitched} imageSizes={{Image1Size, Image2Size}} extraSrc={{ cam1Image, cam2Image }} mgBorderWidth={1} zoomFactor={1.2} ismobile={ismobile} />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
})

export default withTranslation()(VistaImage);