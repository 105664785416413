import React from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { Clear } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import utils from '../../../utils';
import swal from 'sweetalert';
import { useSnackbar } from '../../SnackBar';
import constants from '../../../utils/constants';

const {CREATOR_QUESTION_DISPLAYNAME, QUESTION_TYPES} = constants;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        padding: theme.spacing(3),
        width: "70%"
    },
    formControl: {
        width: '180px'
    },
    questionSelect: {
        backgroundColor: '#E7EDF6',
        '& .MuiOutlinedInput-input': {
            padding: '15px',
            '&::placeholder': {
                color: 'white'
            }
        }
    },
    operatorSelect: {
        backgroundColor: '#FAF0E2',
        '& .MuiOutlinedInput-input': {
            padding: '15px',
            '&::placeholder': {
                color: 'white'
            },
        }
    },
    inputLabel: {
        color: 'white'
    },
    rightHeaderBtn: {
        position: 'absolute',
        right: '0px',
        top: '10px'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        }
    }
};

const DefineConditionModal = ({ open, handleClose, questions, qid, onApplyConditions, questionsToSelect, existingConditions, onRemoveDependency }) => {
    const classes = useStyles();
    const [updatedQuestions, setUpdatedQuestions] = React.useState(existingConditions);
    const [cleanedQuestions, setQuestionsToSelect] = React.useState([]);
    const [selectedCheckboxOption, setSelectedCheckboxOption] = React.useState([]);
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const selectedQuestion = questions[qid];
    const snackbar = useSnackbar();

    React.useEffect(() => {
        setUpdatedQuestions(existingConditions)
      }, [existingConditions]);

    const getQuestionOperators = (type) => {
        switch (type) {
            case 'radio':
                return [
                    { type: 'Equals', value: '=' },
                    { type: 'Not Equals', value: '!=' },
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            case 'boolean':
                return [
                    { type: 'Equals', value: '=' },
                    { type: 'Not Equals', value: '!=' },
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            case 'checkbox':
                return [
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' },
                    { type: 'In', value: 'in' },
                    { type: 'Not In', value: 'not_in' }
                ];
            case 'date':
                return [
                    { type: 'Greater Than', value: '>' },
                    { type: 'Greater Than or Equal To', value: '>=' },
                    { type: 'Less Than', value: '<' },
                    { type: 'Less Than or Equal To', value: '<=' }
                ];
            case 'number':
                return [
                    { type: 'Greater Than', value: '>' },
                    { type: 'Greater Than or Equal To', value: '>=' },
                    { type: 'Less Than', value: '<' },
                    { type: 'Less Than or Equal To', value: '<=' }
                ];
            case 'barcode':
                return [
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            case 'image':
                return [
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            default:
                return [];
        }
    }

    const onSaveDependencyCondition = async() => {
        if (updatedQuestions.length > 0){
        try {
            const userConfirmed = await swal({
                title: `${t("Are you sure?", tOpts)}`,
                text: `${t("You are about to add a dependent question", tOpts)}`,
                icon: "warning",
                buttons: true,
                dangerMode: true
            });

            if (userConfirmed) {
                if (updatedQuestions[0].hasOwnProperty('answerkey')){
                    onApplyConditions(updatedQuestions);
                } else {
                    snackbar.showError(`${t("You have to select a value to apply a dependency.", tOpts)}`);
                }
            }
        } catch (error) {
            console.error("Error adding dependent condition:", error);
            snackbar.showError(`${t("Error adding dependent condition, please try after some time.", tOpts)}`);
        }
    } else {
            snackbar.showError(`${t("You have to select a dependent question in order to apply", tOpts)}`);
        }
    }

    const handleOptionInputChange = (e, qid) => {
        let {value} = e.target;
        if (!value) return;
      
        const updatedConditionArray = updatedQuestions.map((item) => {
          if (item.id === qid) {
            const updatedItem = { ...item };
            updatedItem.answerkey = [Number(value)];
            return updatedItem;
          }
          return item;
        });
      
        setUpdatedQuestions(updatedConditionArray);
      };

    const handleCheckboxInputChange = (e, qid) => {
        let { value, type } = e.target;

        const currentSelectedOptions = updatedQuestions
          .find((item) => item.id === qid)
          ?.answerkey || [];
      
        if (!value) return;
      
        let updatedSelectedOptions;

        if (type === 'checkbox') {
            if (currentSelectedOptions.includes(Number(value))) {
              updatedSelectedOptions = currentSelectedOptions.filter(
                (option) => option !== (Number(value))
              );
            } else {
                updatedSelectedOptions = [...currentSelectedOptions, Number(value)];
            }
        } else {
            updatedSelectedOptions = value;
        }

        const updatedConditionArray = updatedQuestions.map((item) => {
          if (item.id === qid) {
            const updatedItem = { ...item };
            updatedItem.answerkey = updatedSelectedOptions;
            return updatedItem;
          }
          return item;
        });
      
        setUpdatedQuestions(updatedConditionArray);
      };
      
      

    const getQuestionValue = (q) => {
        switch (q.type || q.displayName) {
            case CREATOR_QUESTION_DISPLAYNAME.RADIO:
            case QUESTION_TYPES.RADIO: //'radio':
                return (
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup
                            aria-labelledby="yes-no-select-label"
                            name="controlled-radio-buttons-group"
                            value={q.answerkey || ''}
                        >
                            {q.options?.map((option, i) => (
                            option.value.length ? (
                                <FormControlLabel
                                key={i}
                                value={option.key}
                                control={<Radio />}
                                name={option.value}
                                disabled={option.disabled}
                                onChange={(e) => handleOptionInputChange(e, q.id)}
                                label={option.value}
                                />
                            ) : null
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case QUESTION_TYPES.BOOLEAN: //'boolean':
                return (
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup
                            aria-labelledby="yes-no-select-label"
                            name="controlled-radio-buttons-group"
                            value={q.answerkey || ''}
                            onChange={(e) => handleOptionInputChange(e, q.id)}
                        >
                            {q.options?.map((option, i) => (
                            <FormControlLabel key={i} value={option.key} control={<Radio />} label={option.value} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case CREATOR_QUESTION_DISPLAYNAME.CHECKBOX:
            case QUESTION_TYPES.CHECKBOX: //'checkbox':
                return (
                    <FormControl sx={{ width: '100%' }}>
                        {q.options.map((option, i) => (
                            option.value.length ? (<FormControlLabel
                                key={option.id}
                                value={q.answerkey || []}
                                onChange={(e) => handleCheckboxInputChange(e, q.id)}
                                control={
                                    <Checkbox
                                        name={option.name}
                                        disabled={false}
                                        checked={q.answerkey?.includes(option.key)}
                                        value={option.key}
                                    />
                                }
                                label={t(option.value, tOpts)}
                            />) : null
                        ))}
                    </FormControl>
                );
            case QUESTION_TYPES.SELECT:
            case CREATOR_QUESTION_DISPLAYNAME.DROPDOWN: //'Dropdown':
                return     <>
                {q.subType === "Multiple Choice" ? (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-name-label">
                      {t("Multiple Choice", tOpts)}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple
                      value={q.answerkey || []}
                      input={<OutlinedInput />}
                      onChange={(e) => handleCheckboxInputChange(e, q.id)}
                      renderValue={(selectedKeys) =>
                        selectedKeys
                          .map((key) =>
                            q.options.find((option) => option.key === key)?.value || key
                          )
                          .join(", ")
                      }
                      MenuProps={MenuProps}
                      sx={{ width: '150px' }}
                    >
                      {q.options.map((option, o) => (
                        <MenuItem key={option.value} value={option.key}>
                          <Checkbox
                            checked={q.answerkey?.includes(option.key)}
                          />
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl>
                    <Select
                    label='Options'
                      id="demo-simple-select"
                      value={q.answerkey || ''}
                      onChange={(e) => handleOptionInputChange(e, q.id)}
                      gutterBottom
                      sx={{ width: '150px' }}
                    >
                      {q.options.map((option, o) => (
                        <MenuItem key={option.value} value={option.key}>
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            default:
                return <></>;
        }
    }

    const removeQuestionFromConditionSet = async (index) => {
        try {
            const userConfirmed = await swal({
                title: `${t("Are you sure?", tOpts)}`,
                text: `${t("You are about to remove this dependency", tOpts)}`,
                icon: "warning",
                buttons: true,
                dangerMode: true
            });
            if (userConfirmed) {
                const clonedItems = [...updatedQuestions];
                const dependentQuestion = clonedItems[index].questionUniqueId;
                setQuestionsToSelect(prev => [...prev, clonedItems[index]]);
                clonedItems.splice(index, 1);
                setUpdatedQuestions(clonedItems);
                onRemoveDependency(selectedQuestion.questionUniqueId, dependentQuestion);   
            }
        } catch (error) {
            snackbar.showError(`${t("Error removing dependency.", tOpts)}`);
        }
    }

    const handleSelectQuestion = (event) => {
        const { value } = event.target;
        const updatedQuestionsToSelect = questionsToSelect.filter(q => q.id !== value.id);
        setQuestionsToSelect(updatedQuestionsToSelect);
        setUpdatedQuestions(prev => [...prev, value]);
    };

    const handleOperatorChange = (qid, event) => {
        const selectedOperator = event.target.value;
        const updatedConditionArray = updatedQuestions.map((item) =>
            item.id === qid ? { ...item, operator: selectedOperator } : item
        );
        setUpdatedQuestions(updatedConditionArray);
    }

    const handleVisibilityChange = (qid, event) => {
        const showOption = event.target.value;
        const updatedConditionArray = updatedQuestions.map((item) =>
            item.id === qid ? { ...item, visibility: showOption } : item
        );
        setUpdatedQuestions(updatedConditionArray);
    }

    return (
        <Modal open={open} onClose={handleClose} className={classes.modal}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>{t('Add Dependency Condition', tOpts)}</Typography>
                        <Stack direction="row" spacing={2} justifyContent="flex-end" className={`${classes.rightHeaderBtn}`}>
                            <Button variant="contained" type="submit" color="success" onClick={onSaveDependencyCondition}>Apply</Button>
                            <Button variant="contained" type="cancel" color="error" onClick={handleClose} >Cancel</Button>
                        </Stack>
                        <Paper elevation={0} sx={{ marginTop: 3 }}>
                            {/* <Typography className='float-right'>{t('Default Visibility:', tOpts)} {selectedQuestion.isVisible !== false ? 'Show' : 'Hide'} </Typography> */}
                            <Typography gutterBottom>Q. {selectedQuestion.text}</Typography>
                        </Paper>
                    </Grid>
                    {updatedQuestions.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t('Parent Question', tOpts)}</TableCell>
                                        {/* <TableCell align="left">{t('Options/Value', tOpts)}</TableCell> */}
                                        <TableCell align="left">{t('Selected Value', tOpts)}</TableCell>
                                        {/* <TableCell align="left">{t('Visibility', tOpts)}</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {updatedQuestions.map((q, i) => (
                                        <TableRow
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Clear color="error" className='cursor-pointer' onClick={() => removeQuestionFromConditionSet(i)} />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography>{t(q.text, tOpts)}</Typography>
                                            </TableCell>

                                            {/* Removing for now as discussed with product team  */}

                                            {/* <TableCell align="left">
                                                <Select
                                                    value={updatedQuestions[i]?.operator || ''}
                                                    onChange={(event) => handleOperatorChange(q.id, event)}
                                                    sx={{ width: '100%' }}
                                                >
                                                    {getQuestionOperators(q.type).map((operator, oIndex) => {
                                                        return <MenuItem value={operator.value}>{t(operator.type, tOpts)}</MenuItem>
                                                    })}
                                                </Select>
                                            </TableCell> */}
                                            <TableCell align="left">
                                                <div style={{ maxHeight: 100, overflowY: 'auto' }}>
                                                    {q.dynamicOptions && q.dynamicOptions.items ?
                                                    <Typography>{t('Dependency not allowed on dynamic question', tOpts)}</Typography>
                                                    : getQuestionValue(q)}
                                                </div>
                                            </TableCell>

                                            {/* Removing for now as discussed with product team  */}

                                            {/* <TableCell align="left">
                                                <RadioGroup
                                                    aria-label="options"
                                                    name="options"
                                                    value={updatedQuestions[i]?.visibility || ''}
                                                    onChange={(event) => handleVisibilityChange(q.id, event)}
                                                >
                                                    <FormControlLabel value="Show" control={<Radio />} label="Show" />
                                                    <FormControlLabel value="Hide" control={<Radio />} label="Hide" />
                                                </RadioGroup>
                                            </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : null}
                        {updatedQuestions.length === 0 ? 
                    <Paper sx={{ marginTop: 3, width: '100%', padding: 3 }}>
                        <Grid container alignItems="center" spacing={2} xs={12} md={12}>
                            <Grid item xs={4}>
                                <Typography>{t('Depends on', tOpts)} :</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ m: 1, width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">{t('Select Dependent Question', tOpts)}</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={questionsToSelect}
                                        onChange={handleSelectQuestion}
                                        input={<OutlinedInput label="Select Dependent Question" />}
                                        MenuProps={MenuProps}
                                    >
                                        {questionsToSelect.map((question, qid) => (
                                            <MenuItem
                                                key={qid}
                                                value={question}
                                            >
                                                {t(question.text, tOpts)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    :null}
                </Grid>
            </Paper>
        </Modal>
    );
};

export default DefineConditionModal;
