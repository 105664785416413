import React from "react";
import { useDrag } from 'react-dnd';
import { renderIcon } from "./CustomInputs";
import { useTranslation } from "react-i18next";
import { Grid, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import utils from "../../../utils";
const DraggableItemTypes = {
    ITEM: 'questionTypes',
    QUESTION: 'question'
};
export const DraggableItem = ({ questionType, questionJson, formatQuestionTypeText }) => {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: DraggableItemTypes.ITEM,
            item: { questionType },
            end(item, monitor) {
                const dropResult = monitor.getDropResult()
                if (item && dropResult) {
                    let alertMessage = '';
                    // alertMessage = `You added ${item.item}!`
                    // alert(alertMessage)
                }
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        [],
    )

    return (
        <Paper
            ref={drag}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            elevation={0}
        >
            <ListItem sx={{ paddingTop: 1, paddingBottom: 0 }}>
                <ListItemIcon>
                    {questionType.displayName ? renderIcon(questionType) : null}
                </ListItemIcon>
                <ListItemText
                    primary={t(formatQuestionTypeText(questionType.displayName), tOpts)}
                />
            </ListItem>
        </Paper>
    );
};