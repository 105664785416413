import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { List, AutoSizer, InfiniteLoader, CellMeasurerCache } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once
import { request, apis } from "../../httpUtil";
import actions from '../../redux/actions';
import { useDispatch, connect, useSelector, shallowEqual } from 'react-redux';
import ScheduleTime from '../ScheduleTime/ScheduleTime'
import { useDebounce } from 'use-debounce';
import { useMedia } from 'the-platform';
import VistaDetailCard from './VistaDetailCard';
import { Button, FormControl, Hidden, MenuItem, Select, TextField, Box, Grid } from '@material-ui/core';
import useMobile from '../../utils/useMobile';
import { Autocomplete } from '@material-ui/lab';
import NeedsAttention from '../VistaLocationCardList/NeedsAttention';
import CurrentRank from '../VistaLocationCardList/CurrentRank';
import CurrentPog from '../VistaLocationCardList/CurrentPog';
import { makeStyles } from "@material-ui/core/styles";
import VistaSearch from '../VistaLocationCardList/VistaSearch';
import { useTranslation } from "react-i18next";
import utils from '../../utils';
import { withTranslation } from 'react-i18next';
const t = utils.t;

let Attention = React.memo(() => <NeedsAttention />);
let Rank = React.memo(() => <CurrentRank />);
let PogRank = React.memo(() => <CurrentPog />);


const useStyles = makeStyles(theme => ({
    inputRoot: {
        height: '53px',
        width: '100%',
        backgroundColor: "#fff",
    },
    input: {
        padding: '5px 4px !important',
    }
}));

let history, dispatch, loadingDetail = false, initialLimit = { startIndex: 0, stopIndex: 10 };
const cache = new CellMeasurerCache({ defaultHeight: 550, fixedWidth: true, fixedHeight: false, minHeight: 485 });

const MemoizedHidden = React.memo(({ sortValue, setSortValueFun,
    setStateValueFun, stateSelectedValue, setStateSelectedValue,
    managerSelectedValue, setManagerSelectedValue, setManagerValueFun, marketSelectedValue, setMarketSelectedValue, setMarketValueFun, channelSelectedValue, setChannelSelectedValue, setChannelValueFun}) => {
        const { t: translate, i18n } = useTranslation();
        const tOpts = { t: translate, i18n };
        const classes = useStyles();
    let comboData = useSelector(state => state.appReducer.comboData);
    const userData = useSelector(state => state.appReducer.userData);
    const { Market = [], LocationType: Channel = [] } = comboData;
    const { Unilever, HideRankByButton } = userData && userData.modules ? userData.modules : {};
    const [innerSortState, setInnerSortState] = useState({ event: "", value: sortValue });
    const [innerState, setInnerState] = useState(stateSelectedValue);
    const [innerManager, setInnerManager] = useState(managerSelectedValue);
    const [innerMarket, setInnerMarket] = useState(-1);
    const [innerChannel, setInnerChannel] = useState(-1);

    let { IsSuperAdmin } = userData && userData.tags ? userData.tags : "";
    const isAdminOrSuperAdmin = utils.isAdminORSuperAdmin(IsSuperAdmin);
    let stateArray = [{ LookupId: '', DisplayValue: 'State' }]
    let managerArray = [{ LookupId: '', DisplayValue: 'Manager' }]
    if (Object.keys(comboData).length && comboData["State"]) {
        stateArray = comboData["State"]
    }
    if (Object.keys(comboData).length && comboData["SalesPerson"]) {
        managerArray = comboData["SalesPerson"]
    }

    const handleChange = (name, e, newVal = null) => {
        const { value } = e.target;
        switch (name) {
            case "sortState":
                setInnerSortState({ event: e, value: value });
                return;
            case "filterState":
                if (newVal) {
                    setInnerState(newVal);
                } else {
                    setInnerState({ LookupId: '', DisplayValue: '' });
                }
                return;
            case "market":
                if (value) {
                    setInnerMarket(value);
                    setMarketSelectedValue(value)
                }
                return;
            case "channel":
                if (value) {
                    setInnerChannel(value);
                    setChannelSelectedValue(value)
                }
                return;
            case "manager":
                if (newVal) {
                    setInnerManager(newVal);
                } else {
                    setInnerManager({ LookupId: '', DisplayValue: '' });
                }
                return;
            default:
                return;
        }
    }

    const apply = () => {
        if (innerSortState.event) {
            setSortValueFun(innerSortState.event);
        }
        setStateSelectedValue(innerState);
        setStateValueFun(innerState.LookupId);
        setManagerSelectedValue(innerManager);
        setManagerValueFun(innerManager.LookupId);
        setMarketSelectedValue(innerMarket);
        setMarketValueFun(innerMarket);
        setChannelSelectedValue(innerChannel);
        setChannelValueFun(innerChannel);
    }

    const clear = () => {
        setStateSelectedValue({ LookupId: '', DisplayValue: 'State' });
        setManagerSelectedValue({ LookupId: '', DisplayValue: 'Manager' });
        setMarketSelectedValue("")
        setChannelSelectedValue("");
        setManagerValueFun("");
        setStateValueFun("");
        setInnerMarket(-1);
        setMarketValueFun("");
        setInnerChannel(-1);
        setChannelValueFun("");
        setInnerManager({ LookupId: '', DisplayValue: '' });
        setInnerState({ LookupId: '', DisplayValue: '' })
        dispatch({ type: 'SET_NEED_ATTENTION', vistaNeedsAttention: false });
        dispatch({ type: 'SET_CURRENT_RANK', currentRank: false });
        dispatch({ type: 'SET_CURRENT_POG', currentPog: false })
    }

    return (<Hidden smDown={false}>
        <Box
            display="flex"
            flexWrap="wrap"
            className="pt-1 pb-1 flex-column w-100"
        >
            <Box className="pt-1 pb-1 w-100">
                <div className="vista-search-select w-100">
                    <FormControl variant="outlined" style={{ width: "100%" }} className="w-100">
                        <Select MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }} className="search-select w-100" value={innerChannel} onChange={(event) => handleChange("channel", event)} autoWidth={true}>
                            <MenuItem value={-1}>{t("Channel", tOpts)}</MenuItem>
                            {Channel && Channel.map((channel, key) => <MenuItem key={key} value={channel.LookupId} > {t(channel.DisplayValue, tOpts)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
            </Box>
            <Box className="pt-1 pb-1">
                <div className="vista-search-select w-100">
                    <FormControl variant="outlined" style={{ width: "100%" }} className="w-100">
                        <Select MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }} className="search-select w-100" value={innerMarket} onChange={(event) => handleChange("market", event)} autoWidth={true}>
                            <MenuItem value={-1}>{t("Market", tOpts)}</MenuItem>
                            {Market && Market.map((market, key) => <MenuItem key={key} value={market.LookupId} > {t(market.DisplayValue)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </div>
            </Box>
            <Box className="pt-1 pb-1">
                <div className="vista-search-select w-100">
                    <FormControl variant="outlined" style={{ width: "100%" }} className="w-100">
                        <Select MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }} className="search-select w-100" value={innerSortState.value} onChange={(event) => handleChange("sortState", event)} autoWidth={true}>
                            <MenuItem key={"LocationASC"} value={"LocationName ASC"}>{t("Sort by A to Z", tOpts)}</MenuItem>
                            <MenuItem key={"LocationDESC"} value={"LocationName DESC"}>{t("Sort by Z to A", tOpts)}</MenuItem>
                            <MenuItem key={"ImageASC"} value={"PurityDateTime ASC"}>{t("Image Taken ASC", tOpts)}</MenuItem>
                            <MenuItem key={"ImageDESC"} value={"PurityDateTime DESC"}>{t("Image Taken DESC", tOpts)}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Box>
            <Box className="pt-1 pb-1">
                <div className="vista-search-select w-100">
                    <FormControl MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }} variant="outlined" style={{ minWidth: "150px" }} className="w-100">
                        <Autocomplete
                            clearText={t("Clear", tOpts)}
                            closeText={t('Close', tOpts)}
                            openText={t('Open', tOpts)}
                            id="combo-box-state"
                            value={innerState}
                            classes={classes}
                            options={stateArray}
                            getOptionLabel={(option) => (t(`${option.DisplayValue}`, tOpts))}
                            onChange={(event, newValue) => handleChange("filterState", event, newValue)}
                            renderInput={(params) => <TextField {...params} placeholder={t("State", tOpts)} value={t(stateSelectedValue, tOpts)} variant="outlined" />}
                        />
                    </FormControl>
                </div>
            </Box>
            <Box className="pt-1 pb-1">
                <div className="vista-search-select w-100">
                    <FormControl variant="outlined" style={{ minWidth: "150px" }} className="w-100">
                        <Autocomplete
                            clearText={t("Clear", tOpts)}
                            closeText={t('Close', tOpts)}
                            openText={t('Open', tOpts)}
                            id="combo-box-manager"
                            fullWidth={true}
                            value={innerManager}
                            classes={classes}
                            options={managerArray}
                            getOptionLabel={(option) => (t(`${option.DisplayValue}`, tOpts))}
                            onChange={(event, newValue) => handleChange("manager", event, newValue)}
                            renderInput={(params) => <TextField {...params} placeholder={t("Manager", tOpts)} value={t(managerSelectedValue, tOpts)} variant="outlined" />}
                        />
                    </FormControl>
                </div>
            </Box>
            <Box className="pt-1 pb-1 w-100">
                {!(Unilever.Module && !isAdminOrSuperAdmin) &&
                    <Attention />
                }
            </Box>
            {!(HideRankByButton.Module && !isAdminOrSuperAdmin) && <>
                < Box className="pt-1 pb-1">
                    <Rank />
                </Box>

                <Box className="pt-1 pb-1" >
                    <PogRank />
                </Box>
            </>
            }
            <div className="d-flex w-100 justify-content-end mt-5">
                <Button variant='contained' className="text-white m-1 background-off-red" onClick={clear}>{t("Clear Filters", tOpts)}</Button>
                <Button variant="contained" className=" text-white m-1 background-theme-blue" onClick={apply}>{t("Apply", tOpts)}</Button>
            </div>
        </Box>

    </Hidden>
    )
});


const VistLocationListCard = React.memo(function VistLocationListCard({ vistaSearch, vistaData, userData, vistaNeedsAttention, t }) {
    const smallDevice = useMedia('(max-width: 400px)');
    const verySmallPixelRange = useMedia('(max-width: 354px)');
    const smallPixelRange = useMedia({ minWidth: 355, maxWidth: 365 });
    const mediumPixelRange = useMedia({ minWidth: 366, maxWidth: 400 });
    const largePixelRange = useMedia({ minWidth: 401, maxWidth: 412 });
    const extraLargePixelRange = useMedia({ minWidth: 413, maxWidth: 420 });
    const hugePixelRange = useMedia({ minWidth: 421, maxWidth: 759 });
    const veryHugePixelRange = useMedia({ minWidth: 760, maxWidth: 960 });
    const isMobile = useMobile()

    const listRef = useRef(null);
    dispatch = useDispatch();
    history = useHistory();

    // State   
    const [fetchIndex, setFetchIndex] = useState(0);

    let { ClientId } = userData && userData.tags ? userData.tags : "";
    const [sortValue, setSortValue] = useState("PurityDateTime DESC");
    const [stateValue, setStateValue] = useState("");
    const [managerValue, setManagerValue] = useState("");
    const [marketValue, setMarketValue] = useState("");
    const [channelValue, setChannelValue] = useState("");
    const [stateSelectedValue, setStateSelectedValue] = useState({ LookupId: '', DisplayValue: 'State' });
    const [managerSelectedValue, setManagerSelectedValue] = useState({ LookupId: '', DisplayValue: 'Manager' });
    const [marketSelectedValue, setMarketSelectedValue] = useState({ LookupId: '', DisplayValue: 'Market' });
    const [channelSelectedValue, setChannelSelectedValue] = useState({ LookupId: '', DisplayValue: 'Channel' });
    const [searchDebounce] = useDebounce(vistaSearch, 800)
    let currentRank = useSelector(state => state.appReducer.currentRank, shallowEqual);
    let currentPog = useSelector(state => state.appReducer.currentPog, shallowEqual);
    const [scrollPosition, setScrollPosition] = useState(null);

    useEffect(() => {
        window.addEventListener("resize", maintainScroll, false);
        return () => {
            window.removeEventListener("resize", maintainScroll, false);
        }
    }, []);

    useEffect(() => {

        return () => {
            dispatch({ type: actions.RESET_VISTA_DATA, vistaData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 } })
        }
    }, [])
    function maintainScroll(event) {
        let getScrollElement = document.getElementsByClassName("ReactVirtualized__Grid ReactVirtualized__List");
        let scrollElementAxis = getScrollElement[0].scrollTop;
        setScrollPosition(scrollElementAxis);
        setTimeout(function () { setScrollPosition(null); }, 500);
    }

    function isRowLoaded({ index }) {
        return !!vistaData.records[index];
    }

    const getFilter = useCallback(
        (params) => {
            if (vistaSearch) {
                params = { ...params, ...{ assetOrLocationName: vistaSearch, isForRank: true } };
            }
            if (sortValue) {
                let sortArray = sortValue.split(' ');
                params = { ...params, ...{ sort: sortArray[0], dir: sortArray[1] } };
            }
            if (stateValue !== "-1" && stateValue) {
                params = { ...params, ...{ StateId: stateValue } };
            }
            if (managerValue !== "-1" && managerValue) {
                params = { ...params, ...{ ManagerId: managerValue } };
            }
            if (marketValue != "-1" && marketValue) {
                params = { ...params, ...{ MarketId: marketValue } };
            }
            if (channelValue != "-1" && channelValue) {
                params = { ...params, ...{ LocationTypeId: channelValue } };
            }
            if (currentRank) {
                params = { ...params, ...{ sort: 'AvgStockPercentage', dir: 'ASC' } };
            }
            if (currentPog) {
                params = { ...params, ...{ sort: 'AvgPlanogramCompliance', dir: 'ASC' } };
            }
            if (vistaNeedsAttention) {
                params = { ...params, ...{ isAttention: true } };
            }
            return params;
        },
        [vistaSearch, sortValue, vistaNeedsAttention, currentRank, currentPog, stateValue, managerValue, marketValue, channelValue],
    )

    const resetAll = useCallback(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [listRef])

    //fetch data
    const getData = useCallback(
        async ({ startIndex = 0 }) => {
            if (!loadingDetail) {
                loadingDetail = true;

                if (startIndex !== 0 && fetchIndex === startIndex) { return }
                setFetchIndex(startIndex)
                let params = {
                    action: "CustomList", asArray: 0, limit: 10, start: startIndex, showAllImages: false, isFetchCoolerData: true
                };
                params = getFilter(params);
                if (Number(ClientId) > 0) {
                    Object.assign(params, { clientId: Number(ClientId) });
                }
                const response = await request({ url: apis.assetLatestImages2, params, history, dispatch });
                if (response && response.records) {
                    let isNeedsAttention = false;
                    let firstFilter = false;
                    if ((vistaSearch || sortValue || vistaNeedsAttention || currentRank || currentPog) && !startIndex) {
                        firstFilter = true;
                        dispatch({ type: actions.RESET_VISTA_DATA, vistaData: { records: [], recordCount: 0, TotalCount: 0, NeedAttentionCount: 0 } })
                    }
                    if (vistaNeedsAttention) {
                        isNeedsAttention = true;
                    }
                    dispatch({ type: actions.SET_VISTA_DATA, vistaData: { records: response.records, recordCount: response.recordCount, TotalCount: response.TotalCount, NeedAttentionCount: response.NeedAttentionCount, startIndex, firstFilter, isNeedsAttention } })
                }
                resetAll()
                loadingDetail = false;
            }
        },
        [vistaSearch, sortValue, stateValue, managerValue, marketValue, channelValue, vistaNeedsAttention, currentRank, currentPog, ClientId, getFilter, fetchIndex, resetAll],
    )

    const setSortValueFun = useCallback((event) => setSortValue(event.target.value), [getData]);
    const setStateValueFun = useCallback((lookupId) => { setStateValue(lookupId) }, [getData]);
    const setManagerValueFun = useCallback((lookupId) => { setManagerValue(lookupId) }, [getData]);
    const setMarketValueFun = useCallback((lookupId) => { setMarketValue(lookupId) }, [getData]);
    const setChannelValueFun = useCallback((lookupId) => { setChannelValue(lookupId) }, [getData]);
    // resize virtual window
    const _onResize = () => cache.clearAll();

    useEffect(() => {
        if (searchDebounce || searchDebounce === '' || sortValue || stateValue || managerValue || marketValue || channelValue || vistaNeedsAttention || currentRank || currentPog) {
            getData(initialLimit);
        }
    }, [searchDebounce, sortValue, stateValue, managerValue, marketValue, channelValue, vistaNeedsAttention, currentRank, currentPog]);

    let recordCount = vistaData && vistaData.recordCount ? vistaData.recordCount : 1
    let recordCountLst = vistaData && vistaData.records.length ? vistaData.records.length : 1

    const mediaQueryHeight = (height) => {
        if (verySmallPixelRange) {
            return (height + 83);
        }
        else if (smallPixelRange) {
            return height;
        }
        else if (mediumPixelRange) {
            return (height + 79);
        }
        else if (largePixelRange) {
            return height;
        }
        else if (extraLargePixelRange) {
            return (height + 100);
        }
        else if (hugePixelRange) {
            return height;
        }
        else if (veryHugePixelRange) {
            return (height + 140);
        }
        else { return height }
    }
    useEffect(() => {
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: <MemoizedHidden
                    sortValue={sortValue}
                    setSortValueFun={setSortValueFun}
                    setStateValueFun={setStateValueFun}
                    stateSelectedValue={stateSelectedValue}
                    setStateSelectedValue={setStateSelectedValue}
                    marketSelectedValue={marketSelectedValue}
                    setMarketSelectedValue={setMarketSelectedValue}
                    setMarketValueFun={setMarketValueFun}
                    channelSelectedValue={channelSelectedValue}
                    setChannelSelectedValue={setChannelSelectedValue}
                    setChannelValueFun={setChannelValueFun}
                    managerSelectedValue={managerSelectedValue}
                    setManagerSelectedValue={setManagerSelectedValue}
                    setManagerValueFun={setManagerValueFun}
                    t={t}
                />, clear: null, apply: null, searchComponent: <VistaSearch />
            }
        });
        return () => {
            dispatch({ type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: { filterButton: null, clear: null, apply: null, searchComponent: null } });
        }
    }, [sortValue, stateSelectedValue, managerSelectedValue, marketSelectedValue, channelSelectedValue, setMarketValueFun, setSortValueFun, setStateValueFun, setManagerValueFun, getData, t])



    return (<div className={`virtual-list-wrapper mb-1 ${!isMobile.tablet ? (isMobile.portrait ? "mt-2" : "") : (isMobile.tablet && isMobile.portrait ? "mt-1" : "mt-n2")}`} container spacing={1}>
        <ScheduleTime />
        <InfiniteLoader
            loadMoreRows={getData}
            isRowLoaded={isRowLoaded}
            threshold={2}
            rowCount={recordCount}>
            {({ onRowsRendered, registerChild }) => {
                return <AutoSizer onResize={_onResize}>
                    {({ height, width }) => {
                        return (
                            <div className="vista-virtual-list" ref={registerChild}>
                                <List
                                    ref={(listref) => {
                                        registerChild(listref);
                                        listRef.current = listref;
                                    }}
                                    onRowsRendered={onRowsRendered}
                                    deferredMeasurementCache={cache}
                                    height={mediaQueryHeight(height)}
                                    rowCount={recordCountLst}
                                    scrollTop={scrollPosition}
                                    rowHeight={({ index }) => {
                                        const rowHt = cache.rowHeight({ index });
                                        return (!smallDevice && recordCountLst - 1 === index ? 100 + rowHt : rowHt)
                                    }
                                    }
                                    overscanRowCount={3}
                                    rowRenderer={({ key, index, style, parent }) => {
                                        return <VistaDetailCard
                                            key={index}
                                            keyCard={key}
                                            index={index}
                                            style={{...style, height:'500px'}}
                                            parent={parent}
                                            cache={cache}
                                            setSortValueFun={setSortValueFun}
                                            sortValue={sortValue}
                                            stateSelectedValue={stateSelectedValue}
                                            setStateSelectedValue={setStateSelectedValue}
                                            setStateValueFun={setStateValueFun}
                                            managerSelectedValue={managerSelectedValue}
                                            setManagerSelectedValue={setManagerSelectedValue}
                                            setManagerValueFun={setManagerValueFun} />
                                    }
                                    }
                                    width={width}
                                /></div>
                        )
                    }}
                </AutoSizer>
            }}
        </InfiniteLoader>
    </div>
    );
});

const mapStateToProps = state => ({
    vistaSearch: state.appReducer.vistaSearch,
    vistaData: state.appReducer.vistaData,
    userData: state.appReducer.userData,
    vistaNeedsAttention: state.appReducer.vistaNeedsAttention,
    comboData: state.appReducer.comboData,
    currentRank: state.appReducer.currentRank
});

export default connect(mapStateToProps)(withTranslation()(VistLocationListCard));