import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import dateUtil from '../../utils/date';
import imageView from '../../assets/images/vista-images/imageview.png';

const VistaPhotoTakeInformation = React.memo(function VistaPhotoTake({
    data: { RowNumber = '', PurityDateTime = '' }
}) {

    let purityDateTime = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : 'N/A';

    return <Box className='vista-box-mid mt-3 mb-2'>
        <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
                <div className="icon-size">
                    <img src={imageView} alt={`img${RowNumber}`} />
                </div>
            </Grid>
            <Grid item xs>
                <Typography variant="body2" gutterBottom className='vista-photo-take'> Photo Taken </Typography>
                <Typography variant="h4" gutterBottom>{purityDateTime}</Typography>
            </Grid>
        </Grid>
    </Box>
}, ({ data: prvData }, { data: nextData }) => {
    return (prvData.RowNumber === nextData.RowNumber)
        && (prvData.PurityDateTime === nextData.PurityDateTime)
})

export default VistaPhotoTakeInformation;