import React from 'react';
import { Grid } from '@material-ui/core';
import utils from '../../utils';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

function objContains(arr, obj) {
    let i = arr.length;
    while (i--) {
        if (JSON.stringify(arr[i]) === JSON.stringify(obj)) return true;
    }
    return false;
}

const ColumnRenderer = React.memo(function ColumnRenderer({ planogramRecord = '', facingRecord = '', index }) {
    let isPlanogram = useSelector(state => state.appReducer.isPlanogram[index]);
    let isInStore = useSelector(state => state.appReducer.isInStore[index]);
    return planogramRecord.map((p, i) => {
        let { id, thumbnail, height, fullName } = p;
        if (!id) thumbnail = './images/blank.png';
        if (!facingRecord[i].id) facingRecord[i].thumbnail = './images/blank.png';

        const bgRGBColor = facingRecord && facingRecord[i].color && utils.hexCodeToRGBA(facingRecord[i].color);
        let borderColor = facingRecord && facingRecord[i] && facingRecord[i].color;
        if (borderColor && !borderColor.includes("#")) borderColor = '#' + borderColor;

        let h = height || 80;
        h = h * 0.7;
        const style = (facingRecord && facingRecord[i].missing && { backgroundColor: bgRGBColor, borderColor: borderColor }) || {};
        isPlanogram = isPlanogram === undefined ? true : isPlanogram;
        isInStore = isInStore === undefined ? true : isInStore;
        return <div key={i} className={clsx({ 'cooler-item-single-data': !(isPlanogram && isInStore), 'cooler-item': (isPlanogram && isInStore) })} style={style}><div style={{ position: 'relative' }}>
            {isPlanogram && <img className={clsx('', { 'planogramProduct-item': (isPlanogram && isInStore) })} alt='' src={thumbnail} title={fullName} style={{ width: '17.18px', height: '52.01px' }} />}
            {isInStore && <img className={clsx('', { 'inStoreProduct-item': (isPlanogram && isInStore) })} alt='' src={facingRecord && facingRecord[i].thumbnail} title={facingRecord[i] && facingRecord[i].fullName} style={{ width: '16.04px', height: '49.25px' }} />}
        </div></div>
    })
})

function updateMissingProductDetail(planogramDetail, facing) {
    const productLegendArray = [];

    if (!planogramDetail.shelves.length > 0) return facing || { shelves: {} };
    const { isCategory, shelves } = planogramDetail;

    shelves.forEach((shelve, i) => {
        const { products } = shelve || {};
        products.forEach((product, j) => {
            const { id, productCategoryId, fullName, color, productCategory, categoryColor, isEmpty } = product;
            const value = { Name: fullName, Color: color, Category: productCategory, CategoryColor: categoryColor };
            if (!objContains(productLegendArray, value)) productLegendArray.push(value);

            let facingProduct = facing.shelves[i].products[j];
            if (!facingProduct) {
                facingProduct = { ...product, ...{ missing: true, hideMissing: true, color } };
            }
            else if (facingProduct && ((!isCategory && facingProduct.id !== id) || (isCategory && facingProduct.productCategoryId !== productCategoryId))) {
                facingProduct.missing = true;
                if (color) facingProduct.color = color;
            }
            else if (facingProduct && facingProduct.isEmpty && !isEmpty) {
                facingProduct = { ...facingProduct, ...{ missing: true, color } }
            }
            facing.shelves[i].products[j] = facingProduct;
        });
    })
    return facing;
}

const ShelvesRenderer = React.memo(function shelvesRenderer(props) {
    const { Planogram, Facings, index } = props;
    const planogramDetail = Planogram || { shelves: {} };
    const facingDetail = updateMissingProductDetail(planogramDetail, Facings);
    const planogram = Planogram.shelves, facing = facingDetail.shelves;

    return planogram.map((p, i) => {
        const planogramRecord = p && p.products;
        const facingRecord = facing && facing[i] && facing[i].products;
        return <div key={i} className="box-wrapper" style={{ display: 'flex' }}>
            <ColumnRenderer planogramRecord={planogramRecord} facingRecord={facingRecord} index={index} />
        </div>
    })
})

const CoolerView = React.memo(function CoolerView({ data: { Planogram = '', Facings = '' }, index }) {
    let isPlanogram = useSelector(state => state.appReducer.isPlanogram[index]);
    let isInStore = useSelector(state => state.appReducer.isInStore[index]);
    isPlanogram = isPlanogram === undefined ? true : isPlanogram;
    isInStore = isInStore === undefined ? true : isInStore;
    return <Grid container wrap="nowrap" >
        <Grid item xs>
            <div className="p-3 customScrollbar" style={{ maxHeight: '392px', height: '392px', overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
                {!isPlanogram && !isInStore ? <div style={{ textAlign: "center", width: '100%' }}> Please check one of the box above </div> : Planogram || Facings ?
                    <ShelvesRenderer Planogram={Planogram} Facings={Facings} index={index} /> :
                    <div style={{ textAlign: "center", width: '100%' }}> No data  </div>
                }
            </div>
        </Grid>
    </Grid>
})

export default CoolerView;
