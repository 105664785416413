import React from 'react';
import { Grid, Card, Box } from '@material-ui/core';
import { CellMeasurer } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';
import { useMedia } from 'the-platform';
import VistaLocation from '../VistaLocationCardList/VistaLocation';
import ShareInformation from './ShareInformation';
import VistaPhotoTakeInformation from './VistaPhotoTakeInformation';
import CoolerView from './CoolerView';
import InventoryNeededList from './InventoryNeededList';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import VistaImage from '../VistaLocationCardList/VistaImage';
import ShowPlanogramInStore from '../Common/ShowPlanogramInStore';


const VistaDetailCard2 = React.memo(function VistaDetailCard2({ keyCard, index, style, parent, cache }) {
    const dispatch = useDispatch();
    const mediumDevice = useMedia({ minWidth: 401, maxWidth: 960 });
    //Vista Data From redux 
    const vistaData = useSelector(state => state.appReducer.vistaData);
    const data = (vistaData.records && vistaData.records[index]) || {};
    const imageURL = (data && data.StitchedImageURL) || ImageNotFound;
    const isPlanogramAvailable = data && data.PlanogramId ? true : false;

    if (Object.keys(data).length <= 0) return false;

    return (
        <CellMeasurer
            key={keyCard}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}>
            {({ measure, registerChild }) => {
                return <div style={style} className="card-wrapper container2" ref={registerChild}>
                    <div className="card-row-wrapper">
                        <Grid container spacing={2} direction="row" alignItems="stretch">
                            <Grid item xs={12} sm={6} md={3} className="d-flex">
                                <Card className="mb-4" style={{ width: '100%' }}>
                                    <Box style={{ padding: '1.25rem' }} className="px-3 d-flex align-items-start justify-content-between">
                                        <h5 style={{ marginBottom: 0 }} className="card-header-text">{data.AssetSerialNumber}</h5>
                                    </Box>
                                    <div className="divider" />
                                    <VistaLocation index={index} data={data} />
                                    <div className="divider" />
                                    <ShareInformation data={data} />
                                    <div className="divider" />
                                    <VistaPhotoTakeInformation index={index} data={data} />
                                    <img onLoad={measure} style={{ objectFit: 'contain', height: mediumDevice ? '462px' : '200px', width: '100%', backgroundColor: '#000' }} alt='' id={`image-view-${index}`} src={imageURL} onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: imageURL, imageName: data.AssetSerialNumber } })} />
                                </Card>
                            </Grid>
                            {!isPlanogramAvailable ?
                                <Grid item xs={12} sm={12} md={9} className="d-flex">
                                    <Card className="mb-4" style={{ width: '100%' }}>
                                        <VistaImage data={data} isFromVistaDetail={true} />
                                    </Card>
                                </Grid> :
                                <>
                                    <Grid item xs={12} sm={6} md={5} className="d-flex">
                                        <Card className="mb-4" style={{ width: '100%' }}>
                                            <ShowPlanogramInStore index={index}/>
                                            <div className="divider" />
                                            <CoolerView index={index} data={data} />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} className="d-flex">
                                        <Card className="mb-4" style={{ width: '100%' }}>
                                            <Box style={{ padding: '1.25rem' }} className="px-3 d-flex align-items-start justify-content-between">
                                                <h5 style={{ marginBottom: 0 }} className="card-header-text">Inventory Needed</h5>
                                            </Box>
                                            <div className="divider" />
                                            <InventoryNeededList index={index} data={data} />
                                        </Card>
                                    </Grid></>
                            }
                        </Grid>
                    </div>
                </div>
            }}
        </CellMeasurer>
    );
})


export default VistaDetailCard2