import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';

const ComplianceChart = ({ categories, complianceValues }) => {
    CommonChartsConfig();
    if (!categories || !complianceValues) {
        return;
    }
    const backgroundSeries = Array.isArray(categories) && categories.map(() => ({
        y: 100,
        color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
                [0, '#35A35C'],
                [0.3, '#35A35C'],
                [0.3, '#FF8A00'],
                [0.7, '#FF8A00'],
                [0.7, 'red'],
                [1, 'red']
            ]
        }
    }));

    const complianceSeries = Array.isArray(complianceValues) && complianceValues.map((value, index) => ({
        x: index,
        y: value,
        marker: {
            enabled: value > 0,
            symbol: value <= 0 ? '' : 'verticalLine',
            lineWidth: 3,
            lineColor: 'black'
        },
        dataLabels: {
            enabled: true,
            format: typeof value === 'number' ? value + '%' : '',
            align: 'center',
            verticalAlign: 'bottom',
            y: -25,
            style: {
                color: 'black'
            }
        }
    }));

    const options = {
        chart: {
            type: 'bar',
            events: {
                render: function () {
                    var series = this.series[1];
                    series.points.forEach(function (point) {
                        if (point.graphic) {
                            point.graphic.translate(3, -18);
                        }
                    });
                }
            }
        },
        title: {
            text: 'Compliance Overview',
            style: {
                fontWeight: 'bold'
            }
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: 'Total compliance percentage'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            scatter: {
                marker: {
                    symbol: 'verticalLine'
                }
            },
            series: {
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
        },
        series: [{
            name: 'Background',
            data: backgroundSeries,
            enableMouseTracking: false
        }, {
            type: 'scatter',
            name: 'Compliance',
            data: complianceSeries,
            enableMouseTracking: true
        }],
        tooltip: {
            formatter: function () {
                return this.series.name === 'Compliance' ? this.point.y + '%' : false;
            }
        },
        credits: {
            enabled: false
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ComplianceChart;