import React from "react";
import ImageViewer from "./ImageViewer";
import MultiImageViewer from "./MultiImageViewer";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeIndex: this.props.activeIndex || this.props.showIndex
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.open) {
      this.setState({
        visible: true,
        activeIndex: 0
      });
    }

  }

  open(activeIndex) {
    this.setState({
      visible: true,
      activeIndex: activeIndex || 0
    });
  }

  close() {
    this.setState({
      visible: false,
      activeIndex: undefined
    });

    if (this.props.closeModal) {
      this.props.closeModal()
    }

  }

  componentWillUnmount() {
    this.setState({
      visible: false,
      activeIndex: 0
    });
  }

  render() {
    const { images, prefixCls, className, showIndex, showPreview, isMultiImages, useSingleDisplay, isFromSurveyAnalysis } = this.props;
    const { activeIndex } = this.state;
    return this.state.visible ? (
      <div className="modal">
        {!isMultiImages ?
          <ImageViewer
            showPreview={showPreview}
            showIndex={showIndex}
            prefixCls={prefixCls}
            className={className}
            activeIndex={activeIndex}
            images={images}
          />
          :
          <MultiImageViewer
            showPreview={showPreview}
            showIndex={showIndex}
            prefixCls={prefixCls}
            className={className}
            activeIndex={activeIndex}
            images={images}
            useSingleDisplay={useSingleDisplay}
            isFromSurveyAnalysis={isFromSurveyAnalysis}
          />}

        <div className="close-button" onClick={this.close.bind(this)} />
      </div>
    ) : null;
  }
}

export default Modal;
