import React from 'react';
import { Grid, Card, Box } from '@material-ui/core';
import { CellMeasurer } from 'react-virtualized';
import { useSelector } from 'react-redux';
import VistaGoodBad from './VistaGoodBad';
import VistaPhotoTake from './VistaPhotoTake';
import VistaImage from './VistaImage';
import VistaLocation from './VistaLocation';
import VistaCardMobile from './VistaCardMobile';
import useMobile from '../../utils/useMobile';

const VistaCard = React.memo(function VistaCard({ keyCard, index, style, parent, cache }) {
    //Vista Data From redux 
    const isMobile = useMobile(true);
    const vistaData = useSelector(state => state.appReducer.vistaData);
    const data = (vistaData.records && vistaData.records[index]) || {}
    if (Object.keys(data).length <= 0) return false;

    return (
        <CellMeasurer
            key={keyCard}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}>
            <div key={keyCard} style={style} className="card-wrapper container2" >
                <div className="card-row-mobile-wrapper">
                    {isMobile && <VistaCardMobile data={{ ...data }} />}
                </div>
                <div className="card-row-wrapper">
                    <Card className="card-box mb-4 vistaCard" >
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={3} style={{ marginBottom: '1.25rem' }} className="virtual-list-left">
                                <Box className="card-box2">
                                    <h4 className="font-size-lg  my-2 name">{data.AssetSerialNumber}</h4>
                                </Box>
                                <div className="divider my-4" />
                                <VistaLocation data={data} />
                                <div className="divider my-4" />
                                <VistaPhotoTake data={{ ...data }} />
                                <div className="divider my-4" />
                                <VistaGoodBad data={{ ...data }} />
                            </Grid>
                            <Grid item xs={12} md={9} className="virtual-list-right">
                                <VistaImage data={data} />
                            </Grid>
                        </Grid>
                    </Card>
                </div>
            </div>
        </CellMeasurer>
    );
}, (prev, next) => JSON.stringify(prev.style) === JSON.stringify(next.style))


export default VistaCard