import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid, Typography, Button, Accordion,
  AccordionSummary, AccordionDetails,
  InputAdornment, TextField,
  FormControl, FormControlLabel,
  Radio, RadioGroup,
  FormGroup, MenuItem,
  Checkbox, ListItemText, OutlinedInput, Box, Chip, InputLabel, Select
} from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import DropDownMenu from "../Common/DropDownMenu";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import actions from "../../redux/actions";
import util from '../../utils';
import { request, apis } from "../../httpUtil";
import { useHistory } from 'react-router-dom';
import SplitButton from '../splitButton'
import dayjs from "dayjs";
import MinMax from './MinMax';
import constants from '../../utils/constants'
const t = util.t;
let history;

const AccordionItem = ({ classes, title, children, defaultExpanded = false }) => {
  return (<Accordion defaultExpanded={defaultExpanded} className={classes.expand}>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls="panel1a-content"
      id="panel1a-header" >
      <Typography className={classes.heading}>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        {children}
      </Grid>
    </AccordionDetails>
  </Accordion>)
};

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    height: "auto",
    width: "100%",
    backgroundColor: "#fff",
    padding: "0px !important",
    "& .MuiAutocomplete-tag": {
      maxWidth: "13em",
    },
  },
  expand: {
    margin: "0.1px !important"
  },
  input: {
    padding: "5px 4px !important",
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "15px",
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const metricsDropDown = [
  { LookupId: "Avg", DisplayValue: "Average" },
  { LookupId: "Max", DisplayValue: "Maximum" },
  { LookupId: "Min", DisplayValue: "Minimum" },
];

const FilterPanel = (props) => {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const { filtersConfig = [], getData, preset = {}, inGridFilter, customDate } = props;
  const today = new Date().getDate();
  const dateFilterMaxDate = new Date(new Date().setDate(today - 1));
  const defaultToDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const defaultFromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7);
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterValues = useSelector((state) => state.appReducer.filterValues) || {};
  const hideDateFilter = useSelector((state) => state.appReducer.hideDateFilter) || false;
  const { fromDate, toDate, daysFilter } = filterValues;
  const [presetData, setPresetData] = useState({});
  history = useHistory();

  const splitButtonOptions = [
    { label: (Number(presetData.selectedPreset) > 0) ? t('Save As', tOpts) : t('Save', tOpts), value: 'Save', color: 'primary', disabled: false, bgColor: "background-theme-blue" },
    { label: t('Update', tOpts), value: 'Update', color: 'primary', disabled: (Number(presetData.selectedPreset) > 0) ? false : true, bgColor: "background-theme-blue" },
    { label: t('Delete', tOpts), value: 'Delete', color: 'secondary', disabled: (Number(presetData.selectedPreset) > 0) ? false : true, bgColor: "background-off-red" }
  ]

  useEffect(() => {
    if (Object.keys(preset).length) {
      fetchPresetData()
        .catch((error) => { console.error(error) });
    }
  }, []);

  const fetchPresetData = async (type) => {
    const response = await request({ url: `${apis.Preference}?action=list`, params: { id: preset.Id, userName: "user" }, history, dispatch });
    const localData = {
      ...presetData,
      presetListDB: response.preferences,
      preferenceLookUp: response.preferences.map((item) => { return { LookupId: item.prefId, DisplayValue: item.prefName } }),
      modal: false,
      formPresetName: "",
      formPresetDesc: "",
      formPresetId: "",
      formAction: "",
      formPresetDefault: false,
      selectedPreset: presetData.formAction === 'Update' ? presetData.selectedPreset : '-1'
    }
    setPresetData(localData);
  }

  const handleDateChange = (date, field) => {
    let filterObj = { ...filterValues };
    filterObj[field] = date;
    dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
  };

  const handleMinMaxChange = (value, name) => {
    let filterObj = { ...filterValues };
    filterObj[name] = value;
    filterObj["changedKey"] = name;
    dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
  }

  const handleMetricsFilter = (event, key) => {
    let { name, value, checked, id } = event.target;
    let filterObj = { ...filterValues };
    if (key === 'checked') {
      filterObj[name][key] = checked;
    } else {
      filterObj[name][key] = value;
    }
    dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
  }

  const handleChange = (event, selected, options, type) => {
    let { name, value, checked, id } = event.target;
    if (Array.isArray(value)) {
      value = value.length ? value.filter((e) => e !== "-1") : ["-1"]
    }
    let filterObj = { ...filterValues };
    const getStateInCountry = async ({ comboType }) => {
      let params = {
        comboType: comboType,
        ParentRecordType: name,
        ScopeId: value,
        start: 0,
        limit: 60,
      }
      await request({ url: apis.combo, params, dispatch, jsonPayload: false, disableLoader: true })
        .then((result) => {
          let formattedRecords = [];
          result?.records?.map((record) => {
            const [LookupId, DisplayValue] = record;
            const formattedRecord = { LookupId, DisplayValue };
            formattedRecords.push(formattedRecord);
          });
          dispatch({ type: actions.SET_OUTLET_FILTER_STATE_COMBO, stateCombo: formattedRecords });
          dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
          return result
        }).catch((error) => {
          console.error(error);
        })
    }
    switch (name) {
      case "daysFilter":
        filterObj[name] = value;
        dispatch({ type: actions.HIDE_DATE_FILTER, hideDateFilter: false });
        if (value !== '-1') {
          const obj = util.getDateFilter(value, dateFilterMaxDate);
          filterObj['fromDate'] = obj.fromDate;
          filterObj['toDate'] = obj.toDate;
        }
        if (value === constants.noneDateFilter.LookupId) {
          dispatch({ type: actions.HIDE_DATE_FILTER, hideDateFilter: true });
        }
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
        break;
      case "Country":
        filterObj[name] = value;
        filterObj["changedKey"] = name;
        getStateInCountry({ comboType: "State" });
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
        break;
      case "City":
        let regexPatternForCity = /^[A-Za-z]*$/;
        if(regexPatternForCity.test(value) || value == ''){
          filterObj[name] = value;
        }
        filterObj["changedKey"] = name;
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
        break;
      default:
        if (type === "checkUncheck" && options && event?.target?.value.includes('-1')) {
          if (filterObj[name] && filterObj[name].length === options.length) {
            filterObj[name] = []
          } else {
            filterObj[name] = options.map((item) => item.LookupId)
          }
        } else {
          if(name === 'marketIds' || name === 'clientIds'){
            if ((typeof value === 'string' || Array.isArray(value)) && value.includes("none")) {
              value = value.length > options.length ? null : options.map(option => option.LookupId);
            }
          }
          filterObj[name] = value;
        }
        filterObj["changedKey"] = name;
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
        break;
    }
    if (options && type === 'checkBoxMultiple') {
      let objIndex = options.findIndex((obj) => obj.dataKey === name);
      if (objIndex > -1) {
        options[objIndex].label = id;
        options[objIndex].dataKey = name;
        options[objIndex].value = checked;
        filterObj.groupBy = options;
        dispatch({ type: actions.SET_FILTER_VALUES, filterValues: filterObj });
      }
    }
  };

  const onApplyFilterClick = () => {
    getData({})
  }

  const lookUp = [
    { LookupId: 1, DisplayValue: "Yes" },
    { LookupId: 255, DisplayValue: "No" }
  ]

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterGenerator = (filter, index) => {
    let filterComponent = <></>;
    let dayDropDownDate = [];
    if (filter.defaultDay) {
      dayDropDownDate = Object.values({ [Object.keys(util.daysDropDown).length]: filter.defaultDay, ...util.daysDropDown });
      [dayDropDownDate[0], dayDropDownDate[dayDropDownDate.length - 1]] = [dayDropDownDate[dayDropDownDate.length - 1], dayDropDownDate[0]];
    } else {
      dayDropDownDate = util.daysDropDown;
    }
    const chipStyle = filter.name === "marketIds" ? "hide-chip-Details" : "alert-chip";
    switch (filter.type) {
      case 'component':
        filterComponent = <filter.component {...filter.componentProps} />;
        break;
      case 'text':
        filterComponent = <TextField
          className="search-background search"
          variant="outlined"
          placeholder={t(filter.placeholder || "Search to Filter", tOpts)}
          name={filter.name}
          value={filterValues[filter.name] || ''}
          onChange={handleChange}
          fullWidth={true}
          size={filter.size}
          InputProps={{ 'aria-label': 'Filter by Location', startAdornment: (<InputAdornment position="start">{filter.icon}</InputAdornment>) }} />
        break;
      case 'select':
        filterComponent = <DropDownMenu
          options={filter.options}
          name={filter.name}
          label={t(filter.label, tOpts)}
          separateLabel={true}
          disabled={filter.disabled || false}
          handleChange={(e) => handleChange(e, null, filter.options, filter.checkUncheck ? "checkUncheck" : "")}
          value={filterValues[filter.name] || []}
          multiple={filter.multiSelect}
          hideMenuLabel={filter.hideMenuLabel || false}
          size={filter.size}
          customPaperProps={filter.customPaperProps || {}} />
        break;
      case 'checkSelect':
        filterComponent = <FormControl className="alert-status-form" size='small' fullWidth>
          <InputLabel id="alert-status-label" className="alert-status-label">{t(filter.label, tOpts)}</InputLabel>
          <Select
            labelId="alert-status-label"
            id="alert-status-select"
            multiple
            fullWidth
            name={filter.name}
            className={filter.className}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            value={(filterValues[filter.name] && filterValues[filter.name][0] !== '-1') ? filterValues[filter.name] : []}
            onChange={(e) => handleChange(e, null, filter.options, '')}
            input={<OutlinedInput label={t(filter.label, tOpts)} />}

            renderValue={(chipFilter) => (
              <Tooltip
                title={filterValues[filter.name]?.map((LookupId) => {
                  const selectedItem = filter.options.find(item => item.LookupId === LookupId);
                  return selectedItem ? t(selectedItem.OriginalDisplayValue || selectedItem.DisplayValue, tOpts) : '';
                }).join(', ')}
                placement="bottom"
              >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(filter.optionYesNo && filterValues[filter.name].length > 1) ?
                    <Chip className={chipStyle}  key={"Chip"} label={t(filter.multiSelectLabel ?? "Multiple selected", tOpts)} />
                    :
                    (chipFilter?.length > 0 && chipFilter[0] !== '-1') && chipFilter.map((LookupId) => {
                      const text = filter.optionYesNo ? filter?.options?.filter((item) => item.LookupId === LookupId)[0] : lookUp.filter((item) => item.LookupId === LookupId)[0];
                      const labelToShow = (text?.DisplayValue || '').slice(0, 30) + ((text?.DisplayValue || '').length > 30 ? '...' : '');
                      return (                      
                          <Chip className={chipStyle}  key={"Chip-" + text?.LookupId} label={t(labelToShow, tOpts)} />
                      );
                    })}
                </Box>
              </Tooltip>
            )}
          >
            {filter.titleMessage && <MenuItem key={'MenuItem'} value='none' className={`${filter.className}-menuItem`}> <ListItemText primary={t(filter.titleMessage, tOpts)} /></MenuItem>}

            {filter.options.map((item) => (

              <MenuItem key={'MenuItem-' + item.LookupId} value={item.LookupId} sx={{ paddingTop: 1, paddingBottom: 1 }} className={`${filter.className}-menuItem`}>
                <Checkbox checked={filterValues[filter.name]?.indexOf(item.LookupId) > -1} />
                <ListItemText primary={(t(item.DisplayValue, tOpts) || '').slice(0, 30) + (t(item.DisplayValue, tOpts).length > 30 ? '...' : '')} />
              </MenuItem>

            ))}
          </Select>
        </FormControl>
        break;

      case 'checkBox':
        filterComponent = <FormControl component="fieldset">
          <RadioGroup
            aria-label={filter.name}
            name={filter.name}
            value={filterValues[filter.name]}
            onChange={handleChange} >
            {filter.options.map((label) => {
              return (
                <FormControlLabel
                  value={label.value}
                  control={<Radio />}
                  label={t(label.label, tOpts)}
                  checked={filterValues[filter.name] === label.value} />
              )
            })}
          </RadioGroup>
        </FormControl>
        break;
      case 'checkBoxMultiple':
        filterComponent = <FormGroup>
          {filter.options.map((label) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={label.value}
                    onChange={(e, s) => handleChange(e, s, filter.options, 'checkBoxMultiple')}
                    name={label.dataKey}
                    id={label.label}
                    color="primary"
                  />
                }
                label={t(label.label, tOpts)}
              />
            );
          })}
        </FormGroup>
        break;
      case 'metric':
        filterComponent = <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={filterValues[filter.name]?.checked || false}
                checked={filterValues[filter.name]?.checked || false}
                onChange={(e) => handleMetricsFilter(e, 'checked')}
                name={filter.name}
                color="primary"
              />
            }
            label={t(filter.label, tOpts)}
          />
          {filter.input && <TextField
            className="search-background search mb-1"
            variant="outlined"
            placeholder='0'
            type='number'
            name={filter.name}
            value={filterValues[filter.name]?.input || ''}
            onChange={(e) => handleMetricsFilter(e, 'input')}
            fullWidth={true}
            size={filter.size} />}
          <DropDownMenu
            options={metricsDropDown}
            name={filter.name}
            size='small'
            handleChange={(e) => handleMetricsFilter(e, 'calc')}
            value={filterValues[filter.name]?.calc || ''}
            disabled={!(filterValues[filter.name]?.checked || false)}
          />
        </FormGroup>
        break;
      case "date":
        if (inGridFilter) {
          filterComponent = (
            <div className="filter-grid-container">
              <DropDownMenu
                classNameParent="w-25"
                options={util.daysDropDown}
                name="daysFilter"
                separateLabel={true}
                label={t("Date Range", tOpts)}
                handleChange={handleChange}
                value={daysFilter === undefined ? "7:day" : daysFilter}
                size={filter.size}
                hideMenuLabel={filter.hideMenuLabel}
              />

              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label={t("Start Date", tOpts)}
                name="fromDate"
                format={util.getUserDateFormat()}
                value={fromDate}
                onChange={(e) => handleDateChange(e, "fromDate")}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                disableFuture={true}
                disabled={customDate === "Disabled" ? true : daysFilter !== "-1"}
                invalidDateMessage={t("Invalid Date Format", tOpts)}
                minDateMessage={t('Date should not be before minimal date',tOpts)}
                maxDateMessage={t('Date should not be after maximal date',tOpts)}
                maxDate={filter.maxDate ? filter.maxDate : new Date()}
              />

              <Grid item className="mt-6 pt-3 text-center">{t("To", tOpts)}</Grid>

              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                name="toDate"
                label={t("End Date", tOpts)}
                format={util.getUserDateFormat()}
                value={toDate}
                onChange={(e) => handleDateChange(e, "toDate")}
                KeyboardButtonProps={{ "aria-label": "change date" }}
                disableFuture={true}
                disabled={customDate === "Disabled" ? true : daysFilter !== "-1"}
                maxDate={filter.maxDate ? filter.maxDate : new Date()}
                invalidDateMessage={t("Invalid Date Format", tOpts)}
                minDateMessage={t('Date should not be before minimal date',tOpts)}
                maxDateMessage={t('Date should not be after maximal date',tOpts)}
                minDate={fromDate}
              />
            </div>
          );
        } else {
          filterComponent = <>
            <Grid item xs={12} sm={12} md={12} lg={12} className="dis-grid mb-1 mt-1">
              <DropDownMenu
                options={props.daysDropDown || dayDropDownDate}
                name="daysFilter"
                separateLabel={true}
                label={t(filter.label || "Date Range", tOpts)}
                handleChange={handleChange}
                value={daysFilter}
                size={filter.size}
                hideMenuLabel={filter.hideMenuLabel} />
            </Grid>
            {!hideDateFilter ?
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} className="dis-grid">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={t("Start Date", tOpts)}
                    name="fromDate"
                    format={util.getUserDateFormat()}
                    value={fromDate}
                    onChange={(e) => handleDateChange(e, "fromDate")}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    disableFuture={true}
                    disabled={props.customDate === "Disabled" ? true : daysFilter !== '-1'}
                    maxDate={filter.maxDate ? filter.maxDate : new Date()}
                    invalidDateMessage={t("Invalid Date Format", tOpts)}
                    minDateMessage={t('Date should not be before minimal date',tOpts)}
                    maxDateMessage={t('Date should not be after maximal date',tOpts)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="dis-grid">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    name="toDate"
                    label={t("End Date", tOpts)}
                    format={util.getUserDateFormat()}
                    value={toDate}
                    onChange={(e) => handleDateChange(e, "toDate")}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    disableFuture={true}
                    disabled={props.customDate === "Disabled" ? true : daysFilter !== '-1'}
                    maxDate={filter.maxDate ? filter.maxDate : new Date()}
                    invalidDateMessage={t("Invalid Date Format", tOpts)}
                    minDateMessage={t('Date should not be before minimal date',tOpts)}
                    maxDateMessage={t('Date should not be after maximal date',tOpts)}
                    minDate={fromDate}
                  />
                </Grid></> : <></>}
          </>
        }
        break;

      case "minmax":
        filterComponent =
          <MinMax
            label={filter.label}
            onChange={(val) => { handleMinMaxChange(val, filter.name) }}
            value={filterValues[filter.name] ? JSON.parse(filterValues[filter.name]) : { "gt": '', "lt": '' }}
          />;
        break;

      default:
        filterComponent = <></>;
    }

    return (
      <Grid key={index} item xs={12} sm={12} md={12} lg={12} className="dis-grid mb-2">
        {filterComponent}
      </Grid>
    )
  }

  return (
    <Grid container style={{ margin: "1px" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {preset.enable && <AccordionItem key={'presetAccord'} defaultExpanded={false} classes={classes} title={'Preset'} >
          <Grid key={'presetAccord-grid'} item xs={12} sm={12} md={12} lg={12} className="dis-grid  mb-1">
            <DropDownMenu
              options={presetData.preferenceLookUp}
              name="presetId"
              label={t("Select Preset to load", tOpts)}
              handleChange={() => { }}
              value={presetData.selectedPreset}
              multiple={false}
              size="small" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} className="text-right">
            <SplitButton options={splitButtonOptions} callback={() => { }} />
          </Grid>
        </AccordionItem>}
        {filtersConfig.map((filterAccord, indexAccord) => {
          return inGridFilter ? (
            <Grid key={indexAccord} className="pt-1" item xs={12} sm={6} md={3} lg={3}>
              {filterAccord.config.map((filter, index) => {
                return filter.hidden ? "" : filterGenerator(filter, index);
              })}
            </Grid>
          ) : (
            <AccordionItem
              key={indexAccord}
              defaultExpanded={filterAccord.openDefault}
              classes={classes}
              title={t(filterAccord.name, tOpts)}
            >
              {filterAccord.config.map((filter, index) => {
                return filter.hidden ? "" : filterGenerator(filter, index);
              })}
            </AccordionItem>
          );
        })}
        {getData && <Accordion defaultExpanded={false} style={{ margin: "0px !important" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} className="text-right m-3">
              <Button
                variant="contained"
                color="primary"
                onClick={onApplyFilterClick} >
                {t('Apply', tOpts)}
              </Button>
            </Grid>
          </Grid>
        </Accordion>}
      </Grid>
    </Grid>
  );
};

export default memo(FilterPanel);