import React from "react";
import MultiImageWrapper from "./MultiImageWrapper";

const VISIBLE_INDICATORS_COUNT = 8;
const KEY_CODE = {
  LEFT: 37,
  RIGTH: 39,
};

class MultiImageViewer extends React.Component {
  container;
  mounted;

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.activeIndex || this.props.showIndex
    };
  }

  renderIndicators(list) {
    const activeIndex = this.state.activeIndex;
    const ret = Math.round(VISIBLE_INDICATORS_COUNT / 2);
    const length = list.length;
    return list.map((item, index) => {
      const isActive = activeIndex === index;
      const itemInvisible =
        length > VISIBLE_INDICATORS_COUNT &&
        (index <
          Math.min(length - VISIBLE_INDICATORS_COUNT - 1, activeIndex - ret) ||
          index > Math.max(activeIndex + ret, VISIBLE_INDICATORS_COUNT));

      const itemCls = `indicators-item ${isActive ? "active" : ""} ${
        itemInvisible ? "invisible" : ""
      } ${this.props.showPreview ? "preview" : ""}`;

      return (
        <div
          key={index}
          className={itemCls}
          onClick={this.itemControl.bind(this, index)}
        >
          {this.props.showPreview && (
            <div className="image" style={{ background: `url(${item.src})` }} />
          )}
        </div>
      );
    });
  }

  onPrev() {
    let index =
      (this.state.activeIndex + this.props.images.length - 1) %
      this.props.images.length;
    this.itemControl(index);
  }

  onNext() {
    let index = (this.state.activeIndex + 1) % this.props.images.length;
    this.itemControl(index);
  }

  itemControl(index) {
    if (index === this.state.activeIndex) return;
    this.state.activeIndex = index;
    this.setState(this.state);
  }

  onKeyDown(e) {
    if (!this.mounted) return;
    e.stopPropagation();

    switch (e.which || e.keyCode) {
      case KEY_CODE.LEFT:
        this.onPrev();
        break;
      case KEY_CODE.RIGTH:
        this.onNext();
        break;
    }
  }

  componentDidMount() {
    this.mounted = true;
    document.documentElement.addEventListener(
      "keydown",
      this.onKeyDown.bind(this)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
    document.documentElement.removeEventListener(
      "keydown",
      this.onKeyDown.bind(this)
    );
  }

  render() {
    const { images, showIndex, prefixCls, useSingleDisplay, isFromSurveyAnalysis } = this.props;
    const { activeIndex = showIndex } = this.state;
    const indicatorVisible = images.length > 1; // To show next/previous image indicator

    const isPrevDisabled = isFromSurveyAnalysis && activeIndex === 0;
    const isNextDisabled = isFromSurveyAnalysis && activeIndex === images.length - 1;

    return (
      <div
        className={`react-multi-image-viewer ${prefixCls}-image-viewer`}
        ref={(component) => (this.container = component)}
      >
        <MultiImageWrapper
          showIndex={activeIndex}
          index={`${activeIndex + 1}/${images.length}`}
          image={images}
          useSingleDisplay={useSingleDisplay}
        />
        {indicatorVisible ? (
          <div className="direction-control-button">
            <div
              className={`prev-button ${
                isFromSurveyAnalysis ? "button-analysis left" : ""
              } button ${
                isPrevDisabled ? "disabled" : ""
              }`}
              onClick={!isPrevDisabled ? this.onPrev.bind(this) : null}
            >
              <div className="bar" />
            </div>
            <div
              className={`next-button ${
                isFromSurveyAnalysis ? "button-analysis right" : "next-button"
              } button ${
                isNextDisabled ? "disabled" : ""
              }`}
              onClick={!isNextDisabled ? this.onNext.bind(this) : null}
            >
              <div className="bar" />
            </div>
            <div className="indicators">
              {indicatorVisible && this.renderIndicators(images)}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default MultiImageViewer;
