// ThemeAdder.js
import * as React from 'react';
import * as locales from '@mui/x-data-grid-premium';
import { useEffect, createContext } from 'react'
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dateLocale from 'date-fns/locale/de';
import ptPT from './data-grid/ptPT';
const ThemeContext = createContext(null);
const ThemeAdder = ({ children }) => {
    const { i18n } = useTranslation();
    const selectedLanguage = i18n.language;
    const languageCodeWithoutHyphen = selectedLanguage.replace('-', '');
    const [locale, setLocale] = React.useState(languageCodeWithoutHyphen);
    const theme = useTheme();
    let usedThemeLanguage = locales[locale]
    useEffect(() => {
        setLocale(languageCodeWithoutHyphen);
    }, [languageCodeWithoutHyphen]);

    if(locale === 'ptPT'){
        usedThemeLanguage = ptPT;
    };
    const themeWithLocale = React.useMemo(
        () => createTheme(theme, usedThemeLanguage),
        [locale, theme]
    );

    return (<>
        <ThemeContext.Provider>
            <ThemeProvider theme={themeWithLocale} >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dateLocale[locale]}>
                {children}
                </LocalizationProvider>
            </ThemeProvider>
        </ThemeContext.Provider>
    </>);
};

export default ThemeAdder;
