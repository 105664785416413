import React from 'react';
import { useTranslation } from 'react-i18next';
import utils from '../../utils'
import { Select, FormControl, MenuItem, InputLabel } from '@material-ui/core';
const t = utils.t

const DropDownMenu = React.memo((props) => {
    const {
        options = [],
        name = "",
        value,
        handleChange,
        label = "",
        style = {},
        className = "",
        classNameParent = "",
        disabled = false,
        multiple = false,
        size = '',
        separateLabel = false,
        hideMenuLabel = false,
        customPaperProps = {} } = props;
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    return (
        <FormControl variant='outlined' className={classNameParent} disabled={disabled} size={size}>
            {separateLabel && <InputLabel id="demo-simple-select-label">{t(label, tOpts)}</InputLabel>}
            <Select
                name={name}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={`filter-select ${className}`}
                MenuProps={{
                    PaperProps: {
                        style: customPaperProps || {},
                    },
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                value={value || -1}
                onChange={handleChange}
                multiple={multiple}
                maxRows={multiple ? 5 : 1}
                label={t(label, tOpts)}
                style={style}>
                {!hideMenuLabel && <MenuItem value="-1">{label}</MenuItem>}
                {options.map((item) =>
                    item ? <MenuItem key={item.LookupId} value={item.LookupId} >{t(item.DisplayValue, tOpts)}</MenuItem> : ""
                )}
            </Select>
        </FormControl>)
}
)

export default DropDownMenu;