import UiModel from './ui-model';

const invoiceLookup = [{ "label": "Yes", "value": 'Yes' }, { "label": "No", "value": 'No' }];
const responsibilityLookup = [{ "label": "CoolR", "value": 'CoolR' }, { "label": "Client", "value": 'Client' }];

const dailyAssetsStatusModel = new UiModel({
    title: "Invoicing",
    defaultSort: 'Date DESC',
    titleDescription: 'Check your monthly invoices',
    idProperty: 'id',
    readOnly: true,
    gridSubTitle: 'Asset Per Day',
    addModifiedOnColumn: false,
    addModifiedByColumn: false,
    module: 'Invoicing',
    api: 'DailyAssetsStatus',
    showHiddenColumn: true,
    disableRowSelectionOnClick: false,
    addCreatedModifiedColumns: false,
    isElasticScreen: true,
    updatePageTitle: false,
    hideBackButton: true,
    preferenceId: 'playbookDailyAssetsStatus',
    columns: [
        {
            field: "ParentAssetSerialNumberId", type: 'number', width: 250, label: "Parent Asset Serial Number", renderCell: (param) => {
                const { value } = param;
                return (
                    <div className="custom-button">
                        {value || ""}
                    </div>
                );
            }
        },
        {
            field: "AssetSerialNumberId", type: 'number', width: 200, label: "Asset Serial Number", renderCell: (param) => {
                const { value } = param;
                return (
                    <div className="custom-button">
                        {value || ""}
                    </div>
                );
            }
        },
        { field: "Date", type: 'date', width: 250, label: "Date" },
        { field: "Status", width: 250, label: "Status", filterField: 'Status.keyword', isKeywordField: true },
        { field: "Responsability", width: 250, label: "Responsible", isKeywordField: true, type: 'radio', customLookup: responsibilityLookup },
        { field: "Invoice", width: 302, label: "Invoice", isKeywordField: true, type: 'radio', customLookup: invoiceLookup }
    ]
});

export default dailyAssetsStatusModel;