import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
const host = process.env.REACT_APP_HOST;
const gaConfig = {
  local: "G-MBCCT9M218",
  dev: "",
  beta: "G-RCDEPGDD9V",
  prod: "G-S87DWKLDL5"
}

const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    let GA_ID = "";

    if (window.location.href.includes("localhost")) {
      GA_ID = gaConfig.local;
    } else if (host.includes('dev')) {
      GA_ID = gaConfig.dev;
    } else if (host.includes('beta')) {
      GA_ID = gaConfig.beta;
    } else if (host.includes('portal')) {
      GA_ID = gaConfig.prod;
    }

    if (GA_ID) {
      ReactGA.initialize(GA_ID, {
        debug: true,
        gaOptions: {
          cookieDomain: "none",
          siteSpeedSampleRate: 100
        }
      });
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname.replace('/', '').replace(/([A-Z])/g, ' $1').trim() });
    }
  }, [initialized, location]);
};

export default useGaTracker;