import { Card, CardContent, Paper } from "@mui/material";
import { GoogleMap, Marker, MarkerClusterer, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import utils from "../../../utils";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";

const clusterMarker = require('../../../assets/images/map/cluster-marker.png');
const outletMarker = require('../../../assets/images/map/outlet-marker.png');
const mapStyles = { height: "100vh", width: "100% !important" };
const clusterStyle = [{ url: clusterMarker, width: 50, height: 50, textSize: 14, textColor: "white", className: 'map-cluster-text' }];

const useStyles = makeStyles((theme) => ({
  customCardContent: {
    width: '100% !important',
    marginTop: '20px !important'
  }
}));

const SurveyMap = ({ locations, t, tOpts }) => {
  const { t: translate, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mapMarkers, setMapMarkers] = useState([]);
  const { isLoaded, } = useLoadScript({
    googleMapsApiKey: utils.Google_ApiKey,
    language: i18n.language
  });

  useEffect(() => {
    const mapMarkersGenerator = async () => {
      try {
        if (isLoaded && locations.length > 0) {
          const localMarkerArray = [], localMarkerMap = [];
          for (let i = 0; i < locations.length; i++) {
            let latitude = locations[i].Latitude,
              longitude = locations[i].Longitude,
              key = latitude + ":" + longitude;

            //if latitude and longitude is 0 do not render markers on map  
            if (latitude != 0 || longitude != 0) {
              localMarkerArray.push({
                lat: latitude,
                lng: longitude,
                count: locations[i].LocationSubmissions,
                id: locations[i].LocationId,
                locations: locations[i]
              });
              localMarkerMap.push(key);
            }
            setMapMarkers(localMarkerArray);
          }
        }
      } catch (error) {
        console.error("Error on Map component =>", error);
      }
    }
    mapMarkersGenerator()
      .catch(console.error);
  }, [locations]);

  function clusterPriority(markers, icons) {
    let highestPriorityColor = 1;
    return {
      text: markers.length,
      index: highestPriorityColor
    };
  }

  const onLoad = React.useCallback(function callback(map) {
    map.setCenter({ lat: 0, lng: 0 });
    map.setZoom(3);
    setTimeout(() => {
      dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
    }, 5000)
  }, []);

  return (
    <Paper xs={12}>

      <CardContent className={classes.customCardContent}>
        {isLoaded && locations.length > 0 ?
          <GoogleMap
            mapContainerStyle={mapStyles}
            onLoad={onLoad}
            zoom={3}
            options={{ gestureHandling: 'greedy', minZoom: 0.2, language: i18n.language }}>
            <MarkerClusterer
              averageCenter={true}
              calculator={clusterPriority}
              styles={clusterStyle}
            >
              {(clusterer) =>
                mapMarkers.map((location) => (
                  <Marker
                    key={location.id}
                    position={new window.google.maps.LatLng(location.lat, location.lng)}
                    title={`ID - ${location.id}, Submissions - ${location.locations.LocationSubmissions}`}
                    clusterer={clusterer}
                    animation={"BOUNCE"}
                    icon={{ url: outletMarker, scaledSize: new window.google.maps.Size(50, 50) }}
                  />
                ))
              }
            </MarkerClusterer>
          </GoogleMap> : <div className='no-map-location'>{t("No map location found", tOpts)}</div>
        }
      </CardContent>
    </Paper>
  );
}

export default SurveyMap