import GridBase from '../components/grid';
import * as yup from 'yup';
import Form from '../components/form/form';
import Paper from '@mui/material/Paper';
import { Divider } from '@mui/material';
import constants from '../utils/constants';

const nonAlphaNumeric = /[^a-zA-Z0-9]/g;

const customStyle = {};

const showRowsSelected = true;

const defaultValueConfigs = {
    "string": "",
    "boolean": false,
    "radio": false,
    "oneToMany": ""
}

class UiModel {
    constructor(modelConfig) {
        const { title, controllerType } = modelConfig;
        let { api, idProperty = api + 'Id' } = modelConfig;
        if (!api) {
            api = `${title.replaceAll(nonAlphaNumeric, '-').toLowerCase()}`;
            idProperty = title.replaceAll(' ', '') + 'Id';
        }
        api = controllerType === 'cs' ? `${api}.ashx` : `internal-reporting/api/${api}`;
        const defaultValues = { ...modelConfig.defaultValues };
        Object.assign(this, { standard: true, idProperty, ...modelConfig, api });
        const columnVisibilityModel = {};
        for (const col of this.columns) {
            const name = col.field || col.id;
            if (col.hide === true) {
                columnVisibilityModel[col.id || col.field] = false;
            }
            defaultValues[name] = col.defaultValue === undefined ? (defaultValueConfigs[col.type] || "") : col.defaultValue;
        }
        this.columnVisibilityModel = columnVisibilityModel;
        this.defaultValues = defaultValues;
    }

    getValidationSchema({ id, t, tOpts }) {
        const { columns } = this;
        let validationConfig = {};
        for (const column of columns) {
            const { field, label, header, type = 'string', requiredIfNew = false, required = false, min = '', max = '', validationLength = 0 } = column;
            const formLabel = label || header;
            if (!formLabel) {
                continue;
            }
            let config;
            switch (type) {
                case 'string':
                    config = yup.string().trim().label(formLabel);
                    if (min) {
                        config = config.min(Number(min), `${formLabel} ${t('must be at least', tOpts)} ${min} ${t('characters long', tOpts)}`);
                    }
                    if (max) {
                        config = config.max(Number(max), `${formLabel} ${t('must be at most', tOpts)} ${max} ${t('characters long', tOpts)}`);
                    }
                    break;
                //Validation "oneToMany" type for grid to show snackbar - commenting out for use in future
                // case 'oneToMany':
                //     config = yup.string().label(formLabel).test(value => {
                //         const valueArray = (value && value.length > 0) ? value.split(',').map(item => item.trim()) : [];
                //         if (valueArray.length < 2) {
                //             snackbar.showError(`Please assign at least 2 ${formLabel}`, null, "error");
                //             return false;
                //         } else {
                //             return true;
                //         }
                //     });
                //     break;
                case 'boolean':
                    config = yup.bool().nullable().transform((value, originalValue) => {
                        if (originalValue === '') return null;
                        return value;
                    }).label(formLabel);
                    break;

                case 'radio':
                case 'dayRadio':
                    config = yup.mixed().label(t(formLabel, tOpts)).required(`${t('Select at least one option for', tOpts)} ${formLabel}`);
                    break;
                case 'date':
                    config = yup.date().nullable().transform((value, originalValue) => {
                        if (originalValue === '' || originalValue === null) return null;
                        return value;
                    }).label(formLabel).required(`${t(formLabel, tOpts)} ${t('is required', tOpts)}`);
                    break;
                case 'autocomplete':
                    config = yup.string().trim().label(t(formLabel, tOpts)).required(`${t('Select at least one', tOpts)} ${formLabel}`);
                    break;
                default:
                    config = yup.mixed().label(formLabel);
                    break;
            }
            if (required) {
                config = config.trim().required(`${t(formLabel, tOpts)} ${t('is required', tOpts)}`);
            }
            if (requiredIfNew && (!id || id === '')) {
                config = config.trim().required(`${t(formLabel, tOpts)} ${t('is required', tOpts)}`);
            }
            validationConfig[field] = config;
        }

        let validationSchema = yup.object({ ...validationConfig, ...this.validationSchema });
        return validationSchema;
    }
    Form = ({ match, ...props }) => {
        return <Form model={this} Layout={this.Layout} {...props} />
    }

    Grid = ({ match, ...props }) => {
        const { chartFilters } = props;
        let filters = {};
        if (!this.chartFilters) {
            this.chartFilters = [];
        }
        if (chartFilters?.items?.length > 0) {
            const { columnField, operatorValue } = chartFilters.items[0];
            filters = { field: constants.chartFilterFields[columnField], operator: operatorValue, value: Math.random(), id: Math.random() };
        }
        this.chartFilters = [filters];
        return <Paper><GridBase model={this} showRowsSelected={showRowsSelected} {...props} /></Paper>
    }

    ChildGrid = (props) => {
        return <>
            <GridBase model={this} {...props} customStyle={customStyle} showRowsSelected={showRowsSelected} />
            <Divider orientation='horizontal' sx={{ mt: 2 }} />
        </>
    }
}

export default UiModel;
export {
    GridBase
}