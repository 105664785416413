import UiModel from './ui-model';

const deviceTrackingModel = new UiModel({
    title: "Device Tracking",
    defaultSort: 'DeviceType DESC',
    titleDescription: 'Device Tracking Mechanism',
    idProperty: 'DeviceTrackingId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    module: 'DeviceTracking',
    hideBackButton: true,
    preferenceId: 'playbookDeviceTracking',
    columns: [
        { field: "ClientName", type: 'string', width: 200, label: "Client" },
        { field: "DeviceType", type: 'string', width: 250, label: "Device Type" },
        { field: "Status", type: "string", width: 150, label: "Status" },
        { field: "Count", type: 'number', width: 150, label: "Total" }
    ]
});

export default deviceTrackingModel;