import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    typography: {
        title_bar: {
            fontSize: "20px",
        },
    },
    palette: {
        app_bar: "#01579B",
        footer_button: "#005FCC",
        card_orange: {
            light: "rgba(255, 167, 38, 0.3)",
            main: "rgba(255, 167, 38, 0.5)"
        },
        card_blue: {
            light: "rgba(144, 202, 249, 0.16)",
            main: "rgba(144, 202, 249, 0.30)"
        },
        card_blue_m: {
            light: "rgba(227, 242, 253, 1)",
            main: "rgba(25, 118, 210, 0.12)",
        },
        main_blue: "#01579B",
        card_green: {
            light: "rgba(46, 125, 50, 0.3)",
            main: "rgba(46, 125, 50, 0.5)"
        },
        card_pink: {
            light: "rgba(244, 67, 54, 0.08)",
            main: "rgba(244, 67, 54, 0.12)"
        },
        icons_pink: {
            light: "rgba(237, 108, 2, 0.12)",
            main: "#ED6C02",
        },
        card_grey: {
            light: "#00000014",
            main: "#0000001F",
        },
        icon: {
            untouched: '#00000061',
            inprogress: '#FF0000',
            completed: '#2E7D32'
        },
        sso_button: "#01579B",
        email_button: "#388E3C",
        mapPopupBackgroundColor: "white",
    },
    spacings: {
        mapPopContentPadding: {
            padding: "10px 20px !important;"
        }
    }
});

export default theme;
