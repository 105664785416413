import UiModel from './ui-model';

const assetTypesListModel = new UiModel({
    title: "Master Data",
    defaultSort: 'AssetType DESC',
    titleDescription: "Check how your master data is set",
    idProperty: 'AssetTypeId',
    readOnly: true,
    api: 'AssetType',
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookMasterAssetType',
    columns: [
        { field: "AssetType", label: "Asset Type", type: 'string', width: 270 },
        { field: "ModelNumber", label: "Model Number", type: 'string', width: 270 },
        { field: "Manufacturer", label: "Manufacturer", type: 'string', width: 300 },
        { field: "ChestCoolerType", label: "Orientation Type", type: 'string', width: 200 },
        { field: "Shelves", label: "Number of Shelves", type: 'number', width: 200 },
        { field: "Columns", label: "Number of Columns", type: 'number', width: 200 },
        { field: "DoorHandleLocationType", label: "Door Handle Location", type: 'string', width: 200 }
    ]
});

export default assetTypesListModel;