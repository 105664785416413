const surveyDataReports = {
    title: 'Survey Data Export',
    defaultSort: 'SurveyDateTime desc',
    controller: "SurveyData",
    columns: [
        { field: "SurveyId", type: 'number', headerName: 'SurveyId', width: 80 },
        { field: "SurveyName", type: 'string', headerName: 'SurveyName', width: 200 },
        { field: "Questionnaires", type: 'string', headerName: 'Questionnaires', width: 200 },
        { field: "SurveyCategory", type: 'string', headerName: 'SurveyCategory', width: 200 },
        { field: "OutletName", type: 'string', headerName: 'OutletName', width: 200 },
        { field: "OutletCode", type: 'string', headerName: 'OutletCode', width: 100 },
        { field: "Latitude", headerName: 'Latitude', width: 80, type: "string" },
        { field: "Longitude", headerName: 'Longitude', width: 80, type: "string" },
        { field: "SectionId", type: 'string', type: 'string', headerName: 'SectionId', width: 100 },
        { field: "SectionTitle", type: 'string', headerName: 'SectionTitle', width: 300 },
        { field: "QuestionId", type: 'string', headerName: 'QuestionId', width: 150 },
        { field: "QuestionText", type: 'string', headerName: 'QuestionText', width: 500 },
        { field: "AnswerKey", type: 'string', headerName: 'AnswerKey', width: 80 },
        { field: "Answer", type: 'string', headerName: 'Answer', width: 200 },
        { field: "Attachment", type: 'string', headerName: 'Attachment', width: 750 },
        { field: "SurveyDateTime", type: "dateTime", headerName: "SurveyDateTime", width: 200 },
        { field: "User", type: 'string', headerName: "User", width: 200 }
    ]
};

export default surveyDataReports;
