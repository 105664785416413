import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import actions from '../redux/actions';
let pendingRequests = 0;
const transport = axios.create({ withCredentials: true });

const HTTP_STATUS_CODES = {
    OK: 200,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

const getFormData = (props) => {
    let formData = new FormData();
    for (let key in props) {
        if (typeof props[key] == "object") {
            formData.append(key, JSON.stringify(props[key]));
        } else {
            formData.append(key, props[key]);
        }
    }
    return formData;
}

const exportRequest = (url, query) => {
    const newURL = new URL(url);
    for (let key in query) {
        const value = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
        newURL.searchParams.append(key, value);
    }
    window.open(newURL, '_blank').focus();
}

const request = async ({ url, params = {}, history, dispatch, jsonPayload = false, additionalParams = {}, additionalHeaders = {}, disableLoader = false }) => {

    if (params.exportData) {
        return exportRequest(url, params);
    }
    if (!disableLoader) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
    }
    pendingRequests++;
    let reqParams = {
        method: 'POST',
        credentials: 'include',
        url: url,
        headers: jsonPayload ? { ...additionalHeaders } : { 'Content-Type': 'multipart/form-data', ...additionalHeaders },
        ...additionalParams
    };
    if (params) {
        reqParams.data = jsonPayload ? params : getFormData(params);
    }

    let returnValue;
    let redirectToLogin = false;
    let responseTitle;
    try {
        const response = await transport(reqParams);
        returnValue = response.data;
        if (response.status === HTTP_STATUS_CODES.OK && response.data.success === false && response.data.info === 'Session has expired!') {
            redirectToLogin = true;
            responseTitle = response.data.info;
        }
    } catch (ex) {
        const statusCode = ex?.response?.status;
        if (statusCode === 401) {
            redirectToLogin = true;
        } else if (statusCode === 500) {
            swal({
                title: ex?.response?.data?.info,
                text: "",
                icon: "error",
                dangerMode: true
            });
        } else {
            console.error(ex);
            returnValue = { error: ex.response };
        }
    }
    if (redirectToLogin) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
        dispatch({ type: actions.SET_USER_DATA, userData: {} });
        swal({
            title: responseTitle,
            text: "You're not authorized to access this page",
            icon: "error",
            dangerMode: true
        });
        // todo: this should really be a refresh
        pendingRequests = 0;
        history.push('/login');
        return;
    }
    pendingRequests = Math.max(0, pendingRequests - 1);
    if (pendingRequests === 0) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
    }

    return returnValue;
}

export {
    HTTP_STATUS_CODES,
    transport
};

export default request;