import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import resources from "./locales.json";

const options = {
    // order and from where user language should be detected
    order: [ 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  
    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
  
    // cache user language on
    caches: ['localStorage', 'cookie'],
    //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  
    // optional expire and domain for set cookie
    cookieMinutes: 240,
    //cookieDomain: 'myDomain',
    cookieOptions: { path: '/', sameSite: 'strict' }
  }
 
let transformedResources = {}
//get all keys to lowercase
for (let keys in resources) {
    transformedResources[keys] = { "translations": {}}
    for (let key in resources[keys]?.translations) {
        transformedResources[keys]["translations"][key.toString().toLowerCase()] = resources[keys]?.translations[key];
    }
}

i18n.use(detector).use(initReactI18next).init({
    detection: options,
    resources: transformedResources,
    ns:["translations"],
    fallbackLng :["en"],
    interpolation: {
        escapeValue:false
    }
})

export default i18n;

