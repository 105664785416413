import UiModel from './ui-model';

const assetListModel = new UiModel({
    title: "Master Data",
    titleDescription: "Check how your master data is set",
    defaultSort: 'SerialNumber ASC',
    idProperty: 'SerialNumber',
    readOnly: true,
    api: 'Asset',
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookMasterAsset',
    columns: [
        { field: "SerialNumber", label: "Serial Number", type: 'string', width: 250 },
        { field: "AssetType", label: "Asset Type", type: 'string', width: 250 },
        { field: "LocationCode", label: "Outlet Code", type: 'string', width: 240 },
        { field: "MDMSerialNumber", label: "Modem", type: 'string', width: 200 },
        { field: "SmartDeviceSerialNumber", label: "Camera", type: 'string', width: 250 },
        { field: "PlanogramName", label: "Planogram", type: 'string', width: 250 },
        { field: "ParentAssetSerialNumber", label: "Parent Asset Serial Number", type: 'string', width: 250 },
        { field: "ExternalAssetCode", label: "External Code", type: 'string', width: 150 }
    ]
});

export default assetListModel;