import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';

const CombinedChart = ({ title, subtitle, series, xLabel, yLabel, align, useCount }) => {
    CommonChartsConfig();
    const chartColors = ['#172EB5', '#5C71EA', '#FF8A00', '#FFB966', '#35A35C', '#9ADFB3', '#C489DB', '#D7B0E7', '#FA7CB6'];
    const { barSeriesData, lineSeriesData } = series;
    const updatedBarSeries = barSeriesData?.map(s => ({ ...s, type: 'column', colorByPoint: true })) || [];
    const updatedLineSeries = lineSeriesData?.map((s, index) => ({ ...s, type: 'line', color: chartColors[index % chartColors.length] })) || [];
    const updatedSeries = [...updatedBarSeries, ...updatedLineSeries];

    const commonPlotOptions = {
        dataLabels: {
            enabled: true,
            formatter: function () {
                const percentage = this?.point?.percentage;
                const formattedPercentage = percentage !== undefined ? percentage?.toFixed(2) : 'N/A';
                return `<div style="display: flex; flex-direction: column; justify-content: flex-start; margin-left: 4px;">
                            <span style="margin-left: 10px"> ${useCount ? `${this.y} (${formattedPercentage}%)` : `${this.y.toFixed(2)}%`}  </span>
                        </div>`;
            },
            useHTML: true,
            fontWeight: 'normal',
            allowOverlap: false
        }
    };
    const options = {
        chart: {
            zoomType: 'xy',
            inverted: true,
        },
        title: {
            text: title,
            align: align,
            style: {
                fontWeight: 'bold'
            }
        },
        subtitle: {
            text: subtitle,
            align: align,
        },
        xAxis: {
            type: 'category',
            title: {
                text: xLabel,
            },
            crosshair: true,
        },
        yAxis:
        {
            min: 0,
            max: 100,
            title: {
                text: yLabel,
            },
        },
        tooltip: {
            headerFormat: '<span >{point.key}</span><table>',
            pointFormat: '<tr><td style="padding:0">{series.name}: </td>' +
                '<td style="padding:0">{point.y:.2f}</td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
            positioner: function (labelWidth, labelHeight, point) {
                var tooltipX, tooltipY;
                if (this.chart.plotWidth + this.chart.plotLeft - point.plotX < labelWidth) {
                    tooltipX = point.plotX + this.chart.plotLeft - labelWidth - 20;
                } else {
                    tooltipX = point.plotX + this.chart.plotLeft + 100;
                }
                tooltipY = point.plotY + this.chart.plotTop - 20;
                return {
                    x: tooltipX.toFixed(2),
                    y: tooltipY.toFixed(2)
                };
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
        },
        series: updatedSeries,
        colors: chartColors,
        credits: {
            enabled: false,
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom',
                        },
                    },
                },
            ],
        },
        plotOptions: {
            column: commonPlotOptions
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CombinedChart;
