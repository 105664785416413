
import React from 'react';
import { Popover } from '@material-ui/core';
import dateUtil from '../../utils/date';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';
const t = utils.t;
const SocialShare = ({ data: { StitchedImageURL = '', AssetSerialNumber = '', Children = null, PurityDateTime = '', LocationName = '' }, anchorEl, closeShareLink, t: translate , i18n }) => {
    if (PurityDateTime) {
        PurityDateTime = dateUtil.dateMatch(PurityDateTime)
    }
    const hasChild = Children && Children.length ? true : false;
    let child_imageURL = '', child_AssetSerialNumber, child_purityDateTime;
    const tOpts = { t: translate, i18n };
    if (hasChild) {
        child_imageURL = (Children && Children[0].StitchedImageURL);
        child_AssetSerialNumber = Children && Children[0].AssetSerialNumber;
        child_purityDateTime = Children && Children[0].PurityDateTime ? dateUtil.dateMatch(Children && Children[0].PurityDateTime) : null;
    }

    const onShare = (id) => {
        let range = document.createRange();
        range.selectNode(document.getElementById(id));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    let mailto;
    if (child_imageURL) {
        mailto = `Hi, here is link %0D%0D ${AssetSerialNumber} - ${PurityDateTime} %0D ${encodeURIComponent(StitchedImageURL)} %0D%0D ${child_AssetSerialNumber} - ${child_purityDateTime} %0D ${encodeURIComponent(child_imageURL)}%0D`
    } else {
        mailto = `Hi, here is link %0D%0D ${AssetSerialNumber} - ${PurityDateTime} %0D ${encodeURIComponent(StitchedImageURL)} %0D`
    }

    return <>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={closeShareLink}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="share-wrapper">
                <div class="social-close-button" onClick={closeShareLink}>
                    <svg id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></svg>
                </div>
                <div className="social-share-link">
                    <a className="social-link" href={`mailto:?subject=${LocationName}&body=${mailto}`}>
                        <svg id="email" viewBox="0 0 24 24" fill="#777" stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        <span>Email</span>
                    </a>
                </div>

                <div className="link">
                    <div id="pen-url-one" className="pen-url">{decodeURIComponent(StitchedImageURL)}</div>
                    <button className="copy-link" style={{ width: '190px' }} onClick={() => onShare('pen-url-one')}>{t("Copy Link",tOpts)}</button>

                </div>
                {child_imageURL && <div className="link">
                    <div id="pen-url-two" className="pen-url">{decodeURIComponent(child_imageURL)}</div>
                    <button className="copy-link" style={{ width: '190px' }} onClick={() => onShare('pen-url-two')}>{t("Copy Link",tOpts)}</button></div>
                }
            </div>


        </Popover>
    </>
}

export default withTranslation()(SocialShare);