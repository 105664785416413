import React, { useMemo, useState ,useCallback } from 'react';
import { Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import date from '../../utils/date';
import clsx from 'clsx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useHistory } from 'react-router-dom';
import actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { request, apis } from "../../httpUtil";
import toast from 'react-hot-toast';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';
const t = utils.t;
dayjs.extend(utc);
dayjs.extend(localizedFormat);

const VistaGoodBad = React.memo(function VistaGoodBad({ data: { RowNumber = '', FirstName = 'N/A', LastName = '', ImageMarkedOn = 'N/A', AssetPurityId, ImageSeen = 0 }, t: translate, i18n }) {

    const dispatch = useDispatch(), history = useHistory();

    let userData = useSelector(state => state.appReducer.userData);
    let { FirstName: updateFirstName, LastName: updateLastName } = userData && userData.tags ? userData.tags : "";
    const tOpts = { t: translate, i18n };
    const [imageActionValue, setImageActionValue] = useState(ImageSeen);
    const updateRecord = useCallback(async (AssetPurityId, imageAction) => {
        if (AssetPurityId > -1 && imageAction) {
            const params = { action: 'UpdateImageSeen', otherAction: 'UpdateImageSeen', imageAction, assetPurityId: Number(AssetPurityId) };
            const response = await request({ url: apis.assetLatestImages2, params, history, dispatch });
            if (response && response.success) {
                setImageActionValue(imageAction)
                dispatch({ type: actions.SET_VISTA_DATA_RECORD, vistaData: { key: 'ImageSeen', RowNumber: RowNumber, value: imageAction, extra: { FirstName: updateFirstName || '', LastName: updateLastName || '', ImageMarkedOn: dayjs.utc().format("YYYYMMDDHHmmss000") } } })
                toast(response.data);
            } else {
                toast(t("Something went wrong, Please try again later.", tOpts));
            }
        }
    }, [updateFirstName, updateLastName, RowNumber, dispatch, history])

    //memorized function
    
    const memorizedGood = useMemo(() => <Button onClick={() => imageActionValue !== 1 ? updateRecord(AssetPurityId, 1) : null} className={clsx('card-switch', { 'card-good': (imageActionValue === 1) })}>{t("Good", tOpts)}</Button>, [AssetPurityId, ImageSeen, imageActionValue ,updateRecord]);
    const memorizedBad = useMemo(() => <Button onClick={() => imageActionValue !== 2 ? updateRecord(AssetPurityId, 2) : null} className={clsx('card-switch', { 'card-bad': (imageActionValue === 2) })}>{t("Bad", tOpts)}</Button>, [AssetPurityId, ImageSeen, imageActionValue ,updateRecord]);



    let isNotReviewed = false, imageMarkedOn = ImageMarkedOn ? date.DateLocalizer({ value: ImageMarkedOn }) : ImageMarkedOn;
    ImageSeen === 0 && (isNotReviewed = true);

    return <Grid container wrap="nowrap" className="vista-photo-taken-wrapper" >
        <Grid item xs>
            <ButtonGroup className="switch-group text-center" variant="contained" aria-label="contained primary button group">
                {memorizedGood}
                {memorizedBad}
            </ButtonGroup>
            <Typography className="username">{isNotReviewed ? t('Not Yet Reviewed', tOpts) : `${(FirstName) || updateFirstName} ${LastName || updateLastName}, ${imageMarkedOn || dayjs().format('lll')}`}</Typography>
        </Grid>
    </Grid>
}, ({ data: prvData }, { data: nextData }) => {
    return prvData.AssetPurityId === nextData.AssetPurityId
        && prvData.ImageSeen === nextData.ImageSeen
        && prvData.RowNumber === nextData.RowNumber
        && prvData.FirstName === nextData.FirstName
        && prvData.LastName === nextData.LastName
}
)

export default withTranslation()(VistaGoodBad);