import React from "react";
const ZOOM_LEVEL = { MIN: 0, MAX: 4 };
const OFFSET_DEFAULT = { x: 0, y: 0 };

function getDistance(firstPoint, secondPoint) {
  return Math.sqrt(
    Math.pow(firstPoint.pageX - secondPoint.pageX, 2) +
    Math.pow(firstPoint.pageY - secondPoint.pageY, 2),
  );
};

function getCurrentDistance(event) {
  return getDistance(event.touches[0], event.touches[1]);
}

class ImageWrapper extends React.Component {
  imageOuter;
  image;
  draggable;
  src;
  clientOffset;
  offsetRange;
  mounted;

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      onload: false,
      zoom: 0,
      offset: OFFSET_DEFAULT,
      pinchDistance: 0
    };
    this.draggable = false;
    this.offsetRange = OFFSET_DEFAULT;
    this.clientOffset = {
      x: undefined,
      y: undefined
    };
  }

  loadImage(src) {
    this.state.loading = true;
    this.setState(this.state);

    this.src = new Image();
    this.src.src = src;
    this.src.onload = () => {
      if (!this.src) return;
      this.state.loading = false;
      this.state.onload = true;
      this.setState(this.state);
    };
    this.src.onerror = () => {
      if (!this.src) return;
      this.state.loading = false;
      this.state.onload = false;
      this.setState(this.state);
    };
  }

  resetOffset() {
    this.state.offset = OFFSET_DEFAULT;
    this.setState(this.state);
  }

  setOffsetRange() { // todo 
    const zoom = this.state.zoom;
    if (this && this.image) {
      const dx =
        this.image.scrollWidth * (1 + zoom / 2) - this.imageOuter.clientWidth;
      const dy =
        this.image.scrollHeight * (1 + zoom / 2) - this.imageOuter.clientHeight;
      this.offsetRange = {
        x: Math.max(0, dx / 2),
        y: Math.max(0, dy / 2)
      };
    }
  }

  zoomIn() {
    if (!this.state.onload) return;
    this.state.zoom = Math.min(this.state.zoom + 1, ZOOM_LEVEL.MAX);
    this.setState(this.state);
    this.setOffsetRange();
  }

  zoomOut() {
    if (!this.state.onload) return;
    this.state.zoom = Math.max(0, this.state.zoom - 1);
    this.setState(this.state);
    this.resetOffset();
    this.setOffsetRange();
  }

  onMoveStart(e) {

    if (e.touches && e.touches.length === 2) {
      const currentDistance = getCurrentDistance(e);
      this.setState({ pinchDistance: currentDistance })
      return;
    }

    if (!this.offsetRange.x && !this.offsetRange.y) {
      return;
    }

    let touch = e.touches && e.touches[0];
    let x = e.clientX || touch.pageX;
    let y = e.clientY || touch.pageY;

    this.clientOffset = { x, y };
    this.draggable = true;
  }



  handleZoomPinch(e) {
    let { pinchDistance, zoom } = this.state;
    const currentDistance = getCurrentDistance(e);
    this.setState({ pinchDistance: currentDistance }, () => {
      if (currentDistance > pinchDistance && zoom < ZOOM_LEVEL.MAX) {
        this.zoomIn();
      }
      else if (currentDistance < pinchDistance && zoom > ZOOM_LEVEL.MIN) {
        this.zoomOut();
      }
    })
  }

  onMove(e) {
    if (e.touches && e.touches.length === 2) {
      this.handleZoomPinch(e)
      return
    }

    let touch = e.touches && e.touches[0];
    let x = e.clientX || touch.pageX;
    let y = e.clientY || touch.pageY;
    if ((!x && !y) || !this.draggable) {
      return;
    }

    const offset = {
      x: x - this.clientOffset.x,
      y: y - this.clientOffset.y
    };

    this.clientOffset = { x, y };

    this.state.offset = {
      x: this.state.offset.x + offset.x,
      y: this.state.offset.y + offset.y
    };
    this.setState(this.state);
  }

  onMoveEnd(e) {
    if (!this.mounted) return;
    this.draggable = false;
    const offset = {
      x: Math.abs(this.state.offset.x),
      y: Math.abs(this.state.offset.y)
    };

    if (Math.abs(this.state.offset.x) >= this.offsetRange.x) {
      this.state.offset.x =
        this.state.offset.x < 0
          ? Math.min(0, -this.offsetRange.x)
          : Math.max(0, this.offsetRange.x);
      this.setState(this.state);
    }

    if (Math.abs(this.state.offset.y) >= this.offsetRange.y) {
      this.state.offset.y =
        this.state.offset.y < 0
          ? Math.min(0, -this.offsetRange.y)
          : Math.max(0, this.offsetRange.y);
      this.setState(this.state);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.image.src != nextProps.image.src) {
      this.resetOffset();
      this.loadImage(nextProps.image.src);
      this.setState({
        zoom: 0
      });
    }
  }

  componentDidMount() {
    this.mounted = true;
    this.loadImage(this.props.image.src);
    window.addEventListener("resize", this.setOffsetRange.bind(this));
    document.documentElement.addEventListener(
      "mouseup",
      this.onMoveEnd.bind(this)
    );
  }

  componentWillUnmount() {
    this.mounted = false;
    if (!!this.src) {
      this.src = undefined;
    }
    window.removeEventListener("resize", this.setOffsetRange.bind(this));
    document.documentElement.removeEventListener(
      "mouseup",
      this.onMoveEnd.bind(this)
    );
  }

  render() {
    const { image, index, showIndex } = this.props;

    const { offset, zoom, loading } = this.state;

    const value = `translate3d(${offset.x}px, ${offset.y}px, 0px)`;
    const imageCls = `zoom-${zoom} image-outer ${this.draggable ? "dragging" : ""}`;

    const caption = (
      <p className="caption">
        {image.title ? <span className="title">{image.title}</span> : null}
        {image.title && image.content ? <span>{` - `}</span> : null}
        {image.title ? <span className="content">{image.content}</span> : null}
      </p>
    );

    return (
      <div className="image-wrapper" >
        <div
          style={{ transform: value }}
          ref={component => (this.imageOuter = component)}
          className={imageCls}
        >
          {loading ? (
            <div className="spinner">
              <div className="bounce" />
            </div>
          ) : (
            <img
              className="image"
              ref={(component) => (this.image = component)}
              src={image.src}
              alt={image.title || ""}
              draggable={true}
              onDragStart={(e) => e.preventDefault()}
              onMouseMove={this.onMove.bind(this)}
              onMouseDown={this.onMoveStart.bind(this)}
              onMouseUp={this.onMoveEnd.bind(this)}
              onTouchStart={this.onMoveStart.bind(this)}
              onTouchEnd={this.onMoveEnd.bind(this)}
              onTouchMove={this.onMove.bind(this)}
            />
          )}
        </div>
        <div className="tool-bar">
          {caption}
          <div className="button-group"> <div className="zoom-out button" onClick={this.zoomOut.bind(this)} />
            <div className="zoom-in button" onClick={this.zoomIn.bind(this)} />
          </div>
          <p className="img-title">{image.imageTitle || ''}<span className="pl-2"><small> {image.timeStamp ? `(${image.timeStamp})` : ''}</small></span></p>

        </div>
      </div >
    );
  }
}

export default ImageWrapper;
