import UiModel from './ui-model'
import Location from './Location';
import User from './ClientUser';

const surveyReportsModel = new UiModel({
    title: "Surveys",
    defaultSort: 'SurveyName ASC',
    linkColumn: 'SurveyName',
    api: 'SurveyMaster',
    module: "SurveyAnalysis",
    rowRedirectLink: "/Survey/Analysis/${SurveyMasterId}",
    pageTitle: "Results Analysis Overview",
    readOnly: true,
    limitToSurveyed: true,
    overrideFileName: 'Results Analysis',
    titleDescription: "Review survey results with options to filter, aggregate, export and more",
    columns: [
        { field: "SurveyMasterId", type: 'number', hide: true, width: 80, label: "Survey Id", fieldLabel: null },
        { field: "SurveyName", type: "string", label: "Name", width: 240, pinned: true, tab: "schedule", required: true, min: 5, max: 50, requiredIfNew: true, linkTo: "/Survey/Analysis/${SurveyMasterId}" },
        {
            field: "StartDate", type: "date", label: "Start Date", width: 100, tab: "schedule", disablePast: true, showErrorText: true
        },
        {
            field: "EndDate", type: "date", label: "End Date", width: 100, tab: "schedule", disablePast: true, shouldDisableDate: (date, formik) => formik.values['StartDate'] && date < formik.values['StartDate']

        },
        { field: "SurveyStatus", type: "boolean", label: "Active", width: 80, tab: "schedule", defaultValue: true },
        { field: "LocationMembers", type: "oneToMany", label: "Outlets", width: 80, relation: Location.ChildGrid, countInList: true, tab: "outlets", filterable: false, sortable: false, showLabel: false },
        { field: "UserMembers", type: "oneToMany", label: "Users", width: 80, relation: User.ChildGrid, countInList: true, tab: "users", filterable: false, sortable: false, showLabel: false },
    ]
});

export default surveyReportsModel;