import React, { memo, useMemo, useCallback } from 'react';
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useMedia } from 'the-platform';
import { withTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";
import utils from '../../utils';
const t = utils.t;

const RedCheckbox = withStyles({
    root: { color: '#fff', '&$checked': { color: '#fff' },
            display:'flex',
            padding:'0 5px'
        },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const NeedsAttention = () => {
    const dispatch = useDispatch();
    let vistaData = useSelector(state => state.appReducer.vistaData, shallowEqual);
    const smallDevice = useMedia('(max-width: 959px)');
    let vistaNeedsAttention = useSelector(state => state.appReducer.vistaNeedsAttention, shallowEqual);
    const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n };
    const setNeedsAttentionCheckFun = () => dispatch({ type: 'SET_NEED_ATTENTION', vistaNeedsAttention: !vistaNeedsAttention });

    const memorizedNeedsAttention = useMemo(() => <FormControlLabel
        className="filter-check-box"
        control={
            <RedCheckbox
                checked={vistaNeedsAttention}
                onChange={setNeedsAttentionCheckFun}
                name="sorting"
                color="primary"
            />
        }
        label={`${vistaData ? vistaData.NeedAttentionCount : 0} ${smallDevice ? t('Alert',tOpts) : t('Needs Attention',tOpts)}`}
    />, [vistaData, setNeedsAttentionCheckFun]);

    return <Box className="mr-2 d-flex align-items-start justify-content-between w-100">
        <div className="vista-search-attention background-off-red w-100">
            {memorizedNeedsAttention}
        </div>
    </Box>
}

export default withTranslation()(NeedsAttention);