import React, { useState, useEffect } from "react";
import actions from "../../redux/actions";
import PageTitle from "../PageTitle";
import { useTranslation } from 'react-i18next';
import utils from "../../utils";
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Tabs, Tab } from "@material-ui/core";
import FormGenerator from "../FormGenerator";
import enums from '../../utils/constants'
import AlertSettings from "./AlertSettings";
import useMobile from "../../utils/useMobile";
const { childRoutes, temperatureCombo } = enums;
const { t } = utils;

const tabList = [
  { icon: "system.png", iconSize: '30', iconPosition: "start", label: "System", disabled: false }
];

const formConfig = [
  {
    active: true,
    mapping: [{
      heading: "Basic Info",
      active: true,
      felids: [
        { name: 'ClientTypeId', label: 'Status', type: "select", comboKey: 'ClientType' },
        { name: 'LanguageId', label: 'Language', type: "select", comboKey: 'Language' },
        { name: 'DateFormatId', label: 'Date Format', type: "select", comboKey: 'DateFormat' },
        { name: 'TempratureUnitId', label: 'Temperature Unit', type: "select", comboKey: 'TemperatureCombo' },
        { name: 'CurrencyId', label: 'Currency', type: "select", comboKey: 'RevenueCurrency' },
        { name: 'SearchUnitId', label: 'Measure Unit', type: "select", comboKey: 'DistanceUnit' },
        { name: 'CountryId', label: 'Country', type: "select", comboKey: 'Country' },
        { name: 'TimeZoneId', label: 'Timezone', type: "select", comboKey: 'TimeZone' },
        { name: 'BusinessOpeningTime', label: 'Business Hours From', type: "date-time" },
        { name: 'BusinessClosingTime', label: 'Business Hours To', type: "date-time" }
      ]
    }]
  }
]

const comboArray = ["ClientType", "Language", "DateFormat", "RevenueCurrency", "DistanceUnit", "Country", "TimeZone"];

function Settings() {
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const userData = useSelector(state => state.appReducer.userData);
  const clientId = userData && userData.tags ? userData.tags.ClientId : 0;
  const moduleName = childRoutes.DataAndSettings.General.MasterData.moduleName;
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMobile()
  const childPages = childRoutes.DataAndSettings;

  let titleDescription, title;
  Object.keys(childPages).map(key => {
    let values = Object.values(childPages[key])
    values.map(value => {
      if (value.page === "Settings") {
        titleDescription = "Your Project's Fundamental Settings";
        title = "Settings";
      }
    })
  })
  useEffect(() => {
    dispatch({
      type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
        filterButton: null,
        hidden: { search: true, operation: true, export: true, print: true, filter: true }
      },
    });
  
    dispatch({
      type: actions.SET_PAGE_TITLE_DETAILS, // Corrected the typo in the action type
      pageTitleDetails: (
        <PageTitle
          titleHeading={t(title, tOpts)}
          titleDescription={t(titleDescription, tOpts)}
          icon=""
          title={t(title, tOpts)}
        />
      ),
    });

    return () => {
      dispatch({
        type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
          filterButton: null,
          clear: null,
          apply: null,
          hidden: { search: false, operation: false, export: false, print: false, filter: false }
        }
      });
    }
  }, []);

  function handleChangeTab(event, newValue) {
    setActiveTab(newValue);
  };

  // System tab component
  const systemComponent = () => {
    return <FormGenerator
      formConfig={formConfig}
      moduleName={moduleName}
      id={clientId}
      api={'SystemUpdate'}
      comboArray={comboArray}
      localComboData={{ "TemperatureCombo": temperatureCombo }}
      isEditEnabled={true}
    />
  }

  // Asset tab component
  const imageComponent = () => {
    return <>{t('Under Development', tOpts)}</>
  }

  // assetType tab component
  const deliveryComponent = () => {
    return <>{t('Under Development', tOpts)}</>
  }

  //alert tab component
  const alertComponent = () => {
    return <AlertSettings moduleName={moduleName} />
  }

  return <>
    <Card className={` mb-1 p-0  ${!isMobile.mobile ? "mt-2 " : (isMobile.portrait ? "mt-4" : "mt-2")}`} >
      <CardContent className="m-0 p-0">
        <Tabs value={activeTab} onChange={handleChangeTab} centered variant="fullWidth">
          {tabList.map(tab => {
            return <Tab
              icon={<img src={require(`../../assets/images/${tab.icon}`)} height={tab.iconSize} alt="" />}
              iconPosition={tab.iconPosition}
              label={t(tab.label, tOpts)}
              disabled={tab.disabled} />
          })}
        </Tabs>
      </CardContent>
    </Card>

    <Card className="mt-2 section-to-print">
      <CardContent>
        {activeTab === 0 ? systemComponent() :
          activeTab === 1 ? alertComponent() :
            activeTab === 2 ? deliveryComponent() :
              activeTab === 3 ? imageComponent() : ""}
      </CardContent>
    </Card>
  </>
}

export default Settings;