import React, { useEffect, useState, useRef } from 'react'
import {
    Grid, Button, TextField, Autocomplete, Typography, Card, CardContent
} from '@mui/material';
import { apis, request } from "../../httpUtil";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import utils from "../../utils";
import useMobile from '../../utils/useMobile';
const { t, deepCloneObject } = utils;

function AlertSettings(props) {

    const { moduleName } = props;
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const isMobile = useMobile();
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = useSelector(state => state.appReducer.userData);
    const { ClientId } = userData && userData.tags ? userData.tags : 0;
    const isEditable = userData?.modules[moduleName]?.Edit;
    const [alertList, setAlertList] = useState([]);
    const [originalAlertList, setOriginalAlertList] = useState([]);
    const [editModeOn, setEditModeOn] = useState(false);
    const [comboList, setComboList] = useState([]);
    const [ownerType, setOwnerType] = useState({ 'Individual': '', 'Role': '' });
    const editModeOnRef = useRef(false);

    useEffect(() => {
        loadAlertConfig()
        loadCombo()
    }, []);

    async function loadAlertConfig() {
        const params = { action: 'list', asArray: 0, ClientId: ClientId }
        const result = await request({ url: apis.SystemAlertConfig, params, history, dispatch });
        if (result.records) {
            setAlertList(result.records)
            setOriginalAlertList(deepCloneObject(result.records))
        }
    }

    async function loadCombo() {
        let data = await request({
            url: apis.Asset, params: {
                action: "load", comboTypes: [
                    { "type": "ClientUserType", "loaded": true },
                    { "type": "NotificationContactType", "loaded": true },
                    { "type": "Role", "loaded": true }]
            }, history, dispatch,
        });
        if (data.combos) {
            const comboObj = data.combos;
            comboObj.NotificationContactType.unshift({ LookupId: -1, DisplayValue: "Blank" });
            comboObj.Role = comboObj.Role.filter((item) => item.DisplayValue === 'Sales Rep');
            comboObj.Role.unshift({ LookupId: -1, DisplayValue: "Blank" });

            const ownerTypeL = { ...ownerType };
            for (const key in ownerTypeL) {
                const value = comboObj.NotificationContactType.filter((item) => item.DisplayValue === key);
                ownerTypeL[key] = value[0].LookupId;
            }
            setOwnerType(ownerTypeL);
            setComboList(comboObj);
        }
    }

    function handleEditMode() {
        setEditModeOn(true);
        editModeOnRef.current = true;
    }

    function handleCancel() {
        setAlertList(deepCloneObject(originalAlertList));
        setEditModeOn(false);
        editModeOnRef.current = false;
    }

    function validateData(data) {
        let isError = false;
        for (const record of alertList) {
            if (record.SLA < 0 || record.SLA > 672) {
                record.SLAError = 'Error, Min:0/Max:672';
                isError = true;
            }
        }
        const newList = [...alertList];
        setAlertList(newList);
        return isError;
    }

    async function handleConfirm() {

        const errorFound = validateData(alertList)
        if (errorFound) {
            return;
        }

        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        for (const record of alertList) {
            if (record.updated) {
                const params = {
                    action: "save",
                    id: record.SystemAlertConfigId,
                    AlertTypeId: record.AlertTypeId,
                    SLA: record.SLA,
                    OwnerTypeId: record.OwnerTypeId,
                    RoleId: record.OwnerTypeId === ownerType.Role ? record.RoleId : 0,
                    OwnerId: record.OwnerTypeId === ownerType.Individual ? record.OwnerId : 0,
                    ManagerId: record.ManagerId,
                };
                await request({ url: apis.SystemAlertConfig, params, history, dispatch, disableLoader: true });
            }
        }
        loadAlertConfig()
        setEditModeOn(false);
        editModeOnRef.current = false;
    }


    function handleChange(e, selectVal, type, key, index, alertTypeId) {
        const re = /^[0-9\b]+$/;
        if (type === 'number' && !re.test(e.target.value) && e.target.value !== '') {
            e.preventDefault()
            return;
        }
        const alertListL = [...alertList];
        if (alertListL[index].AlertTypeId === alertTypeId) {
            alertListL[index][key] = type === 'select' ? selectVal.LookupId : e.target.value;
            alertListL[index]['updated'] = true;
            setAlertList(alertListL);
        }
    }

    function fieldGenerator(props) {
        const { inputType, key, value, error, index, alertTypeId, lookup = [] } = props;
        let jsxComponent = '';
        switch (inputType) {
            case 'text':
            case 'number':
                jsxComponent = <div> <TextField
                    key={inputType + '-' + index}
                    name={key}
                    value={value}
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={(e) => handleChange(e, null, inputType, key, index, alertTypeId)} />
                    {error && <p className='mb-0 pb-0 text-danger'>{t(error, tOpts)}</p>}
                </div>
                break;
            case 'select':
                jsxComponent = <Autocomplete
                    key={inputType + '-' + index}
                    value={lookup.filter((item) => Number(item.LookupId) === Number(value))[0]}
                    getOptionLabel={(option) => option.DisplayValue}
                    options={lookup}
                    size="small"
                    fullWidth={true}
                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                    onChange={(e, newVal) => handleChange(e, newVal, inputType, key, index, alertTypeId)} />
                break;
            default:
                jsxComponent = <></>;
        }
        return jsxComponent;
    }


    return (
        <div>
            <Card >
                <CardContent className='pl-2 pr-2 pt-1 pb-0'>
                    <Grid container spacing={1} className='pl-2 pr-2 pt-2 pb-3'>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <Typography variant={`${isMobile.mobile ? "subtitle2 " : "subtitle1"} fw-500`} >{t('Alert', tOpts)}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Typography variant={`${isMobile.mobile ? "subtitle2" : "subtitle1"} fw-500`} >{t('SLA', tOpts)}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Typography variant={`${isMobile.mobile ? "subtitle2" : "subtitle1"} fw-500`} >{t('Manager', tOpts)}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Typography variant={`${isMobile.mobile ? "subtitle2" : "subtitle1"} fw-500`} >{t('Owner Type', tOpts)}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                            <Typography variant={`${isMobile.mobile ? "subtitle2" : "subtitle1"} fw-500`} >{t('Owner/Role', tOpts)}</Typography>
                        </Grid>
                    </Grid>

                    {(editModeOn ? alertList : originalAlertList).map((alertItem, index) => {
                        return (<>
                            <Grid container spacing={1} className='pl-2 pr-2 pt-2 pb-3 bt-g-1'>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <p className={`${editModeOn ? 'mt-2' : ''} mb-0 font-italic fw-500`} >{alertItem.AlertType || 'N/A'}</p>
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    {editModeOn ?
                                        fieldGenerator({ inputType: 'number', key: 'SLA', value: alertItem.SLA, error: alertItem.SLAError, index, alertTypeId: alertItem.AlertTypeId }) :
                                        <p className={editModeOn ? 'mt-2 mb-0' : 'mb-0'} >{alertItem.SLA || '-'}</p>}
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    {editModeOn ?
                                        fieldGenerator({ inputType: 'select', key: 'ManagerId', value: alertItem.ManagerId, index, alertTypeId: alertItem.AlertTypeId, lookup: comboList.ClientUserType }) :
                                        <p className={editModeOn ? 'mt-2 mb-0' : 'mb-0'} >{alertItem.Manager || '-'}</p>}
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    {editModeOn ?
                                        fieldGenerator({ inputType: 'select', key: 'OwnerTypeId', value: alertItem.OwnerTypeId, index, alertTypeId: alertItem.AlertTypeId, lookup: comboList.NotificationContactType }) :
                                        <p className={editModeOn ? 'mt-2 mb-0' : 'mb-0'} >{alertItem.OwnerType || '-'}</p>}
                                </Grid>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    {editModeOn ?
                                        fieldGenerator({
                                            inputType: 'select',
                                            key: (alertItem.OwnerTypeId === ownerType.Individual ? 'OwnerId' : alertItem.OwnerTypeId === ownerType.Role ? 'RoleId' : "Blank"),
                                            value: (alertItem.OwnerTypeId === ownerType.Individual ? alertItem.OwnerId : alertItem.OwnerTypeId === ownerType.Role ? alertItem.RoleId : -1),
                                            index, alertTypeId: alertItem.AlertTypeId,
                                            lookup: alertItem.OwnerTypeId === ownerType.Individual ? comboList.ClientUserType : alertItem.OwnerTypeId === ownerType.Role ? comboList.Role : [{ LookupId: -1, DisplayValue: "Blank" }]
                                        }) : <p className={editModeOn ? 'mt-2 mb-0' : 'mb-0'} >{alertItem.Owner || '-'}</p>}
                                </Grid>
                            </Grid>
                        </>);
                    })}
                </CardContent>
            </Card>
            <hr />
            <Grid container spacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                    {isEditable ?
                        <Grid justifyContent="flex-end" container spacing={1}>
                            <Grid item>
                                {!editModeOn && <Button variant="contained" size='small' className="background-theme-blue sub-header-button" onClick={handleEditMode}>{t("Edit", tOpts)}</Button>}
                            </Grid>
                            <Grid item>
                                {editModeOn && <Button variant="contained" size='small' className="background-theme-green sub-header-button" onClick={handleConfirm}>{t("Confirm", tOpts)}</Button>}
                            </Grid>
                            <Grid item >
                                {editModeOn && <Button variant="contained" size='small' className="background-theme-red sub-header-button" onClick={handleCancel}>{t("Cancel", tOpts)}</Button>}
                            </Grid>
                        </Grid> : ''}
                </Grid>
            </Grid>

        </div>
    );
}

export default AlertSettings
