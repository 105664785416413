import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'the-platform';
import { useTranslation } from "react-i18next";
import utils from '../../utils';
const t = utils.t;


const CurrentPog = () => {
    const dispatch = useDispatch();
    const smallDevice = useMedia('(max-width: 959px)');
    let currentPog = useSelector(state => state.appReducer.currentPog);
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };

    const setCurrentPogCheckFun = () => {
        dispatch({ type: 'SET_CURRENT_POG', currentPog: !currentPog })
        if (!currentPog) {
            dispatch({ type: 'SET_CURRENT_RANK', currentRank: false })
        }
    }

    const memorizedCurrentPog = useMemo(() =>
        <>
            <input type="checkbox" id="todo" name="todo" checked={currentPog} onChange={setCurrentPogCheckFun} />
            <label className="chk-label" htmlFor="todo" data-content={`${smallDevice ? t('POG',tOpts) : t('Rank by POG',tOpts)}`}>{`${smallDevice ? t('POG',tOpts) : t('Rank by POG',tOpts)}`}</label>
        </>, [currentPog, setCurrentPogCheckFun, t]);

    return <div className={"vista-search-current-rank w-100 background-theme-blue"}>
        {memorizedCurrentPog}
    </div>
}

export default   withTranslation()(CurrentPog);