import React, { useState, useEffect } from 'react';
import { Dialog, Button, Chip, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { request, apis } from "../../httpUtil";
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { TimePicker } from 'antd';
import toast from 'react-hot-toast';
import 'antd/dist/antd.css';


const ScheduleTime = React.memo(function ScheduleTime() {
    const [chipData, setChipData] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const scheduleData = useSelector(state => state.appReducer.scheduleData);
    const openDialog = useSelector(state => state.appReducer.timeSlotDialogState);
    const { SmartDeviceId, TimeSlotSettings, RowNumber } = scheduleData;

    useEffect(() => {
        let timeSlots = TimeSlotSettings ? JSON.parse(TimeSlotSettings) : {};
        let { ImageTimeSlot } = timeSlots;
        if (ImageTimeSlot) {
            let timeSlotArr = ImageTimeSlot && ImageTimeSlot.length > 0 ? ImageTimeSlot.split(",") : [];
            const dataValue = [];
            if (timeSlotArr.length > 0) {
                timeSlotArr.forEach((d, i) => {
                    dataValue.push({ key: i, label: d.toString() })
                })
                setChipData(dataValue);
            }
        }
        return () => {
            setChipData([]);
        }

    }, [TimeSlotSettings])

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const handleAddSchedule = (value) => {
        if (value) {
            dayjs(value).format('HH:mm')
            let chipLength = (chipData && chipData.length) || 0;
            chipData.push({ key: chipLength, label: dayjs(value).format('HH:mm') })
            setChipData([...chipData]);
        }
    }

    const addTimeSlots = async () => {
        let timeSlotArr = [];
        chipData.forEach(d => timeSlotArr.push(d.label))
        let timeSlotString = timeSlotArr && timeSlotArr.length > 0 ? timeSlotArr.join() : '';
        let timeSlotObj = { ImageTimeSlot: timeSlotString, EnableImageTimeSlot: timeSlotString ? true : false };
        const params = { action: 'updateTimeSlots', otherAction: 'updateTimeSlots', timeSlots: JSON.stringify(timeSlotObj), smartDeviceId: Number(SmartDeviceId) }
        const response = await request({ url: apis.Asset, params, history, dispatch });
        if (response && response.success) {
            toast(response.data);
            dispatch({ type: 'SET_VISTA_DATA_RECORD', vistaData: { key: 'TimeSlotSettings', RowNumber: RowNumber, value: JSON.stringify(timeSlotObj) } });
            timeSlotDialogStateClose();
        } else {
            toast("Something went wrong. Please try again later");
        }
    }

    const timeSlotDialogStateClose = () => dispatch({ type: 'SET_TIMESLOT_DIALOG_STATE', timeSlotDialogState: false, scheduleData: {} });

    return <Dialog open={openDialog}>
        <div className=" p-3 schedule-time-wrappr">
            <h4 className="heading" >Schedule Time Slot</h4>
            <Paper className="schedule-slot-input" component="ul" >
                <div className="clip-area text-center">
                    {chipData.map((data) => {
                        let icon;
                        return (
                            <li key={data.key}>
                                <Chip icon={icon} label={data.label} onDelete={handleDelete(data)} />
                            </li>
                        );
                    })}
                </div>
            </Paper>
            <Paper className="schedule-timePicker" component="div" >
                <TimePicker
                    allowClear="true"
                    size="large"
                    format="HH:mm"
                    onChange={handleAddSchedule}
                    suffixIcon={<WatchLaterIcon className="icon-add" />}
                    clearIcon={<HighlightOffIcon className="icon-clear" />}
                />
            </Paper>
            <div className="pt-2 text-center">
                <Button variant="contained" color="secondary" className="m-2" onClick={timeSlotDialogStateClose}>
                    <span className="btn-wrapper--label">CLOSE</span>
                </Button>
                <Button variant="contained" color="primary" className="m-2" onClick={addTimeSlots}>
                    <span className="btn-wrapper--label">SET</span>
                </Button>
            </div>
        </div>
    </Dialog>
})

export default ScheduleTime