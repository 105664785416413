import React from 'react';
import { Grid, Typography, Box, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dateUtil from '../../utils/date';

//icons
import userImage1 from '../../assets/images/avatars/avatar3.png'

const useStyles = makeStyles((theme) => ({ small: { width: theme.spacing(3), height: theme.spacing(3) } }));

const CameraPhotoTakenInformation = React.memo(function CameraPhotoTakenInformation({ data: { RowNumber = '', PurityDateTime = '' } }) {
    const classes = useStyles();
    let purityDateTime = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : 'N/A';
    return <Box className='vista-box-mid mt-3 mb-2'>
        <Grid container wrap="nowrap" spacing={1}>
            <Grid item>
                <Avatar className={classes.small} src={userImage1} />
            </Grid>
            <Grid item xs>
                <Typography variant="body1" gutterBottom className='vista-photo-take'> Tim O'Neil </Typography>
                <Typography variant="body2" gutterBottom className='vista-photo-take'> Serviced on </Typography>
                <Typography variant="h4" gutterBottom>{purityDateTime}</Typography>
            </Grid>
        </Grid>
    </Box>
}, ({ data: prvData }, { data: nextData }) => {
    return (prvData.RowNumber === nextData.RowNumber)
        && (prvData.PurityDateTime === nextData.PurityDateTime)
})

export default CameraPhotoTakenInformation;