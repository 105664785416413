import UiModel from './ui-model'
import User from './ClientUser';

const userGroupModel = new UiModel({
        title: "Create Group",
        defaultSort: 'GroupName ASC',
        linkColumn: 'GroupName',
        api: 'UserGroup',
        module: "UserGroup",
        gridTitle: "Users",
        titleDescription: 'Create and configure groups (clusters) of outlets for use in StoreBuddy',
        pageTitle: "User Groups Overview",
        showAddIcon: false,
        overrideLoaderOnInitialRender: true,
        breadCrumbs: "User Group",
        overrideFileName: 'User Groups',
        columns: [
                { field: "UserGroupId", type: 'number', hide: true, width: 80, label: "Group ID", fieldLabel: null },
                { field: "GroupName", type: "string", label: "Group Name", width: 240, required: true, min: 5, max: 50, requiredIfNew: true, pinned: true, link: true },
                { field: "Description", type: "string", label: "Description", required: false    , width: 300, max: 200 },
                { field: "IsActive", type: "boolean", label: "Active", width: 80, tab: "schedule", defaultValue: true },
                { field: "UserGroupMembers", type: "oneToMany", label: "Users", countInGrid: true, relation: User.ChildGrid, filterable: false, sortable: false, showLabel: false }
        ]
});

export default userGroupModel;