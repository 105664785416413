import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';
import utils from '../../utils';

const PosmChart = ({ title, subtitle, series, xLabel, yLabel, align, useCount }) => {
    CommonChartsConfig();
    const analysisColorCodes = utils.analysisColorCodes;
    const chartColors = [
        analysisColorCodes.blue,
        analysisColorCodes.orange,
        analysisColorCodes.lightOrange,
        analysisColorCodes.green,
        analysisColorCodes.lightGreen,
        analysisColorCodes.pink,
        analysisColorCodes.lightPink,
        analysisColorCodes.purple,
        analysisColorCodes.lightPurple]
    const updatedBarSeries = series?.map(s => ({ ...s, type: 'column', colorByPoint: true })) || [];
    const commonPlotOptions = {
        dataLabels: {
            enabled: true,
            formatter: function () {
                const percentage = this?.point?.percentage;
                const formattedPercentage = percentage !== undefined ? percentage?.toFixed(2) : 'N/A';
                return `<div style="display: flex; flex-direction: column; justify-content: flex-start; margin-left: 2px;">
                            <span style="margin-left: 8px"> ${useCount ? `${this.y} (${formattedPercentage}%)` : `${this.y.toFixed(2)}%`}  </span>
                        </div>`;
            },
            fontWeight: 'normal',
            allowOverlap: false,
            crop: false,
            overflow: 'none'
        }
    };
    const options = {
        chart: {
            zoomType: 'xy',
            inverted: true,
            spacingRight: 40
        },
        title: {
            text: title,
            align: align,
            style: {
                fontWeight: 'bold'
            }
        },
        subtitle: {
            text: subtitle,
            align: align,
        },
        xAxis: {
            type: 'category',
            title: {
                text: xLabel,
            },
            crosshair: true,
        },
        yAxis: [
            {
                title: {
                    text: yLabel,
                },
                allowDecimals: false
            },
        ],
        tooltip: {
            formatter: function () {
                return '<span>' + this.point.name + ' - ' + this.point.y + ' (' + this.point.percentage.toFixed(2) + '%)</span>';
            }
        },
        series: updatedBarSeries,
        colors: chartColors,
        credits: {
            enabled: false,
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    }
                },
            ],
        },
        plotOptions: {
            column: commonPlotOptions
        },
        legend: {
            enabled: false
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PosmChart;
