import React from 'react';
import { List, Typography } from '@material-ui/core';
import SidebarMenuListItem from './SidebarMenuListItem';
import useRouter from '../../utils/useRouter';
import { PageIcon } from '../../utils/Icons';
import useMobile from '../../utils/useMobile';
import { matchPath, useLocation } from 'react-router';

const SidebarMenuList = props => {
  const { pages, modules, ...rest } = props;
  const { pathname } = useLocation();
  const isMobileDevice = useMobile();
  return (
    <List className="p-0"> {pages.reduce((items, page) => reduceChildRoutes({ items, page, modules, ...rest, pathname, isMobileDevice }), [])} </List>
  );
};

const reduceChildRoutes = props => {
  const { router, items, page, modules, depth, pathname, isMobileDevice } = props;

  if (page.children && page.children.length) {
    const matched = page.children.find(child => matchPath(pathname, { path: child.url, exact: true, }));
    items.push(
      <SidebarMenuListItem depth={depth} icon={page.icon && PageIcon[page.icon.slice(17)]} key={page.id} label={page.badge} title={page.text} open={false} active={!!matched}>
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList depth={depth + 1} pages={page.children} modules={modules} router={router} />
        </div>
      </SidebarMenuListItem>
    );
  } else if (modules && modules[page.url.slice(1)]?.Module) {
    const matched = page.url.indexOf(pathname.split("/")[1]) > -1;
    const AppId = JSON.parse(page.metaData)
    const showMenu = ((isMobileDevice.mobile && !isMobileDevice.tablet) && AppId.AppId.includes('2')) ? true : (isMobileDevice.tablet && AppId.AppId.includes('3')) ? true : ((!isMobileDevice.mobile && !isMobileDevice.tablet) && AppId.AppId.includes('1')) ? true : false
    showMenu && items.push(
      <SidebarMenuListItem depth={depth} href={page.url} icon={page.icon && PageIcon[page.icon.slice(17)]} iconclass={page.iconclass || ''} key={page.id} label={page.badge} title={page.text} />
    );
  }

  return items;
};

const SidebarMenu = props => {
  const { title, pages, className, component: Component, modules, ...rest } = props;
  const router = useRouter();
  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} router={router} modules={modules} />
    </Component>
  );
};


export default SidebarMenu;
