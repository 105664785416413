import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PageTitle } from '..';
import actions from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { apis, request } from '../../httpUtil';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, Menu, MenuItem, Paper, Radio, RadioGroup, Select, Tab, TextField, Typography, Tabs } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import surveyAnswerReports from './SurveyAnswerReports';
import { useDebounce } from 'use-debounce';
import _ from "lodash";
import { Questions } from './Questions.js';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { answerType } from './answersConfig.js';

const { t, numberWithCommas } = utils;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    cardMain: {
        marginBottom: '16px !important',
        padding: '16px !important'
    },
    cardSecondary: {
        marginBottom: '16px !important'
    },
    surveyDetailsMain: {
        alignItems: "center !important",
        mt: ' 8px !important'
    },
    surveyDetailsItems: {
        fontWeight: 'bold !important',
        flex: '5 !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    outletsSurveyed: {
        fontWeight: 'bold !important',
        flex: '5 !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: '10px !important'
    },
    stringType: {
        marginBottom: '8px !important',
        padding: "8px !important",
        backgroundColor: '#A6D8F7 !important',
        flex: '1px !important',
        overflow: 'hidden',
        height: '50px',
        '&.expanded': {
            height: 'auto'
        }
    },
    collapseSection: {
        display: "flex !important",
        justifyContent: "flex-end !important",
        alignItems: "center !important",
        flexWrap: "nowrap !important"
    },
    section: {
        display: 'flex !important',
        alignItems: 'center !important'
    },
    SectionIconMain: {
        display: 'flex !important',
        alignItems: 'center !important',
        gap: '8px !important'
    },
    dtnSectionIconSecondary: {
        display: 'flex !important',
        alignItems: 'center !important',
        marginRight: '15px !important'
    },
    surveyDetailsContainer: {
        marginTop: '8px !important'
    },
    surveyDetailsExpanded: {
        marginTop: '4px !important',
        marginBottom: '10px !important'
    },
    mainHeader: {
        display: 'flex !important',
        justifyContent: 'space-between !important'
    },
    questionsContent: {
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'flex-start !important',
        marginLeft: '16px !important'
    },
    toggleSection: {
        display: 'flex !important',
        justifyContent: 'flex-end !important',
        marginRight: '16px !important'
    },
    clippedText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        cursor: 'pointer'
    },
    fullText: {
        cursor: 'pointer'
    },
    expandIcon: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    cardContainer: {
        display: 'flex',
        alignItems: 'start'
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px'
    },
    icon: {
        marginRight: '6px'
    },
    horizontalCollapse: {
        maxWidth: theme.spacing(7),
        overflowX: 'hidden',
        transition: 'max-width 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    horizontalExpand: {
        maxWidth: '100%',
        transition: 'max-width 300ms cubic-bezier(0.4, 0, 0.2, 1)',
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        justifyContent: 'space-between'
    },
    gridItem: (props) => ({
        flex: props.open ? '0 0 240px' : '0 0 0px',
        transition: 'flex 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        overflow: props.open ? 'visible' : 'hidden'
    }),
    paperBox: {
        flexGrow: 0,
        flexShrink: 0,
        width: '100%',
        overflow: 'hidden'
    },
    transitionBox: {
        flexGrow: 1,
        flexShrink: 1,
        transition: 'margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
    },
    paddingBox: {
        padding: '1px !important'
    },
    resultsMainSectionHeader: {
        textTransform: 'none !important',
        color: 'black !important',
        fontWeight: 'bold !important'
    },
    resultsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftGroup: {
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'flex-start'
    },
    rightGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    filterButton: {
        marginRight: '16px !important'
    },
    fullWidth: {
        width: "100%"
    },
    assortment: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 'bold'
    },
    assortmentDetails: {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal'
    },
    tabFont: {
        textTransform: 'none !important',
        fontSize: '16px !important'
    }
}))

const getFrequencyType = (val) => {
    switch (val) {
        case "V":
            return "At every visit";
        case "O":
            return "Every outlet only once";
        case "W":
            return "Once per week";
        case "M":
            return "Once per month";
        case "Q":
            return "Once per quarter";
        case "Y":
            return "Once per Year";
        default:
            return "";
    }
}
const possibleFormats = [
    "DD/MM/YYYY", "D/MM/YYYY", "DD-MMM-YYYY", "D-MMM-YYYY", "DD.MM.YYYY", "D.M.YYYY",
    "YYYY-MM-DD", "YYYY/MM/DD", "YYYY.M.DD",
    "MM/DD/YYYY", "MMM DD, YYYY", "MMMM DD, YYYY",
    "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss",
    "D-MMM-YY", "DD/MMM/YYYY"
];

const defaultComparator = "Market";



const ExportMenuItem = React.memo(({ handleExport, type, contentType, isPivotExport = false }) => {
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    return (
        <MenuItem
            onClick={() => {
                handleExport({ type, contentType, isPivotExport });
            }}
            data-type={type}
            data-content-type={contentType}
            data-is-pivot-export={isPivotExport}
        >
            {t("Export", tOpts)} {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
        </MenuItem>
    );
});

const CustomExportButton = React.memo((props) => {
    const exportTypes = [
        { type: "csv", contentType: "text/csv" },
        { type: "xlsx", contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
        { type: "xml", contentType: "text/xml" },
        { type: "html", contentType: "text/html" },
        { type: "json", contentType: "application/json" }
    ];
    return (
        <div {...props}>
            {exportTypes.map((exportType) => (
                <ExportMenuItem
                    key={exportType.type}
                    handleExport={() => props.handleExport(exportType)}
                    type={exportType.type}
                    contentType={exportType.contentType}
                    isPivotExport={exportType.isPivotExport}
                />
            ))}
        </div>
    );
});

const FilterCollapseItem = ({ name, classes, children }) => {
    return (
        <Accordion className={classes.cardMain}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${name}-content`}
                id={`${name}-id`}
            >
                <Typography className={classes.surveyDetailsItems}>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};


const MoreActions = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    return (
        <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 0.5px 0.5px rgba(0,0,0,0.1))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    }
                },
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem
                onClick={handleMenuClose}
            // className={classes.menuItem}
            >
                <img className={`accordion-export-icon`} src={require(`../../assets/images/excel.png`)} style={{ width: "20%", height: "auto", margin: '0 auto' }} alt={"Export"} />
                {/* {t(``, tOpts)} */}
            </MenuItem>
            {/* Hiding for now - To be added postMVP */}
            {/* <MenuItem onClick={handleMenuClose} className={classes.menuItem}>
                <AccountTreeIcon className={classes.icon} />
                View Data
            </MenuItem>
            <MenuItem onClick={handleMenuClose} className={classes.menuItem}>
                <PrintIcon className={classes.icon} />
                Print
            </MenuItem> */}
        </Menu>
    );
};
const Answers = ({ answers, ...props }) => {
    if (!answers) {
        return null;
    }
    return <>
        {answers.map((answer, index) => {
            return <Answer key={answer.id} answer={answer} props={props} index={index} />
        })}
    </>
}

const Answer = ({ answer, index, props }) => {
    if (!answer) {
        return null;
    }
    const { classes, tabValues, utils, handleTabChange, isAdvanced, loadChart, handleMenuOpen, handleToggleChange, toggleStates, t, tOpts, checkSentenceLength, handleExpandClick, expandedText } = props;
    const format = utils.systemDateTimeFormat(true);
    const typesWithDynamicOptions = ["Dropdown", "checkbox", "radio"];
    const validatedFromAsset = (answer?.type === 'barcode') && !answer?.validateFromAsset;
    const questionTypeArray = ['text', 'textarea', 'checkbox', 'number', 'file', 'multiFile', 'scene'];
    const textTotalCount = answer.type === 'text' || answer.type === 'textarea' || (typesWithDynamicOptions.includes(answer.type) && answer.dynamicOptions) ? answer.totalCount : 0;
    let textDistinctCount;
    let isCustomOption = answer.isCustomOption;
    if (answer.type === 'text' || answer.type === 'textarea') {
        const uniqueTextValues = new Set(answer.sortedText.map(item => item[0]));
        const uniqueTextArray = [...uniqueTextValues];
        textDistinctCount = uniqueTextArray.length;
    }
    if ((answer.type === 'number') && answer.series.data.length === 0) return null;
    if ((answer.type === 'radio' || answer.type === 'checkbox') && answer.series[0].data.length === 0 && !isCustomOption) return null;
    if ((answer.type === 'text' || answer.type === 'textarea') && Object.entries(answer.sortedText).length === 0) return null;
    if (answer.type === 'date' && Object.entries(answer.sortedDateData).length === 0) return null;
    if ((answer.type === 'file' || answer.type === 'scene' || answer.type === 'multiFile') && answer.imageData.length === 0) return null;
    if ((answer.type === 'file' || answer.type === 'scene' || answer.type === 'multiFile') && (answer.AssortmentDepth === 0)) return null;
    const questionWithDynamicOptions = Boolean(typesWithDynamicOptions.includes(answer.type) && answer.dynamicOptions);
    let minDate = answer.sortedDateData.minDate;
    let maxDate = answer.sortedDateData.maxDate;
    const top10 = 10;
    const bottom10 = 10;
    let AnswerDetail = answerType[answer.type]({ answer, utils, toggleStates, top10, bottom10, questionWithDynamicOptions, validatedFromAsset, textTotalCount, textDistinctCount, isCustomOption, format, classes, checkSentenceLength, handleExpandClick, expandedText });

    return (
        <Card className={classes.cardSecondary}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValues[answer.id] || '1'}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValues[answer.id] || '1'} data-questionid={answer.id} onChange={handleTabChange} aria-label='main tabs'>
                            <Tab label="Total" value="1" />
                            <Tab label="Comparison" value="2" />
                        </Tabs>
                    </Box>
                    <TabPanel value="1">
                        <Questions
                            question={answer}
                            answer={answer.answer}
                            index={index}
                            classes={classes}
                            handleMenuOpen={handleMenuOpen}
                            moreActions={<MoreActions />}
                            t={t}
                            tOpts={tOpts}
                            toggleStates={toggleStates}
                            handleToggleChange={handleToggleChange}
                            answerDetail={AnswerDetail}
                            validatedFromAsset={validatedFromAsset}
                            questionTypeArray={questionTypeArray}
                            textTotalCount={textTotalCount}
                            textDistinctCount={textDistinctCount}
                            questionWithDynamicOptions={questionWithDynamicOptions}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </TabPanel>
                    < TabPanel value="2">
                        {isAdvanced && loadChart && (
                            <Questions
                                question={answer}
                                answer={answer.answer}
                                index={index}
                                classes={classes}
                                handleMenuOpen={handleMenuOpen}
                                moreActions={<MoreActions />}
                                t={t}
                                tOpts={tOpts}
                                toggleStates={toggleStates}
                                handleToggleChange={handleToggleChange}
                            // comparatorDetail={comparatorDetail} // todo: To work on comparator ticket
                            />
                        )}
                    </TabPanel>
                </TabContext>
            </Box>
        </Card >
    );
};

const RenderMenu = ({ lookupData, selectedComparatorCategory, comparatorAnchorEl, handleItemCheckChange, handleAllMainCheckChange, isAllMainChecked, ITEM_HEIGHT, checkedItems, handleApplyClick, handleCloseMenu }) => {
    const itemsToShow = lookupData[selectedComparatorCategory] || [];
    return (
        <Menu
            anchorEl={comparatorAnchorEl}
            open={Boolean(comparatorAnchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{
                style: {
                    width: ITEM_HEIGHT * 3.5,
                    overflowX: 'hidden !important',
                },
            }}
        >
            <div style={{ overflowY: 'auto', overflowX: 'hidden !important', maxHeight: ITEM_HEIGHT * 4.5 }}>
                <MenuItem key="all" onClick={handleAllMainCheckChange}>
                    <Checkbox checked={isAllMainChecked} />
                    {"All"}
                </MenuItem>
                {itemsToShow.map((item, idx) => (
                    <MenuItem key={idx} data-itemvalue={item.DisplayValue} onClick={handleItemCheckChange}>
                        <Checkbox checked={checkedItems[item.DisplayValue]} />
                        {item.DisplayValue}
                    </MenuItem>
                ))}
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 1,
                borderTop: '1px solid #e0e0e0',
                padding: '6px'
            }}>
                <Button
                    variant="contained"
                    onClick={handleCloseMenu}
                    color="error"
                    style={{ flex: 1, marginRight: '2px' }}
                >
                    {"Cancel"}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleApplyClick}
                    color="success"
                    style={{ flex: 1, marginLeft: '2px' }}
                >
                    {"Apply"}
                </Button>
            </div>
        </Menu>
    );
};


const AnalysisDetails = React.memo(() => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isAllChecked, setIsAllChecked] = useState(true);
    const [checkedOutlets, setCheckedOutlets] = useState({});
    const [toggleStates, setToggleStates] = useState({});
    const [expandedText, setExpandedText] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [locationId, setLocationId] = useState();
    const [isFilter, setIsFilter] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedTerm] = useDebounce(searchTerm, 1000);
    const [anchorElP, setAnchorElP] = React.useState(false);
    const portalCombos = useSelector(state => state.appReducer.comboData) || {};
    const reportingCombos = useSelector(state => state.appReducer.resultAnalysisComboData) || {};
    const { LocationType: Channels, LocationClassification: Classification, State } = portalCombos;
    const { Market, City, Users, Distributor } = reportingCombos;
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const classes = useStyles({ open });
    const [isClient, setIsClient] = useState(false);
    const [filterModel, setFilterModel] = useState({
        MarketId: null,
        LocationTypeId: null,
        ClassificationId: null
    });
    const [tempFilterModel, setTempFilterModel] = useState({
        MarketId: null,
        LocationTypeId: null,
        ClassificationId: null
    });
    const [isAllMainChecked, setIsAllMainChecked] = useState(true);
    const [tabValues, setTabValues] = useState({});
    const Time = [];
    const Region = [];
    const Account = [];
    const lookupData = {
        Market: Market,
        LocationType: Channels,
        LocationClassification: Classification,
        Users: Users,
        City: City,
        State: State,
        Time: Time,
        Distributor: Distributor,
        Region: Region,
        Account: Account
    };
    const displayNameMapping = {
        Market: "Market",
        LocationType: "Channel",
        LocationClassification: "Classification",
        Users: "Users",
        City: "City",
        State: "State",
        Time: "Time",
        Distributor: "Distributor",
        Region: "Region",
        Account: "Account"
    };

    const [loadChart, setLoadChart] = useState(false);

    const [selectedComparatorCategory, setSelectedComparatorCategory] = useState(defaultComparator);
    const ITEM_HEIGHT = 70;
    const [comparatorAnchorEl, setComparatorAnchorEl] = useState(null);
    const [checkedItems, setCheckedItems] = useState(() => {
        if (Array.isArray(lookupData[defaultComparator])) {
            const categoryItems = lookupData[defaultComparator];
            const newCheckedItems = {};
            categoryItems.forEach((item) => {
                newCheckedItems[item.DisplayValue] = true;
            });
            return newCheckedItems;
        } else {
            return {};
        }
    });
    const [isAdvanced, setIsAdvanced] = useState(false);

    const [tabValue, setTabValue] = useState("1");

    const handleCloseMenu = () => {
        setComparatorAnchorEl(null);
    };

    const handleComparatorCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedComparatorCategory(category);
        setComparatorAnchorEl(event.currentTarget);
        setIsAllMainChecked(true)
        if (Array.isArray(lookupData[category])) {
            setIsAdvanced(true);
            const categoryItems = lookupData[category];
            const newCheckedItems = {};
            categoryItems.forEach(item => { newCheckedItems[item.DisplayValue] = true; });
            setCheckedItems(newCheckedItems);
            setIsAllChecked(true);
        } else {
            setCheckedItems({});
            setIsAllChecked(false);
            setIsAdvanced(false);
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        const tabsWithQuestionId = document.querySelectorAll('[data-questionid]');
        const questionIds = Array.from(tabsWithQuestionId).map(tab => tab.getAttribute('data-questionid'));
        questionIds.forEach(questionId => {
            setTabValues(prev => ({ ...prev, [questionId]: newValue }));
        });

    }



    const handleTabChange = (event, newValue) => {
        const questionId = event.target.closest('[data-questionid]').getAttribute('data-questionid');
        setTabValues(prev => ({ ...prev, [questionId]: newValue }));
    };

    const handleAllMainCheckChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllMainChecked(isChecked);
        const newCheckedItems = {};
        Object.keys(checkedItems).forEach(key => newCheckedItems[key] = isChecked);
        setCheckedItems(newCheckedItems);
    };

    const handleItemCheckChange = (event) => {
        const itemValue = event.currentTarget.getAttribute('data-itemvalue');
        const newCheckedItems = { ...checkedItems };
        newCheckedItems[itemValue] = !newCheckedItems[itemValue];
        setCheckedItems(newCheckedItems);
        const isAllChecked = Object.values(newCheckedItems).every(value => value);
        setIsAllMainChecked(isAllChecked);
    };


    const handleApplyClick = () => {
        fetchSurveyAnalysis();
        setLoadChart(true);
        setComparatorAnchorEl(null);
    };

    useEffect(() => {
        utils.getAnalysisComboData();
    }, []);

    const handleClick = (event) => {
        setAnchorElP(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElP(false);
    };
    const handleToggleDrawer = () => {
        setOpen(!open);
    };


    const handleExpandClick = (questionId, answerKey) => {
        setExpandedText(prevState => ({
            ...prevState,
            [questionId]: {
                ...prevState[questionId],
                [answerKey]: !prevState[questionId]?.[answerKey]
            }
        }));
    };

    const handleToggleChange = (event, newValue, questionId) => {
        if (newValue !== null) {
            setToggleStates(prevState => ({ ...prevState, [questionId]: newValue }));
        }
    };
    const handleAllCheckChange = useCallback((event) => {
        const isChecked = event.target.checked;
        setIsAllChecked(isChecked);
        setIsFilter(true);
        const updatedChecks = {};
        data.outlets?.forEach((outlet) => {
            updatedChecks[outlet.LocationId] = isChecked;
        });
        setCheckedOutlets(isChecked ? updatedChecks : {});
        setLocationId(isChecked ? data.outlets?.map((outlet) => outlet.LocationId) : []);
        setFilterModel(prevModel => {
            const newModel = {
                ...prevModel,
                LocationId: isChecked ? data.outlets?.map((outlet) => outlet.LocationId) : null
            };
            return newModel;
        })
        setIsClient(true);
    }, [data]);

    const handleOutletCheckChange = useCallback((id) => {
        setIsFilter(true);
        setCheckedOutlets((prevChecks) => {
            const updatedChecks = { ...prevChecks, [id]: !prevChecks[id] };
            const isAllSelected = data.outlets.every((outlet) => updatedChecks[outlet.LocationId]);
            setIsAllChecked(isAllSelected);

            const newCheckedOutlets = data.outlets?.filter((outlet) => updatedChecks[outlet.LocationId]);
            setFilterModel(prevModel => {
                const newModel = {
                    ...prevModel,
                    LocationId: updatedChecks[id]
                        ? [...(prevModel.LocationId || []), id]
                        : (prevModel.LocationId || []).filter(storedId => storedId !== id)
                };
                return newModel;
            });

            setLocationId(newCheckedOutlets.map((outlet) => outlet.LocationId));
            return updatedChecks;
        });
        setIsClient(true);
    }, [data]);


    const onChangeCreator = (type) => {
        return (event) => {
            const value = event.target.value;
            const validTypes = Object.keys(tempFilterModel);

            if (!validTypes.includes(type)) {
                console.error(`Invalid type: ${type}. Cannot update filterModel.`);
                return;
            }
            setTempFilterModel(prevModel => {
                const newModel = {
                    ...prevModel,
                    [type]: value !== "all" ? value : null,
                };
                return newModel;
            });
            setIsFilter(true);
        }
    };
    const onDateCreator = (name) => {
        return (date) => {
            const adjustedDate = dayjs(date);
            if (name === "startDate") {
                setStartDate(adjustedDate);
            } else if (name === "endDate") {
                setEndDate(adjustedDate);
            }
            setIsFilter(true);
        };
    };

    const onClassificationChange = onChangeCreator('ClassificationId');
    const onMarketChange = onChangeCreator('MarketId');
    const onLocationTypeChange = onChangeCreator('LocationTypeId');
    const onStartDateChange = onDateCreator('startDate');
    const onEndDateChange = onDateCreator('endDate');

    function getSelectedDays(value) {
        const selectedDays = [];
        for (let i = 0, iLen = value.length; i < iLen; i++) {
            if (value[i] === '1') {
                selectedDays.push(daysOfWeek[i]);
            }
        }
        return selectedDays.join(', ');
    }




    useEffect(() => {
        if (data?.outlets) {
            const newLocationIds = Object.keys(checkedOutlets)
                .filter(id => checkedOutlets[id])
                .map(id => parseInt(id));

            if (newLocationIds.length === 0) return;

            if (JSON.stringify(newLocationIds) !== JSON.stringify(locationId)) {
                setLocationId(newLocationIds);
            }
        }
    }, [data, checkedOutlets, locationId]);

    useEffect(() => {
        if (data?.outlets && isAllChecked) {
            const initialChecks = {};
            const newLocationIds = [];
            data?.outlets?.forEach(outlet => {
                initialChecks[outlet.LocationId] = true;
                newLocationIds.push(outlet.LocationId);
            });
            setLocationId(newLocationIds);
            setCheckedOutlets(initialChecks);
        }
    }, [data, isAllChecked]);


    const handleSearchChange = (event) => {
        const value = event.target.value;
        if (value === "") {
            setTimeout(() => {
                setSearchTerm(value);
            }, 10);
        } else {
            setSearchTerm(value);
        }
    };

    const fetchSurveyAnalysis = async (columns, contentType, isForExport) => {
        const id = location?.state?.id || location?.pathname.split('/').pop();
        const template = null;
        try {
            const reqParams = {
                id: id,
                startDate: startDate,
                endDate: endDate,
                LocationId: locationId,
                isFilter: isFilter,
                ...tempFilterModel,
                isClient: isClient,
                isForExport: isForExport,
                contentType: contentType,
                selectedComparatorCategory: selectedComparatorCategory,
                comboList: [selectedComparatorCategory],
                checkedComparatorItems: checkedItems
            }
            if (searchedTerm) {
                reqParams.searchedTerm = searchedTerm;
                reqParams.isFilter = true;
            }
            let url = `${apis.surveyAnalysis}?isForExport=true&id=${id}&contentType=${contentType || 'xlsx'}`;
            if (contentType) {
                const form = document.createElement("form");
                reqParams.responseType = contentType;
                reqParams.columns = columns;
                reqParams.showExport = true;
                form.setAttribute("method", "POST");
                form.setAttribute("id", "exportForm");
                form.setAttribute("target", "_blank")
                if (template === null) {
                    for (const key in reqParams) {
                        let v = reqParams[key];
                        if (v === undefined || v === null) {
                            continue;
                        } else if (typeof v !== 'string') {
                            v = JSON.stringify(v);
                        }
                        let hiddenTag = document.createElement('input');
                        hiddenTag.type = "hidden";
                        hiddenTag.name = key;
                        hiddenTag.value = v;
                        form.append(hiddenTag);
                    }
                }
                form.setAttribute('action', url);
                document.body.appendChild(form);
                form.submit();
                setTimeout(() => {
                    document.getElementById("exportForm").remove();
                }, 3000)
                return;
            }

            const result = await request({ url: apis.surveyAnalysis, params: { ...reqParams }, history, dispatch, jsonPayload: true, disableLoader: false });
            setData(result?.data);
        } catch (error) {
            console.error(t('Error fetching survey analysis:', tOpts), error);
        }
    };


    useEffect(() => {
        fetchSurveyAnalysis()
    }, [filterModel, searchedTerm]);

    const onApplyFilterClick = () => {
        setFilterModel({ ...tempFilterModel });
        fetchSurveyAnalysis();
    };

    const clearFilter = () => {
        const clearedModel = {
            MarketId: null,
            LocationTypeId: null,
            ClassificationId: null
        };

        setFilterModel(clearedModel);
        setTempFilterModel(clearedModel);
        setStartDate(null);
        setEndDate(null);
        setIsAllChecked(true)
        setLocationId(data?.outlets?.map((outlet) => outlet.LocationId));
        setIsFilter(false);
    };


    useEffect(() => {
        dispatch({ type: actions.SET_PAGE_TITLE_DETAILS, pageTitleDetails: <PageTitle icon="" titleHeading={t(data?.surveyMaster?.SurveyName, tOpts)} titleDescription={t(data?.surveyMaster?.SurveyDescription, tOpts)} title={t(data?.surveyMaster?.SurveyName, tOpts)} /> });
    }, [data])

    useEffect(() => {
        let backRoute = location.pathname;
        backRoute = backRoute.split("/");
        backRoute.pop();
        backRoute = backRoute.join("/");
        dispatch({
            type: actions.SET_PAGE_BACK_BUTTON,
            pageBackButton: { status: true, backRoute: backRoute }
        });
    }, []);


    const checkSentenceLength = (sentence) => {
        const lettersLen = 100;
        return sentence.length > lettersLen;
    }

    const surveysSurveyedToDate = useMemo(() => {
        return (data?.outlets || []).reduce((accumulator, outlet) => accumulator + (outlet.SurveyCount || 0), 0);
    }, [data?.outlets]);
    const isAllLocations = data?.surveyMaster?.IsAllLocations;
    const isAllUsers = data?.surveyMaster?.IsAllUsers;

    const handleExport = (config) => {
        const { columns, contentType } = config;
        let isForExport = true;
        const expoColumns = {};

        columns.forEach(column => {
            expoColumns[column.field] = {
                field: column.field,
                width: column.width,
                headerName: column.headerName,
                type: column.type,
                keepUTC: column.keepUTC === true
            };
        });

        fetchSurveyAnalysis(expoColumns, contentType, isForExport);
    };
    return (
        <>
            {data && Object.entries(data)?.length ? <>
                <FilterCollapseItem classes={classes} name={t('Survey Details', tOpts)} >
                    <Grid container spacing={0} >
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Surveys`, tOpts)}</Typography>
                            <Typography>{t(`Submitted Surveys: ${surveysSurveyedToDate || 0}`, tOpts)}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Users`, tOpts)}</Typography>
                            <Typography>{t(`Users Included: ${isAllUsers ? data?.users[0]?.Users || 0 : data?.users[0]?.AllAssignedUsers || 0}`, tOpts)} </Typography>
                            <Typography>{t(`Users With Submissions: ${data?.users[0]?.SurveyedUsers || 0}`, tOpts)}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Outlets`, tOpts)}</Typography>
                            <Typography>{t(`Outlets Included: ${isAllLocations ? data?.outlets[0]?.AllLocations || 0 : data?.outlets[0]?.AllAssignedOutlets || 0}`, tOpts)} </Typography>
                            <Typography>{t(`Outlets Surveyed: ${data?.outlets[0]?.OutletsSurveyed || 0}`, tOpts)} </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Start - End`, tOpts)}</Typography>
                            <Typography>
                                {dayjs(data?.surveyMaster?.StartDate).format(utils.systemDateTimeFormat(true))} - {dayjs(data?.surveyMaster?.EndDate).format(utils.systemDateTimeFormat(true))}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Recurrence Period`, tOpts)}</Typography>
                            <Typography>{getFrequencyType(data?.surveyMaster?.FrequencyType) || ''}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography className={classes.surveyDetailsItems}>{t(`Specific Days`, tOpts)}</Typography>
                            <Typography>{getSelectedDays(data?.surveyMaster?.DefinedDays || [])}</Typography>
                        </Grid>
                    </Grid>
                </FilterCollapseItem>

                <FilterCollapseItem classes={classes} name={t('Filters Set-Up', tOpts)} >
                    <Grid container spacing={2} className={classes.surveyDetailsExpanded}>
                        <Grid item xs={6} sm={4} md={2}>
                            <DatePicker
                                label={t("Start Date", tOpts)}
                                name="startDate"
                                onChange={onStartDateChange}
                                value={startDate}
                                slotProps={{ textField: { size: 'small' } }}
                                format={utils.systemDateTimeFormat(true)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <DatePicker
                                label={t("End Date", tOpts)}
                                name="endDate"
                                value={endDate}
                                onChange={onEndDateChange}
                                slotProps={{ textField: { size: 'small' } }}
                                format={utils.systemDateTimeFormat(true)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel >{t("Market", tOpts)}</InputLabel>
                                <Select label={t("Market", tOpts)} value={tempFilterModel.MarketId || 'all'} onChange={onMarketChange}>
                                    <MenuItem value="all">All</MenuItem>
                                    {Market?.map((market, key) => (
                                        <MenuItem key={market?.LookupId} value={market.LookupId}>
                                            {t(`${market?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel >{t("Channel", tOpts)}</InputLabel>
                                <Select label={t("Channel", tOpts)} value={tempFilterModel.LocationTypeId || 'all'} onChange={onLocationTypeChange}>
                                    <MenuItem value="all">All</MenuItem>
                                    {Channels?.map((channel, key) => (
                                        <MenuItem key={channel?.LookupId} value={channel.LookupId}>
                                            {t(`${channel?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel >{t("Classification", tOpts)}</InputLabel>
                                <Select label={t("Classification", tOpts)} value={tempFilterModel.ClassificationId || 'all'} onChange={onClassificationChange}>
                                    <MenuItem value="all">All</MenuItem>
                                    {Classification?.map((classification, key) => (
                                        <MenuItem key={classification?.LookupId} value={classification.LookupId}>
                                            {t(`${classification?.DisplayValue} `, tOpts)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" color="primary" onClick={onApplyFilterClick} className={classes.filterButton} > {t("Filter", tOpts)}</Button>
                            <Button variant="contained" color="primary" onClick={clearFilter} className={classes.filterButton}> {t("Clear", tOpts)}</Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.surveyDetailsExpanded}>
                        {/* Commenting out to first have completely working filters */}
                        {/* <Grid item xs={6} sm={4} md={2}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <InputLabel >{t("Outlet Group", tOpts)}</InputLabel>
                                    <Select label={t("Outlet Group", tOpts)} value={filterModel.outletGroups} onChange={(e) => onChange(e, "outletGroups")}>
                                        <MenuItem value="all">All</MenuItem>
                                        {data?.outletGroup?.map((outlet, key) => (
                                            <MenuItem key={key} value={outlet?.LocationGroupId}>
                                                {t(`${outlet?.GroupName} `, tOpts)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={4} md={2}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <InputLabel >{t("User Group", tOpts)}</InputLabel>
                                    <Select label={t("User Group", tOpts)} value={filterModel.userGroups} onChange={(e) => onChange(e, "userGroups")}>
                                        <MenuItem value="all">All</MenuItem>
                                        {data?.userGroup?.map((user, key) => (
                                            <MenuItem key={key} value={user.UserGroupId}>
                                                {t(`${user?.GroupName} `, tOpts)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                    </Grid>
                </FilterCollapseItem>

                <FilterCollapseItem classes={classes} name={t('Comparator Set-Up', tOpts)}>
                    <Grid container spacing={2} className={classes.surveyDetailsExpanded}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" variant="outlined" fullWidth size="small">
                                <RadioGroup
                                    row
                                    aria-label="category-options"
                                    name="categoryOptions"
                                    value={selectedComparatorCategory}
                                    onChange={handleComparatorCategoryChange}
                                    onClick={handleComparatorCategoryChange}
                                >
                                    <Grid container spacing={2}>
                                        {Object.keys(displayNameMapping).map((category, key) => {
                                            const data = portalCombos[category] || reportingCombos[category];
                                            return (
                                                <Grid item xs={12} sm={6} md={3} key={key}>
                                                    <FormControlLabel
                                                        value={category}
                                                        control={<Radio size="small" />}
                                                        label={t(displayNameMapping[category], tOpts)}
                                                        disabled={!data || data.length === 0}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FilterCollapseItem>

                <RenderMenu {...{ lookupData, selectedComparatorCategory, comparatorAnchorEl, setComparatorAnchorEl, handleItemCheckChange, handleAllMainCheckChange, isAllMainChecked, checkedItems, ITEM_HEIGHT, handleApplyClick, handleCloseMenu }} />

                <Card className={classes.cardMain}>
                    <Grid container direction="row" className={classes.gridContainer}>
                        <Grid item xs={open ? 2 : 0} className={classes.gridItem}>
                            <Box component={Paper} elevation={3} className={classes.paperBox}>
                                <Box className={classes.flexContainer}>
                                    <Typography className={classes.outletsSurveyed}>{t(`Outlets Surveyed`, tOpts)}</Typography>
                                </Box>
                                <Grid item xs>
                                    <Card>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        placeholder={`Search Outlets`}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        size="small"
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset" size="small">
                                                        <FormGroup>
                                                            {
                                                                !searchTerm && (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={isAllChecked}
                                                                                onChange={handleAllCheckChange}
                                                                            />
                                                                        }
                                                                        label="All"
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                data?.outlets.sort((a, b) => {
                                                                    if (a.Name < b.Name) return -1;
                                                                    if (a.Name > b.Name) return 1;
                                                                    return 0;
                                                                }).map((outlet, key) => (
                                                                    <FormControlLabel
                                                                        key={outlet?.LocationId}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={checkedOutlets[outlet?.LocationId] || false}
                                                                                onChange={() => handleOutletCheckChange(outlet?.LocationId)}
                                                                            />
                                                                        }
                                                                        label={`${outlet.Name} (${outlet.SurveyCount})`}
                                                                    />
                                                                ))
                                                            }
                                                        </FormGroup >
                                                    </FormControl >
                                                </Grid >
                                            </Grid >
                                        </CardContent >
                                    </Card >
                                </Grid >
                            </Box >
                        </Grid >
                        <Grid item xs={open ? 10 : 12} className={classes.gridItem}>

                            <TabContext value={tabValue}>
                                <Box className={classes.transitionBox}>
                                    <Box className={classes.paddingBox}>
                                        <div className={classes.resultsContainer}>
                                            <div className={classes.leftGroup}>
                                                <Button
                                                    variant="bold"
                                                    onClick={handleToggleDrawer}
                                                    startIcon={open ? <MenuOpenIcon /> : <MenuIcon />}
                                                    sx={{ marginTop: 1 }}
                                                ></Button>
                                                <TabList onChange={handleChangeTab} aria-label="comparator tabs" >
                                                    <Tab label={t(`Results and Charts`, tOpts)} value="1" className={classes.tabFont} />
                                                    <Tab label={t(`Comparison View`, tOpts)} value="2" className={classes.tabFont} />
                                                </TabList>
                                            </div>
                                            <div className={classes.rightGroup}>
                                                <Typography variant="body1" sx={{ marginRight: 2 }}>{t(`${displayNameMapping[selectedComparatorCategory]}`, tOpts)}</Typography>
                                                <Typography variant="body1" sx={{ marginRight: 2 }}>{t(`Export`, tOpts)}</Typography>
                                                <img
                                                    className={`accordion-export-icon cursor-pointer`}
                                                    src={require(`../../assets/images/excel.png`)}
                                                    height='35'
                                                    alt={t(`Export`, tOpts)}
                                                    onClick={handleClick}
                                                    onKeyDown={handleClick}
                                                />
                                                <Menu
                                                    anchorEl={anchorElP}
                                                    open={Boolean(anchorElP)}
                                                    onClose={handleClose}
                                                >
                                                    <CustomExportButton handleExport={(exportType) => handleExport({ ...surveyAnswerReports, ...exportType })} />
                                                </Menu>
                                            </div>
                                        </div>
                                    </Box>
                                    <Card className={classes.cardSecondary}>
                                        <CardContent><Answers answers={data.answers} classes={classes} tabValues={tabValues} utils={utils} handleTabChange={handleTabChange} isAdvanced={isAdvanced} loadChart={loadChart} handleMenuOpen={handleMenuOpen} handleToggleChange={handleToggleChange} toggleStates={toggleStates} t={t} tOpts={tOpts} checkSentenceLength={checkSentenceLength} handleExpandClick={handleExpandClick} expandedText={expandedText} handleChangeTab={handleChangeTab} /></CardContent>
                                    </Card>

                                </Box>
                            </TabContext>
                        </Grid >
                    </Grid >
                </Card >
            </> :
                <div>No data available.</div>
            }
        </>
    )
});

export default AnalysisDetails;