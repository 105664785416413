import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';
import utils from '../../utils';

const BarChart = ({ ...props }) => {
    CommonChartsConfig();
    const { title, subtitle, categories, series, orientation = 'vertical', orderBy = 'descending', showLegend = false, xLabel, yLabel, custom, type } = props;
    let chartType, legendOptions;
    let xAxisTitle, yAxisTitle;
    if (Array.isArray(series) && orderBy) {
        series.forEach(item => {
            item.data.sort((a, b) => orderBy === 'ascending' ? a.y - b.y : b.y - a.y);
        });
    }
    const analysisColorCodes = utils.analysisColorCodes;
    const commonPlotOptions = (type) => ({
        pointPadding: 0.2,
        borderWidth: 0,
        colorByPoint: true,
        dataLabels: {
            enabled: true,
            formatter: function () {
                const percentage = this?.point?.percentage;
                const formattedPercentage = percentage !== undefined ? percentage.toFixed(2) + '%' : 'N/A';
                return `<div style="display: flex; flex-direction: column;  margin-left: ${type === "column" ? '1px' : '40px'};">
                            <span  style="margin-left: ${type === "column" ? '1px' : '40px'}">(${formattedPercentage})</span>
                            <span  style="margin-left: ${type === "column" ? '16px' : '60px'}">${this.y}</span>    
                        </div>`;
            },
            useHTML: true,
            align: 'center',
            verticalAlign: 'bottom',
            crop: false,
            overflow: 'none',
            fontWeight: 'normal',
            y: 8,
        },
        point: {
            events: {
                mouseOver: function () {
                    this.dataLabel.hide();
                },
                mouseOut: function () {
                    this.dataLabel.show();
                }
            }
        }
    });
    if (orientation === 'horizontal') {
        chartType = 'bar';
        xAxisTitle = xLabel;
        yAxisTitle = yLabel;
        legendOptions = {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        };
    } else {
        chartType = 'column';
        xAxisTitle = yLabel;
        yAxisTitle = xLabel;
        legendOptions = {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'middle'
        };
    }

    const options = {
        chart: {
            type: chartType
        },
        title: {
            text: title
        },
        subtitle: {
            text: subtitle
        },
        xAxis: {
            categories: categories,
            crosshair: true,
            allowDecimals: false,
            title: {
                text: xAxisTitle
            }
        },
        yAxis: {
            min: 0,
            allowDecimals: false,
            title: {
                text: yAxisTitle
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            formatter: function () {
                const categoryName = this.point.name || 'Unknown Category';
                const pointValue = this.point.y;
                let percentage = this.point.percentage;
                percentage = (percentage !== undefined) ? percentage.toFixed(2) + '%' : 'N/A';
                return `<span>${categoryName}</span>  ${custom ? `- ${pointValue} (${percentage})` : ''} `;
            },
            style: {
                width: 200
            }
        },
        plotOptions: {
            column: commonPlotOptions("column"),
            bar: commonPlotOptions("bar")
        },
        legend: {
            enabled: showLegend
        },
        series: series,
        scales: {
            xAxes: [{
                ticks: {
                    stepSize: 1,
                    beginAtZero: true
                }
            }]
        },
        colors: [
            analysisColorCodes.blue,
            analysisColorCodes.lightBlue,
            analysisColorCodes.orange,
            analysisColorCodes.lightOrange,
            analysisColorCodes.green,
            analysisColorCodes.lightGreen,
            analysisColorCodes.pink,
            analysisColorCodes.lightPink,
            analysisColorCodes.purple,
            analysisColorCodes.lightPurple],
        credits: {
            enabled: false
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default BarChart;