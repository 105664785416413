import React, { } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Button, Divider } from "@material-ui/core"
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const t = utils.t;

const Filters = ({ filters, openClose, handleOpenClose, showCloseButton = true }) => {
  const { t: trans, i18n } = useTranslation();
  const tOpts = { t: trans, i18n };
  const { filterButton, filterButtonName, apply, clear, other } = useSelector(state => state.appReducer.filtersInHeader) || {};

  function applyLocal() {
    apply();
    setTimeout(handleOpenClose, 1000);
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={openClose}
      color={"primary"}
      className={`${utils.isMobile() ? "right-filter-mobile" : "right-filter-web"}`}
    >
      <div className='d-flex  w-100 flex-column p-2  h-100 background-off-white'>
        <div className='w-100 d-flex justify-content-between mb-1' >
          <h3 className='text-theme-blue'>{t((filterButtonName || "Filter"), tOpts)}</h3>
          <Button onClick={handleOpenClose} className='d-flex  justify-content-center  '><FontAwesomeIcon icon={faWindowClose} role="button" fontSize={20} className={"text-off-red  btn"} /></Button>
        </div>
        <Divider />
        {filterButton}
        {!filterButton && other}
        <div className='w-100 d-flex flex-row  justify-content-center'>
          {showCloseButton && <Button variant='contained' className="text-white m-1 background-off-red" onClick={handleOpenClose}>{t("Close", tOpts)}</Button>}
          {clear && <Button variant='contained' className="text-white m-1 background-off-red" onClick={() => clear({ resetHeaderFilters: true })}>{t("Reset", tOpts)}</Button>}
          {apply && <Button variant="contained" className=" text-white m-1 background-theme-blue" onClick={applyLocal}>{t("Apply", tOpts)}</Button>}
        </div>
      </div>
    </SwipeableDrawer>
  )
}
export default Filters;