import UiModel from './ui-model';

const unAssignedAndTotalCameras = ["1", "0"];

const monthlyInvoiceModel = new UiModel({
    title: "Invoicing",
    defaultSort: 'SubscriptionModelId DESC',
    titleDescription: 'Check your monthly invoices',
    idProperty: 'id',
    readOnly: true,
    gridSubTitle: 'Projects',
    addModifiedOnColumn: false,
    addModifiedByColumn: false,
    module: 'Invoicing',
    api: 'MonthlyInvoiceDetails',
    showHiddenColumn: true,
    disableRowSelectionOnClick: false,
    addCreatedModifiedColumns: false,
    isElasticScreen: true,
    hideBackButton: true,
    preferenceId: 'playbookMonthlyInvoice',
    columns: [
        { field: "SubscriptionModelId", width: 300, label: "Subscription Model", type: 'radio', lookup: 'SubscriptionModelType' },
        {
            field: "NumberOfAssets", type: 'number', width: 150, label: "Number Of Camera", sortable: false, renderCell: (param) => {
                const { value } = param;
                let showValue = true;
                if (unAssignedAndTotalCameras.includes(param.row.SubscriptionModelId)) {
                    showValue = false
                }
                return (
                    <div className="custom-button">
                        {showValue ? value || "" : null}
                    </div>
                );
            }
        },
        {
            field: "Price", type: 'string', width: 200, label: "Price", sortable: false, renderCell: (param) => {
                const { value } = param;
                return (
                    <div className="custom-button">
                        {value || ""}
                    </div>
                );
            }
        },
        { field: "InvoiceAssets", type: 'number', width: 300, label: "Invoice Assets" },
        { field: "NonInvoiceAssets", type: 'number', width: 300, label: "Non Invoice Assets" },
        { field: "Cost", type: 'number', width: 250, label: "Cost" }
    ]
});

export default monthlyInvoiceModel;