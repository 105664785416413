import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Paper, Radio, RadioGroup, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import utils from '../../../utils';
import { useTranslation } from 'react-i18next';
import dbUtils from "../../../pages/RenderSurveyAnswer/dbUtil";
import { Clear } from '@mui/icons-material';
import { useSnackbar } from '../../SnackBar';
import constants from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalPaper: {
        width: '60% !important'
    },
    rightHeaderBtn: {
        position: 'absolute',
        right: '0px',
        top: '0px'
    }
}));

const AddPanelModal = ({ openAddPanel, closeAddPanel, list, addPanelItem, prevQuestionIndex, sectionToEdit, droppedItems, editSectionInfo, onDeleteSection }) => {
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState('');
    const [title, setTitle] = useState(droppedItems[sectionToEdit]?.title);
    const [methodSelected, setMethodSelected] = useState(null);
    const repeatMethods = ["Auto repeat", "Prompted"];
    const [sectionQuestions, setSectionQuestions] = useState(droppedItems[sectionToEdit] ? droppedItems[sectionToEdit].questions || [] : []);
    const [error, setError] = useState({});
    const titleRegex = new RegExp(/^.{5,50}$/);
    const snackbar = useSnackbar();
    const sectionRepeatItems = droppedItems[sectionToEdit]?.items; 
    const [includeSerialNumber, setIncludeSerialNumber] = useState(true);
    const [includeModelType, setIncludeModelType] = useState(true);

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    React.useEffect(() => {
        async function fetchQuestionnaireInfo() {
            const questionnaire = await dbUtils.get('questionnaire');
            const { title, items, repeatType } = questionnaire;
            setTitle(title);
            setMethodSelected(repeatType);
            setSelectedOption(items || null);
            setSectionQuestions(questionnaire[sectionToEdit] ? questionnaire[sectionToEdit].questions : []);
        }
        fetchQuestionnaireInfo();
    }, [droppedItems])

    const handleAddButtonClick = () => {
        validate({ ['title']: title });
        if (!title) return;
        let edittedTitle = title;
        if (edittedTitle) {
            const isValidTitle = titleRegex.test(edittedTitle);
            if (!isValidTitle) return snackbar.showError(`${t(error['title'], tOpts)}`);
        } else {
            edittedTitle = droppedItems[sectionToEdit]?.title;
        }
        if (typeof (sectionToEdit) === 'number') {
            const includeProductInfo = methodSelected === "Prompted";
            editSectionInfo(sectionToEdit, edittedTitle, selectedOption, methodSelected, includeProductInfo, sectionQuestions, includeSerialNumber, includeModelType, typeof(sectionRepeatItems) === 'string')
        } else {
            addPanelItem([{ title: edittedTitle, type: constants.dynamicOptionType["None"]}]);
        }
        handleClosePanel();
    };

    const handleClosePanel = () => {
        setError({});
        setTitle('');
        closeAddPanel();
    }
    
    function validate(field) {
        let msg = { ...error };
        const { title } = field
        if ("title" in field) {
            if (!title) {
                msg[Object.keys(field)] = t("Title is required", tOpts);
            } else {
                const isValid = titleRegex.test(title);
                if (!isValid) {
                    if (title.length > 50) {
                        msg[Object.keys(field)] = t("Title must be at most 50 characters long", tOpts);
                    } else if (title.length < 5) {
                        msg[Object.keys(field)] = t("Title must be at least 5 characters long", tOpts);
                    }
                } else {
                    msg[Object.keys(field)] = '';
                }
            }
        }
        setError({ ...msg });
    }

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        validate({ [name]: value });
        setTitle(e.target.value);
    }
    
    const handleOptionInputChange = async (e, qid) => {
        const selectedValue = e.target.value;
        let initialQuestionnaire = await dbUtils.get('questionnaire');
        if (!selectedValue) return;
        const updatedQuestionsArray = initialQuestionnaire[sectionToEdit].questions.map((item) =>
            item.id === qid ? { ...item, repeatKey: [Number(selectedValue)], sectionId: initialQuestionnaire[sectionToEdit].questions.id } : item
        );
        setSectionQuestions(updatedQuestionsArray);
    }

    return (
        <Modal open={openAddPanel} onClose={closeAddPanel} className={classes.modal}>
            <Paper sx={{ padding: 3 }} className={classes.modalPaper}>
                <Grid item xs={12} sx={{marginBottom: 4}}>
                    <Typography variant='h5' gutterBottom>
                        {typeof (sectionToEdit) === 'number' ? `${t("Edit", tOpts)} ` : `${t("Add", tOpts)} `}
                        {t("Questionnaire Section", tOpts)}
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" className={`${classes.rightHeaderBtn}`}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleAddButtonClick}
                        >
                            {`${t("Save", tOpts)}`}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleClosePanel}
                            color='error'
                        >
                            {t("Cancel", tOpts)}
                        </Button>
                    {
                    // sectionRepeatItems && typeof(sectionRepeatItems) === 'string' ? null :
                    typeof(sectionToEdit) === 'number' ? 
                        <Button
                            variant="contained"
                            onClick={(e) => onDeleteSection(e, sectionToEdit)}
                            color='error'
                        >
                            {t("Delete", tOpts)}
                        </Button> : null }
                    </Stack>
                </Grid>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                        <Typography>{t("Title", tOpts)} :</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            fullWidth
                            name="title"
                            variant="standard"
                            value={title}
                            placeholder={droppedItems[sectionToEdit]?.title?.replace('- Serial Number ${SerialNumber}', '').replace('- Model Type ${AssetType}', '').trim() || ''}
                            onChange={handleTextChange}
                            {...(error['title'] && {
                                error: true,
                                helperText: error['title']
                            })}
                        />
                    </Grid>
                    {sectionRepeatItems && typeof(sectionRepeatItems) === 'string' ? 
                    <>
                    <Grid item >
                        <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeSerialNumber}
                                onChange={(e) => setIncludeSerialNumber(e.target.checked)}
                                name="isActive"
                                color="primary"
                            />
                        }
                        label={t("Include serial number", tOpts)}
                    />
                    </Grid>
                    <Grid item >
                        <FormControlLabel
                        control={
                            <Checkbox
                                checked={includeModelType}
                                onChange={(e) => setIncludeModelType(e.target.checked)}
                                name="isActive"
                                color="primary"
                            />
                        }
                        label={t("Include Model type", tOpts)}
                    />
                    </Grid>
                    </>
                    : null}
                </Grid>
            </Paper>
        </Modal>
    );
};

export default AddPanelModal;