/* eslint-disable */
// Added for Logging the JS Errors.
'use strict';

var sendmail = function (excObject, appName, lineNumber) {
	var today = new Date();
	var dd = today.getDate();
	var mm = today.getMonth() + 1
	var hh = today.getHours();
	var h = hh > 12 ? hh - 12 : hh;
	var mi = today.getMinutes();
	var ss = today.getSeconds();
	var aa = hh > 12 ? 'pm' : 'am'

	var yyyy = today.getFullYear().toString();
	if (dd < 10) {
		dd = '0' + dd
	}
	if (mm < 10) {
		mm = '0' + mm
	}
	if (h < 10) {
		h = '0' + h
	}
	if (mi < 10) {
		mi = '0' + mi
	}
	if (ss < 10) {
		ss = '0' + ss
	}
	var today = mm + '/' + dd + '/' + yyyy + ' ' + h + ':' + mi + ':' + ss + ' ' + aa;
	var prefix = document.URL.indexOf("https:") > -1 ? 'https' : 'http',
		params = {}, url = prefix + "://exceptionbrowser.coolrgroup.com/ExceptionHandler.ashx?MachineName=" + appName + "&DateTime=" + today + "&DateTimeUTC=" + today;
	params.rawurl = window.location.href;
	params.rawurl = params.rawurl.replace(/&/g, '_');
	params.exception = [
		'<b>Message:</b> ' + excObject.message,
		'<b>Stack Trace:</b> ' + excObject.stack,
		'<b>Version:</b>' + window.appVersion ? window.appVersion : '1.0.0',
		'<b>Location1:</b>' + localStorage.companyId
	].join('\r\n'),
		params.User = localStorage.username,
		params.UserAgent = navigator.userAgent,
		params.Browser = navigator.appVersion,
		params.VirtualPath = appName,
		params.SystemPath = appName,
		params.Location = appName
	if (url) {
		var http = new XMLHttpRequest();
		var query = [];
		for (var o in params) {
			query.push(o + '=' + params[o]);
		}
		http.open("POST", url, true);
		http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
		http.onreadystatechange = function () {
			if (http.readyState == 4 && http.status == 200) {
				//alert(http.responseText);
			}
		}
		http.send(query.join('&'));
	}
};
module.exports = {
	initialize: function (a, b) {
		window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
		
			var appName = 'CoolR_Dashboard_3';
			url = document.URL;
			if (url.indexOf('localhost') > -1) {
				return;
			}
			// Send object with all data to server side log, using severity fatal, 
			// from logger "onerrorLogger"
			if (appName) {
				var exceptionObject = JL.Logger.prototype.buildExceptionObject(errorObj);
				sendmail(exceptionObject, appName, lineNumber);
				return false;
			}
		}
	}
};