import Highcharts from 'highcharts';

export const CommonChartsConfig = () => {
    const globalTextStyle = {
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 'normal'
    };

    Highcharts.setOptions({
        chart: {
            style: globalTextStyle
        },
        title: {
            style: globalTextStyle
        },
        subtitle: {
            style: globalTextStyle
        },
        xAxis: {
            labels: {
                style: globalTextStyle
            },
            title: {
                style: globalTextStyle
            }
        },
        yAxis: {
            labels: {
                style: globalTextStyle
            },
            title: {
                style: globalTextStyle
            }
        },
        legend: {
            itemStyle: globalTextStyle,
            title: {
                style: globalTextStyle
            }
        },
        tooltip: {
            style: globalTextStyle
        },
        plotOptions: {
            series: {
                dataLabels: {
                    style: globalTextStyle
                }
            }
        }
    });

    Highcharts.SVGRenderer.prototype.symbols.verticalLine = function (x, y, width, height) {
        const fixedHeight = 45;
        return ['M', x, y, 'L', x, y + fixedHeight];
    };
};
