import React from 'react'
import utils from '.';
import { useTranslation } from "react-i18next";
const t = utils.t


const TextForObjects = ({text = ""}) => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n }
  return (
    <div>{t(text,tOpts)}</div>
  )
}

export default TextForObjects