import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CommonChartsConfig } from './CommonChartsConfig';

const PieChart = ({ title, data, align, subtitle, custom, size }) => {
    CommonChartsConfig();
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 250,
            margin: custom ? [0, 0, 0, 0] : []
        },
        title: {
            text: title,
            align: align,
            style: {
                fontWeight: 'bold'
            }
        },
        subtitle: {
            text: subtitle,
            align: align
        },
        tooltip: {
            pointFormat: '{point.z}',
            style: {
                fontSize: '20px'
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                size: custom ? size : '80%',
                center: ['25%', '50%'],
                allowPointSelect: true,
                cursor: 'pointer',
                showInLegend: true,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}',
                    allowOverlap: custom ? true : false
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle',
        },
        series: [{
            colorByPoint: true,
            data: data
        }],
        colors: ['#112388', '#FF8A00', '#5C71EA', '#FFB966', '#236D3E', '#9ADFB3'],
        credits: {
            enabled: false
        }
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default PieChart;
