import React, { useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormHelperText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const Field = ({ column, field, fieldLabel, formik, otherProps, classes, onChange }) => {
    const handleChange = (event) => {
        formik.setFieldValue(field, event.target.checked);
    }

    useEffect(() => {
        if (formik.values[field] === undefined) {
            formik.setFieldValue(field, column.defaultValue);
        }
    }, [formik.values[field]]);
    
    return (
        formik.values[field] !== undefined && (
            <div key={field}>
                <FormControlLabel
                    control={
                        <Checkbox
                            {...otherProps}
                            name={field}
                            readOnly={column.readOnly === true}
                            checked={formik.values[field]}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                        />
                    }
                    label={fieldLabel}
                />
                <FormHelperText>{formik.touched[field] && formik.errors[field]}</FormHelperText>
            </div>
        )
    );
}

export default Field;
