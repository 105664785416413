import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
 Grid, Typography,
 Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { Replay, Close } from '@material-ui/icons';
import actions from '../redux/actions';
import { useTranslation } from "react-i18next";
import utils from '../utils';
const t = utils.t;

const HelpModal = () => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState();
  const [loading, setLoading] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const openModal = useSelector(state => state.appReducer.modal);
  const ratio = 16 / 9;
  let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
  const { t: translate, i18n } = useTranslation();
  const tOpts = { t: translate, i18n };

  const updateHeight = () => {
    let widthPercentage = document.getElementById('tutorial-iframe');
    if (widthPercentage) {
      widthPercentage = widthPercentage.offsetWidth;
      widthPercentage = widthPercentage / window.innerWidth;
    } else {
      widthPercentage = 0.9;
    }
    const calculatedHeight = window.innerWidth * widthPercentage * (1 / ratio);
    const maxHeight = window.innerHeight - 180; 
    setHeight(Math.min(calculatedHeight, maxHeight));
  };
  

useEffect(() => {
    if (openModal?.status) {
        setLoading(true);
        updateHeight();
    }
}, [openModal, zoom])

useEffect(() => {
    window.addEventListener("resize", updateHeight);

    return () => {
        window.removeEventListener("resize", updateHeight);
    }
}, [])

 function handleRedirect(url) {
        if (url)
            window.open(url, '_blank', 'noopener,noreferrer');
    }
    
    function resetIframe() {
        const iframe = document.getElementById('tutorial-iframe');
        iframe.src = openModal?.data?.url;
    }

  return (
    <div>
    {openModal?.status &&
            <Dialog fullWidth={true} maxWidth={"lg"} open={openModal.status} onClose={() => { dispatch({
                type:actions.OPEN_MODAL, modal:{ status: false, data:{},}
               }) }} >
                <DialogTitle className='pt-2 pb-0'>
                    <Grid container spacing={1}>
                        <Grid item className="" xs={11} sm={11} md={11} lg={11} >
                            <Typography variant="h7" component='div'> {t(openModal?.data?.title,tOpts) || ''}</Typography>
                            <Typography variant="caption" component='div'>{t(openModal?.data?.subTitle,tOpts) ||  <>&nbsp;</>}</Typography>
                        </Grid>
                        <Grid item className="text-right" xs={1} sm={1} md={1} lg={1} >
                            <Replay className='cursor_pointer mt-2 mr-2' onClick={resetIframe} />
                            <Close className='cursor_pointer mt-2' onClick={() => { dispatch({
               type:actions.OPEN_MODAL, modal:{ status: false, data:{}}
              }) }} />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                {loading && <div>{t("Loading...",tOpts)}</div>}
                    {openModal?.data?.url && <iframe
                        id='tutorial-iframe'
                        style={{ width: '100%', height: `${height}px` }}
                        title={openModal?.data?.title || ''}
                        src={openModal?.data?.url}
                        allowfullscreen
                        frameborder="0"
                        loading="lazy"
                        onLoad={() => setLoading(false)}
                    />}
                </DialogContent>
            </Dialog>}
    </div>
  )
}

export default HelpModal